import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Style } from "../../../utils/Style";
import orgApi from "../../../api/org_api";
import { addDays, format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import Multiselect from "multiselect-react-dropdown";
import { BsInfoCircleFill, BsLightbulb, BsUpload } from "react-icons/bs";
import { CloseModal } from "../../../component/Layout/CloseModal";
import Select from "react-select";

type UploadJdProps = {
  setLoading: Function;
  setPostId: Function;
  postId: string;
};

const UploadJd: React.FC<UploadJdProps> = ({
  setLoading,
  setPostId,
  postId,
}: UploadJdProps) => {
  const [jdTemplate, setJdTemplate] = React.useState([]);
  const [roleTemplate, setRoleTemplate] = React.useState([]);
  const [skillList, setSkillList] = React.useState([]);
  const [validity_error, setValidityError] = React.useState("");
  const [primary, setPrimary] = React.useState([] as any);
  const [secondary, setSecondary] = React.useState([] as any);
  const [selectedFile, setSelectedFile] = React.useState<File>();
  const [fileError, setfileError] = React.useState("");
  const [validity, setSelectedDate] = React.useState<Date>();
  const [secondary_error, serSecondaryError] = React.useState("");
  const [primary_error, serPrimaryError] = React.useState("");
  const [selectedJdTemplate, setSelectedTemplate] = React.useState("");
  const [file_content, setFileContanet] = React.useState("");
  const [templateJdattachment, setTemplateJdattachment] = React.useState("");
  const [showJdAttachment, setShowJdAttachment] = React.useState(true);
  const [client, setClient] = React.useState<string>("no");
  const [enableTemplate, setEnableTemplate] = React.useState(false);
  const [enableRoleTemplate, setEnableRoleTemplate] = React.useState(true);
  const year_options = Array.from({ length: 19 }, (_, index) => index + 2);
  const month_options = Array.from({ length: 10 }, (_, index) => index + 2);
  const [showTemplate, setShowTemplate] = React.useState(false);
  const [showRoleTemplate, setShowRoleTemplate] = React.useState(false);
  const [values, setValues] = React.useState({} as any);
  const [templateConfirmation, setTemplateConfirmation] = React.useState(false);
  const [isDisabled, setisDisabled] = React.useState(false);
  const [addBU, setAddBU] = React.useState(false);
  const [businessUnitList, setBusinessUnit] = React.useState([]);

  React.useEffect(() => {
    getBusinessUnit();
    setShowTemplate(true);
    setShowRoleTemplate(true);
    openIdForm();
  }, []);

  const getJdDetails = async (pId: any) => {
    const res1 = await orgApi.getSingleJdDetail(pId);
    var templatedata = res1.data.post;
    if (
      templatedata["client_name"] !== "" &&
      templatedata["client_name"] !== null
    ) {
      formik.setFieldValue("client_name", templatedata["client_name"]);
      formik.setFieldValue("client_email", templatedata["client_email"]);
      setClient("yes");
    }
    formik.setFieldValue("title", templatedata["job_title"]);
    formik.setFieldValue("ats_number", templatedata["ats_number"]);
    formik.setFieldValue(
      "business_unit",
      templatedata["business_unit_id"] === null
        ? ""
        : templatedata["business_unit_id"]
    );
    const tenDaysLater = new Date(templatedata["post_validity"]);
    setSelectedDate(tenDaysLater);
    var primarySkill = [];
    for (let i = 0; i < templatedata["template"]["t_p_skill"].length; i++) {
      primarySkill.push({
        id: templatedata["template"]["t_p_skill"][i]["skill_id"],
        name: templatedata["template"]["t_p_skill"][i]["skill"]["name"],
        year: 0,
        month: 0,
        weightage: templatedata["template"]["t_p_skill"][i]["weightage"],
        weightage_error: "",
      });
    }
    setPrimary(primarySkill);
    formik.setFieldValue("primary_skill", primarySkill);
    var secondarySkill = [];
    for (let i = 0; i < templatedata["template"]["t_s_skill"].length; i++) {
      secondarySkill.push({
        id: templatedata["template"]["t_s_skill"][i]["skill_id"],
        name: templatedata["template"]["t_s_skill"][i]["skill"]["name"],
        year: 0,
        month: 0,
        weightage: templatedata["template"]["t_s_skill"][i]["weightage"],
        weightage_error: "",
      });
    }
    setSecondary(secondarySkill);
    formik.setFieldValue("secondary_skill", secondarySkill);
    formik.setFieldValue("opening", templatedata["no_of_candidate"]);
    formik.setFieldValue(
      "job_description",
      templatedata["template"]["description"]
    );
    formik.setFieldValue("remarks", templatedata["remarks"]);
    setFileContanet(templatedata["template"]["description"]);
    setTemplateJdattachment(templatedata["template"]["attachment"]);
    templatedata["template"]["description"] && setShowJdAttachment(false);
    setLoading(false);
  };
  React.useEffect(() => {
    if (postId === null || postId === undefined || postId === "") {
      templateSelection();
    }
  }, [enableTemplate === true]);
  const getSkills = async () => {
    setLoading(true);
    const data = { search: "" };
    const res = await orgApi.skills(data);
    setSkillList(res.data.skills);
    setLoading(false);
  };
  const openIdForm = async () => {
    formik.resetForm();
    await getSkills();
    const tenDaysLater = addDays(new Date(), 10);
    setSelectedDate(tenDaysLater);
    setPrimary([]);
    setSecondary([]);
    setLoading(false);
    if (postId !== null && postId !== undefined && postId !== "") {
      setShowTemplate(false);
      setShowRoleTemplate(false);
      setEnableRoleTemplate(false);
      getJdDetails(postId);
    } else {
      await getRoles();
    }
  };
  const getRoles = async () => {
    setLoading(true);
    const res = await orgApi.roleTemplate();
    setLoading(false);
    if (res.data.success) {
      setRoleTemplate(res.data.role_tem);
    } else {
      setRoleTemplate([]);
    }
  };
  const setTemplateData = async (templateId: any) => {
    if (templateId !== "") {
      resetJd();
      setLoading(true);
      const res1 = await orgApi.templateDetail(templateId);
      var templatedata = res1.data.tem[0];
      formik.setFieldValue("title", templatedata["name"]);
      var primarySkill = [];
      for (let i = 0; i < templatedata["t_p_skill"].length; i++) {
        primarySkill.push({
          id: templatedata["t_p_skill"][i]["skill_id"],
          name: templatedata["t_p_skill"][i]["skill"]["name"],
          year: 0,
          month: 0,
          weightage: templatedata["t_p_skill"][i]["weightage"],
          weightage_error: "",
        });
      }
      setPrimary(primarySkill);
      formik.setFieldValue("primary_skill", primarySkill);
      var secondarySkill = [];
      for (let i = 0; i < templatedata["t_s_skill"].length; i++) {
        secondarySkill.push({
          id: templatedata["t_s_skill"][i]["skill_id"],
          name: templatedata["t_s_skill"][i]["skill"]["name"],
          year: 0,
          month: 0,
          weightage: templatedata["t_s_skill"][i]["weightage"],
          weightage_error: "",
        });
      }
      setSecondary(secondarySkill);
      formik.setFieldValue("secondary_skill", secondarySkill);
      formik.setFieldValue("job_description", templatedata["description"]);
      setFileContanet(templatedata["description"]);
      setTemplateJdattachment(templatedata["attachment"]);
      templatedata["description"] && setShowJdAttachment(false);
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      title: "",
      exp_year: 6,
      exp_month: 0,
      opening: 1,
      job_description: "",
      remarks: "",
      client_name: "",
      client_email: "",
      primary_skill: [] as any,
      secondary_skill: [] as any,
      business_unit: "",
      ats_number: "",
      new_business_unit: "",
      additional_skill: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      exp_year: Yup.number().required("Year is required"),
      exp_month: Yup.number().required("Month is required"),
      opening: Yup.number().required("Number of Opening is required"),
      job_description: Yup.string().required("Job Description is required"),
      remarks: Yup.string(),
      primary_skill: Yup.array().required("Select Required Technical Skills"),
      secondary_skill: Yup.array().required(
        "Select Preferred Technical Skills"
      ),
      client_name:
        client === "yes"
          ? Yup.string().required("Client name is required")
          : Yup.string(),
      client_email:
        client === "yes"
          ? Yup.string()
              .required("Registered email is required")
              .email("Invalid email")
          : Yup.string(),
      business_unit: Yup.string(),
      ats_number: Yup.string()
        .min(7, "ATS Number should be 7 digit")
        .max(7, "ATS Number should be 7 digit"),
      new_business_unit: Yup.string(),
      additional_skill: Yup.string().max(
        25,
        "Skill name must be maximum 25 characters"
      ),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      setValues(values);
      if (selectedJdTemplate) {
        setTemplateConfirmation(true);
      } else {
        submitPost(values);
      }
    },
  });

  const submitPost = async (values: any) => {
    setTemplateConfirmation(false);
    if (validity) {
      setValidityError("");
    } else {
      setValidityError("Please select the validity");
      return;
    }

    if (primary.length === 0) {
      serPrimaryError("Select Required Technical Skills");
      return;
    } else {
      serPrimaryError("");
    }
    if (secondary.length === 0) {
      serSecondaryError("Select Preferred Technical Skills");
      return;
    } else {
      serSecondaryError("");
    }
    // if (showJdAttachment && selectedFile === undefined) {
    //   setfileError("Please upload JD");
    //   return;
    // }

    var flag = 0;
    var primarySkill = [] as any;
    for (let i = 0; i < primary.length; i++) {
      if (primary[i]["weightage"] === 0) {
        primary[i]["weightage_error"] = "Please enter the weightage";
        setPrimary(primary);
      }
      if (primary[i]["weightage_error"] !== "") {
        flag = 1;
        break;
      }
      primarySkill.push({
        skill_id: primary[i]["id"],
        year: primary[i]["year"],
        month: primary[i]["month"],
        weightage: Number(primary[i]["weightage"]),
      });
    }
    if (flag === 1) {
      return;
    }
    var secondarySkill = [] as any;
    for (let i = 0; i < secondary.length; i++) {
      if (secondary[i]["weightage"] === 0) {
        secondary[i]["weightage_error"] = "Please enter the weightage";
        setSecondary(secondary);
      }
      if (secondary[i]["weightage_error"] !== "") {
        flag = 1;
        break;
      }
      secondarySkill.push({
        skill_id: secondary[i]["id"],
        year: secondary[i]["year"],
        month: secondary[i]["month"],
        weightage: Number(secondary[i]["weightage"]),
      });
    }
    if (flag === 1) {
      return;
    }
    var totalWeightage = 0;
    for (let i = 0; i < primary.length; i++) {
      totalWeightage = totalWeightage + Number(primary[i]["weightage"]);
    }
    for (let i = 0; i < secondary.length; i++) {
      totalWeightage = totalWeightage + Number(secondary[i]["weightage"]);
    }
    if (totalWeightage !== 100) {
      toast("Sum of skills weightage should be 100%");
      return;
    }
    var userInfo = JSON.parse(
      localStorage.getItem("user__details")?.toString()!
    );
    var orgId = userInfo.id;
    var userId = localStorage.getItem("user_id")?.toString();

    const formattedDate = format(validity, "yyyy-MM-dd");
    var reqData: any = {
      job_title: values.title,
      post_validity: formattedDate,
      year_of_experience: values.exp_year,
      month_of_experience: values.exp_month,
      name: selectedJdTemplate !== "" ? "" : values.title,
      description: values.job_description,
      primary: selectedJdTemplate !== "" ? "" : primarySkill,
      secondary: selectedJdTemplate !== "" ? "" : secondarySkill,
      job_comments: values.job_description,
      remarks: values.remarks,
      no_of_candidate: values.opening,
      user_id: userId,
      client_name: values.client_name,
      client_email: values.client_email,
      ats_number: values.ats_number,
      business_unit_id:
        values.business_unit === "" ? null : values.business_unit,
    };
    selectedJdTemplate && (reqData.template_id = selectedJdTemplate);
    setisDisabled(true);
    setLoading(true);
    if (showTemplate) {
      const res = await orgApi.addPost(orgId, reqData);
      if (res.data.success) {
        var postId1 = res.data.post.id;
        localStorage.setItem("createPostId", postId1);
        if (
          showJdAttachment &&
          selectedFile !== undefined &&
          selectedFile !== null
        ) {
          var templateId = res.data.post.template_id;
          const formData = new FormData();
          formData.append("jd", selectedFile!);
          const res1 = await orgApi.uploadJd(templateId, formData);
          setisDisabled(false);
          setLoading(false);
          if (res1.data.success) {
            toast("JD created successfully");
            setSelectedFile(undefined);
            setPrimary([]);
            setSecondary([]);
            setTemplateJdattachment("");
            setShowJdAttachment(true);
            setPostId(postId1);
          }
        } else {
          setisDisabled(false);
          setLoading(false);
          toast("JD created successfully");
          setSelectedFile(undefined);
          setPrimary([]);
          setSecondary([]);
          setTemplateJdattachment("");
          setShowJdAttachment(true);
          setPostId(postId1);
        }
      }
    } else {
      const res = await orgApi.editPost(
        localStorage.getItem("createPostId")!,
        reqData
      );
      if (res.data.success) {
        var postId1 = res.data.post.id;
        if (showJdAttachment) {
          var templateId = res.data.post.template_id;
          const formData = new FormData();
          formData.append("jd", selectedFile!);
          const res1 = await orgApi.uploadJd(templateId, formData);
          setLoading(false);
          if (res1.data.success) {
            toast("JD edited successfully");
            setSelectedFile(undefined);
            setPrimary([]);
            setSecondary([]);
            setTemplateJdattachment("");
            setShowJdAttachment(true);
            setTimeout(() => {
              setisDisabled(false);
              setPostId(postId1);
            }, 1000);
          }
        } else {
          setLoading(false);
          toast("JD edited successfully");
          setSelectedFile(undefined);
          setPrimary([]);
          setSecondary([]);
          setTemplateJdattachment("");
          setShowJdAttachment(true);
          setTimeout(() => {
            setisDisabled(false);
            setPostId(postId1);
          }, 1000);
        }
      }
    }
  };
  const handleDateChange = (date: any) => {
    setValidityError("");
    setSelectedDate(date);
  };
  const resetJd = () => {
    formik.resetForm();
    setSelectedFile(undefined);
    setPrimary([]);
    setSecondary([]);
    const tenDaysLater = addDays(new Date(), 10);
    setSelectedDate(tenDaysLater);
  };
  const handleImageChange = async (event: any) => {
    setfileError("");
    const file = event.target.files[0];
    if (file) {
      if (
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/pdf"
      ) {
        if (file.size <= 3 * 1024 * 1024) {
          setSelectedFile(file);
          const formData = new FormData();
          formData.append("file", file);
          const res = await orgApi.fileReader(formData);
          formik.values.job_description = res.data.str;
          setFileContanet(res.data.str);
          setfileError("");
        } else {
          setfileError("Please upload the file with size <3 MB");
          setSelectedFile(undefined);
        }
      } else {
        setfileError("Please upload only pdf or docx format");
        setSelectedFile(undefined);
      }
    }
  };
  const handleChangeJdTemplate = (event: any) => {
    resetJd();
    setSelectedTemplate(event.target.value);
    setTemplateData(event.target.value);
  };
  const handleChangeRoleTemplate = async (event: any) => {
    setLoading(true);
    resetJd();
    const res = await orgApi.roleTemplateSkill(event);
    setLoading(false);
    if (res.data.success) {
      const templatedata = res.data.role_tem[0];
      formik.setFieldValue("title", templatedata["name"]);
      const t_len =
        templatedata["r_p_skill"].length + templatedata["r_s_skill"].length;
      let wtg = 0;
      wtg = 100 / t_len;
      let add = 0;
      if (Math.floor(wtg) * t_len < 100) {
        add = 100 - Math.floor(wtg) * t_len;
      }
      let primarySkill = [];
      for (let i = 0; i < templatedata["r_p_skill"].length; i++) {
        primarySkill.push({
          id: templatedata["r_p_skill"][i]["skill_id"],
          name: templatedata["r_p_skill"][i]["skill"]["name"],
          year: 0,
          month: 0,
          weightage: i === 0 ? Math.floor(wtg) + add : Math.floor(wtg),
          weightage_error: "",
        });
      }
      setPrimary(primarySkill);
      formik.setFieldValue("primary_skill", primarySkill);
      let secondarySkill = [];
      for (let i = 0; i < templatedata["r_s_skill"].length; i++) {
        if(templatedata["r_p_skill"].length == 0) {
          secondarySkill.push({
            id: templatedata["r_s_skill"][i]["skill_id"],
            name: templatedata["r_s_skill"][i]["skill"]["name"],
            year: 0,
            month: 0,
            weightage: i === 0 ? Math.floor(wtg) + add : Math.floor(wtg),
            weightage_error: "",
          });
        } else {
          secondarySkill.push({
            id: templatedata["r_s_skill"][i]["skill_id"],
            name: templatedata["r_s_skill"][i]["skill"]["name"],
            year: 0,
            month: 0,
            weightage: Math.floor(wtg),
            weightage_error: "",
          });
        }
        
      }
      setSecondary(secondarySkill);
      formik.setFieldValue("secondary_skill", secondarySkill);
    }
  };
  const templateSelection = async () => {
    if (!enableTemplate) {
      setSelectedTemplate("");
      setTemplateData("");
      resetJd();
    } else {
      setLoading(true);
      var userInfo = JSON.parse(
        localStorage.getItem("user__details")?.toString()!
      );
      var orgId =
        userInfo.master_id !== null ? userInfo.master_id : userInfo.id;
      const data = { search: "", org_id: orgId };
      const res = await orgApi.jdTemplate(orgId, data);
      setLoading(false);
      if (res.data.success) {
        setJdTemplate(res.data.tem);
      } else {
        toast("Template not found");
        setJdTemplate([]);
        setSelectedTemplate("");
        setTemplateData("");
        resetJd();
        setTimeout(() => {
          setEnableTemplate(false);
        }, 1500);
      }
    }
  };
  const getDate = (dateStr: any) => {
    const formattedDate = format(dateStr, "dd MMM yyyy");
    return formattedDate;
  };
  const getBusinessUnit = async () => {
    var userInfo = JSON.parse(
      localStorage.getItem("user__details")?.toString()!
    );
    var orgId = userInfo.master_id !== null ? userInfo.master_id : userInfo.id;
    const res = await orgApi.getBusinessUnit(orgId);
    res.data.success
      ? setBusinessUnit(res.data.business_unit)
      : setBusinessUnit([]);
  };
  const addNewBu = async () => {
    if (formik.getFieldProps("new_business_unit").value === "") {
      return;
    }
    var userInfo = JSON.parse(
      localStorage.getItem("user__details")?.toString()!
    );
    var orgId = userInfo.master_id !== null ? userInfo.master_id : userInfo.id;
    const reqData = {
      name: formik.getFieldProps("new_business_unit").value,
      org_id: orgId,
    };
    const res = await orgApi.addBusinessUnit(orgId, reqData);
    if (res.data.success) {
      toast(res.data.msg);
      getBusinessUnit();
      setAddBU(false);
      formik.setFieldValue("new_business_unit", "");
      formik.setFieldValue("business_unit", res.data.business_unit.id);
    } else {
      toast(res.data.msg);
    }
  };
  const addNewSkill = async () => {
    const reqData = {
      name: formik.getFieldProps("additional_skill").value.trim(),
    };
    const res = await orgApi.addAdditionalSkill(reqData);
    if (res.data.success) {
      toast(res.data.msg);
      getSkills();
      formik.setFieldValue("additional_skill", "");
    } else {
      toast(res.data.msg);
    }
  };
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      {templateConfirmation && (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
                <div className="sm:flex">
                  <div className="mt-1 text-center sm:text-left w-full">
                    <div className=" w-full mt-4 max-w-lg text-xs text-gray-500 font-normal">
                      JD will be created using this template
                    </div>

                    <div className=" gap-2 mt-3 sm:flex md:flex lg:flex flex-row-reverse">
                      <button
                        className="mt-2 py-1 px-2.5 text-white button-primary outline-none text-sm"
                        onClick={() => submitPost(values)}
                      >
                        Proceed
                      </button>
                      <button
                        className=" mt-2 py-1 px-2.5 text-gray-800 outline-none border text-sm"
                        onClick={() => setTemplateConfirmation(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <form onSubmit={formik.handleSubmit}>
        <div
          className="p-1 mb-4 flex items-center bg-blue-50 gap-1"
          role="alert"
        >
          <span className="p-1 bg-primary rounded-md">
            <BsLightbulb color="white" size={12} />
          </span>
          <span className="text-xs text-blue-800 font-bold">Note:</span>
          <span className="text-xs text-blue-800 font-light">
            Once you post, relevant interviewers will be notified
          </span>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-2 ">
          <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
            <label className={Style.label + " text-primary"}>
              Creating on behalf of client
            </label>
            <div className="gap-2 sm:flex md:flex lg:flex flex-row">
              <button
                type="button"
                className={
                  client === "yes"
                    ? "mt-2 py-1.5 px-3.5 text-primary outline-none border-primary text-xs rounded-md"
                    : "mt-2 py-1.5 px-3.5 text-gray-500 outline-none border text-xs rounded-md"
                }
                onClick={() => setClient("yes")}
              >
                Yes
              </button>
              <button
                type="button"
                className={
                  client === "no"
                    ? "mt-2 py-1.5 px-3.5 text-primary outline-none border-primary text-xs rounded-md"
                    : "mt-2 py-1.5 px-3.5 text-gray-500 outline-none border text-xs rounded-md"
                }
                onClick={() => setClient("no")}
              >
                No
              </button>
            </div>
          </div>
          <div className="flex items-center gap-2 justify-end">
            {showRoleTemplate && (
              <div
                className={
                  enableTemplate
                    ? "sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5 flex flex-row items-center gap-3 justify-end disabled-div"
                    : "sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5 flex flex-row items-center gap-3 justify-end"
                }
              >
                <label className={Style.label + " text-primary"}>
                  Role Template
                </label>
                <div className="gap-2 flex flex-row">
                  <h1 className=" col-span-1 text-xs font-normal text-gray-500 ">
                    <div
                      className="w-12 h-6 flex items-center justify-between bg-gray-200 rounded-full p-1 cursor-pointer"
                      onClick={() => {
                        setEnableRoleTemplate(!enableRoleTemplate);
                      }}
                    >
                      <p
                        className={
                          "text-gray-700 font-semibold" +
                          (!enableRoleTemplate
                            ? " transform translate-x-7  "
                            : " ")
                        }
                        style={{ fontSize: "8px" }}
                      >
                        {enableRoleTemplate ? "Yes" : "No"}
                      </p>
                      <div
                        className={
                          "h-5 w-5 rounded-full shadow-md transform duration-300 ease-in-out" +
                          (enableRoleTemplate
                            ? " bg-blue-500 "
                            : " bg-gray-500 transform -translate-x-5")
                        }
                      ></div>
                    </div>
                  </h1>
                </div>
              </div>
            )}
            {showTemplate && (
              <div
                className={
                  enableRoleTemplate
                    ? "sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5 flex flex-row items-center gap-3 justify-end disabled-div"
                    : "sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5 flex flex-row items-center gap-3 justify-end"
                }
              >
                <label className={Style.label + " text-primary"}>
                  Template
                </label>
                <div className="gap-2 flex flex-row">
                  <h1 className=" col-span-1 text-xs font-normal text-gray-500 ">
                    <div
                      className="w-12 h-6 flex items-center justify-between bg-gray-200 rounded-full p-1 cursor-pointer"
                      onClick={() => {
                        // templateSelection(enableTemplate);
                        setEnableTemplate(!enableTemplate);
                      }}
                    >
                      <p
                        className={
                          "text-gray-700 font-semibold" +
                          (!enableTemplate ? " transform translate-x-7  " : " ")
                        }
                        style={{ fontSize: "8px" }}
                      >
                        {enableTemplate ? "Yes" : "No"}
                      </p>
                      <div
                        className={
                          "h-5 w-5 rounded-full shadow-md transform duration-300 ease-in-out" +
                          (enableTemplate
                            ? " bg-blue-500 "
                            : " bg-gray-500 transform -translate-x-5")
                        }
                      ></div>
                    </div>
                  </h1>
                </div>
              </div>
            )}
          </div>
          {enableTemplate && (
            <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
              <label className={Style.label}>
                Select template<span className="error"> *</span>
              </label>
              <select
                onChange={(e) => handleChangeJdTemplate(e)}
                className="w-full border text-xs text-gray-700 border-gray-300  py-2 px-3 focus:outline-none focus:border-blue-500"
              >
                <option value="">Select Template</option>
                {jdTemplate.map((val) => (
                  <option value={val["id"]}>
                    {val["name"]} - {getDate(val["created_on"])}
                  </option>
                ))}
              </select>
            </div>
          )}
          {enableRoleTemplate && (
            <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
              <label className={Style.label}>Select Role</label>
              <Select
                placeholder="Select Role"
                options={roleTemplate}
                getOptionLabel={(options: any) => {
                  return options["name"];
                }}
                getOptionValue={(options: any) => {
                  return options["id"];
                }}
                id="role"
                name="role"
                onChange={(e: any) => {
                  handleChangeRoleTemplate(e.id);
                }}
                className="w-full text-xs text-gray-700 border-gray-300 focus:outline-none focus:border-blue-500"
              />
            </div>
          )}
          {(enableTemplate || enableRoleTemplate) && (
            <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5"></div>
          )}
          {client === "yes" ? (
            <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
              <label className={Style.label}>
                Client Name<span className="error"> *</span>
              </label>
              <input
                placeholder="Client Name"
                type="text"
                id="client_name"
                name="client_name"
                value={formik.values.client_name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className={
                  formik.touched.client_name && formik.errors.client_name
                    ? Style.iError
                    : Style.iInput
                }
              ></input>
              {formik.touched.client_name && formik.errors.client_name && (
                <div className="error">{formik.errors.client_name}</div>
              )}
            </div>
          ) : (
            <></>
          )}
          {client === "yes" ? (
            <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
              <label className={Style.label}>
                Registered Mail id<span className="error"> *</span>{" "}
              </label>
              <input
                placeholder="Registered Email*"
                type="text"
                id="client_email "
                name="client_email"
                value={formik.values.client_email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className={
                  formik.touched.client_email && formik.errors.client_email
                    ? Style.iError
                    : Style.iInput
                }
              ></input>
              {formik.touched.client_email && formik.errors.client_email && (
                <div className="error">{formik.errors.client_email}</div>
              )}
            </div>
          ) : (
            <></>
          )}
          <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
            <label className={Style.label}>Business Unit</label>
            <div className="gap-2 sm:flex md:flex lg:flex flex-row grid grid-cols-4 gap-1">
              <select
                id="business_unit"
                name="business_unit"
                value={formik.values.business_unit}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className={
                  formik.touched.business_unit && formik.errors.business_unit
                    ? Style.iError + " col-span-3"
                    : Style.iInput + " col-span-3"
                }
              >
                <option value="" disabled>
                  Business Unit
                </option>
                {businessUnitList.map((e: any) => (
                  <option key={e["id"]} value={e.id}>
                    {e.name}
                  </option>
                ))}
              </select>
              {!addBU && (
                <button
                  onClick={() => setAddBU(true)}
                  className="col-span-1 bg-primary rounded px-2 py-0.5 text-xs text-white"
                  type="button"
                >
                  Add
                </button>
              )}
            </div>
            {addBU && (
              <div className="gap-2 sm:flex md:flex lg:flex flex-row grid grid-cols-4 gap-1 mt-3">
                <input
                  placeholder="Enter Business Unit"
                  type="text"
                  id="new_business_unit"
                  name="new_business_unit"
                  value={formik.values.new_business_unit}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  className={
                    formik.touched.new_business_unit &&
                    formik.errors.new_business_unit
                      ? Style.iError
                      : Style.iInput
                  }
                ></input>
                <button
                  onClick={() => addNewBu()}
                  className="col-span-1 bg-primary rounded px-2 py-0.5 text-xs text-white"
                  type="button"
                >
                  Add
                </button>
                <button
                  onClick={() => {
                    setAddBU(false);
                    formik.setFieldValue("new_business_unit", "");
                  }}
                  className="col-span-1 bg-white border rounded px-2 py-0.5 text-xs text-gray-600"
                  type="button"
                >
                  <CloseModal />
                </button>
              </div>
            )}
          </div>
          <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
            <label className={Style.label}>ATS Number</label>
            <input
              placeholder="ATS Number"
              type="text"
              id="ats_number"
              name="ats_number"
              value={formik.values.ats_number}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              className={
                formik.touched.ats_number && formik.errors.ats_number
                  ? Style.iError
                  : Style.iInput
              }
            ></input>
            {formik.touched.ats_number && formik.errors.ats_number && (
              <div className="error">{formik.errors.ats_number}</div>
            )}
          </div>
          <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
            <label className={Style.label}>
              Title<span className="error"> *</span>
            </label>
            <input
              placeholder="Title"
              type="text"
              id="title"
              name="title"
              value={formik.values.title}
              disabled={selectedJdTemplate !== "" ? true : false}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              className={
                formik.touched.title && formik.errors.title
                  ? Style.iError
                  : Style.iInput
              }
            ></input>
            {formik.touched.title && formik.errors.title && (
              <div className="error">{formik.errors.title}</div>
            )}
          </div>
          <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
            <label className={Style.label}>
              Validity<span className="error"> *</span>
            </label>
            <br />
            <DatePicker
              selected={validity}
              onChange={handleDateChange}
              onBlur={formik.handleBlur}
              dateFormat="dd MMM, yyyy"
              isClearable
              placeholderText="Select validity"
              className={Style.iInput}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
            {validity_error && <div className="error">{validity_error}</div>}
          </div>

          <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
            <label className={Style.label}>
              Overall Experience<span className="error"> *</span>
            </label>
            <div className="gap-2 sm:flex md:flex lg:flex flex-row">
              <select
                id="exp_year"
                name="exp_year"
                value={formik.values.exp_year}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className={
                  formik.touched.exp_year && formik.errors.exp_year
                    ? Style.iError
                    : Style.iInput
                }
              >
                <option value="" disabled>
                  Select Year *
                </option>
                <option value="1">1 year</option>
                {year_options.map((number) => (
                  <option key={number} value={number}>
                    {number} years
                  </option>
                ))}
              </select>
              <select
                id="exp_month"
                name="exp_month"
                value={formik.values.exp_month}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className={
                  formik.touched.exp_month && formik.errors.exp_month
                    ? Style.iError
                    : Style.iInput
                }
              >
                <option value="" disabled>
                  Select Month *
                </option>
                <option value="0">0 month</option>
                <option value="1">1 month</option>
                {month_options.map((number) => (
                  <option key={number} value={number}>
                    {number} months
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
            <label className={Style.label}>
              Number of Opening<span className="error"> *</span>
            </label>
            <input
              placeholder="Number of Opening"
              type="number"
              min={1}
              id="opening"
              name="opening"
              value={formik.values.opening}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              className={
                formik.touched.opening && formik.errors.opening
                  ? Style.iError
                  : Style.iInput
              }
            ></input>
            {formik.touched.opening && formik.errors.opening && (
              <div className="error">{formik.errors.opening}</div>
            )}
          </div>
          <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
            <label className={Style.label}>
              Required Technical Skills<span className="error"> *</span>
            </label>
            <Multiselect
              options={skillList}
              selectedValues={primary}
              disable={selectedJdTemplate ? true : false}
              onSelect={(selectedList, _) => {
                var data: any = [];
                var wtg = 0;
                wtg = 100 / (selectedList.length + secondary.length);
                var add = 0;
                if (
                  Math.floor(wtg) * (selectedList.length + secondary.length) <
                  100
                ) {
                  add =
                    100 -
                    Math.floor(wtg) * (selectedList.length + secondary.length);
                }
                selectedList.map((e: any, index: number) => {
                  var data1 = primary.filter(
                    (item: any) => item.id === e["id"]
                  );
                  if (data1.length === 0) {
                    return data.push({
                      id: e["id"],
                      name: e["name"],
                      year: 0,
                      month: 0,
                      weightage:
                        index === 0 ? Math.floor(wtg) + add : Math.floor(wtg),
                      weightage_error: "",
                    });
                  } else {
                    return data.push({
                      id: e["id"],
                      name: e["name"],
                      year: 0,
                      month: 0,
                      weightage:
                        index === 0 ? Math.floor(wtg) + add : Math.floor(wtg),
                      // weightage: data1[0]["weightage"],
                      weightage_error: "",
                    });
                  }
                });
                setPrimary(data);
                setSecondary((prevItems: any) =>
                  prevItems.map((item: any) => ({
                    ...item,
                    weightage: Math.floor(wtg),
                  }))
                );
                formik.values.primary_skill = data;
                serPrimaryError("");
              }}
              onRemove={(selectedList, _) => {
                var data: any = [];
                var wtg = 0;
                wtg = 100 / (selectedList.length + secondary.length);
                var add = 0;
                if (
                  Math.floor(wtg) * (selectedList.length + secondary.length) <
                  100
                ) {
                  add =
                    100 -
                    Math.floor(wtg) * (selectedList.length + secondary.length);
                }
                selectedList.map((e: any, index: number) => {
                  var data1 = primary.filter(
                    (item: any) => item.id === e["id"]
                  );
                  if (data1.length === 0) {
                    return data.push({
                      id: e["id"],
                      name: e["name"],
                      year: 0,
                      month: 0,
                      weightage:
                        index === 0 ? Math.floor(wtg) + add : Math.floor(wtg),
                      weightage_error: "",
                    });
                  } else {
                    return data.push({
                      id: e["id"],
                      name: e["name"],
                      year: 0,
                      month: 0,
                      weightage:
                        index === 0 ? Math.floor(wtg) + add : Math.floor(wtg),
                      // weightage: data1[0]["weightage"],
                      weightage_error: "",
                    });
                  }
                });
                serPrimaryError("");
                setPrimary(data);
                setSecondary((prevItems: any) =>
                  prevItems.map((item: any) => ({
                    ...item,
                    weightage: Math.floor(wtg),
                  }))
                );
                formik.values.primary_skill = data;
              }}
              displayValue="name"
            ></Multiselect>
            {primary_error && (
              <div className="error">
                Please select the Required Technical skills
              </div>
            )}
            <div>
              {primary.length > 0 && (
                <p className="my-2 text-xs font-semibold text-gray-800">
                  Weightage<span className="error"> *</span>
                </p>
              )}
              {primary.map((val: any) => (
                <div className="gap-2 flex flex-row justify-between grid grid-cols-2">
                  <label className="text-xs font-normal text-gray-600 py-2 col-span-1">
                    {val["name"]}
                  </label>
                  <div className="flex flex-col col-span-1">
                    <div className="flex flex-row items-center">
                      <input
                        placeholder="Weightage (%)"
                        type="number"
                        step={5}
                        id={val["name"]}
                        name={val["name"]}
                        // defaultValue={val["weightage"]}
                        value={val["weightage"]}
                        onChange={(v: any) => {
                          var err =
                            v.target.value > 100 || v.target.value < 0
                              ? "Weightage must be 0 to 100"
                              : "";
                          val["weightage_error"] = err;

                          val["weightage"] = v.target.value;
                          setPrimary((prevItems: any) =>
                            prevItems.map((item: any) =>
                              item.name === val["name"]
                                ? { ...item, weightage_error: err }
                                : item
                            )
                          );
                          setPrimary((prevItems: any) =>
                            prevItems.map((item: any) =>
                              item.name === val["name"]
                                ? { ...item, weightage: v.target.value }
                                : item
                            )
                          );
                        }}
                        className="w-1/3 border border-gray-300  py-2 px-3 focus:outline-none focus:border-blue-500 rounded m-1"
                      ></input>
                      %
                    </div>
                    {val["weightage_error"] && (
                      <div className="error">{val["weightage_error"]}</div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
            <label className={Style.label}>
              Preferred Technical Skills<span className="error"> *</span>
            </label>
            <Multiselect
              options={skillList}
              selectedValues={secondary}
              disable={selectedJdTemplate ? true : false}
              onSelect={(selectedList, _) => {
                let data: any = [];
                var wtg = 0;
                wtg = 100 / (selectedList.length + primary.length);
                var add = 0;
                if (
                  Math.floor(wtg) * (selectedList.length + primary.length) <
                  100
                ) {
                  add =
                    100 -
                    Math.floor(wtg) * (selectedList.length + primary.length);
                }
                selectedList.map((e: any, index: number) => {
                  var data1 = secondary.filter(
                    (item: any) => item.id === e["id"]
                  );
                  if (data1.length === 0) {
                    return data.push({
                      id: e["id"],
                      name: e["name"],
                      year: 0,
                      month: 0,
                      weightage:
                        index === 0 ? Math.floor(wtg) + add : Math.floor(wtg),
                      weightage_error: "",
                    });
                  } else {
                    return data.push({
                      id: e["id"],
                      name: e["name"],
                      year: 0,
                      month: 0,
                      weightage:
                        index === 0 ? Math.floor(wtg) + add : Math.floor(wtg),
                      // weightage: data1[0]["weightage"],
                      weightage_error: "",
                    });
                  }
                });
                serSecondaryError("");
                setSecondary(data);
                setPrimary((prevItems: any) =>
                  prevItems.map((item: any) => ({
                    ...item,
                    weightage: Math.floor(wtg),
                  }))
                );
                formik.values.secondary_skill = data;
              }}
              onRemove={(selectedList, _) => {
                let data: any = [];
                var wtg = 0;
                wtg = 100 / (selectedList.length + primary.length);
                var add = 0;
                if (
                  Math.floor(wtg) * (selectedList.length + primary.length) <
                  100
                ) {
                  add =
                    100 -
                    Math.floor(wtg) * (selectedList.length + primary.length);
                }
                selectedList.map((e: any, index: number) => {
                  var data1 = secondary.filter(
                    (item: any) => item.id === e["id"]
                  );
                  if (data1.length === 0) {
                    return data.push({
                      id: e["id"],
                      name: e["name"],
                      year: 0,
                      month: 0,
                      weightage:
                        index === 0 ? Math.floor(wtg) + add : Math.floor(wtg),
                      weightage_error: "",
                    });
                  } else {
                    return data.push({
                      id: e["id"],
                      name: e["name"],
                      year: 0,
                      month: 0,
                      weightage:
                        index === 0 ? Math.floor(wtg) + add : Math.floor(wtg),
                      // weightage: data1[0]["weightage"],
                      weightage_error: "",
                    });
                  }
                });
                serSecondaryError("");
                setSecondary(data);
                setPrimary((prevItems: any) =>
                  prevItems.map((item: any) => ({
                    ...item,
                    weightage: Math.floor(wtg),
                  }))
                );
                formik.values.secondary_skill = data;
              }}
              displayValue="name"
            ></Multiselect>
            {secondary_error && (
              <div className="error">
                Please select the Preferred Technical skills
              </div>
            )}
            <div>
              {secondary.length > 0 && (
                <p className="my-2 text-xs font-semibold text-gray-800">
                  Weightage<span className="error"> *</span>
                </p>
              )}

              {secondary.map((val: any) => (
                <div className="gap-2 flex flex-row justify-between grid grid-cols-2">
                  <label className="text-xs font-normal text-gray-600 py-2 col-span-1">
                    {val["name"]}
                  </label>
                  <div className="flex flex-col col-span-1">
                    <div className="flex flex-row items-center">
                      <input
                        placeholder="Weightage (%)"
                        type="number"
                        step={5}
                        id={val["name"]}
                        name={val["name"]}
                        // defaultValue={val["weightage"]}
                        value={val["weightage"]}
                        onChange={(v: any) => {
                          var err =
                            v.target.value > 100 || v.target.value < 0
                              ? "Weightage must be 0 to 100"
                              : "";
                          val["weightage_error"] = err;

                          val["weightage"] = v.target.value;
                          setSecondary((prevItems: any) =>
                            prevItems.map((item: any) =>
                              item.name === val["name"]
                                ? { ...item, weightage_error: err }
                                : item
                            )
                          );
                          setSecondary((prevItems: any) =>
                            prevItems.map((item: any) =>
                              item.name === val["name"]
                                ? { ...item, weightage: v.target.value }
                                : item
                            )
                          );
                        }}
                        className="w-1/3 border border-gray-300  py-2 px-3 focus:outline-none focus:border-blue-500 rounded m-1"
                      ></input>
                      %
                    </div>
                    {val["weightage_error"] && (
                      <div className="error">{val["weightage_error"]}</div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5 col-span-2">
            <div className="gap-2 sm:flex md:flex lg:flex flex-row grid grid-cols-4 gap-1">
              <input
                placeholder="Additional Skill"
                type="text"
                id="additional_skill"
                name="additional_skill"
                value={formik.values.additional_skill}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className={
                  formik.touched.additional_skill &&
                  formik.errors.additional_skill
                    ? Style.iError
                    : Style.iInput
                }
              ></input>
              <button
                className="col-span-1 bg-primary rounded px-2 py-0.5 text-xs text-white"
                type="button"
                onClick={() => addNewSkill()}
              >
                Add
              </button>
            </div>
            {formik.touched.additional_skill &&
              formik.errors.additional_skill && (
                <div className="error">{formik.errors.additional_skill}</div>
              )}
            <div
              className="p-1 mb-4 flex items-center bg-blue-50 gap-1"
              role="alert"
            >
              <span className="p-1 text-primary rounded-md">
                <BsInfoCircleFill size={14} />
              </span>
              <span className="text-xs text-blue-800 font-light">
                Use this option to add the Skillsets to the drop-down menu.
                You'll be able to access them directly the next time you create
                a Job Description
              </span>
            </div>
          </div>
          {/* <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5 col-span-1"></div> */}
          {showJdAttachment ? (
            <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
              <label className={Style.label}>
                Attachment
                {/* <span className="error"> *</span> */}
              </label>
              <div className="flex items-center w-full border-primary rounded ">
                <div className="cursor-pointer  w-full  ">
                  <label className="cursor-pointer w-full flex justify-start">
                    {selectedFile ? (
                      <div className="flex justify-center items-center border-r overflow-hidden font-semibold text-xs p-2 bg-primary text-white w-2/4">
                        <BsUpload color="white" size={10} /> &nbsp; Change
                      </div>
                    ) : (
                      <div className="flex justify-center items-center border-r  overflow-hidden font-semibold text-xs p-2 bg-primary text-white w-2/4">
                        <BsUpload color="white" size={10} /> &nbsp; Upload
                      </div>
                    )}
                    <div className="flex justify-start items-center w-2/4">
                      &nbsp;
                      {selectedFile && (
                        <div className="text-sm px-3 text-gray-400 truncate">
                          {selectedFile?.name}
                        </div>
                      )}
                      <input
                        type="file"
                        className="hidden"
                        onChange={handleImageChange}
                      ></input>
                    </div>
                  </label>
                </div>
              </div>
              {fileError && <div className="error mb-2">{fileError}</div>}
              <div className="mt-1 text-xs text-gray-500" id="user_avatar_help">
                Supported format (.pdf, .docx), Size (Max 3MB)
              </div>
            </div>
          ) : (
            <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
              <label className={Style.label}>
                Attachment
                {/* <span className="error"> *</span> */}
              </label>
              <div className="flex flex-row items-center  w-full justify-between gap-2">
                <div className="flex items-center  justify-center w-full border-primary rounded py-2">
                  <a
                    href={templateJdattachment}
                    className="text-xs text-primary"
                  >
                    View File
                  </a>
                </div>
                <div
                  className="flex items-center justify-center w-full bg-primary  rounded py-2 cursor-pointer"
                  onClick={() => setShowJdAttachment(true)}
                >
                  <span className="text-xs text-white">Change</span>
                </div>
              </div>
            </div>
          )}
          <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5"></div>
          <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
            <label className={Style.label}>
              Job Description<span className="error"> *</span>
            </label>
            <textarea
              placeholder="Job Description"
              id="job_description"
              name="job_description"
              defaultValue={file_content}
              value={formik.values.job_description}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              className={
                formik.touched.job_description && formik.errors.job_description
                  ? Style.iError
                  : Style.iInput
              }
            ></textarea>
            {formik.touched.job_description &&
              formik.errors.job_description && (
                <div className="error">{formik.errors.job_description}</div>
              )}
          </div>
          <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
            <label className={Style.label}>Remarks</label>
            <textarea
              placeholder="Remarks"
              id="remarks"
              name="remarks"
              value={formik.values.remarks}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              className={
                formik.touched.remarks && formik.errors.remarks
                  ? Style.iError
                  : Style.iInput
              }
            ></textarea>
            {formik.touched.remarks && formik.errors.remarks && (
              <div className="error">{formik.errors.remarks}</div>
            )}
          </div>
        </div>
        <div className=" gap-2 mt-3 sm:flex md:flex lg:flex flex-row-reverse">
          <button
            className={
              !isDisabled
                ? "mt-2 py-1.5 px-2.5 text-white button-primary outline-none text-xs"
                : "mt-2 py-1.5 px-2.5 text-white button-primary-tint outline-none text-xs"
            }
            type="submit"
            disabled={isDisabled}
          >
            Save & Next
          </button>
          <button
            className=" mt-2 py-1.5 px-2.5 text-gray-800 outline-none border text-xs"
            onClick={() => resetJd()}
            type="button"
          >
            Reset
          </button>
          {selectedJdTemplate && (
            <button
              className=" mt-2 py-1.5 px-2.5 text-gray-800 outline-none border bg-gray-100 text-xs"
              onClick={() => setSelectedTemplate("")}
              type="button"
            >
              Edit
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default UploadJd;
