import React from "react";
import { ToastContainer, toast } from "react-toastify";
import orgApi from "../../../api/org_api";
import { BsPersonFill, BsPlus } from "react-icons/bs";
import S from "../../../Services/Services";
import { CloseModal } from "../../../component/Layout/CloseModal";
const nodata = require("../../../assets/images/nodata.png");

type SelectGroupsProps = { roundId: any; callBack: any; selectedGroups: [] };

const SelectGroups: React.FC<SelectGroupsProps> = ({
  roundId,
  callBack,
  selectedGroups,
}: SelectGroupsProps) => {
  const [search, setSearch] = React.useState("");
  const [selected, setStatus] = React.useState("false");
  const [type, setType] = React.useState("add");
  const [intGroup, setIntGroup] = React.useState([] as any);
  const [openIndex, setopenIndex] = React.useState(-1);
  const [selectedGroup, setSelectedGroup] = React.useState([] as any);
  const userInfo = JSON.parse(
    localStorage.getItem("user__details")?.toString()!
  );
  const orgId = userInfo.master_id !== null ? userInfo.master_id : userInfo.id;

  React.useEffect(() => {
    if (roundId === null) {
      setSelectedGroup(selectedGroups);
      setType("add");
    } else {
      setType("edit");
    }
  }, []);

  React.useEffect(() => {
    if (search.length > 2 || search.length === 0) {
      getGroups();
    }
  }, [search, selected]);

  const getGroups = async () => {
    const data = {
      search: search,
      selected: selected === "true",
      org_id: orgId,
      round_id: roundId,
    };
    const res = await orgApi.listRoundGroup(roundId, data);
    if (res.data.success) {
      setIntGroup(res.data.group);
    } else {
      setIntGroup([]);
    }
  };

  const addGroup = async (group_id: any) => {
    if (type === "edit") {
      const data = { selected: selected === "false", group_id };
      const res = await orgApi.addRoundGroup(roundId, data);
      if (res.data.success) {
        getGroups();
        toast(res.data.msg);
      } else {
        toast(res.data.msg);
      }
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      <div className="flex flex-col ">
        <div className="gap-3 mt-4 overflow-y-auto" style={{ height: "75vh" }}>
          <div className="flex flex-col">
            <div className="flex flex-row justify-between mb-2">
              <div className="w-full mr-5">
                <form className="searchForm">
                  <input
                    placeholder="Search.."
                    type="search"
                    id="search"
                    name="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className={"w-full border py-1 px-3 outline-none  rounded"}
                  ></input>
                  <button className="searchButton" type="button">
                    Search
                  </button>
                </form>
              </div>
              <div className="w-full"></div>
              <div className="w-full">
                {type === "edit" && (
                  <div className="flex flex-row items-center gap-2">
                    <div className="text-xs font-bold text-gray-800">
                      Status
                    </div>
                    <select
                      id="selected"
                      name="selected"
                      value={selected}
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                      className="w-full border border-gray-200 py-2 px-3 focus:outline-none focus:border-gray-300 rounded"
                    >
                      <option value="false">UnSelected</option>
                      <option value="true">Selected</option>
                    </select>
                  </div>
                )}
              </div>
            </div>
            <div>
              {intGroup.length === 0 && (
                <div className="flex flex-col justify-center items-center my-16 gap-1">
                  <img
                    src={nodata}
                    alt="user_profile_photo"
                    className="h-32 w-32 object-cover"
                  ></img>
                  <p className="text-xs font-thin text-gray-400">
                    No Data Found.
                  </p>
                </div>
              )}
              <div id="accordion-collapse" data-accordion="collapse">
                {intGroup.length !== 0 &&
                  intGroup.map((v: any, index: number) => {
                    return (
                      <>
                        <h2 id="accordion-collapse-heading-1">
                          <button
                            type="button"
                            onClick={() =>
                              openIndex !== index
                                ? setopenIndex(index)
                                : setopenIndex(-1)
                            }
                            className="flex items-center justify-between w-full px-5 py-3 font-medium rtl:text-right text-gray-600 font-semibold border border-gray-200 focus:ring-1 focus:ring-gray-200 hover:bg-gray-100 gap-3"
                            data-accordion-target="#accordion-collapse-body-1"
                            aria-expanded="true"
                            aria-controls="accordion-collapse-body-1"
                          >
                            <span>
                              {v.name}{" "}
                              <span className="ml-2 px-1.5 py-0.5 bg-primary text-white text-xs font-semi-bold rounded">
                                {v.g_interviewer.length}
                              </span>
                            </span>
                            {type === "edit" ? (
                              <div className="flex justify-end items-center gap-2">
                                {selected === "false" ? (
                                  <button
                                    type="button"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      addGroup(v.id);
                                    }}
                                    className="py-2 px-3.5 text-gray-600 outline-none bg-white border border-gray-200 text-xs rounded-md flex gap-2 items-center"
                                  >
                                    <BsPlus /> Add
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      addGroup(v.id);
                                    }}
                                    className="py-2 px-3.5 text-red-600 outline-none bg-white border border-gray-200 text-xs rounded-md flex gap-2 items-center"
                                  >
                                    <CloseModal /> Remove
                                  </button>
                                )}
                              </div>
                            ) : (
                              <div className="flex justify-end items-center gap-2">
                                {!selectedGroup.includes(v.id) ? (
                                  <button
                                    type="button"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setSelectedGroup([
                                        ...selectedGroup,
                                        v.id,
                                      ]);
                                    }}
                                    className="py-2 px-3.5 text-gray-600 outline-none bg-white border border-gray-200 text-xs rounded-md flex gap-2 items-center"
                                  >
                                    <BsPlus /> Add
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      const updatedItems = selectedGroup.filter(
                                        (item: any) => item !== v.id
                                      );
                                      setSelectedGroup(updatedItems);
                                    }}
                                    className="py-2 px-3.5 text-red-600 outline-none bg-white border border-gray-200 text-xs rounded-md flex gap-2 items-center"
                                  >
                                    <CloseModal /> Remove
                                  </button>
                                )}
                              </div>
                            )}
                          </button>
                        </h2>
                        <div
                          id="accordion-collapse-body-1"
                          className={openIndex === index ? "" : "hidden"}
                          aria-labelledby="accordion-collapse-heading-1"
                        >
                          <div className="p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                            <table className="table-fixed text-xs w-full">
                              <thead>
                                <tr>
                                  <th align="left">
                                    <p className="p-2">Profile Pic</p>
                                  </th>
                                  <th align="left">
                                    <p className="p-2">Name</p>
                                  </th>
                                  <th align="left">
                                    <p className="p-2">Email</p>
                                  </th>
                                  <th align="left">
                                    <p className="p-2">Contact Number</p>
                                  </th>
                                  <th align="left">
                                    <p className="p-2">Role</p>
                                  </th>
                                  <th align="left">
                                    <p className="p-2">Designation</p>
                                  </th>
                                  <th align="left">
                                    <p className="p-2">Skills</p>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="text-gray-600">
                                {v.g_interviewer.map((value: any) => (
                                  <tr className="bg-gray-50 border-b-2 border-white ">
                                    <td>
                                      <p className="p-2">
                                        <div className="h-16 w-16 bg-gray-100 rounded-full overflow-hidden">
                                          {value.interviewer.candidate
                                            .profile_pic ? (
                                            <img
                                              src={
                                                value.interviewer.candidate
                                                  .profile_pic
                                              }
                                              alt="user_profile_photo"
                                              className="h-full w-full object-cover"
                                            ></img>
                                          ) : (
                                            <div className="flex justify-center items-center h-16">
                                              <BsPersonFill
                                                color="grey"
                                                size={28}
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </p>
                                    </td>
                                    <td>
                                      <p className="p-2">
                                        {value.interviewer.candidate
                                          .first_name +
                                          " " +
                                          value.interviewer.candidate.last_name}
                                      </p>
                                    </td>
                                    <td>
                                      <p
                                        className="p-2 truncate"
                                        title={
                                          value.interviewer.candidate.email
                                        }
                                      >
                                        {value.interviewer.candidate.email}
                                      </p>
                                    </td>
                                    <td>
                                      <p className="p-2">
                                        +
                                        {
                                          value.interviewer.candidate
                                            .mobile_country_code
                                        }{" "}
                                        {value.interviewer.candidate.mobile}
                                      </p>
                                    </td>
                                    <td>
                                      <p className="p-2">
                                        {value.interviewer.candidate
                                          .u_p_skill[0].role === "interviewer"
                                          ? "Market Place"
                                          : value.interviewer.candidate
                                              .u_p_skill[0].role === "external"
                                          ? "External"
                                          : "In-House"}
                                      </p>
                                    </td>
                                    <td>
                                      <p className="p-2">
                                        {value.interviewer.type}
                                      </p>
                                    </td>
                                    <td>
                                      <p className="p-2 truncate">
                                        {S.getSkills(
                                          value.interviewer.candidate.u_p_skill
                                        )}
                                      </p>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className=" gap-2 mt-3 sm:flex md:flex lg:flex flex-row-reverse">
          <button
            className="mt-2 py-1.5 px-2.5 text-white button-primary outline-none text-xs"
            type="button"
            onClick={() => callBack(selectedGroup)}
          >
            Save & Next
          </button>
          <button
            className=" mt-2 py-1.5 px-2.5 text-gray-800 outline-none border text-xs"
            onClick={() => callBack(selectedGroup)}
            type="button"
          >
            Back
          </button>
        </div>
      </div>
    </>
  );
};
export default SelectGroups;
