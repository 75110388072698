import React from "react";
import { SVG } from "../../assets/SVG/SVG";
import Carousel from "../../component/Carousel/Carousel";
import * as slides from "../../component/Carousel/CarousalSlides";

const IntForgotPasswordSuccess: React.FC = () => {

  return (
    <div>
      <div className=" flex justify-center items-center max-h-screen">
        <div className="lg:p-24 md:p-52 sm:20 p-8 w-full lg:w-1/2">
          <SVG.LogoDark />
          <div className="my-8">
            <SVG.Success />
          </div>
          <h6 className="text-md font-semibold text-gray-800 mb-4 mt-4 text-center">
            Link to reset password is sent to your registered email!
          </h6>
          <h6 className="text-xs text-gray-400 mb-4 mt-4 text-center">
            You can now sign in with your new password
          </h6>
        </div>
        <div className="w-1/2 h-screen hidden lg:block">
          <div className="onboard-box">
            <Carousel slides={slides.slides} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default IntForgotPasswordSuccess;
