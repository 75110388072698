import React from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import Carousel from "../../component/Carousel/Carousel";
import * as slides from "../../component/Carousel/CarousalSlides";
import { SVG } from "../../assets/SVG/SVG";
import { Style } from "../../utils/Style";
import * as Yup from "yup";
import intApi from "../../api/int_api";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const InterviewerSignup: React.FC = () => {
  const [errorMsg, setErrorMessage] = React.useState("");
  const [phErrorMsg, setPhErrorMessage] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: Yup.object({
      email: Yup.string().required("Email is required").email("Invalid email"),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      setErrorMessage("");
      var ph;
      if (
        phone !== undefined &&
        countryCode !== "" &&
        countryCode !== undefined
      ) {
        ph = phone.replace("+" + countryCode, "");
        if (ph !== "") {
        } else {
          setPhErrorMessage("Please enter the valid Phone number");
          return;
        }
      } else {
        setPhErrorMessage("Please enter the valid Phone number");
        return;
      }

      if(ph.length >= 10 && ph.length <= 15) {
      } else {
        setPhErrorMessage("Mobile number must have 10 to 15 digits");
        return;
      }
      
      const data = {
        email: values.email.toLowerCase(),
        mobile: ph!,
        mobile_country_code: countryCode,
      };
      const res = await intApi.signup(data);
      if (res.data.success === true) {
        localStorage.clear();
        localStorage.setItem("int_email", values.email.toLowerCase());
        localStorage.setItem("int_mobile", ph!);
        navigate("/intervierer_verify_otp/" + res.data.id + "/1", { replace: true });
      } else {
        setErrorMessage(res.data.msg);
      }
    },
  });

  function handlechange(e: any) {
    setPhErrorMessage("");
    setPhone(e);
    // if (e !== undefined && countryCode !== "") {
    //   const ph = e.replace("+" + countryCode, "");
    //   !ph && setPhErrorMessage("Please enter the valid Phone number");
    // } else {
    //   setPhErrorMessage("Please enter the valid Phone number");
    // }
  }
  function handleBlur(e: any) {
    setPhErrorMessage("");
    const len = e.target.value.split(" ").length;
    const c = e.target.value.split(" ");
    const cc = c[0].split("+");
    setCountryCode(cc[1]);
    const ph = e.target.value.substring(e.target.value.indexOf(" ") + 1);
    if (len > 1 && ph !== "") {
      if(ph.length >= 10 && ph.length <= 15) {
      } else {
        setPhErrorMessage("Mobile number must have 10 to 15 digits");
        return;
      }
    } else {
      setPhErrorMessage("Please enter the valid Phone number");
    }
  }

  return (
    <div>
      <div className="flex justify-center items-center h-screen">
        <div className="lg:p-24 md:p-52 sm:20 p-8 w-full lg:w-1/2">
          <div className="">
            <SVG.LogoDark />
          </div>
          <h1 className="text-xl mt-6 font-semibold">
            Welcome. Let's get started.
          </h1>
          <h6 className="text-sm text-gray-400 mb-4">
            To begin, tell us a bit about yourself
          </h6>
          {errorMsg !== "" && (
            <div className="error text-center ">{errorMsg}</div>
          )}
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="username"
                className="text-xs font-semibold text-gray-600 py-2"
              >
                Email<span title="required">*</span>
              </label>
              <input
                type="text"
                id="email"
                name="email"
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className={
                  formik.touched.email && formik.errors.email
                    ? Style.iError
                    : Style.iInput
                }
                autoComplete="off"
                placeholder="name@example.com"
              ></input>
              {formik.touched.email && formik.errors.email && (
                <div className="error">{formik.errors.email}</div>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="username"
                className="text-xs font-semibold text-gray-600 py-2"
              >
                Mobile Number<span title="required">*</span>
              </label>
              <PhoneInput
                defaultCountry="IN"
                international
                countryCallingCodeEditable={false}
                initialValueFormat="national"
                name="phone"
                value={phone}
                onBlur={handleBlur}
                onChange={handlechange}
                className={phErrorMsg !== "" ? Style.iError : Style.iInput}
                autoComplete="off"
              />
              {phErrorMsg && <div className="error">{phErrorMsg}</div>}
            </div>
            <button
              type="submit"
              className="button-primary text-sm text-white font-semibold rounded-md py-2 px-4 w-full mt-2"
            >
              Sign Up
            </button>
          </form>
          <div className="mt-4">
            <span className="text-input-label">Already have an account? </span>
            <span
              onClick={() => navigate("/interviewer_signin")}
              className="hover:underline text-button-primary"
            >
              Sign In
            </span>
          </div>
        </div>
        <div className="w-1/2 h-screen hidden lg:block">
          <div className="onboard-box">
            <Carousel slides={slides.slides} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewerSignup;
