import React from "react";
import {
  BsBoxArrowRight,
  BsCalendarWeek,
  BsFillGridFill,
  BsFillPeopleFill,
} from "react-icons/bs";
import { FaUserTie } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../utils/UseContext";
const logo = require("../../assets/images/logo_new.png");

type sidebarProps = {
  state: string;
  setState: Function;
};
const Sidebar: React.FC<sidebarProps> = ({
  state,
  setState,
}: sidebarProps): JSX.Element => {
  const { logout } = useAuth();
  React.useEffect(() => {}, [state]);
  const [isLogout, setIsLogout] = React.useState(false);
  const userInfo = JSON.parse(
    localStorage.getItem("user__details")?.toString()!
  );

  return (
    <aside className="hidden sm:flex sm:flex-col">
      {isLogout && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-xl p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl rounded-tl-3xl">
              <div className="sm:flex">
                <div className="mt-1 sm:text-left w-full">
                  <p className="text-center">Are you sure you want to exit?</p>
                  <div className="flex items-center justify-evenly my-4">
                    <button
                      className="mt-2 py-1 px-2.5 text-gray-800 outline-none border text-sm rounded-md"
                      onClick={() => logout()}
                    >
                      Yes
                    </button>
                    <button
                      onClick={() => setIsLogout(false)}
                      className="mt-2 py-1 px-2.5 text-white button-primary outline-none text-sm rounded-md font-medium float-right"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <NavLink
        to="/dashboard"
        onClick={() => setState("dashboard")}
        className="inline-flex items-center flex-col justify-center h-20 w-20 sidebar-bg-dark"
      >
        <img src={logo} alt="" className="p-2"></img>
        {/* <span className="border-b mt-2  mb-4 border-gray-400 w-full"></span> */}
      </NavLink>

      <div className="flex-grow flex flex-col justify-between text-gray-500 sidebar-bg-dark">
        <nav className="flex flex-col mx-1">
          <NavLink
            to="/dashboard"
            onClick={() => setState("dashboard")}
            className="flex flex-col items-center"
          >
            <div
              className={
                state === "dashboard"
                  ? "inline-flex items-center justify-center py-3 rounded-lg sidebar-icons-select"
                  : "inline-flex items-center justify-center py-3 rounded-lg sidebar-icons"
              }
            >
              <BsFillGridFill />
            </div>
            <span
              className={
                state === "dashboard"
                  ? "text-xs 2xl:text-sm sidebar-icons-select-text"
                  : "text-xs 2xl:text-sm sidebar-icons-text"
              }
            >
              Dashboard
            </span>
            <span
              className={
                state === "dashboard"
                  ? "border-b-2 mt-2 mb-4 w-3/4	"
                  : "border-b mt-2 mb-4 border-gray-400 w-3/4	"
              }
            ></span>
          </NavLink>
          {userInfo.master_id === null && (
            <NavLink
              onClick={() => setState("user_management")}
              to="/user_management"
              className="flex flex-col items-center"
            >
              <div
                className={
                  state === "user_management"
                    ? "inline-flex items-center justify-center py-3 rounded-lg sidebar-icons-select"
                    : "inline-flex items-center justify-center py-3 rounded-lg sidebar-icons"
                }
              >
                <BsFillPeopleFill />
              </div>
              <span
                className={
                  state === "user_management"
                    ? "text-xs 2xl:text-sm sidebar-icons-select-text"
                    : "text-xs 2xl:text-sm sidebar-icons-text"
                }
              >
                User
              </span>
              <span
                className={
                  state === "user_management"
                    ? "border-b-2 mt-2 mb-4 w-3/4	"
                    : "border-b mt-2  mb-4 border-gray-400 w-3/4	"
                }
              ></span>
            </NavLink>
          )}
          <NavLink
            onClick={() => setState("interviewer_management")}
            to="/interviewer_management"
            className="flex flex-col items-center"
          >
            <div
              className={
                state === "interviewer_management"
                  ? "inline-flex items-center justify-center py-3 rounded-lg sidebar-icons-select"
                  : "inline-flex items-center justify-center py-3 rounded-lg sidebar-icons"
              }
            >
              <FaUserTie />
            </div>
            <span
              className={
                state === "interviewer_management"
                  ? "text-xs 2xl:text-sm sidebar-icons-select-text"
                  : "text-xs 2xl:text-sm sidebar-icons-text"
              }
            >
              Interviewer
            </span>
            <span
              className={
                state === "interviewer_management"
                  ? "border-b-2 mt-2 mb-4 w-3/4	"
                  : "border-b mt-2  mb-4 border-gray-400 w-3/4	"
              }
            ></span>
          </NavLink>
          <NavLink
            onClick={() => setState("report")}
            to="/report_list"
            className="flex flex-col items-center"
          >
            <div
              className={
                state === "report"
                  ? "inline-flex items-center justify-center py-3 rounded-lg sidebar-icons-select"
                  : "inline-flex items-center justify-center py-3 rounded-lg sidebar-icons"
              }
            >
              <BsCalendarWeek />
            </div>
            <span
              className={
                state === "report"
                  ? "text-xs 2xl:text-sm sidebar-icons-select-text"
                  : "text-xs 2xl:text-sm sidebar-icons-text"
              }
            >
              Reports
            </span>
            <span
              className={
                state === "report"
                  ? "border-b-2 mt-2 mb-4 w-3/4	"
                  : "border-b mt-2  mb-4 border-gray-400 w-3/4	"
              }
            ></span>
          </NavLink>
        </nav>
        <div className="inline-flex items-center justify-center h-20 w-20 border-t border-white">
          <button className="p-3 hover:text-gray-400 2xl:text-sm focus:text-gray-400">
            <span className="sr-only">Logout</span>
            <div
              onClick={() => setIsLogout(true)}
              className={
                "inline-flex items-center justify-center py-3 rounded-lg sidebar-icons"
              }
            >
              <span className="sr-only">Logout</span>
              <BsBoxArrowRight />
            </div>
          </button>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
