import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { SVG } from "../../assets/SVG/SVG";
import Carousel from "../../component/Carousel/Carousel";
import * as slides from "../../component/Carousel/CarousalSlides";
import { Style } from "../../utils/Style";
import intApi from "../../api/int_api";
import { useAuth } from "../../utils/UseContext";

const InterviewerSignin: React.FC = () => {
  const [errorMsg, setErrorMessage] = React.useState("");
  const navigate = useNavigate();
  const { intlogin } = useAuth();
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(true);

  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState: any) => !prevState);
  }

  const goToForgotPassword = () => navigate("/int_forgot_password");
  
  const formik = useFormik({
    initialValues: { username: "", password: "" },
    validationSchema: Yup.object({
      username: Yup.string()
        .required("Username is required")
        .email("Invalid username"),
      password: Yup.string().required("Password is required"),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      setErrorMessage("");
      var reqData = { email: values.username.toLowerCase(), password: values.password };
      const res = await intApi.signin(reqData);
      if (res.data.success === true) {
        localStorage.clear();
        localStorage.setItem("int_token", res.data.token);
        localStorage.setItem(
          "candidate",
          JSON.stringify(res.data.candidate[0])
        );
        intlogin();
      } else {
        setErrorMessage(res.data.msg);
      }
    },
  });

  return (
    <div>
      <div className=" flex justify-center items-center max-h-screen">
        <div className="lg:p-24 md:p-52 sm:20 p-8 w-full lg:w-1/2">
          <div className="">
            <SVG.LogoDark />
          </div>
          <h1 className="text-xl mt-4 font-semibold">Welcome Back!</h1>
          <h6 className="text-xs text-gray-400 mb-4">
            Let's get you logged in.
          </h6>
          {errorMsg !== "" && (
            <div className="error text-center ">{errorMsg}</div>
          )}
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-2">
              <label
                htmlFor="username"
                className="text-xs font-semibold text-gray-600 py-2"
              >
                Username
              </label>
              <input
                type="text"
                id="username"
                name="username"
                value={formik.values.username}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className={
                  formik.touched.username && formik.errors.username
                    ? Style.iError
                    : Style.iInput
                }
                autoComplete="off"
              ></input>
              {formik.touched.username && formik.errors.username && (
                <div className="error">{formik.errors.username}</div>
              )}
            </div>
            <div className="mb-2">
              <label
                htmlFor="username"
                className="text-xs font-semibold text-gray-600 py-2"
              >
                Password
              </label>
              <div className="relative container mx-auto">
                <input
                  type={!isPasswordVisible ? "text" : "password"}
                  id="password"
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  name="password"
                  className={
                    formik.touched.password && formik.errors.password
                      ? Style.iError
                      : Style.iInput
                  }
                  autoComplete="off"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                  onClick={togglePasswordVisibility}
                >
                  {isPasswordVisible ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                  )}
                </button>
              </div>
              {formik.touched.password && formik.errors.password && (
                <div className="error">{formik.errors.password}</div>
              )}
            </div>
            <div className="mb-4 text-button-primary">
              <div
                onClick={() => goToForgotPassword()}
                className="hover:underline text-button-primary"
              >
                Forgot Password?
              </div>
            </div>
            <button
              type="submit"
              className="button-primary text-sm text-white font-semibold rounded-md py-2 px-4 w-full"
            >
              Sign In
            </button>
          </form>
          <div className="mt-2">
            <span className="text-input-label">Don't have an account yet?</span>
            <span
              onClick={() => navigate("/interviewer_signup")}
              className="hover:underline text-button-primary pl-2"
            >
              Sign Up
            </span>
          </div>
        </div>
        <div className="w-1/2 h-screen hidden lg:block">
          <div className="onboard-box">
            <Carousel slides={slides.slides} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewerSignin;
