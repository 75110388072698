import axios from "./axios";

const api = {
  signIn: async (email: string, password: string) => {
    const req = { email, password };
    return await axios.post(`/signin`, req);
  },
  signUp: async (data: any) => {
    return await axios.post(`/signup`, data);
  },
  setPassword: async (data: any, key: any) => {
    return await axios.post(`/org/${key}/verify`, data);
  },
  register: async (data: any, userId: any) => {
    return await axios.post(`/org/${userId}/register`, data);
  },
  updateName: async (data: any, orgId: any) => {
    return await axios.post(`/org/${orgId}/name`, data);
  },
  forgotPassword: async (data: any) => {
    return await axios.post(`/forgot_password`, data);
  },
  resetPassword: async (data: any, key: any) => {
    console.log(key)
    return await axios.post(`/reset_password`, data);
  },
  updateProfile: async (data: any, orgId: any) => {
    return await axios.put(`/org/${orgId}/details`, data);
  },
  changePassword: async (data: any, userId: any) => {
    return await axios.post(`/org/${userId}/change_password`, data);
  },
};

export default api;
