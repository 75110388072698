import React from "react";
import OtpInput from "react-otp-input";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { SVG } from "../../assets/SVG/SVG";
import Carousel from "../../component/Carousel/Carousel";
import * as slides from "../../component/Carousel/CarousalSlides";
import intApi from "../../api/int_api";

const InterviewerVerifyOtp: React.FC = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = React.useState("");
  const [otpError, setOtpError] = React.useState("");
  const { id, type } = useParams();

  const verify = async () => {
    if (otpError !== "") {
      return;
    }
    if (otp.length < 6) {
      setOtpError("Enter the valid OTP");
      return;
    }
    var reqData = { id: id, otp: otp };
    if ( type === '1' ) {
      const res = await intApi.verifyOtp(reqData);
      if (res.data.success === true) {
        var email = localStorage.getItem("int_email")!;
        var mobile = localStorage.getItem("int_mobile")!;
        localStorage.setItem("int_user_id", res.data.id);
        navigate("/interviewer_register/" + res.data.id + '/' + email + '/' + mobile, { replace: true });
      } else {
        toast(res.data.msg);
      }
    } else {
      const res = await intApi.verifyOtp(reqData);
      if (res.data.success === true) {
        navigate("/int_set_password/" + res.data.id, { replace: true });
      } else {
        toast(res.data.msg);
      }
    }
  };

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      <div className=" flex justify-center items-center max-h-screen">
        <div className="lg:p-24 md:p-52 sm:20 p-8 w-full lg:w-1/2">
          <div className="">
            <SVG.LogoDark />
          </div>
          <h1 className="text-xl mt-4 font-semibold">OTP Verification</h1>
          <h1 className="text-xs mt-2 font-normal mb-8 text-gray-500">Please enter the OTP sent to your mail id</h1>
          <form>
            <div className="flex flex-col item-center w-full">
              <div className="flex justify-center w-full">
                <OtpInput
                  value={otp}
                  shouldAutoFocus={true}
                  onChange={(v) => {
                    setOtpError("");
                    isNaN(Number(v))
                      ? setOtpError("Enter the valid OTP")
                      : setOtp(v);
                  }}
                  numInputs={6}
                  renderSeparator={<span> &nbsp;&nbsp; </span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={
                    "w-9 border border-gray-300  py-2 px-2 focus:outline-none focus:border-blue-500 rounded text-center"
                  }
                  skipDefaultStyles={true}
                />
              </div>
              {otpError && (
                <div className="error text-center w-full mt-2">{otpError}</div>
              )}
            </div>
            <button
              type="button"
              className="button-primary text-sm text-white font-semibold rounded-md py-2 px-4 w-full mt-5"
              onClick={() => verify()}
            >
              Verify
            </button>
          </form>
        </div>
        <div className="w-1/2 h-screen hidden lg:block">
          <div className="onboard-box">
            <Carousel slides={slides.slides} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewerVerifyOtp;
