import React from "react";
import { format } from "date-fns";
import orgApi from "../../../api/org_api";
import PaginationButtons from "../../../component/PaginationButtons";
import { BsArrowLeftShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import S from "../../../Services/Services";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";

type InterviewReportProps = { setLoading: Function };

const InterviewReport: React.FC<InterviewReportProps> = ({
  setLoading,
}: InterviewReportProps) => {
  const [schedule, setSchedule] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPost, setTotal] = React.useState(0);
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [verdict, setVerdict] = React.useState("");
  const [csvData, setCsvData] = React.useState([] as any);
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchData = async () => {
      const page = totalPages === 0 ? 1 : Math.min(currentPage + 1, totalPages);
      const org = JSON.parse(
        localStorage.getItem("user__details")?.toString()!
      );
      const reqData = {
        page: page,
        from: from
          ? format(from, "yyyy-MM-dd 00:00:00")
          : !from && to
          ? format(to, "yyyy-MM-dd 00:00:00")
          : "",
        to: to
          ? format(to, "yyyy-MM-dd 23:59:59")
          : !to && from
          ? format(from, "yyyy-MM-dd 23:59:59")
          : "",
        recommended_status: verdict === "" ? null : verdict,
      };
      const res = await orgApi.interviewReport(org.id, reqData);

      if (res.data.success) {
        setTotal(res.data.total);
        var t = Math.ceil(res.data.total / 10);
        setTotalPages(t);
        setSchedule(res.data.schedule);
      } else {
        setSchedule([]);
      }
    };
    fetchData();
  }, [currentPage, from, to, verdict]);

  const exportData = async () => {
    setLoading(true);
    const org = JSON.parse(localStorage.getItem("user__details")?.toString()!);
    const reqData = {
      from: from
        ? format(from, "yyyy-MM-dd 00:00:00")
        : !from && to
        ? format(to, "yyyy-MM-dd 00:00:00")
        : "",
      to: to
        ? format(to, "yyyy-MM-dd 23:59:59")
        : !to && from
        ? format(from, "yyyy-MM-dd 23:59:59")
        : "",
    };
    const res = await orgApi.exportInterviewReport(org.id, reqData);

    if (res.data.success) {
      const datas = res.data.schedule;
      const data = [];
      for (let index = 0; index < datas.length; index++) {
        const element = datas[index];
        const val = {
          PostTitle: element.post.job_title,
          RecruiterName:
            element.post.org.first_name + "" + element.post.org.last_name,
          ClientName: element.post.client_name,
          Status: `${element.status === "NR" ? "Next Round" : element.status}`,
          CandidateName:
            element.candidate.first_name + " " + element.candidate.last_name,
          InterviewerName: `${
            element.interviewer
              ? element.interviewer.candidate.first_name +
                " " +
                element.interviewer.candidate.last_name
              : "-"
          }`,
          IntervieweExperience: `${
            element.interviewer
              ? element.interviewer.candidate.year_of_experience +
                " years " +
                element.interviewer.candidate.month_of_experience +
                " months"
              : "-"
          }`,
          InterviewedDate: format(element.utc_starttime, "dd MMM yyyy"),
          InterviewedTime: element.utc_endtime
            ? format(element.utc_starttime, "hh:mm a") +
              " " +
              format(element.utc_endtime, "hh:mm a")
            : "-",
          Verdict:
            element.feedback.length > 0
              ? element.feedback[0].recommended_status
                ? element.feedback[0].recommended_status
                : "NA"
              : "NA",
          CandidateNoShow:
            element.feedback.length > 0
              ? element.feedback[0].candidate_no_show
                ? "Yes"
                : "No"
              : "-",
          CandidateProxy:
            element.feedback.length > 0
              ? element.feedback[0].proxy
                ? "Yes"
                : "No"
              : "-",
        } as any;
        if (S.getOrgDomain() !== "nam-it.com") {
          val["SITScore"] = `${
            element.feedback.length > 0
              ? element.feedback[0].sit_score
              : element.sit
          }`;
        } else {
          val["RecommendedStatus"] = `${
            element.feedback.length > 0
              ? element.feedback[0].eligible_for_this_position
                ? "Yes"
                : "No"
              : "Yes"
          }`;
        }
        data.push(val);
      }
      setCsvData(data);
      setTimeout(() => {
        document.getElementById("expbtn")?.click();
      }, 1000);
    }
  };

  const timeConvert = (t1: any, t2: any) => {
    return format(t1, "hh:mm a") + " - " + format(t2, "hh:mm a");
  };

  const handleFromDateChange = (date: any) => setFrom(date);
  const handleToDateChange = (date: any) => setTo(date);
  const clearFilter = () => {
    setFrom(null);
    setTo(null);
    setVerdict("");
  };

  return (
    <>
      <h3 className="text-center font-bold">Interview Report</h3>
      <div className="flex justify-between items-center my-2 w-auto">
        <div className="flex flex-row items-center gap-2 mb-2">
          <div className="text-xs font-bold text-gray-800">Select Date</div>
          <div className="flex flex-row gap-2">
            <DatePicker
              selected={from}
              onChange={handleFromDateChange}
              dateFormat="dd MMM, yyyy"
              isClearable
              placeholderText="Select From Date"
              className="w-full border border-gray-200 py-2 px-3 focus:outline-none focus:border-gray-300 rounded"
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
            <DatePicker
              selected={to}
              onChange={handleToDateChange}
              dateFormat="dd MMM, yyyy"
              isClearable
              placeholderText="Select To Date"
              className="w-full border border-gray-200 py-2 px-3 focus:outline-none focus:border-gray-300 rounded"
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          </div>
          {S.getOrgDomain() !== "nam-it.com" && (
            <>
              <label className="text-xs font-bold text-gray-800">
                Select Verdict
              </label>
              <select
                id="verdict"
                name="verdict"
                placeholder="Select Verdict"
                value={verdict}
                onChange={(e) => setVerdict(e.target.value)}
                className="border border-gray-200 py-2 px-3 focus:outline-none focus:border-gray-300 rounded"
              >
                <option value="">{"Select Verdict"}</option>
                <option value="Rejected">{"Rejected"}</option>
                <option value="Near Fit">{"Near Fit"}</option>
                <option value="Hire">{"Hire"}</option>
                <option value="Strong Hire">{"Strong Hire"}</option>
              </select>
            </>
          )}
          <button
            type="button"
            onClick={() => clearFilter()}
            className="text-primary text-xs font-thin bg-transparent rounded-lg text-sm w-8 h-8 ms-auto underline"
          >
            Clear
          </button>
        </div>
        <div className="flex flex-row items-center gap-2 justify-end">
          <CSVLink
            className="bg-primary rounded px-2.5 py-1.5 text-xs text-white hidden"
            type="button"
            filename="InterviewReport.csv"
            data={csvData}
            id="expbtn"
            onClick={() => {
              setLoading(false);
            }}
          >
            Export to CSV
          </CSVLink>
          <button
            className="bg-primary rounded px-2.5 py-1.5 text-xs text-white"
            type="button"
            onClick={() => exportData()}
          >
            Export to CSV
          </button>
          <button
            type="button"
            className="text-primary text-sm font-semibold py-1 px-2 flex items-center"
            onClick={() => navigate(-1)}
          >
            <BsArrowLeftShort /> Back
          </button>
        </div>
      </div>
      <div style={{ width: "91vw", height: "70vh", overflow: "auto" }}>
        <table className="text-sm text-left rtl:text-right text-gray-500 table-auto">
          <thead className="text-xs text-white bg-primary">
            <tr>
              <th className="p-2">S.No</th>
              <th scope="col" className="p-2">
                Post Title
              </th>
              <th scope="col" className="p-2">
                Recruiter Name
              </th>
              <th scope="col" className="p-2">
                Client Name
              </th>
              <th scope="col" className="p-2">
                Status
              </th>
              <th scope="col" className="p-2">
                Candidate Name
              </th>
              <th scope="col" className="p-2">
                Interviewer Name
              </th>
              <th scope="col" className="p-2">
                Interviewer Experience
              </th>
              <th scope="col" className="p-2">
                Interview Date
              </th>
              <th scope="col" className="p-2">
                Interview Time
              </th>
              <th scope="col" className="p-2">
                {S.getOrgDomain() !== "nam-it.com" ? "SIT Score" : "Feedback"}
              </th>
              {S.getOrgDomain() === "nam-it.com" && (
                <th scope="col" className="p-2">
                  Recommended Status
                </th>
              )}
              <th scope="col" className="p-2">
                Verdict
              </th>
              <th scope="col" className="p-2">
                No Show
              </th>
              <th scope="col" className="p-2">
                Proxy
              </th>
            </tr>
          </thead>
          <tbody>
            {schedule.map((x: any, index: any) => (
              <tr className="odd:bg-white even:bg-gray-100 border-b text-gray-700">
                <th className="p-2 text-xs 2xl:text-md font-medium text-gray-700 whitespace-nowrap">
                  {currentPage * 10 + index + 1}
                </th>
                <td
                  className="p-2 text-xs 2xl:text-md truncate"
                  title={x.post.job_title}
                >
                  {x.post.job_title}
                </td>
                <td className="p-2 text-xs 2xl:text-md">
                  {x.org.first_name + " " + x.org.last_name}
                </td>
                <td className="p-2 text-xs 2xl:text-md">
                  {x.post.client_name ? x.post.client_name : "-"}
                </td>
                <td className="p-2 text-xs 2xl:text-md">
                  {x.status === "NR" ? "Next Round" : x.status}
                </td>
                <td className="p-2 text-xs 2xl:text-md">
                  {x.candidate.first_name}
                </td>
                <td className="p-2 text-xs 2xl:text-md">
                  {x.interviewer ? x.interviewer.candidate.first_name : "-"}
                </td>
                <td className="p-2 text-xs 2xl:text-md">
                  {x.interviewer
                    ? x.interviewer.candidate.year_of_experience +
                      " years " +
                      x.interviewer.candidate.month_of_experience +
                      " months"
                    : "-"}
                </td>
                <td className="p-2 text-xs 2xl:text-md">
                  {format(x.utc_starttime, "yyyy-MMM-dd")}
                </td>
                <td className="p-2 text-xs 2xl:text-md">
                  {x.utc_endtime
                    ? timeConvert(x.utc_starttime, x.utc_endtime)
                    : "-"}
                </td>
                <td
                  className="p-2 text-xs 2xl:text-md cursor-pointer hover:text-blue-500 hover:text-sm hover:font-semibold"
                  onClick={() => {
                    x.feedback.length > 0
                      ? S.viewFeedback(x.id)
                      : window.open(x.feedback_url);
                  }}
                >
                  {S.getOrgDomain() !== "nam-it.com" ? (
                    <span>
                      {x.feedback.length > 0 ? x.feedback[0].sit_score : x.sit}
                    </span>
                  ) : (
                    <span className="text-blue-500 underline">Link</span>
                  )}
                </td>
                {S.getOrgDomain() === "nam-it.com" && (
                  <td className="p-2 text-xs 2xl:text-md">
                    {x.feedback.length > 0
                      ? x.feedback[0].eligible_for_this_position
                        ? "Yes"
                        : "No"
                      : "Yes"}
                  </td>
                )}

                <td className="p-2 text-xs 2xl:text-md">
                  {x.feedback.length > 0
                    ? x.feedback[0].candidate_no_show || x.feedback[0].proxy
                      ? "NA"
                      : x.feedback[0].recommended_status
                    : "NA"}
                </td>
                <td className="p-2 text-xs 2xl:text-md">
                  {x.feedback.length > 0
                    ? x.feedback[0].candidate_no_show
                      ? "Yes"
                      : "No"
                    : "-"}
                </td>
                <td className="p-2 text-xs 2xl:text-md">
                  {x.feedback.length > 0
                    ? x.feedback[0].proxy
                      ? "Yes"
                      : "No"
                    : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {totalPost > 10 && (
        <PaginationButtons
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
export default InterviewReport;
