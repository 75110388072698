import React from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const Navbar: React.FC = (): JSX.Element => {  
  const navigate = useNavigate();
  var [candata , setCandidateData] = React.useState({} as any);

  const gotoProfile = () => {
    navigate('/interviewer_profile');
  }
  React.useEffect(() => {
    var d = localStorage.getItem('candidate')!
    setCandidateData(JSON.parse(d));
  }, [])
  return (
    <header className="flex items-center h-15 px-6 sm:px-10 bg-white border-b">
      <button className="block sm:hidden relative flex-shrink-0 p-2 mr-2 text-gray-600 hover:bg-gray-100 hover:text-gray-800 focus:bg-gray-100 focus:text-gray-800 rounded-full">
        <span className="sr-only">Menu</span>
        <svg
          aria-hidden="true"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="h-6 w-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h7"
          />
        </svg>
      </button>
      
      <div className="flex flex-shrink-0 items-center ml-auto">
        <button onClick={() => gotoProfile()} className="inline-flex items-center mt-1 mb-1  p-1 hover:bg-gray-100 focus:bg-gray-100 rounded-lg">
          <div className="hidden md:flex md:flex-col md:items-end md:leading-tight">
            <span className="text-sm font-semibold">{candata['first_name']} {candata['last_name']}</span>
          </div>
          <span className="h-10 w-10 ml-2 sm:ml-3 mr-2 bg-gray-100 rounded-full overflow-hidden">
            {candata['profile_pic'] == null && <div className="h-full w-full flex items-center justify-center"><BsFillPersonFill color="grey" size={20}/></div>}
            {candata['profile_pic'] != null && <img
              src={candata['profile_pic']}
              alt="user_profile_photo"
              className="h-full w-full object-cover"
            ></img>}
          </span>
        </button>
      </div>
    </header>
  );
};

export default Navbar;
