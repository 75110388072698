import { format } from "date-fns";
import React from "react";
import orgApi from "../../../api/org_api";
import PaginationButtons from "../../../component/PaginationButtons";
import DatePicker from "react-datepicker";
import { BsLink, BsSubtract } from "react-icons/bs";
import { feedbackUrl } from "../../../api/basr_url";
import { ToastContainer, toast } from "react-toastify";

const AdminReport: React.FC = () => {
  const [schedule, setSchedule] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPost, setTotal] = React.useState(0);
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [openScheduleModal, setOpenScheduleModal] = React.useState(false);
  const [details, setDetails] = React.useState({} as any);
  const feedbackUrl1: any = feedbackUrl;

  const schDetail = async (data: any) => {
    setDetails(data);
    setOpenScheduleModal(true);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const page = totalPages === 0 ? 1 : Math.min(currentPage + 1, totalPages);
      const reqData = {
        page: page,
        from: from
          ? format(from, "yyyy-MM-dd 00:00:00")
          : !from && to
          ? format(to, "yyyy-MM-dd 00:00:00")
          : "",
        to: to
          ? format(to, "yyyy-MM-dd 23:59:59")
          : !to && from
          ? format(from, "yyyy-MM-dd 23:59:59")
          : "",
        type: "id",
      };
      const res = await orgApi.adminReport(reqData);

      if (res.data.success) {
        setTotal(res.data.total);
        var t = Math.ceil(res.data.total / 10);
        setTotalPages(t);
        setSchedule(res.data.schedule);
      } else {
        setSchedule([]);
      }
    };
    fetchData();
  }, [currentPage, from, to]);

  const timeConvert = (t1: any, t2: any) => {
    return format(t1, "hh:mm a") + " - " + format(t2, "hh:mm a");
  };
  const handleFromDateChange = (date: any) => setFrom(date);
  const handleToDateChange = (date: any) => setTo(date);
  const clearFilter = () => {
    setFrom(null);
    setTo(null);
  };

  const getOrgDomain = (userEmail: string) => {
    const splitDomain = userEmail.split("@")[1];
    return splitDomain;
  };

  const copyToClipboard = (text: any) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast("Link Copied");
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
      });
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      {openScheduleModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-xl p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
              <div className="sm:flex">
                <div className="mt-1 text-center sm:text-left w-full">
                  <div className="flex items-center justify-between border-b">
                    <h6 className="text-sm font-bold text-gray-800 mb-2">
                      Schedule Details
                    </h6>
                    <button
                      onClick={() => setOpenScheduleModal(false)}
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <svg
                        className="w-2 h-2"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  <div className="mt-1 flex flex-col items-start gap-2 w-full">
                    <div className="flex flex-col items-start w-full">
                      <p className="text-sm text-gray-800 font-semibold">
                        Candidate
                      </p>
                      <div className="border rounded bg-gray-50 grid grid-cols-5 gap-1 p-3 w-full">
                        <p className="text-xs text-gray-600 font-semibold col-span-1">
                          Meeting Link :
                        </p>
                        <p className="text-xs text-gray-400 font-normal col-span-4">
                          {feedbackUrl1 === "#" ? (
                            <div className="flex flex-row items-center gap-2">
                              <span>
                                {"https://demo.screenit.io/screenitMeeting/#/agora/" +
                                  details.id +
                                  "/3"}
                              </span>

                              <span className="hover:text-blue-500 cursor-pointer">
                                <BsSubtract
                                  onClick={() =>
                                    copyToClipboard(
                                      "https://demo.screenit.io/screenitMeeting/#/agora/" +
                                        details.id +
                                        "/3"
                                    )
                                  }
                                />
                              </span>
                            </div>
                          ) : (
                            <div className="flex flex-row items-center gap-2">
                              <span>
                                {"https://org.screenit.io/v2/screenitMeeting/#/agora/" +
                                  details.id +
                                  "/3"}
                              </span>

                              <span className="hover:text-blue-500 cursor-pointer">
                                <BsSubtract
                                  onClick={() =>
                                    copyToClipboard(
                                      "https://org.screenit.io/v2/screenitMeeting/#/agora/" +
                                        details.id +
                                        "/3"
                                    )
                                  }
                                />
                              </span>
                            </div>
                          )}
                        </p>
                        <p className="text-xs text-gray-600 font-semibold col-span-1">
                          Meeting ID :
                        </p>
                        <p className="text-xs text-gray-400 font-normal col-span-4">
                          {details.meeting_id}
                        </p>
                        <p className="text-xs text-gray-600 font-semibold col-span-1">
                          Password :
                        </p>
                        <p className="text-xs text-gray-400 font-normal col-span-4">
                          {details.candidate_pwd}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-start w-full">
                      <p className="text-sm text-gray-800 font-semibold">
                        Interviewer
                      </p>
                      <div className="border rounded bg-gray-50 grid grid-cols-5 gap-1 p-3 w-full">
                        <p className="text-xs text-gray-600 font-semibold col-span-1">
                          Meeting Link :
                        </p>
                        <p className="text-xs text-gray-400 font-normal col-span-4">
                          {feedbackUrl1 === "#" ? (
                            <div className="flex flex-row items-center gap-2">
                              <span>
                                {"https://demo.screenit.io/screenitMeeting/#/agora/" +
                                  details.id +
                                  "/2"}
                              </span>

                              <span className="hover:text-blue-500 cursor-pointer">
                                <BsSubtract
                                  onClick={() =>
                                    copyToClipboard(
                                      "https://demo.screenit.io/screenitMeeting/#/agora/" +
                                        details.id +
                                        "/2"
                                    )
                                  }
                                />
                              </span>
                            </div>
                          ) : (
                            <div className="flex flex-row items-center gap-2">
                              <span>
                                {"https://org.screenit.io/v2/screenitMeeting/#/agora/" +
                                  details.id +
                                  "/2"}
                              </span>

                              <span className="hover:text-blue-500 cursor-pointer">
                                <BsSubtract
                                  onClick={() =>
                                    copyToClipboard(
                                      "https://org.screenit.io/v2/screenitMeeting/#/agora/" +
                                        details.id +
                                        "/2"
                                    )
                                  }
                                />
                              </span>
                            </div>
                          )}
                        </p>
                        <p className="text-xs text-gray-600 font-semibold col-span-1">
                          Meeting ID :
                        </p>
                        <p className="text-xs text-gray-400 font-normal col-span-4">
                          {details.meeting_id}
                        </p>
                        <p className="text-xs text-gray-600 font-semibold col-span-1">
                          Password :
                        </p>
                        <p className="text-xs text-gray-400 font-normal col-span-4">
                          {details.interviewer_pwd}
                        </p>
                        <p className="text-xs text-gray-600 font-semibold col-span-1">
                          Feedback Link :
                        </p>
                        <p className="text-xs text-gray-400 font-normal col-span-4">
                          {getOrgDomain(details.post.org.email) ===
                          "nam-it.com" ? (
                            <>
                              {feedbackUrl1 === "#" ? (
                                <div className="flex flex-row items-center gap-2">
                                  <span>
                                    {"https://demo.screenit.io/#/add_feedback_tech_namit/" +
                                      details.id}
                                  </span>

                                  <span className="hover:text-blue-500 cursor-pointer">
                                    <BsSubtract
                                      onClick={() =>
                                        copyToClipboard(
                                          "https://demo.screenit.io/#/add_feedback_tech_namit/" +
                                            details.id
                                        )
                                      }
                                    />
                                  </span>
                                </div>
                              ) : (
                                <div className="flex flex-row items-center gap-2">
                                  <span>
                                    {"https://org.screenit.io/v2/add_feedback_tech_namit/" +
                                      details.id}
                                  </span>

                                  <span className="hover:text-blue-500 cursor-pointer">
                                    <BsSubtract
                                      onClick={() =>
                                        copyToClipboard(
                                          "https://org.screenit.io/v2/add_feedback_tech_namit/" +
                                            details.id
                                        )
                                      }
                                    />
                                  </span>
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {feedbackUrl1 === "#" ? (
                                <div className="flex flex-row items-center gap-2">
                                  <span>
                                    {"https://demo.screenit.io/#/add_feedback_tech/" +
                                      details.id}
                                  </span>

                                  <span className="hover:text-blue-500 cursor-pointer">
                                    <BsSubtract
                                      onClick={() =>
                                        copyToClipboard(
                                          "https://demo.screenit.io/#/add_feedback_tech/" +
                                            details.id
                                        )
                                      }
                                    />
                                  </span>
                                </div>
                              ) : (
                                <div className="flex flex-row items-center gap-2">
                                  <span>
                                    {"https://org.screenit.io/v2/add_feedback_tech/" +
                                      details.id}
                                  </span>

                                  <span className="hover:text-blue-500 cursor-pointer">
                                    <BsSubtract
                                      onClick={() =>
                                        copyToClipboard(
                                          "https://org.screenit.io/v2/add_feedback_tech/" +
                                            details.id
                                        )
                                      }
                                    />
                                  </span>
                                </div>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-start w-full">
                      <p className="text-sm text-gray-800 font-semibold">
                        Organization
                      </p>
                      <div className="border rounded bg-gray-50 grid grid-cols-5 gap-1 p-3 w-full">
                        <p className="text-xs text-gray-600 font-semibold col-span-1">
                          Meeting Link :
                        </p>
                        <p className="text-xs text-gray-400 font-normal col-span-4">
                          {feedbackUrl1 === "#" ? (
                            <div className="flex flex-row items-center gap-2">
                              <span>
                                {"https://demo.screenit.io/screenitMeeting/#/agora/" +
                                  details.id +
                                  "/1"}
                              </span>

                              <span className="hover:text-blue-500 cursor-pointer">
                                <BsSubtract
                                  onClick={() =>
                                    copyToClipboard(
                                      "https://demo.screenit.io/screenitMeeting/#/agora/" +
                                        details.id +
                                        "/1"
                                    )
                                  }
                                />
                              </span>
                            </div>
                          ) : (
                            <div className="flex flex-row items-center gap-2">
                              <span>
                                {"https://org.screenit.io/v2/screenitMeeting/#/agora/" +
                                  details.id +
                                  "/1"}
                              </span>

                              <span className="hover:text-blue-500 cursor-pointer">
                                <BsSubtract
                                  onClick={() =>
                                    copyToClipboard(
                                      "https://org.screenit.io/v2/screenitMeeting/#/agora/" +
                                        details.id +
                                        "/1"
                                    )
                                  }
                                />
                              </span>
                            </div>
                          )}
                        </p>
                        <p className="text-xs text-gray-600 font-semibold col-span-1">
                          Meeting ID :
                        </p>
                        <p className="text-xs text-gray-400 font-normal col-span-4">
                          {details.meeting_id}
                        </p>
                        <p className="text-xs text-gray-600 font-semibold col-span-1">
                          Password :
                        </p>
                        <p className="text-xs text-gray-400 font-normal col-span-4">
                          {details.org_pwd}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="p-5">
        <h1 className="text-center font-bold">Admin Report</h1>
        <div className="flex justify-between items-center my-2">
          <div className="flex flex-row items-center gap-2 mb-2">
            <div className="text-xs font-bold text-gray-800">Select Date</div>
            <div className="flex flex-row gap-2">
              <DatePicker
                selected={from}
                onChange={handleFromDateChange}
                dateFormat="dd MMM, yyyy"
                isClearable
                placeholderText="Select From Date"
                className="w-full border border-gray-200 py-2 px-3 focus:outline-none focus:border-gray-300 rounded"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
              <DatePicker
                selected={to}
                onChange={handleToDateChange}
                dateFormat="dd MMM, yyyy"
                isClearable
                placeholderText="Select To Date"
                className="w-full border border-gray-200 py-2 px-3 focus:outline-none focus:border-gray-300 rounded"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
              <button
                type="button"
                onClick={() => clearFilter()}
                className="text-primary text-xs font-thin bg-transparent rounded-lg text-sm w-8 h-8 ms-auto underline"
              >
                Clear
              </button>
            </div>
          </div>
          {/* <button
            type="button"
            className="text-primary text-sm font-semibold py-1 px-2 flex items-center"
            onClick={() => navigate(-1)}
          >
            <BsArrowLeftShort /> Back
          </button> */}
        </div>
        <div style={{ height: "67vh", overflow: "auto" }}>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 overflow-y-auto table-fixed">
            <thead className="text-xs text-white bg-primary">
              <tr>
                <th className="p-2">S.No</th>
                <th className="p-2">Post Title</th>
                <th className="p-2">Organization Name</th>
                <th className="p-2">Client Name</th>
                <th className="p-2">Interviewer Name</th>
                <th className="p-2">Interview Date</th>
                <th className="p-2">Interview Time</th>
                <th className="p-2">Meeting ID</th>
                <th className="p-2">Candidate Name</th>
                <th className="p-2">Candidate Email</th>
                <th className="p-2">Resume</th>
                <th className="p-2">Status</th>
                <th className="p-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {schedule.map((x: any, index: any) => (
                <tr className="odd:bg-white even:bg-gray-100 border-b text-gray-700">
                  <th className="p-2 text-xs 2xl:text-md font-medium text-gray-700">
                    {currentPage * 10 + index + 1}
                  </th>
                  <td
                    className="p-2 text-xs 2xl:text-md truncate"
                    title={x.post.job_title}
                  >
                    {x.post.job_title}
                  </td>
                  <td className="p-2 text-xs 2xl:text-md">
                    {x.post.org.org_name}
                  </td>
                  <td className="p-2 text-xs 2xl:text-md">
                    {x.post.client_name ? x.post.client_name : "-"}
                  </td>
                  <td className="p-2 text-xs 2xl:text-md">
                    {x.interviewer ? x.interviewer.candidate.first_name : "-"}
                  </td>
                  <td className="p-2 text-xs 2xl:text-md">
                    {format(x.utc_starttime, "yyyy-MMM-dd")}
                  </td>
                  <td className="p-2 text-xs 2xl:text-md">
                    {timeConvert(x.utc_starttime, x.utc_endtime)}
                  </td>
                  <td className="p-2 text-xs 2xl:text-md">
                    {x.meeting_id ? x.meeting_id : "-"}
                  </td>
                  <td className="p-2 text-xs 2xl:text-md">
                    {x.candidate.first_name}
                  </td>
                  <td
                    className="p-2 text-xs 2xl:text-md truncate"
                    title={x.candidate.email}
                  >
                    {x.candidate.email}
                  </td>
                  <td className="p-2 text-xs 2xl:text-md">
                    <a
                      href={x.candidate.resume[0].url}
                      target="_blank"
                      className="text-xs font-normal text-blue-500"
                    >
                      Download
                    </a>
                  </td>
                  <td className="p-2 text-xs 2xl:text-md">{x.status}</td>
                  <td className="p-2 text-xs 2xl:text-md  text-primary text-sm ">
                    {(x.status == "Rescheduled" || x.status == "Scheduled") && (
                      <div
                        className="cursor-pointer h-full "
                        onClick={() => {
                          schDetail(x);
                        }}
                      >
                        <BsLink />
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {totalPost > 10 && (
          <PaginationButtons
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </>
  );
};

export default AdminReport;
