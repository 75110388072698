import React from "react";
import orgApi from "../../../api/org_api";
import PaginationButtons from "../../../component/PaginationButtons";
import {
  BsArrowLeftShort,
  BsEnvelope,
  BsFillTelephoneFill,
} from "react-icons/bs";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import S from "../../../Services/Services";

const TAReport: React.FC = () => {
  const [schedule, setSchedule] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(0);
  const [pages, setPages] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPost, setTotal] = React.useState(0);
  const [showStats, setShowStats] = React.useState(false);
  const [stats, setStats] = React.useState([] as any);
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = React.useState("");
  const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    schedule.length === 0 && getSchedule();
  }, []);
  React.useEffect(() => {
    getSchedule();
  }, [search.length > 2, search.length === 0]);
  const getSchedule = async () => {
    const org = JSON.parse(localStorage.getItem("user__details")?.toString()!);
    const data = { search };
    const res = await orgApi.taReport(org.id, data);
    setSchedule(res.data.org);
    setTotal(res.data.org.length);
    const t = Math.ceil(res.data.org.length / 10);
    setTotalPages(t);
    const from = 0;
    const to = 10;
    const d = res.data.org.slice(from, to);
    setPages(d);
  };

  React.useEffect(() => {
    const from = currentPage * 10;
    let to = currentPage * 10 + 10;
    currentPage * 10 + 10 > totalPost && (to = totalPost);
    const d = schedule.slice(from, to);
    setPages(d);
  }, [currentPage]);

  const nextPage = async (org_id: any, status: any) => {
    setStats([]);
    const data = { status };
    const res = await orgApi.taReportSchedule(org_id, data);
    setStats(res.data.schedule);
    setSelectedStatus(status);
    setShowStats(true);
  };

  const timezoneList = [
    { id: 0, name: "IST", fullName: "Asia/Kolkata" },
    { id: 1, name: "IST", fullName: "Asia/Calcutta" },
    { id: 2, name: "EST", fullName: "America/New_York" },
    { id: 3, name: "GMT", fullName: "Europe/London" },
    { id: 4, name: "CST", fullName: "America/Chicago" },
    { id: 5, name: "PST", fullName: "America/Los_Angeles" },
    { id: 6, name: "HST", fullName: "Pacific/Honolulu" },
    { id: 7, name: "AHST", fullName: "America/Anchorage" },
  ];

  const getTz = (tz: any) => {
    let timezonetype = "";
    for (let i = 0; i < timezoneList.length; i++) {
      timezoneList[i].fullName === tz && (timezonetype = timezoneList[i].name);
    }
    return timezonetype;
  };

  return (
    <div className="overflow-auto shadow-lg sm:rounded-lg p-3">
      {!showStats ? (
        <div className="shadow-lg sm:rounded-lg p-3">
          <h3 className="text-center font-bold">TA Report</h3>
          <div className="flex justify-between my-2">
            <div className="w-1/4  mr-5">
              <form className="searchForm">
                <input
                  placeholder="Search.."
                  type="search"
                  id="search"
                  name="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className={"w-full border py-2 px-3 outline-none rounded"}
                ></input>
                <button className="searchButton" type="button">
                  Search
                </button>
              </form>
            </div>
            <button
              type="button"
              className="text-primary text-sm font-semibold py-1 px-2 flex items-center"
              onClick={() => navigate(-1)}
            >
              <BsArrowLeftShort /> Back
            </button>
          </div>

          <div style={{ height: "80vh", width: "91vw", overflow: "auto" }}>
            <table
              align="center"
              className="table-auto text-sm text-left text-center text-gray-500 dark:text-gray-400"
            >
              <thead className="text-xs text-white bg-primary">
                <tr>
                  <th scope="col" className="p-2">
                    S.No
                  </th>
                  <th scope="col" className="p-2">
                    TA Name
                  </th>
                  <th scope="col" className="p-2">
                    Setup
                  </th>
                  <th scope="col" className="p-2">
                    Scheduled
                  </th>
                  <th scope="col" className="p-2">
                    Rescheduled
                  </th>
                  <th scope="col" className="p-2">
                    Completed
                  </th>
                  <th scope="col" className="p-2">
                    No Show
                  </th>
                  <th scope="col" className="p-2">
                    Proxy
                  </th>
                  <th scope="col" className="p-2">
                    Rejected
                  </th>
                  <th scope="col" className="p-2">
                    Cancelled
                  </th>
                  <th scope="col" className="p-2">
                    Selected
                  </th>
                  <th scope="col" className="p-2">
                    Client Submit
                  </th>
                  <th scope="col" className="p-2">
                    Client Interview
                  </th>
                  <th scope="col" className="p-2">
                    Offered
                  </th>
                  <th scope="col" className="p-2">
                    To Join
                  </th>
                  <th scope="col" className="p-2">
                    Declined
                  </th>
                  <th scope="col" className="p-2">
                    Joined
                  </th>
                  <th scope="col" className="p-2">
                    Schedule vs Submission
                  </th>
                  <th scope="col" className="p-2">
                    Submission vs Interview
                  </th>
                  <th scope="col" className="p-2">
                    Interview vs Offered
                  </th>
                  <th scope="col" className="p-2">
                    Offered vs Declined
                  </th>
                  <th scope="col" className="p-2">
                    Offered vs Joined
                  </th>
                  <th scope="col" className="p-2">
                    Schedule vs Joined
                  </th>
                </tr>
              </thead>
              <tbody>
                {pages &&
                  pages.map((x: any, index: any) => (
                    <tr className="odd:bg-white even:bg-gray-100 border-b text-gray-700">
                      <th className="p-2 text-xs 2xl:text-md font-medium text-gray-700 whitespace-nowrap">
                        {currentPage * 10 + index + 1}
                      </th>
                      <td className="p-2 text-xs 2xl:text-md">
                        {x.first_name ? x.first_name : "-"}
                        {x.last_name && x.last_name}
                      </td>
                      <td className="p-2 text-xs 2xl:text-md">{x.setup}</td>
                      <td
                        className="p-2 text-xs 2xl:text-md cursor-pointer hover:text-blue-500 hover:text-xs hover:font-semibold"
                        onClick={() =>
                          x.scheduled > 0 && nextPage(x.org_id, "Scheduled")
                        }
                      >
                        {x.scheduled}
                      </td>
                      <td
                        className="p-2 text-xs 2xl:text-md cursor-pointer hover:text-blue-500 hover:text-xs hover:font-semibold"
                        onClick={() =>
                          x.rescheduled > 0 && nextPage(x.org_id, "Rescheduled")
                        }
                      >
                        {x.rescheduled}
                      </td>
                      <td
                        className="p-2 text-xs 2xl:text-md cursor-pointer hover:text-blue-500 hover:text-xs hover:font-semibold"
                        onClick={() =>
                          x.completed > 0 && nextPage(x.org_id, "Completed")
                        }
                      >
                        {x.completed}
                      </td>
                      <td
                        className="p-2 text-xs 2xl:text-md cursor-pointer hover:text-blue-500 hover:text-xs hover:font-semibold"
                        onClick={() =>
                          x.no_show > 0 && nextPage(x.org_id, "No Show")
                        }
                      >
                        {x.no_show}
                      </td>
                      <td
                        className="p-2 text-xs 2xl:text-md cursor-pointer hover:text-blue-500 hover:text-xs hover:font-semibold"
                        onClick={() =>
                          x.proxy > 0 && nextPage(x.org_id, "Proxy")
                        }
                      >
                        {x.proxy}
                      </td>
                      <td
                        className="p-2 text-xs 2xl:text-md cursor-pointer hover:text-blue-500 hover:text-xs hover:font-semibold"
                        onClick={() =>
                          x.rejected > 0 && nextPage(x.org_id, "Rejected")
                        }
                      >
                        {x.rejected}
                      </td>
                      <td
                        className="p-2 text-xs 2xl:text-md cursor-pointer hover:text-blue-500 hover:text-xs hover:font-semibold"
                        onClick={() =>
                          x.cancelled > 0 && nextPage(x.org_id, "Cancelled")
                        }
                      >
                        {x.cancelled}
                      </td>
                      <td
                        className="p-2 text-xs 2xl:text-md cursor-pointer hover:text-blue-500 hover:text-xs hover:font-semibold"
                        onClick={() =>
                          x.selected > 0 && nextPage(x.org_id, "Selected")
                        }
                      >
                        {x.selected}
                      </td>
                      <td
                        className="p-2 text-xs 2xl:text-md cursor-pointer hover:text-blue-500 hover:text-xs hover:font-semibold"
                        onClick={() =>
                          x.submitted > 0 && nextPage(x.org_id, "Submitted")
                        }
                      >
                        {x.submitted}
                      </td>
                      <td
                        className="p-2 text-xs 2xl:text-md"
                        onClick={() =>
                          x.interview > 0 && nextPage(x.org_id, "Interview")
                        }
                      >
                        {x.interview}
                      </td>
                      <td
                        className="p-2 text-xs 2xl:text-md cursor-pointer hover:text-blue-500 hover:text-xs hover:font-semibold"
                        onClick={() =>
                          x.offered > 0 && nextPage(x.org_id, "Offered")
                        }
                      >
                        {x.offered}
                      </td>
                      <td
                        className="p-2 text-xs 2xl:text-md"
                        onClick={() =>
                          x.tojoin > 0 && nextPage(x.org_id, "ToJoin")
                        }
                      >
                        {x.tojoin}
                      </td>
                      <td
                        className="p-2 text-xs 2xl:text-md"
                        onClick={() =>
                          x.declined > 0 && nextPage(x.org_id, "Declined")
                        }
                      >
                        {x.declined}
                      </td>
                      <td
                        className="p-2 text-xs 2xl:text-md"
                        onClick={() =>
                          x.joined > 0 && nextPage(x.org_id, "Joined")
                        }
                      >
                        {x.joined}
                      </td>
                      <td className="p-2 text-xs 2xl:text-md">
                        {x.setup == 0
                          ? 0 + "%"
                          : Math.round((x.submitted_count / x.setup) * 100) +
                            "%"}
                      </td>
                      <td className="p-2 text-xs 2xl:text-md">
                        {x.submitted_count == 0
                          ? 0 + "%"
                          : Math.round(
                              (x.interview_count / x.submitted_count) * 100
                            ) + "%"}
                      </td>
                      <td className="p-2 text-xs 2xl:text-md">
                        {x.interview_count == 0
                          ? 0 + "%"
                          : Math.round(
                              (x.offered_count / x.interview_count) * 100
                            ) + "%"}
                      </td>
                      <td className="p-2 text-xs 2xl:text-md">
                        {x.offered_count == 0
                          ? 0 + "%"
                          : Math.round((x.declined / x.offered_count) * 100) +
                            "%"}
                      </td>
                      <td className="p-2 text-xs 2xl:text-md">
                        {x.offered_count == 0
                          ? 0 + "%"
                          : Math.round((x.joined / x.offered_count) * 100) +
                            "%"}
                      </td>
                      <td className="p-2 text-xs 2xl:text-md">
                        {x.setup == 0
                          ? 0 + "%"
                          : Math.round((x.joined / x.setup) * 100) + "%"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {totalPost > 10 && (
            <PaginationButtons
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      ) : (
        <div style={{ height: "80vh" }}>
          <div className="flex justify-end my-2">
            <button
              type="button"
              className="text-primary text-sm font-semibold py-1 px-2 flex items-center"
              onClick={() => setShowStats(false)}
            >
              <BsArrowLeftShort /> Back
            </button>
          </div>

          <table className="table-fixed text-xs w-full">
            <thead>
              <tr>
                <th align="left" className="w-1/5">
                  <p className="p-2">Candidate Details</p>
                </th>
                <th align="left">
                  <p className="p-2">Post Title</p>
                </th>
                <th align="left">
                  <p className="p-2">Round</p>
                </th>
                <th align="left">
                  <p className="p-2">Interviewer Name</p>
                </th>
                <th align="left">
                  <p className="p-2">Interview Date</p>
                </th>
                <th align="left">
                  <p className="p-2">Interview Time</p>
                </th>
                {selectedStatus === "Completed" && (
                  <th align="left">
                    <p className="p-2">Status</p>
                  </th>
                )}
                {(selectedStatus === "Completed" ||
                  selectedStatus === "Rejected" ||
                  selectedStatus === "Selected" ||
                  selectedStatus === "Submitted" ||
                  selectedStatus === "Offered") && (
                  <th align="left">
                    {S.getOrgDomain() !== "nam-it.com" ? (
                      <p className="p-2">SIT Score</p>
                    ) : (
                      <p className="p-2">Feedback</p>
                    )}
                  </th>
                )}
                {(selectedStatus === "No Show" ||
                  selectedStatus === "Proxy") && (
                  <th align="left">
                    <p className="p-2">Feedback</p>
                  </th>
                )}
                {(selectedStatus === "Completed" ||
                  selectedStatus === "Rejected" ||
                  selectedStatus === "Selected" ||
                  selectedStatus === "Submitted" ||
                  selectedStatus === "Offered") && (
                  <th align="left">
                    <p className="p-2">Recommended</p>
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="text-gray-500">
              {stats.map((value: any) => (
                <tr className="bg-gray-50 border-b-2 border-white ">
                  <td>
                    <div className="p-2 flex flex-col items-start gap-1 truncate">
                      <p className="font-semibold truncate">
                        {value.candidate.first_name} {value.candidate.last_name}
                      </p>
                      <p className="flex flex-row items-center gap-1 truncate">
                        <BsEnvelope /> &nbsp;
                        <p>{value.candidate.email}</p>
                      </p>
                      <p className="flex flex-row items-center gap-1">
                        <BsFillTelephoneFill /> &nbsp;
                        {value.candidate.mobile}
                      </p>
                    </div>
                  </td>

                  <td>
                    <p className="p-2 truncate">{value.post.job_title}</p>
                  </td>
                  <td align="left">
                    <p className="p-2">{value.level}</p>
                  </td>
                  <td>
                    <p className="p-2 truncate">
                      {value.interviewer && value.interviewer.candidate.first_name}{" "}
                      {value.interviewer && value.interviewer.candidate.last_name}
                    </p>
                  </td>
                  <td>
                    <p className="p-2">
                      {format(value.utc_starttime, "dd MMM yyyy")}
                    </p>
                  </td>
                  <td>
                    {value.utc_endtime && <p className="p-2">
                      {format(value.utc_starttime, "hh:mm a")} to{" "}
                      {format(value.utc_endtime, "hh:mm a")}{" "}
                      {getTz(value.candidate_tz)}
                    </p>}
                  </td>
                  {selectedStatus === "Completed" && (
                    <td>
                      {value.status === "Completed" &&
                      value.feedback_status === "Completed" &&
                      value.feedback.length > 0 && 
                      value.feedback[0].interview_status !== "Completed" ? (
                        <p className="p-2">
                          {value.feedback[0].interview_status}
                        </p>
                      ) : (
                        <p className="p-2">
                          {value.status === "NR" ? "Next Round" : value.status}
                        </p>
                      )}
                    </td>
                  )}
                  {(selectedStatus === "Completed" ||
                    selectedStatus === "Rejected" ||
                    selectedStatus === "Selected" ||
                    selectedStatus === "Submitted" ||
                    selectedStatus === "Offered") && (
                    <td>
                      {value.feedback_status === "Completed" ? (
                        <p
                          className="p-2 text-xs 2xl:text-md cursor-pointer hover:text-blue-500 hover:text-sm hover:font-semibold"
                          onClick={() => value.feedback.length > 0 ? S.viewFeedback(value.id) : window.open(value.feedback_url)}
                        >
                          {S.getOrgDomain() !== "nam-it.com" ? (
                            value.feedback.length > 0 ? value.feedback[0].sit_score: value.sit
                          ) : (
                            <span className="underline text-blue-500">
                              Link
                            </span>
                          )}
                        </p>
                      ) : (
                        <p className="p-2">-</p>
                      )}
                    </td>
                  )}
                  {(selectedStatus === "No Show" ||
                    selectedStatus === "Proxy") && (
                    <td>
                      <p
                        className="p-2 text-xs 2xl:text-md cursor-pointer text-primary font-semibold"
                        onClick={() => S.viewFeedback(value.id)}
                      >
                        View Feedback
                      </p>
                    </td>
                  )}
                  {(selectedStatus === "Completed" ||
                    selectedStatus === "Rejected" ||
                    selectedStatus === "Selected" ||
                    selectedStatus === "Submitted" ||
                    selectedStatus === "Offered") && (
                    <td>
                      {value.feedback_status === "Completed" &&
                      value.feedback.length > 0 &&
                      value.feedback[0].interview_status === "Completed" ? (
                        <p className="p-2">
                          {S.getOrgDomain() !== "nam-it.com" ? (
                            value.feedback[0].recommended_status
                          ) : (
                            <>
                              {value.feedback[0].eligible_for_this_position
                                ? "Yes"
                                : "No"}
                            </>
                          )}
                        </p>
                      ) : (
                        <p className="p-2">-</p>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
export default TAReport;
