import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import api from "../../api/auth_api";
import { SVG } from "../../assets/SVG/SVG";
import Carousel from "../../component/Carousel/Carousel";
import * as slides from "../../component/Carousel/CarousalSlides";
import { Style } from "../../utils/Style";

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [errorMsg, setErrorMessage] = React.useState("");
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .email("Email not valid"),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      setErrorMessage("");
      const data = { email: values.email };
      const res = await api.forgotPassword(data);
      if (res.data.success === true) {
        navigate("/forgotpass_success", { replace: true });
      } else {
        setErrorMessage(res.data.msg);
      }
    },
  });

  return (
    <div>
      <div className=" flex justify-center items-center max-h-screen">
        <div className="lg:p-24 md:p-52 sm:20 p-8 w-full lg:w-1/2">
          <div className="">
            <SVG.LogoDark />
          </div>
          <h1 className="text-xl mt-4 font-semibold">Forgot Password</h1>

          <form onSubmit={formik.handleSubmit}>
            {errorMsg !== "" && (
              <div className="error text-center ">{errorMsg}</div>
            )}
            <div className="mt-4">
              <div>
                <div>
                  <label
                    htmlFor="username"
                    className="text-xs font-semibold text-gray-600 py-2"
                  >
                    Business Email
                  </label>

                  <div className="mb-2">
                    <input
                      id="email"
                      name="email"
                      type="text"
                      placeholder="Email"
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      className={
                        formik.touched.email && formik.errors.email
                          ? Style.iError
                          : Style.iInput
                      }
                    ></input>
                    {formik.touched.email && formik.errors.email && (
                      <div className="error">{formik.errors.email}</div>
                    )}
                  </div>
                </div>
                <div className="w-full"></div>
              </div>
              <div className="mt-4">
                <div className="w-full">
                  <button
                    className="button-primary text-sm text-white font-semibold rounded-md py-2 px-4 w-full"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className="mt-2">
            <span className="text-input-label">Back to</span>
            <span
              onClick={() => navigate("/signin")}
              className="hover:underline text-button-primary pl-2"
            >
              Sign In
            </span>
          </div>
        </div>
        <div className="w-1/2 h-screen hidden lg:block">
          <div className="onboard-box">
            <Carousel slides={slides.slides} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
