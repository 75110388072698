import React from "react";
import orgApi from "../../../api/org_api";
import { ToastContainer, toast } from "react-toastify";
import {
  BsArrowLeft,
  BsBookmarkStar,
  BsBookmarkStarFill,
  BsFileEarmarkText,
  BsPersonFill,
  BsPlus,
} from "react-icons/bs";
import StarRatings from "react-star-ratings";
import S from "../../../Services/Services";
import { Style } from "../../../utils/Style";

type SelectFavouritesProps = { postId: any; callBack: any; from_type: any };

const SelectFavourites: React.FC<SelectFavouritesProps> = ({
  postId,
  callBack,
  from_type,
}: SelectFavouritesProps) => {
  const [interviewerList, setInterviewerList] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [favFilter, setFavFilter] = React.useState(false);
  const [role, setStatus] = React.useState("");
  const [type, setType] = React.useState("Technical");
  const [favCount, setFavCount] = React.useState(0);
  const [openInsDetailModal, setOpenInsDetailModal] = React.useState(false);
  const [interviewer, setInterviewer] = React.useState({} as any);
  const [defaultPage, setDefaultPage] = React.useState("profile");

  React.useEffect(() => {
    fetchJdDetails();
  }, []);

  const fetchJdDetails = async () => {
    // const res = await orgApi.getSingleJdDetail(postId!);
    // if (res.data.success) {
    //   setFavCount(res.data.post.fav_count);
    // } else {
    // }
    setType("Technical");
    if (
      S.getOrgDomain() === "sightspectrum.com" ||
      S.getOrgDomain() === "vegaintellisoft.com" ||
      S.getOrgDomain() === "nam-it.com" ||
      S.getOrgDomain() === "infinitisoftware.net"
    ) {
      setStatus("in-house");
    } else {
      setStatus("interviewer");
    }
  };

  React.useEffect(() => {
    if (role !== "") {
      from_type === "viewFav" ? setFav(true) : fetchInterviewers();
    }
  }, [type, role, search.length > 3, search.length === 0]);

  React.useEffect(() => {
    favcount();
  }, [role]);

  const fetchInterviewers = async () => {
    var userInfo = JSON.parse(
      localStorage.getItem("user__details")?.toString()!
    );
    var orgId = userInfo.master_id !== null ? userInfo.master_id : userInfo.id;
    var reqData = { search, type, role: role ? role : null, org_id: orgId };
    const res = await orgApi.getInterviewer(postId!, reqData);
    res.data.success
      ? setInterviewerList(res.data.interviewer)
      : setInterviewerList([]);
  };
  const setFav = async (fav: Boolean) => {
    if (fav === true) {
      setFavFilter(true);
      var reqData = { search, type, role: role ? role : null };
      const res = await orgApi.getFavInterviewer(postId!, reqData);
      if (res.data.success) {
        setInterviewerList(res.data.interviewer);
        setFavCount(res.data.interviewer.length);
      } else {
        setInterviewerList([]);
      }
    } else {
      setFavFilter(false);
      fetchInterviewers();
    }
  };

  const favcount = async () => {
    if (favFilter === false) {
      var reqData = { search, type, role: role ? role : null };
      const res = await orgApi.getFavInterviewer(postId!, reqData);
      if (res.data.success) {
        setFavCount(res.data.interviewer.length);
      } else {
        setInterviewerList([]);
      }
    }
  };

  const selectInt = async (status: any, data: any) => {
    if (status) {
      var reqData = {
        interviewer_id: data.interviewer.id,
        type: data.interviewer.type,
        role: data.u_p_skill[0].role,
      };
      const res = await orgApi.addFavInterviewer(postId!, reqData);
      if (res.data.success) {
        setInterviewerList((prevState) =>
          prevState.filter((prevItem) => prevItem["id"] !== data.id)
        );
        toast(res.data.msg);
        setFavCount(favCount + 1);
      } else {
        toast(res.data.msg);
      }
    } else {
      const res = await orgApi.removeFavInterviewer(
        postId!,
        data.interviewer.id
      );
      if (res.data.success) {
        setFavCount(favCount - 1);
        setInterviewerList((prevState) =>
          prevState.filter((prevItem) => prevItem["id"] !== data.id)
        );
        toast("Interviewer unfavorited");
      } else {
        toast(res.data.msg);
      }
    }
  };

  const clearFilter = () => {
    if (
      S.getOrgDomain() === "sightspectrum.com" ||
      S.getOrgDomain() === "vegaintellisoft.com" ||
      S.getOrgDomain() === "nam-it.com" ||
      S.getOrgDomain() === "infinitisoftware.net"
    ) {
      setStatus("in-house");
    } else {
      setStatus("interviewer");
    }
    setType("");
  };

  const intDetail = async (data: any) => {
    setInterviewer(data);
    setOpenInsDetailModal(true);
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      {openInsDetailModal && (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-50"
              onClick={() => setOpenInsDetailModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-3xl p-4 mx-auto bg-white shadow-lg">
                <div className="sm:flex">
                  <div className="mt-1 text-center sm:text-left w-full">
                    <div className="flex items-center justify-between border-b">
                      <h6 className="text-sm font-bold text-gray-800 mb-2">
                        Profile
                      </h6>
                      <button
                        onClick={() => setOpenInsDetailModal(false)}
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        <svg
                          className="w-2 h-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span className="sr-only">Close modal</span>
                      </button>
                    </div>
                    <div className=" w-full mt-4 max-w-3xl">
                      <div className=" grid grid-cols-4 gap-1 px-5 py-4 items-center mb-4">
                        <div className="col-span-1 flex justify-center">
                          <div className="h-20 w-20 ml-4 sm:ml-3 mr-1 bg-gray-100 rounded-full overflow-hidden">
                            {interviewer.profile_pic ? (
                              <img
                                src={interviewer.profile_pic}
                                alt="user_profile_photo"
                                className="h-full w-full object-cover"
                              ></img>
                            ) : (
                              <div className="flex justify-center items-center h-20">
                                <BsPersonFill color="grey" size={28} />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-span-1 ">
                          <div className="flex flex-col justify-between gap-3 h-24">
                            <div>
                              <p className="text-xs text-gray-400 font-thin">
                                Name
                              </p>
                              <p className="text-xs text-gray-900 font-semibold truncate">
                                {interviewer.first_name} {interviewer.last_name}
                              </p>
                            </div>
                            <div>
                              <p className="text-xs text-gray-400 font-thin">
                                Designation
                              </p>
                              <p className="text-xs text-gray-900 font-semibold truncate">
                                {interviewer.designation}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-1">
                          <div className="flex flex-col justify-between gap-3 h-24">
                            <div>
                              <p className="text-xs text-gray-400 font-thin">
                                Rating
                              </p>
                              <p className="text-xs text-gray-900 font-semibold truncate">
                                <StarRatings
                                  rating={interviewer.interviewer.avg_rating}
                                  changeRating={(rate: number) =>
                                    console.log(rate)
                                  }
                                  numberOfStars={5}
                                  name="rating"
                                  starRatedColor="orange"
                                  starHoverColor="orange"
                                  starDimension="14px"
                                  starSpacing="2px"
                                />
                              </p>
                            </div>
                            <div>
                              <p className="text-xs text-gray-400 font-thin">
                                Experience
                              </p>
                              <p className="text-xs text-gray-900 font-semibold truncate">
                                {interviewer.year_of_experience} Years{" "}
                                {interviewer.month_of_experience} Months
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-1">
                          <div className="flex flex-col justify-between gap-3 h-24">
                            <div>
                              <p className="text-xs text-gray-400 font-thin">
                                Conducted Interviews
                              </p>
                              <p className="text-xs text-gray-900 font-semibold truncate">
                                {interviewer.interviewer.no_of_schedule}
                              </p>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                        <ul
                          className="flex justify-start -mb-px"
                          id="default-tab"
                          data-tabs-toggle="#default-tab-content"
                          role="tablist"
                        >
                          <li className="me-2">
                            <a
                              onClick={() => setDefaultPage("profile")}
                              className={
                                defaultPage === "profile"
                                  ? "inline-flex items-center justify-center  p-2 text-blue-600 border-b-4 border-blue-600 rounded-t-lg active font-semibold text-xs text-gray-900 dark:border-blue-500"
                                  : "inline-flex items-center justify-center  p-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 text-xs"
                              }
                            >
                              Profile Details
                            </a>
                          </li>
                          <li className="me-2">
                            <a
                              onClick={() => setDefaultPage("skill")}
                              className={
                                defaultPage === "skill"
                                  ? "inline-flex items-center justify-center  p-2 text-blue-600 border-b-4 border-blue-600 rounded-t-lg active font-semibold text-xs text-gray-900 dark:border-blue-500"
                                  : "inline-flex items-center justify-center  p-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 text-xs"
                              }
                            >
                              Skill Set
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div id="default-tab-content">
                        {defaultPage === "profile" ? (
                          <div className="flex flex-col justify-start mt-2 gap-2">
                            {interviewer.resume.length > 0 && (
                              <div className="my-2 flex flex-col gap-2">
                                <label className={Style.label}>Resume</label>
                                <a
                                  className="cursor-pointer"
                                  href={interviewer.resume[0].url}
                                >
                                  <BsFileEarmarkText color="grey" size={36} />
                                </a>
                              </div>
                            )}
                            {interviewer.profile_video != null && (
                              <div className="my-2 flex flex-col gap-2">
                                <label className={Style.label}>
                                  Short Introduction
                                </label>
                                <video
                                  width="300"
                                  height="200"
                                  controls
                                  autoPlay={false}
                                >
                                  <source
                                    src={interviewer.profile_video}
                                    type="video/mp4"
                                  />
                                </video>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="">
                            {interviewer.u_p_skill.map((x: any) => {
                              return (
                                <div className="grid grid-cols-2 border-b py-2 px-2 gap-3">
                                  <p className="text-sm text-gray-500 ">
                                    {x.skill.name}
                                  </p>
                                  <div className="">
                                    <StarRatings
                                      rating={x.self_rating}
                                      changeRating={(rate: number) =>
                                        console.log(rate)
                                      }
                                      numberOfStars={5}
                                      name="rating"
                                      starRatedColor="blue"
                                      starHoverColor="blue"
                                      starDimension="18px"
                                      starSpacing="5px"
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className=" gap-2 mt-3 sm:flex md:flex lg:flex flex-row-reverse">
                      <button
                        className=" mt-2 py-1 px-2.5 text-gray-800 outline-none border text-sm"
                        onClick={() => setOpenInsDetailModal(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="flex flex-col ">
        <div
          className="grid grid-cols-4 gap-3 mt-4 overflow-y-auto"
          style={{ height: "75vh" }}
        >
          <div className="col-span-1">
            <div className="bg-gray-100 rounded-lg flex flex-col border rounded-lg  p-3">
              <div className="flex items-center justify-between  mb-4">
                <h6 className="text-xs font-bold text-gray-800">Filter By</h6>
                <button
                  type="button"
                  onClick={() => clearFilter()}
                  className="text-primary text-xs font-thin bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                >
                  Clear
                </button>
              </div>
              <div className="flex flex-col gap-2 mb-2">
                <div className="text-xs font-bold text-gray-800">Role</div>
                <select
                  id="role"
                  name="role"
                  value={role}
                  onChange={(e) => {
                    setStatus(e.target.value);
                    setFavFilter(false);
                  }}
                  className="w-full border border-gray-200 py-2 px-3 focus:outline-none focus:border-gray-300 rounded"
                >
                  <option value="">Select Role</option>
                  <option value="interviewer">Market Place</option>
                  <option value="in-house">In-House</option>
                  <option value="external">External</option>
                </select>
              </div>
              <div className="flex flex-col gap-2 mb-2">
                <div className="text-xs font-bold text-gray-800">Type</div>
                <select
                  id="type"
                  name="type"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  className="w-full border border-gray-200 py-2 px-3 focus:outline-none focus:border-gray-300 rounded"
                >
                  <option value="Technical">Tech</option>
                  <option value="HR">HR</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-span-3 flex flex-col">
            <div className="flex flex-row justify-between">
              <div className="w-full mr-5">
                <form className="searchForm">
                  <input
                    placeholder="Search.."
                    type="search"
                    id="search"
                    name="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className={"w-full border py-1 px-3 outline-none  rounded"}
                  ></input>
                  <button className="searchButton" type="button">
                    Search
                  </button>
                </form>
              </div>
              <div className="w-full"></div>
              <div className="w-full">
                <div className="gap-2 flex flex-row justify-end">
                  {!favFilter ? (
                    <button
                      type="button"
                      onClick={() => setFav(true)}
                      className="mt-2 py-0.5 px-3.5 text-red-500 outline-none border border-red-500 text-xs rounded-sm flex gap-2 items-center"
                    >
                      <BsBookmarkStar />
                      Interviewers
                      <span className="bg-red-500 rounded-full px-1.5 text-white text-xs">
                        {favCount}
                      </span>
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => setFav(false)}
                      className="mt-2 px-3.5 py-0.5 text-white outline-none border border-red-500 bg-red-500 text-xs rounded-sm flex gap-2 items-center"
                    >
                      <BsBookmarkStarFill />
                      Interviewers
                      <span className="bg-white rounded-full px-1.5 text-red-500 text-xs">
                        {favCount}
                      </span>
                    </button>
                  )}
                  {favFilter && from_type !== "viewFav" && (
                    <button
                      onClick={() => setFav(false)}
                      type="button"
                      className="mt-2 px-3.5 py-0.5 text-white outline-none bg-primary border-primary text-xs rounded-sm flex gap-2 items-center"
                    >
                      <BsArrowLeft />
                      Back
                    </button>
                  )}
                  {favFilter && from_type === "viewFav" && (
                    <button
                      onClick={() => setFav(false)}
                      type="button"
                      className="mt-2 py-0.5  px-3.5 text-white outline-none bg-primary border-primary text-xs rounded-sm flex gap-2 items-center"
                    >
                      <BsPlus /> Add
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center mt-2 gap-2">
              {role !== "" ? (
                <p className="text-sm font-semibold text-gray-700">
                  {role === "in-house"
                    ? "In-House "
                    : role == "external"
                    ? "External "
                    : "Market Place "}
                  Interviewers
                </p>
              ) : (
                <p className="text-md font-bold text-gray-900">
                  All Interviewers
                </p>
              )}
              {favFilter && <BsBookmarkStarFill color="red" size={20} />}
            </div>
            <div className="flex flex-col gap-4 mt-2 overflow-y-auto h-full">
              {interviewerList.map((value: any) => (
                <>
                  <div
                    key={value.id}
                    className=" grid grid-cols-5 gap-1 border border-gray-200 rounded-md bg-gray-50 px-5 py-4 items-center"
                  >
                    <div className="col-span-1 flex justify-center">
                      <div
                        onClick={() => intDetail(value)}
                        className="h-20 w-20 ml-4 sm:ml-3 mr-1 bg-gray-100 rounded-full overflow-hidden cursor-pointer"
                      >
                        {value.profile_pic ? (
                          <img
                            src={value.profile_pic}
                            alt="user_profile_photo"
                            className="h-full w-full object-cover"
                          ></img>
                        ) : (
                          <div className="flex justify-center items-center h-20">
                            <BsPersonFill color="grey" size={28} />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="flex flex-col gap-3">
                        <div>
                          <p className="text-xs text-gray-400 font-thin">
                            Name
                          </p>
                          <p className="text-xs text-gray-900 font-semibold truncate">
                            {value.first_name} {value.last_name}
                          </p>
                        </div>
                        <div>
                          <p className="text-xs text-gray-400 font-thin">
                            Designation
                          </p>
                          <p className="text-xs text-gray-900 font-semibold truncate">
                            {value.designation}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="flex flex-col gap-3">
                        <div>
                          <p className="text-xs text-gray-400 font-thin">
                            Rating
                          </p>
                          <p className="text-xs text-gray-900 font-semibold truncate">
                            <StarRatings
                              rating={value.interviewer.avg_rating}
                              changeRating={(rate: number) => {
                                console.log(rate);
                              }}
                              numberOfStars={5}
                              name="rating"
                              starRatedColor="orange"
                              starHoverColor="orange"
                              starDimension="14px"
                              starSpacing="2px"
                            />
                          </p>
                        </div>
                        <div>
                          <p className="text-xs text-gray-400 font-thin">
                            Skills
                          </p>
                          <p className="text-xs text-gray-900 font-semibold truncate">
                            {S.getSkills(value.u_p_skill)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1 flex justify-end">
                      {!favFilter && (
                        <button
                          type="button"
                          onClick={() => selectInt(true, value)}
                          className="bg-transparent text-red-500 text-lg"
                        >
                          <BsBookmarkStar />
                        </button>
                      )}
                      {favFilter && (
                        <button
                          type="button"
                          onClick={() => selectInt(false, value)}
                          className="bg-transparent text-red-500 text-lg"
                        >
                          <BsBookmarkStarFill />
                        </button>
                      )}
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
        <div className=" gap-2 mt-3 sm:flex md:flex lg:flex flex-row-reverse">
          <button
            className="mt-2 py-1.5 px-2.5 text-white button-primary outline-none text-xs"
            type="button"
            onClick={() => callBack()}
          >
            Save & Next
          </button>
          <button
            className=" mt-2 py-1.5 px-2.5 text-gray-800 outline-none border text-xs"
            onClick={() => callBack()}
            type="button"
          >
            Back
          </button>
        </div>
      </div>
    </>
  );
};
export default SelectFavourites;
