import React from "react";
import { ToastContainer, toast } from "react-toastify";
import Carousel from "../../../component/Carousel/Carousel";
import { slides } from "../../../component/Carousel/CarousalSlides";
import { SVG } from "../../../assets/SVG/SVG";
import { useParams } from "react-router-dom";
import orgApi from "../../../api/org_api";
import StarRatings from "react-star-ratings";
import { Style } from "../../../utils/Style";

const CandidateSkillRate = () => {
  const { schedule_id } = useParams();
  const [primary, setPrimary] = React.useState([] as any);
  const [secondary, setSecondary] = React.useState([] as any);
  const year_options = Array.from({ length: 19 }, (_, index) => index + 2);
  const month_options = Array.from({ length: 10 }, (_, index) => index + 2);
  React.useEffect(() => {
    fetchPostDetails();
  }, []);
  const fetchPostDetails = async () => {
    const res = await orgApi.fetchSelfEveluation(schedule_id);
    if (res.data.post[0].template) {
      let pskil = res.data.post[0].template.t_p_skill;
      let ppskill = [] as any;
      for (let i = 0; i < pskil.length; i++) {
        ppskill.push({
          name: pskil[i].skill.name,
          skill_id: pskil[i].skill_id,
          self_rating: 0,
          year_of_experience: "0",
          month_of_experience: "0",
        });
      }
      setPrimary(ppskill);

      let sskil = res.data.post[0].template.t_s_skill;
      let psskill = [] as any;
      for (let i = 0; i < sskil.length; i++) {
        psskill.push({
          name: sskil[i].skill.name,
          skill_id: sskil[i].skill_id,
          self_rating: 0,
          year_of_experience: "0",
          month_of_experience: "0",
        });
      }
      setSecondary(psskill);
    }
  };

  const submitRate = async () => {
    const data = {
      primary, secondary
    }
    const res = await orgApi.submitSelfEveluation(schedule_id, data);
    if (res.data.success) {
      toast(res.data.msg);
      setTimeout(() => {
        window.location.href = "https://screenit.io";
      }, 1000);
    } else {
      toast(res.data.msg);
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      <div>
        <div className=" flex justify-center items-center max-h-screen overflow-hidden">
          <div className="lg:p-24 md:p-52 sm:20 p-8 w-full lg:w-1/2 ">
            <div>
              <SVG.LogoDark />
            </div>
            <h1 className="text-sm mt-5 font-semibold mb-2">
              Candidate Self Evaluation
            </h1>
            <div className="w-full overflow-y-auto" style={{ height: "25rem" }}>
              <h1 className="text-xs font-semibold mb-2">
                Required Technical Skills
              </h1>
              {primary.map((val: any) => {
                return (
                  <div className="flex flex-col items-start gap-2 w-full mb-1 border-b">
                    <span className=" text-xs font-medium">{val.name}</span>
                    <div className="flex flex-row justify-between items-center my-2 w-full">
                      <StarRatings
                        rating={val.self_rating}
                        changeRating={(rate: number) => {
                          setPrimary((prevItems: any) =>
                            prevItems.map((item: any) =>
                              item.skill_id === val.skill_id
                                ? { ...item, self_rating: rate }
                                : item
                            )
                          );
                        }}
                        numberOfStars={5}
                        name="self_rating"
                        starRatedColor="orange"
                        starHoverColor="orange"
                        starDimension="15px"
                        starSpacing="2px"
                      />
                      <div className="flex flex-row justify-end items-center gap-2">
                        <select
                          id="exp_year"
                          name="exp_year"
                          defaultValue={val.year_of_experience}
                          onChange={(v : any) => {
                            setPrimary((prevItems: any) =>
                              prevItems.map((item: any) =>
                                item.skill_id === val.skill_id
                                  ? { ...item, year_of_experience: v.target.value }
                                  : item
                              )
                            );
                          }}
                          className={Style.iInput}
                        >
                          <option value="" disabled>
                            Select Year *
                          </option>
                          <option value="0">0 year</option>
                          <option value="1">1 year</option>
                          {year_options.map((number) => (
                            <option key={number} value={number}>
                              {number} years
                            </option>
                          ))}
                        </select>
                        <select
                          id="exp_month"
                          name="exp_month"
                          value={val.month_of_experience}
                          onChange={(v : any) => {
                            setPrimary((prevItems: any) =>
                              prevItems.map((item: any) =>
                                item.skill_id === val.skill_id
                                  ? { ...item, month_of_experience: v.target.value }
                                  : item
                              )
                            );
                          }}
                          className={Style.iInput}
                        >
                          <option value="" disabled>
                            Select Month *
                          </option>
                          <option value="0">0 month</option>
                          <option value="1">1 month</option>
                          {month_options.map((number) => (
                            <option key={number} value={number}>
                              {number} months
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                );
              })}
              <h1 className="text-xs mt-6 font-semibold mb-5">
                Preferred Technical Skills
              </h1>
              {secondary.map((val: any) => {
                return (
                  <div className="flex flex-col items-start gap-2 w-full mb-1 border-b">
                    <span className=" text-xs font-medium">{val.name}</span>
                    <div className="flex flex-row justify-between items-center my-2 w-full">
                      <StarRatings
                        rating={val.self_rating}
                        changeRating={(rate: number) => {
                          setSecondary((prevItems: any) =>
                            prevItems.map((item: any) =>
                              item.skill_id === val.skill_id
                                ? { ...item, self_rating: rate }
                                : item
                            )
                          );
                        }}
                        numberOfStars={5}
                        name="self_rating"
                        starRatedColor="orange"
                        starHoverColor="orange"
                        starDimension="15px"
                        starSpacing="2px"
                      />
                      <div className="flex flex-row justify-end items-center gap-2">
                        <select
                          id="exp_year"
                          name="exp_year"
                          defaultValue={val.year_of_experience}
                          onChange={(v : any) => {
                            setSecondary((prevItems: any) =>
                              prevItems.map((item: any) =>
                                item.skill_id === val.skill_id
                                  ? { ...item, year_of_experience: v.target.value }
                                  : item
                              )
                            );
                          }}
                          className={Style.iInput}
                        >
                          <option value="" disabled>
                            Select Year *
                          </option>
                          <option value="0">0 year</option>
                          <option value="1">1 year</option>
                          {year_options.map((number) => (
                            <option key={number} value={number}>
                              {number} years
                            </option>
                          ))}
                        </select>
                        <select
                          id="exp_month"
                          name="exp_month"
                          value={val.month_of_experience}
                          onChange={(v : any) => {
                            setSecondary((prevItems: any) =>
                              prevItems.map((item: any) =>
                                item.skill_id === val.skill_id
                                  ? { ...item, month_of_experience: v.target.value }
                                  : item
                              )
                            );
                          }}
                          className={Style.iInput}
                        >
                          <option value="" disabled>
                            Select Month *
                          </option>
                          <option value="0">0 month</option>
                          <option value="1">1 month</option>
                          {month_options.map((number) => (
                            <option key={number} value={number}>
                              {number} months
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="w-full flex flex-row justify-end my-3">
                <button
                  onClick={() => {
                    submitRate();
                  }}
                  className="button-primary text-sm text-white font-semibold rounded-md py-2 px-4"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="w-1/2 h-screen hidden lg:block">
            <div className="onboard-box">
              <Carousel slides={slides} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateSkillRate;
