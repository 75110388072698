import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SVG } from "../../../assets/SVG/SVG";
import Carousel from "../../../component/Carousel/Carousel";
import * as slides from "../../../component/Carousel/CarousalSlides";
import { Style } from "../../../utils/Style";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import orgApi from "../../../api/org_api";
import { format } from "date-fns";
import S from "../../../Services/Services";

const CandidateSetAvailability: React.FC = () => {
  const navigate = useNavigate();
  const [available_datetime, setAvailableDatetime] = React.useState<Date | null>(null);
  const [datetime_error, setDatetimeError] = React.useState("");
  const { schedule_id, type } = useParams();
  const [role, setRole] = React.useState([] as any);
  const [isDisabled, setisDisabled] = React.useState(false);

  React.useEffect(() => {
    fetchScheduleDetails();
    fetchRoundDetails();
  }, []);

  const fetchRoundDetails = async () => {
    const res = await orgApi.fetchRoundDetails(schedule_id);
    if (res.data["round"]["fav"] !== true) {
      var roles = [];
      res.data["round"]["external"] === true && roles.push("external");
      res.data["round"]["house"] === true && roles.push("in-house");
      // res.data["round"]["market"] === true && roles.push("interviewer");
      setRole(roles);
    }
  }

  const fetchScheduleDetails = async () => {
    if (schedule_id) {
    const res = await orgApi.getScheduleData(schedule_id);
    if (res.data.success) {
      if (res.data.schedule) {
        var data = res.data.schedule;
        if (type === "1") {
          if (
            (data.status === "Schedule Initiated" &&
              data.sub_status === "Schedule Initiated") ||
            (data.status === "Reschedule Initiated" &&
              data.sub_status === "Reschedule Initiated") ||
            (data.status === "Schedule ReInitiated" &&
              data.sub_status === "Schedule Initiated")
          ) {
            navigate("/success/availability_success/null", { replace: true });
          } else {
            navigate("/success/availability_set/null", { replace: true });
          }
        } else if (type === "2") {
          data.status !== "New" && data.status !== "Schedule ReInitiated" &&
            navigate("/success/availability_set/null", { replace: true });
        }
      } else {
        // navigate("/success/snft/null", { replace: true });
      }
    }
  } else {
    toast("Schedule id is undefined");
  }
  };

  const handleDateChange = (date: any) => {
    setDatetimeError("");
    setAvailableDatetime(date);
  };

  const setAvailability = async () => {
    if (available_datetime) {
      setDatetimeError("");
    } else {
      setDatetimeError("Please select the availability date and time");
      return;
    }
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setisDisabled(true);
    var data = {
      available_datetime: format(available_datetime, "yyyy-MM-dd HH:mm:ss"),
      tz: timezone,
    } as any;
    if(role.length !== 0) {
      data['role'] = role;
    }
    const res = await orgApi.candidateAvailability(data, schedule_id);
    res.data.success
      ? navigate("/success/availability_success/null", { replace: true })
      : toast(res.data.msg);
    setisDisabled(false);
  };
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      <div>
        <div className=" flex justify-center items-center max-h-screen">
          <div className="lg:p-24 md:p-52 sm:20 p-8 w-full lg:w-1/2">
            <div className="">
              <SVG.LogoDark />
            </div>
            <h1 className="text-xl mt-6 font-semibold mb-5">
              Set Your Availability
            </h1>
            <form>
              <div className="rounded-t-lg text-xs text-gray-800 w-full flex items-center justify-between border-gray-300">
                <span className="block font-semibold">Select Date & Time</span>
              </div>

              <div className="mb-1 py-2">
                <DatePicker
                  selected={available_datetime}
                  onChange={handleDateChange}
                  dateFormat="dd MMM, yyyy hh:mm a"
                  showTimeSelect
                  timeFormat="hh:mm a"
                  timeIntervals={30}
                  minDate={new Date()}
                  excludeTimes={S.generateDisabledTimes(available_datetime)}
                  isClearable
                  placeholderText="Select date and time"
                  className={Style.iInput}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
                {datetime_error && (
                  <div className="error">{datetime_error}</div>
                )}
              </div>

              <div className="w-full"></div>
              <div className="mt-4">
                <div className="w-full">
                  <button
                    className={!isDisabled ? "button-primary text-sm text-white font-semibold rounded-md py-2 px-4 w-full": "button-primary-tint text-sm text-white font-semibold rounded-md py-2 px-4 w-full"}
                    type="button"
                    onClick={() => setAvailability()}
                    disabled={isDisabled}
                  >
                    Set Availability
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="w-1/2 h-screen hidden lg:block">
            <div className="onboard-box">
              <Carousel slides={slides.slides} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateSetAvailability;
