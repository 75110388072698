import React from "react";
import orgApi from "../../../api/org_api";
import {
  BsCheckCircleFill,
  BsCircle,
  BsPencil,
  BsPersonFill,
  BsPlus,
} from "react-icons/bs";
import { CloseModal } from "../../../component/Layout/CloseModal";
import { Style } from "../../../utils/Style";
import S from "../../../Services/Services";
import StarRatings from "react-star-ratings";
import Multiselect from "multiselect-react-dropdown";
import { ToastContainer, toast } from "react-toastify";
const nodata = require("../../../assets/images/nodata.png");

type InterviewerProps = { setLoading: Function };

const InterviewerGroup: React.FC<InterviewerProps> = ({
  setLoading,
}: InterviewerProps): JSX.Element => {
  const [interviewerList, setInterviewerList] = React.useState([] as any);
  const [intGroup, setIntGroup] = React.useState([] as any);
  const [openIndex, setopenIndex] = React.useState(-1);
  const [role, setRole] = React.useState("interviewer");
  const [type, setType] = React.useState("Technical");
  const [search, setSearch] = React.useState("");
  const [searchG, setSearchG] = React.useState("");
  const [groupName, setGroupName] = React.useState("");
  const [groupId, setGroupId] = React.useState("");
  const [grpType, setGrpType] = React.useState("add");
  const [selectedInts, setSelectedInts] = React.useState([] as any);
  const [skillList, setSkillList] = React.useState([] as any);
  const [selectedSkill, setSelectedSkill] = React.useState([] as any);
  const [showAddInterviewersModal, setShowAddInterviewersModal] =
    React.useState(false);
  const [showAddGroupModal, setShowAddGroupModal] = React.useState(false);
  const userInfo = JSON.parse(
    localStorage.getItem("user__details")?.toString()!
  );
  const orgId = userInfo.master_id !== null ? userInfo.master_id : userInfo.id;
  React.useEffect(() => {
    getSkills();
  }, []);
  React.useEffect(() => {
    if (searchG.length > 2 || searchG.length === 0) {
      getGroups();
    }
  }, [searchG]);
  React.useEffect(() => {
    if (groupId) {
      getInterviewerList(groupId);
    }
  }, [type, role, search.length > 2, search.length === 0, selectedSkill]);
  const getSkills = async () => {
    setLoading(true);
    const data = { search: "" };
    const res = await orgApi.skills(data);
    setSkillList(res.data.skills);
    setLoading(false);
  };
  const getGroups = async () => {
    setLoading(true);
    const data = { search: searchG };
    const res = await orgApi.listInterviewerGroup(orgId, data);
    if (res.data.success) {
      setIntGroup(res.data.group);
    } else {
      setIntGroup([]);
    }
    setLoading(false);
  };
  const addGroup = async () => {
    setLoading(true);
    const data = { name: groupName, org_id: orgId };
    const res = await orgApi.addInterviewerGroup(data);
    setLoading(false);
    if (res.data.success) {
      getGroups();
      setGroupName("");
      toast(res.data.msg);
      setShowAddGroupModal(false);
    } else {
      toast(res.data.msg);
    }
    setLoading(false);
  };
  const editGroup = async () => {
    setLoading(true);
    const data = { name: groupName };
    const res = await orgApi.updateInterviewerGroup(groupId, data);
    setLoading(false);
    if (res.data.success) {
      getGroups();
      setGroupName("");
      toast(res.data.msg);
      setShowAddGroupModal(false);
    } else {
      toast(res.data.msg);
    }
    setLoading(false);
  };
  const openAddInt = (e: any, gid: any) => {
    setGroupId(gid);
    setSelectedSkill([]);
    setType("Technical");
    setRole("interviewer");
    setSelectedInts([]);
    e.stopPropagation();
    setShowAddInterviewersModal(true);
    getInterviewerList(gid);
  };
  const getInterviewerList = async (gid: any) => {
    setLoading(true);
    let ss = [] as any;
    selectedSkill.forEach((element: any) => {
      ss.push(element.id);
    });
    const data = { search: search, role: role, type: type, skill: ss };
    const res = await orgApi.listGroupInterviewer(gid, data);
    setLoading(false);
    if (res.data.success) {
      let d = res.data.interviewer;
      d.map((element: any) => {
        if (selectedInts.includes(element.interviewer.id)) {
          element["selected"] = true;
        } else {
          element["selected"] = false;
        }
      });
      setInterviewerList(d);
    } else {
      setInterviewerList([]);
    }
    setLoading(false);
  };
  const addInterviewers = async () => {
    setLoading(true);
    const data = { interviewers: selectedInts };
    const res = await orgApi.addGroupInterviewer(groupId, data);
    setLoading(false);
    if (res.data.success) {
      toast(res.data.msg);
      setShowAddInterviewersModal(false);
      let d = intGroup;
      let ind = d.findIndex((e: any) => e.id === groupId);
      if (ind != -1) {
        d[ind] = res.data.group[0];
      }
      setIntGroup(d);
    } else {
      toast(res.data.msg);
    }
    setLoading(false);
  };
  const removeInterviewers = async (groupId: any, grpIntId: any) => {
    setLoading(true);
    const res = await orgApi.removeGroupInterviewer(groupId, grpIntId);
    setLoading(false);
    if (res.data.success) {
      toast(res.data.msg);
      let d = intGroup;
      let ind = d.findIndex((e: any) => e.id === groupId);
      if (ind != -1) {
        d[ind] = res.data.group[0];
      }
      setIntGroup(d);
    } else {
      toast(res.data.msg);
    }
    setLoading(false);
  };

  const clearFilter = () => {
    if (
      S.getOrgDomain() === "sightspectrum.com" ||
      S.getOrgDomain() === "vegaintellisoft.com" ||
      S.getOrgDomain() === "nam-it.com" ||
      S.getOrgDomain() === "infinitisoftware.net"
    ) {
      setRole("in-house");
    } else {
      setRole("interviewer");
    }
    setType("Technical");
    setSelectedSkill([]);
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      {showAddInterviewersModal && (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowAddInterviewersModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-6xl p-4 mx-auto bg-white shadow-lg">
                <div className="sm:flex">
                  <div className="mt-1 text-center sm:text-left w-full">
                    <div className="flex items-center justify-between border-b">
                      <h6 className="text-sm font-bold text-gray-800 mb-2">
                        Add Interviewers
                      </h6>
                      <button
                        onClick={() => setShowAddInterviewersModal(false)}
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        <CloseModal />
                        <span className="sr-only">Close modal</span>
                      </button>
                    </div>
                    <div className="m-3">
                      <div className="flex flex-col ">
                        <div
                          className="grid grid-cols-6 gap-3 mt-4 overflow-y-auto"
                          style={{ height: "70vh" }}
                        >
                          <div className="col-span-2">
                            <div className="bg-gray-100 rounded-lg flex flex-col border rounded-lg  p-3">
                              <div className="flex items-center justify-between  mb-4">
                                <h6 className="text-xs font-bold text-gray-800">
                                  Filter By
                                </h6>
                                <button
                                  type="button"
                                  onClick={() => clearFilter()}
                                  className="text-primary text-xs font-thin bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                                >
                                  Clear
                                </button>
                              </div>
                              <div className="flex flex-col gap-2 mb-2">
                                <div className="text-xs font-bold text-gray-800">
                                  Role
                                </div>
                                <select
                                  id="role"
                                  name="role"
                                  value={role}
                                  onChange={(e) => {
                                    setRole(e.target.value);
                                  }}
                                  className="w-full border border-gray-200 py-2 px-3 focus:outline-none focus:border-gray-300 rounded"
                                >
                                  <option value="">Select Role</option>
                                  <option value="interviewer">
                                    Market Place
                                  </option>
                                  <option value="in-house">In-House</option>
                                  <option value="external">External</option>
                                </select>
                              </div>
                              <div className="flex flex-col gap-2 mb-2">
                                <div className="text-xs font-bold text-gray-800">
                                  Type
                                </div>
                                <select
                                  id="type"
                                  name="type"
                                  value={type}
                                  onChange={(e) => setType(e.target.value)}
                                  className="w-full border border-gray-200 py-2 px-3 focus:outline-none focus:border-gray-300 rounded"
                                >
                                  <option value="Technical">Tech</option>
                                  <option value="HR">HR</option>
                                </select>
                              </div>
                              <div className="flex flex-col gap-2 mb-2">
                                <div className="text-xs font-bold text-gray-800">
                                  Skills
                                </div>
                                <Multiselect
                                  className="w-full bg-white rounded"
                                  options={skillList}
                                  selectedValues={selectedSkill}
                                  onSelect={(selectedList, _) => {
                                    setSelectedSkill(selectedList);
                                  }}
                                  onRemove={(selectedList, _) => {
                                    setSelectedSkill(selectedList);
                                  }}
                                  displayValue="name"
                                ></Multiselect>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-4 flex flex-col">
                            <div className="flex flex-row justify-between">
                              <div className="w-full mr-5">
                                <form className="searchForm">
                                  <input
                                    placeholder="Search.."
                                    type="search"
                                    id="search"
                                    name="search"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    className={
                                      "w-full border py-1 px-3 outline-none  rounded"
                                    }
                                  ></input>
                                  <button
                                    className="searchButton"
                                    type="button"
                                  >
                                    Search
                                  </button>
                                </form>
                              </div>
                              <div className="w-full"></div>
                              <div className="w-full">
                                <div className="gap-2 flex flex-row justify-end"></div>
                              </div>
                            </div>
                            <div className="flex flex-row items-center mt-2 gap-2">
                              {role !== "" ? (
                                <p className="text-sm font-semibold text-gray-700">
                                  {role === "in-house"
                                    ? "In-House "
                                    : role == "external"
                                    ? "External "
                                    : "Market Place "}
                                  Interviewers
                                </p>
                              ) : (
                                <p className="text-md font-bold text-gray-900">
                                  All Interviewers
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col gap-4 mt-2 overflow-y-auto h-full">
                              {interviewerList.length != 0 &&
                                interviewerList.map((value: any) => {
                                  return (
                                    <>
                                      <div
                                        key={value}
                                        className=" grid grid-cols-6 gap-1 border border-gray-200 rounded-md bg-gray-50 px-2 py-4 items-center"
                                      >
                                        <div className="col-span-1 flex justify-center">
                                          <div
                                            // onClick={() => intDetail(value)}
                                            className="h-20 w-20 mr-1 bg-gray-100 rounded-full overflow-hidden cursor-pointer"
                                          >
                                            {value.profile_pic ? (
                                              <img
                                                src={value.profile_pic}
                                                alt="user_profile_photo"
                                                className="h-full w-full object-cover"
                                              ></img>
                                            ) : (
                                              <div className="flex justify-center items-center h-20">
                                                <BsPersonFill
                                                  color="grey"
                                                  size={28}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="col-span-2">
                                          <div className="flex flex-col gap-3">
                                            <div>
                                              <p className="text-xs text-gray-400 font-thin">
                                                Name
                                              </p>
                                              <p className="text-xs text-gray-900 font-semibold truncate">
                                                {value.first_name}{" "}
                                                {value.last_name}
                                              </p>
                                            </div>
                                            <div>
                                              <p className="text-xs text-gray-400 font-thin">
                                                Designation
                                              </p>
                                              <p className="text-xs text-gray-900 font-semibold truncate">
                                                {value.designation}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-span-2">
                                          <div className="flex flex-col gap-3">
                                            <div>
                                              <p className="text-xs text-gray-400 font-thin">
                                                Rating
                                              </p>
                                              <p className="text-xs text-gray-900 font-semibold truncate">
                                                <StarRatings
                                                  rating={
                                                    value.interviewer.avg_rating
                                                  }
                                                  changeRating={(
                                                    rate: number
                                                  ) => {
                                                    console.log(rate);
                                                  }}
                                                  numberOfStars={5}
                                                  name="rating"
                                                  starRatedColor="orange"
                                                  starHoverColor="orange"
                                                  starDimension="14px"
                                                  starSpacing="2px"
                                                />
                                              </p>
                                            </div>
                                            <div>
                                              <p className="text-xs text-gray-400 font-thin">
                                                Skills
                                              </p>
                                              <p className="text-xs text-gray-900 font-semibold truncate">
                                                {S.getSkills(value.u_p_skill)}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-span-1 flex justify-end">
                                          {!value["selected"] && (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                let d = selectedInts;
                                                d.push(value.interviewer.id);
                                                setSelectedInts(d);
                                                setInterviewerList(
                                                  (prevItem: any) =>
                                                    prevItem.map((item: any) =>
                                                      item.id === value.id
                                                        ? {
                                                            ...item,
                                                            selected: true,
                                                          }
                                                        : {
                                                            ...item,
                                                            selected:
                                                              item.selected,
                                                          }
                                                    )
                                                );
                                              }}
                                              className="bg-transparent text-gray-300 text-lg"
                                            >
                                              <BsCircle />
                                            </button>
                                          )}
                                          {value["selected"] && (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                var d = selectedInts.filter(
                                                  (item: any) =>
                                                    item ===
                                                    value.interviewer.id
                                                );
                                                setSelectedInts(d);
                                                setInterviewerList(
                                                  (prevItem: any) =>
                                                    prevItem.map((item: any) =>
                                                      item.id === value.id
                                                        ? {
                                                            ...item,
                                                            selected: false,
                                                          }
                                                        : {
                                                            ...item,
                                                            selected:
                                                              item.selected,
                                                          }
                                                    )
                                                );
                                              }}
                                              className="bg-transparent text-primary text-lg"
                                            >
                                              <BsCheckCircleFill />
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" gap-2 mt-3 sm:flex md:flex lg:flex justify-end">
                      <button
                        className=" mt-2 py-1.5 px-2.5 text-gray-800 outline-none border bg-gray-100 text-xs"
                        onClick={() => setShowAddInterviewersModal(false)}
                        type="button"
                      >
                        Cancel
                      </button>
                      <button
                        className="mt-2 py-1.5 px-2.5 text-white button-primary outline-none text-xs"
                        type="button"
                        onClick={() => {
                          addInterviewers();
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showAddGroupModal && (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowAddInterviewersModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white shadow-lg">
                <div className="sm:flex">
                  <div className="mt-1 text-center sm:text-left w-full">
                    <div className="flex items-center justify-between border-b">
                      <h6 className="text-sm font-bold text-gray-800 mb-2">
                        {grpType === "add" ? "Add" : "Edit"} Interviewer Group
                      </h6>
                      <button
                        onClick={() => setShowAddGroupModal(false)}
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        <CloseModal />
                        <span className="sr-only">Close modal</span>
                      </button>
                    </div>
                    <div className="m-3">
                      <p className={Style.label}>Group Name</p>
                      <input
                        placeholder="Enter Group Name"
                        type="text"
                        id="group_name"
                        name="group_name"
                        value={groupName}
                        onChange={(e: any) => setGroupName(e.target.value)}
                        className={Style.iInput}
                      ></input>
                    </div>
                    <div className=" gap-2 mt-3 sm:flex md:flex lg:flex justify-end">
                      <button
                        className=" mt-2 py-1.5 px-2.5 text-gray-800 outline-none border bg-gray-100 text-xs"
                        onClick={() => setShowAddGroupModal(false)}
                        type="button"
                      >
                        Cancel
                      </button>
                      <button
                        className="mt-2 py-1.5 px-2.5 text-white button-primary outline-none text-xs"
                        type="button"
                        onClick={() =>
                          grpType === "add" ? addGroup() : editGroup()
                        }
                      >
                        {grpType === "add" ? "Add" : "Update"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="flex flex-col" style={{ height: "86vh" }}>
        <div className="flex flex-row justify-between">
          <p className="text-md font-semibold text-gray-700">
            Interviewer Group
          </p>
          <div className="flex justify-end items-center gap-2">
            <form className="searchForm">
              <input
                placeholder="Search.."
                type="search"
                id="searchG"
                name="searchG"
                value={searchG}
                onChange={(e) => setSearchG(e.target.value)}
                className={"w-full border py-1.5 px-3 outline-none  rounded"}
              ></input>
              <button className="searchButton" type="button">
                Search
              </button>
            </form>
            <button
              type="button"
              onClick={(e: any) => {
                e.stopPropagation();
                setShowAddGroupModal(true);
                setGroupName("");
                setGrpType("add");
              }}
              className="py-2 px-3.5 text-white outline-none bg-primary border-primary text-xs rounded-md flex gap-2 items-center"
            >
              + Add Group
            </button>
          </div>
        </div>
        <div className="overflow-y-auto mt-3">
          {intGroup.length === 0 && (
            <div className="flex flex-col justify-center items-center my-16 gap-1">
              <img
                src={nodata}
                alt="user_profile_photo"
                className="h-32 w-32 object-cover"
              ></img>
              <p className="text-xs font-thin text-gray-400">No Data Found.</p>
            </div>
          )}
          <div id="accordion-collapse" data-accordion="collapse">
            {intGroup.length !== 0 &&
              intGroup.map((v: any, index: number) => {
                return (
                  <>
                    <h2 id="accordion-collapse-heading-1">
                      <button
                        type="button"
                        onClick={() =>
                          openIndex !== index
                            ? setopenIndex(index)
                            : setopenIndex(-1)
                        }
                        className="flex items-center justify-between w-full px-5 py-3 font-medium rtl:text-right text-gray-600 font-semibold border border-gray-200 focus:ring-1 focus:ring-gray-200 hover:bg-gray-100 gap-3"
                        data-accordion-target="#accordion-collapse-body-1"
                        aria-expanded="true"
                        aria-controls="accordion-collapse-body-1"
                      >
                        <span>
                          {v.name}{" "}
                          <span className="ml-2 px-1.5 py-0.5 bg-primary text-white text-xs font-semi-bold rounded">
                            {v.g_interviewer.length}
                          </span>
                        </span>
                        <div className="flex justify-end items-center gap-2">
                          <button
                            type="button"
                            onClick={(event) => openAddInt(event, v.id)}
                            className="py-2 px-3.5 text-gray-600 outline-none bg-white border border-gray-200 text-xs rounded-md flex gap-2 items-center"
                          >
                            <BsPlus /> Add Interviewers
                          </button>
                          <button
                            type="button"
                            onClick={(e: any) => {
                              e.stopPropagation();
                              setGroupName(v.name);
                              setGroupId(v.id);
                              setGrpType("edit");
                              setShowAddGroupModal(true);
                            }}
                            className="py-2 px-3.5 text-gray-600 outline-none bg-white border border-gray-200 text-xs rounded-md flex gap-2 items-center"
                          >
                            <BsPencil /> Edit Group
                          </button>
                        </div>
                      </button>
                    </h2>
                    <div
                      id="accordion-collapse-body-1"
                      className={openIndex === index ? "" : "hidden"}
                      aria-labelledby="accordion-collapse-heading-1"
                    >
                      <div className="p-5 border border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                        <table className="table-fixed text-xs w-full">
                          <thead>
                            <tr>
                              <th align="left">
                                <p className="p-2">Profile Pic</p>
                              </th>
                              <th align="left">
                                <p className="p-2">Name</p>
                              </th>
                              <th align="left">
                                <p className="p-2">Email</p>
                              </th>
                              <th align="left">
                                <p className="p-2">Contact Number</p>
                              </th>
                              <th align="left">
                                <p className="p-2">Role</p>
                              </th>
                              <th align="left">
                                <p className="p-2">Designation</p>
                              </th>
                              <th align="left">
                                <p className="p-2">Skills</p>
                              </th>
                              <th align="right"></th>
                            </tr>
                          </thead>
                          <tbody className="text-gray-600">
                            {v.g_interviewer.map((value: any) => (
                              <tr className="bg-gray-50 border-b-2 border-white ">
                                <td>
                                  <p className="p-2">
                                    <div className="h-16 w-16 bg-gray-100 rounded-full overflow-hidden">
                                      {value.interviewer.candidate
                                        .profile_pic ? (
                                        <img
                                          src={
                                            value.interviewer.candidate
                                              .profile_pic
                                          }
                                          alt="user_profile_photo"
                                          className="h-full w-full object-cover"
                                        ></img>
                                      ) : (
                                        <div className="flex justify-center items-center h-16">
                                          <BsPersonFill
                                            color="grey"
                                            size={28}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </p>
                                </td>
                                <td>
                                  <p className="p-2">
                                    {value.interviewer.candidate.first_name +
                                      " " +
                                      value.interviewer.candidate.last_name}
                                  </p>
                                </td>
                                <td>
                                  <p
                                    className="p-2 truncate"
                                    title={value.interviewer.candidate.email}
                                  >
                                    {value.interviewer.candidate.email}
                                  </p>
                                </td>
                                <td>
                                  <p className="p-2">
                                    +
                                    {
                                      value.interviewer.candidate
                                        .mobile_country_code
                                    }{" "}
                                    {value.interviewer.candidate.mobile}
                                  </p>
                                </td>
                                <td>
                                  <p className="p-2">
                                    {value.interviewer.candidate.u_p_skill[0]
                                      .role === "interviewer"
                                      ? "Market Place"
                                      : value.interviewer.candidate.u_p_skill[0]
                                          .role === "external"
                                      ? "External"
                                      : "In-House"}
                                  </p>
                                </td>
                                <td>
                                  <p className="p-2">
                                    {value.interviewer.type}
                                  </p>
                                </td>
                                <td>
                                  <p className="p-2 truncate">
                                    {S.getSkills(
                                      value.interviewer.candidate.u_p_skill
                                    )}
                                  </p>
                                </td>
                                <td>
                                  <p className="py-2 px-5 flex justify-end">
                                    <button
                                      onClick={() => {
                                        removeInterviewers(v.id, value.id);
                                      }}
                                    >
                                      <CloseModal />
                                    </button>
                                  </p>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default InterviewerGroup;
