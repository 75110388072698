import React from "react";
import {
  BsBoxArrowRight,
  BsFillGridFill,
} from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../utils/UseContext";
const logo = require("../../assets/images/logo_new.png");
// const logo = require(" ../../../public/assets/images/logo.png");

type sidebarProps = {
  state: string;
  setState: Function;
};
const Sidebar: React.FC<sidebarProps> = ({
  state,
  setState,
}: sidebarProps): JSX.Element => {
  const { intlogout } = useAuth();
  React.useEffect(() => {}, [state]);
  return (
    <aside className="hidden sm:flex sm:flex-col">
      <NavLink
        to="/schedule_history"
        onClick={() => {
          setState("schedule_history");
        }}
        className="inline-flex items-center flex-col justify-center h-20 w-20 sidebar-bg-dark"
      >
        <></>
        <img src={logo} alt="" className="p-2"></img>
        {/* <span className="border-b mt-2  mb-4 border-gray-400 w-full"></span> */}
      </NavLink>
      <div className="flex-grow flex flex-col justify-between text-gray-500 sidebar-bg-dark">
        <nav className="flex flex-col mx-1 ">
          <NavLink
            to="/schedule_history"
            onClick={() => {
              setState("schedule_history");
            }}
            className="flex flex-col  items-center"
          >
            <div
              className={
                state === "schedule_history"
                  ? "inline-flex items-center justify-center py-3 rounded-lg sidebar-icons-select"
                  : "inline-flex items-center justify-center py-3 rounded-lg sidebar-icons"
              }
            >
              <BsFillGridFill />
            </div>
            <span
              className={
                state === "schedule_history"
                  ? "text-xs sidebar-icons-select-text"
                  : "text-xs sidebar-icons-text"
              }
            >
              Schedules
            </span>
            <span
              className={
                state === "schedule_history"
                  ? "border-b-2 mt-2 mb-4 w-full	"
                  : "border-b mt-2 mb-4 border-gray-400 w-full	"
              }
            ></span>
          </NavLink>
        </nav>
        <div className="inline-flex items-center justify-center h-20 w-20 border-t border-white">
          <button className="p-3 hover:text-gray-400 focus:text-gray-400">
            <span className="sr-only">Logout</span>
            <div
              onClick={() => {
                intlogout();
              }}
              className={
                "inline-flex items-center justify-center py-3 rounded-lg sidebar-icons"
              }
            >
              <span className="sr-only">Logout</span>
              <BsBoxArrowRight />
            </div>
          </button>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
