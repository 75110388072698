import { Navigate, Route, Routes } from "react-router-dom";

import { useAuth } from "./utils/UseContext";

import SignIn from "./pages/Public/Signin";
import SignUp from "./pages/Public/Signup";
import RegistrationSuccess from "./pages/Public/RegistrationSuccess";
import SetPassword from "./pages/Public/SetPassword";
import OrganizationProfileUpdate from "./pages/Public/OrganizationProfileUpdate";
import ProfileNameUpdate from "./pages/Public/profileNameUpdate";
import Layout from "./pages/Private/Layout";
import Dashboard from "./pages/Private/Dashboard";
import UserManagement from "./pages/Private/UserManagement";
import ForgotPasswordSuccess from "./pages/Public/ForgotPasswordSuccess";
import ForgotPassword from "./pages/Public/ForgotPassword";
import OnboardingSuccess from "./pages/Public/OnboardingSuccess";
import JdList from "./pages/Private/UploadCandidate/JdList";
import JdRounds from "./pages/Private/UploadCandidate/JdRounds";
import { CandidateList } from "./pages/Private/UploadCandidate/CandidateList";
import CandidateSetAvailability from "./pages/Private/UploadCandidate/CandidateSetAvailability";
import AvailabilitySuccess from "./pages/Private/UploadCandidate/SuccessScreen";
import ScheduleConfirmation from "./pages/Private/UploadCandidate/ScheduleConfirmation";
import InterviewerSignup from "./pages/Interviewer/InterviewerSignup";
import InterviewerSignin from "./pages/Interviewer/InterviewerSignin";
import InterviewerRegiter from "./pages/Interviewer/InterviewerRegister";
import InterviewerOnboarding from "./pages/Interviewer/InterviewerOnboarding";
import InterviewerVerifyOtp from "./pages/Interviewer/InterviewerVerifyOtp";
import InterviewerLayout from "./pages/Interviewer/private/InterviewerLayout";
import ScheduleHistory from "./pages/Interviewer/private/ScheduleHistory";
import InterviewerProfile from "./pages/Interviewer/private/InterviewerProfile";
import SelectInterviewers from "./pages/Private/UploadCandidate/SelectInterviewers";
import InterviewerAddTechFeedback from "./pages/Interviewer/InterviewerAddTechFeedback";
import ViewTechFeedback from "./pages/Private/UploadCandidate/ViewTechFeedback";
import Report from "./pages/Private/Reports/Report";
import InterviewReport from "./pages/Private/Reports/InterviewReport";
import UploadJdLayout from "./pages/Private/UploadJd/UploadJdLayout";
import InterviewerManagement from "./pages/Private/ManageInterviewer/InterviewerManagement";
import JDReport from "./pages/Private/Reports/JDReport";
import InterviewResult from "./pages/Private/InterviewResult";
import DownloadTechFeedback from "./pages/Private/UploadCandidate/DownloadTechFeedback";
import TAReport from "./pages/Private/Reports/TAReport";
import SingleDateSchedule from "./pages/Private/SingleDateSchedule";
import IntForgotPassword from "./pages/Interviewer/IntForgotPassword";
import IntForgotPasswordSuccess from "./pages/Interviewer/IntForgotPasswordSuccess";
import IntSetPassword from "./pages/Interviewer/IntSetPassword";
import ReportList from "./pages/Private/Reports/ReportList";
import OrgProfile from "./pages/Private/Profile/OrgProfile";
import InterviewerAddTechFeedbackNamIT from "./pages/Interviewer/InterviewerAddTechFeedbackNamIT";
import ViewTechFeedbackNamIT from "./pages/Private/UploadCandidate/ViewTechFeedbackNamIt";
import AdminReport from "./pages/Private/Reports/AdminReport";
import ViewChat from "./pages/Private/UploadCandidate/ViewChat";
import InterviewerGroup from "./pages/Private/ManageInterviewer/InterviewerGroup";
import CandidateSkillRate from "./pages/Private/UploadCandidate/CandidateSkillRate";
import ViewCandidateRate from "./pages/Private/UploadCandidate/ViewCandidateRate";

function App() {
  const { authenticated } = useAuth();
  const { int_authenticated } = useAuth();

  return (
    <div>
      <Routes>
        {/* Organization */}
        <Route
          path="/"
          element={
            authenticated ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to={"/signin"} />
            )
          }
        />
        <Route
          path="/signin"
          element={authenticated ? <Navigate to="/dashboard" /> : <SignIn />}
        />
        <Route
          path="/signup"
          element={authenticated ? <Navigate to="/dashboard" /> : <SignUp />}
        />
        <Route path="/registration_success" element={<RegistrationSuccess />} />
        <Route path="/onboarding_success" element={<OnboardingSuccess />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/forgotpass_success" element={<ForgotPasswordSuccess />} />
        <Route
          path="/set_password/:email/:key/:type"
          element={<SetPassword />}
        />
        <Route
          path="/organization_profile_update"
          element={<OrganizationProfileUpdate />}
        />
        <Route path="/profile_name_update" element={<ProfileNameUpdate />} />
        <Route
          path="/set_availability_v3/:schedule_id/:type"
          element={<CandidateSetAvailability />}
        />
        <Route
          path="/schedule_confirmation/:schedule_id/:interviewer_id"
          element={<ScheduleConfirmation />}
        />
        <Route path="/success/:type/:msg" element={<AvailabilitySuccess />} />
        <Route
          path="/view_feedback_tech/:schedule_id"
          element={<ViewTechFeedback />}
        />
        <Route
          path="/download_tech_feedback"
          element={<DownloadTechFeedback />}
        />
        <Route
          path="/view_feedback_tech_namit/:schedule_id"
          element={<ViewTechFeedbackNamIT />}
        />
        <Route
          path="/view_chat/:schedule_id"
          element={<ViewChat />}
        />
        <Route path="/admin_report" element={<AdminReport />} />
        <Route path="/self_evaluation/:schedule_id" element={<CandidateSkillRate />} />
        <Route path="/view_candidate_rating/:schedule_id" element={<ViewCandidateRate />} />
        <Route
          path="/"
          element={authenticated ? <Layout /> : <Navigate to="/signin" />}
        >
          <Route
            path="/dashboard"
            element={<Dashboard notify={false} setLoading={() => {}} />}
          />
          <Route
            path="/interview_result/:type"
            element={<InterviewResult setLoading={() => {}} />}
          />
          <Route
            path="/signle_date_schedule/:date"
            element={<SingleDateSchedule setLoading={() => {}} />}
          />
          <Route path="/job_description" element={<JdList setLoading={() => {}} />} />
          <Route
            path="/upload_candidate/:id"
            element={<JdRounds setLoading={() => {}} />}
          />
          <Route
            path="/candidate_list/:post_id/:round_id/:level"
            element={<CandidateList setLoading={() => {}} />}
          />
          <Route
            path="/select_interviewers/:post_id/:round_id/:from_page/:int_type"
            element={<SelectInterviewers setLoading={() => {}} />}
          />
          <Route
            path="/upload_jd"
            element={<UploadJdLayout setLoading={() => {}} />}
          />
          <Route
            path="/user_management"
            element={<UserManagement setLoading={() => {}} />}
          />
          <Route
            path="/interviewer_management"
            element={<InterviewerManagement setLoading={() => {}} />}
          />
          <Route
            path="/interviewer_group"
            element={<InterviewerGroup setLoading={() => {}} />}
          />
          <Route
            path="/org_profile"
            element={<OrgProfile setLoading={() => {}} />}
          />
          <Route path="/report_list" element={<ReportList />} />
          <Route path="/report" element={<Report />} />
          <Route path="/interview_report" element={<InterviewReport  setLoading={() => {}} />} />
          <Route path="/jd_report" element={<JDReport />} />
          <Route path="/ta_report" element={<TAReport />} />
        </Route>
        <Route path="/*" element={<Navigate to="/signin" />} />
        {/* Interviewer */}
        <Route
          path="/"
          element={
            int_authenticated ? (
              <Navigate to="/schedule_history" />
            ) : (
              <Navigate to={"/interviewer_signin"} />
            )
          }
        />
        <Route
          path="/interviewer_signup"
          element={
            int_authenticated ? (
              <Navigate to="/schedule_history" />
            ) : (
              <InterviewerSignup />
            )
          }
        />
        <Route path="/int_forgot_password" element={<IntForgotPassword />} />
        <Route path="/int_forgotpass_success" element={<IntForgotPasswordSuccess />} />
        <Route
          path="/int_set_password/:userId"
          element={<IntSetPassword />}
        />
        <Route
          path="/interviewer_signin"
          element={
            int_authenticated ? (
              <Navigate to="/schedule_history" />
            ) : (
              <InterviewerSignin />
            )
          }
        />
        <Route
          path="/interviewer_register/:user_id/:email/:phone"
          element={<InterviewerRegiter />}
        />
        <Route
          path="/intervierer_onboarding"
          element={<InterviewerOnboarding />}
        />
        <Route
          path="/intervierer_verify_otp/:id/:type"
          element={<InterviewerVerifyOtp />}
        />
        <Route
          path="/add_feedback_tech/:schedule_id"
          element={<InterviewerAddTechFeedback />}
        />
        <Route
          path="/add_feedback_tech_namit/:schedule_id"
          element={<InterviewerAddTechFeedbackNamIT />}
        />
        <Route
          path="/"
          element={
            int_authenticated ? (
              <InterviewerLayout />
            ) : (
              <Navigate to="/interviewer_signin" />
            )
          }
        >
          <Route
            path="/schedule_history"
            element={<ScheduleHistory setLoading={() => {}} />}
          />
          <Route
            path="/interviewer_profile"
            element={<InterviewerProfile setLoading={() => {}} />}
          />
        </Route>
        {/* Admin services */}
        <Route
          path="/admin_service"
          element={<InterviewerAddTechFeedbackNamIT />}
        />
      </Routes>
    </div>
  );
}

export default App;
