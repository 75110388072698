import React from "react";
import StarRatings from "react-star-ratings";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { Style } from "../../../utils/Style";
import html2pdf from "html2pdf.js";

const DownloadTechFeedback: React.FC = () => {
  const { state } = useLocation();
  const schedule = state?.schedule || {};
  const navigate = useNavigate();

  React.useEffect(() => {
    setTimeout(() => {
      downloadPDF();
    }, 500);
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  }, []);
  const downloadPDF = () => {
    const element = document.getElementById("pdfdocument");
    const options = {
      margin: 5,
      // filename: "feedback.pdf",
      filename: `${schedule["candidate"]["first_name"]}-${schedule.post.job_title}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 3 },
      pagebreak: { avoid: [".avoid", ".text-xs", ".text-sm"] },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().from(element).set(options).save();
  };
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const timezoneList = [
    { id: 0, name: "IST", fullName: "Asia/Kolkata" },
    { id: 1, name: "IST", fullName: "Asia/Calcutta" },
    { id: 2, name: "EST", fullName: "America/New_York" },
    { id: 3, name: "GMT", fullName: "Europe/London" },
    { id: 4, name: "CST", fullName: "America/Chicago" },
    { id: 5, name: "PST", fullName: "America/Los_Angeles" },
    { id: 6, name: "HST", fullName: "Pacific/Honolulu" },
    { id: 7, name: "AHST", fullName: "America/Anchorage" },
  ];
  const getTz = (tz: any) => {
    var timezonetype = "";
    for (let i = 0; i < timezoneList.length; i++) {
      if (timezoneList[i].fullName === tz) {
        timezonetype = timezoneList[i].name;
      }
    }
    return timezonetype;
  };

  return (
    <>
      {Object.keys(schedule).length !== 0 && (
        <div className="flex justify-center bg-gray-100 w-full min-h-screen">
          {/* ref={targetRef} */}
          <div className="w-4/6 bg-white pl-5 pr-5 py-5 my-5">
            <div id="pdfdocument" className="pl-5 pr-5">
              <div className="flex justify-center items-center max-h-screen overflow-hidden">
                <div className="p4-10">
                  <h1 className="text-md font-semibold">
                    Interview Evaluation Sheet
                  </h1>
                </div>
              </div>
              <div className="flex justify-left mb-2">
                <div className="pt-3 text-md ">
                  <h1 className="text-slate-800 text-sm font-semibold ">
                    {schedule.post.job_title}
                  </h1>
                </div>
              </div>
              <div className="border-b border-blue-300"></div>
              <div className="grid grid-cols-2 gap-4 py-5">
                <div className="">
                  <h1 className="text-gray-500 text-xs font-light ">
                    Candidate Name
                  </h1>
                  <h1 className="text-black text-xs font-bold">
                    {" "}
                    {schedule["candidate"]["first_name"]}{" "}
                    {schedule.candidate.last_name}
                  </h1>
                </div>
                <div className="">
                  <h1 className="text-gray-500 text-xs font-light">
                    Candidate Email Id
                  </h1>
                  <h1 className="text-black text-xs font-bold">
                    {schedule.candidate.email}
                  </h1>
                </div>
                <div className="">
                  <h1 className="text-gray-500 text-xs font-light">
                    Candidate Phone No
                  </h1>
                  <h1 className="text-black text-xs font-bold">
                    {schedule.candidate.mobile}
                  </h1>
                </div>
                <div className="">
                  <h1 className="text-gray-500 text-xs font-light">
                    Interviewer Name
                  </h1>
                  <h1 className="text-black text-xs font-bold">
                    {schedule.interviewer.candidate.first_name}
                  </h1>
                </div>
                <div className="">
                  <h1 className="text-gray-500 text-xs font-light">
                    Interview Date
                  </h1>
                  <h1 className="text-black text-xs font-bold">
                    {format(schedule.utc_starttime, "dd MMM yyyy")}
                  </h1>
                </div>
                <div className="">
                  <h1 className="text-gray-500 text-xs font-light">
                    Interview Time
                  </h1>
                  <h1 className="text-black text-xs font-bold">
                    {format(schedule.utc_starttime, "hh:mm a")} to{" "}
                    {format(schedule.utc_endtime, "hh:mm a")}{" "}
                    {getTz(schedule.candidate_tz)}
                  </h1>
                </div>
                <div className="">
                  <h1 className="text-gray-500 text-xs font-light">
                    Mode of Interview
                  </h1>
                  <h1 className="text-black text-xs font-bold">
                    {schedule.post.interview_type}
                  </h1>
                </div>
                <div className="">
                  <h1 className="text-gray-500 text-xs font-light">
                    Interview Duration
                  </h1>
                  <h1 className="text-black text-xs font-bold">
                    {schedule.interview_duration}
                  </h1>
                </div>
                <div className="">
                  <h1 className="text-gray-500 font-normal text-xs ">
                    Candidate No Show
                  </h1>

                  <div className="flex justify-left gap-2">
                    <div className="flex justify-start item-center gap-2">
                      <input
                        type="radio"
                        name="candidate_noshow"
                        value="yes"
                        checked={schedule.feedback[0].candidate_no_show}
                      />
                      <h1 className="text-black text-xs font-bold">Yes</h1>
                    </div>
                    <div className="flex justify-start item-center gap-2">
                      <input
                        type="radio"
                        name="candidate_noshow"
                        value="no"
                        checked={!schedule.feedback[0].candidate_no_show}
                      />
                      <h1 className="text-black text-xs font-bold">No</h1>
                    </div>
                  </div>
                </div>
                <div className="">
                  <h1 className="text-xs font-normal text-gray-500">
                    Candidate Proxy
                  </h1>

                  <div className="flex justify-left gap-2">
                    <div className="flex justify-start item-center gap-2">
                      <input
                        type="radio"
                        name="candidate_proxy"
                        value="yes"
                        checked={schedule.feedback[0].proxy}
                      />
                      <h1 className="text-black text-xs font-bold">Yes</h1>
                    </div>
                    <div className="flex justify-start item-center gap-2">
                      <input
                        type="radio"
                        name="candidate_proxy"
                        value="no"
                        checked={!schedule.feedback[0].proxy}
                      />
                      <h1 className="text-black text-xs font-bold">No</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-200 rounded p-4 w-32">
                <div>
                  <h1 className="text-xs font-normal text-black ">
                    Interview Status
                  </h1>
                  <h1
                    className={
                      schedule.feedback[0].interview_status === "Completed"
                        ? "text-xs font-bold text-primary col-span-3"
                        : "text-xs font-bold text-red-500 col-span-3"
                    }
                  >
                    {schedule.feedback[0].interview_status}
                  </h1>
                </div>
              </div>
              {schedule.feedback[0].interview_status !== "Completed" && (
                <>
                  <h1 className="text-xs font-bold text-black col-span-1 mt-2">
                    Reason
                  </h1>
                  <h1 className="text-xs font-thin text-black col-span-3">
                    {schedule.feedback[0].incomplete_reason_cmt}
                  </h1>
                </>
              )}
              {schedule.feedback[0].interview_status !== "Completed" && (
                <>
                  <h1 className="text-xs font-bold text-black col-span-1 mt-2">
                    Comments
                  </h1>
                  <div className="col-span-3 w-full">
                    <textarea
                      name="Enter your Comments"
                      className={Style.iInput}
                      disabled={true}
                      rows={2}
                      cols={40}
                      value={schedule.feedback[0].comments}
                    />
                  </div>
                </>
              )}
              {schedule.feedback[0].interview_status === "Completed" && (
                <>
                  <div className="pt-7">
                    <h1 className="text-sm font-semibold text-gray-800 mb-2">
                      Technical Evaluation
                    </h1>
                    <h1 className="text-xs font-normal text-gray-400">
                      Please evaluate the candidate on the following on a scale
                      of 1 to 5 (1 Lowest, 5 Highest)
                    </h1>
                  </div>

                  <div className="pt-7 border-b border-blue-300">
                    <h1 className="text-sm font-semibold text-gray-800 mb-2">
                    Required Technical Skill Details
                    </h1>
                  </div>
                  {schedule.feedback[0].fp_skill.map((x: any) => (
                    <div className="px-2 py-2">
                      <div className=" text-sm font-bold mb-3">
                        {x.skill.name}
                      </div>
                      <div className="flex justify-center">
                        <table className="table-fixed w-full">
                          <tbody className="text-gray-600 text-xs">
                            <tr className="bg-gray-50 border-b-2 border-white avoid">
                              <td>
                                <p className="p-2">Rating</p>
                              </td>
                              <td>
                                <p className="p-2">
                                  <StarRatings
                                    rating={x.skill_rating}
                                    changeRating={(rate: number) =>
                                      console.log(rate)
                                    }
                                    numberOfStars={5}
                                    name="rating"
                                    starRatedColor="orange"
                                    starHoverColor="orange"
                                    starDimension="15px"
                                    starSpacing="2px"
                                  />
                                </p>
                              </td>
                            </tr>
                            <tr className="bg-gray-50 border-b-2 border-white avoid">
                              <td>
                                <p className="p-2">Experience</p>
                              </td>
                              <td>
                                <p className="p-2">{x.experience}</p>
                              </td>
                            </tr>
                            <tr className="bg-gray-50 border-b-2 border-white avoid">
                              <td>
                                <p className="p-2">Technical Concepts</p>
                              </td>
                              <td>
                                <p className="p-2">{x.program_concept}</p>
                              </td>
                            </tr>
                            <tr className="bg-gray-50 border-b-2 border-white avoid">
                              <td>
                                <p className="p-2">Logic</p>
                              </td>
                              <td>
                                <p className="p-2">{x.logics}</p>
                              </td>
                            </tr>
                            <tr className="bg-gray-50 border-b-2 border-white avoid">
                              <td>
                                <p className="p-2">Development Tools</p>
                              </td>
                              <td>
                                <p className="p-2">{x.development_tools}</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))}
                  <div className="pt-7 border-b border-blue-300 avoid">
                    <h1 className="text-sm font-semibold text-gray-800 mb-2">
                    Preferred Technical Skill Details
                    </h1>
                  </div>
                  {schedule.feedback[0].fs_skill.map((x: any) => (
                    <div className="px-2 py-2">
                      <div className=" text-sm font-bold mb-3">
                        {x.skill.name}
                      </div>
                      <div className="flex justify-center">
                        <table className="table-fixed w-full">
                          <tbody className="text-gray-600 text-xs">
                            <tr className="bg-gray-50 border-b-2 border-white avoid">
                              <td>
                                <p className="p-2">Rating</p>
                              </td>
                              <td>
                                <p className="p-2">
                                  <StarRatings
                                    rating={x.skill_rating}
                                    changeRating={(rate: number) =>
                                      console.log(rate)
                                    }
                                    numberOfStars={5}
                                    name="rating"
                                    starRatedColor="orange"
                                    starHoverColor="orange"
                                    starDimension="15px"
                                    starSpacing="2px"
                                  />
                                </p>
                              </td>
                            </tr>
                            <tr className="bg-gray-50 border-b-2 border-white avoid">
                              <td>
                                <p className="p-2">Experience</p>
                              </td>
                              <td>
                                <p className="p-2">{x.experience}</p>
                              </td>
                            </tr>
                            <tr className="bg-gray-50 border-b-2 border-white avoid">
                              <td>
                                <p className="p-2">Technical Concepts</p>
                              </td>
                              <td>
                                <p className="p-2">{x.program_concept}</p>
                              </td>
                            </tr>
                            <tr className="bg-gray-50 border-b-2 border-white avoid">
                              <td>
                                <p className="p-2">Logic</p>
                              </td>
                              <td>
                                <p className="p-2">{x.logics}</p>
                              </td>
                            </tr>
                            <tr className="bg-gray-50 border-b-2 border-white avoid">
                              <td>
                                <p className="p-2">Development Tools</p>
                              </td>
                              <td>
                                <p className="p-2">{x.development_tools}</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ))}
                  <div className="pt-7 border-b border-blue-300 avoid">
                    <h1 className="text-sm font-semibold text-gray-800 mb-2">
                      Non-Technical Skills
                    </h1>
                  </div>

                  <div className="grid grid-cols-2 gap-3 py-5">
                    <div>
                      <p className="text-xs text-black font-thin">
                        Communucation Level
                      </p>
                      <p className="text-xs text-gray-900 font-semibold truncate pt-2 pb-5">
                        <StarRatings
                          rating={schedule.feedback[0].communication_skill}
                          changeRating={(rate: number) => console.log(rate)}
                          numberOfStars={5}
                          name="rating"
                          starRatedColor="orange"
                          starHoverColor="orange"
                          starDimension="18px"
                          starSpacing="3px"
                        />
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-black font-thin">Integrity</p>
                      <p className="text-xs text-gray-900 font-semibold truncate pt-2 pb-5">
                        <StarRatings
                          rating={schedule.feedback[0].intrigity}
                          changeRating={(rate: number) => console.log(rate)}
                          numberOfStars={5}
                          name="rating"
                          starRatedColor="orange"
                          starHoverColor="orange"
                          starDimension="18px"
                          starSpacing="3px"
                        />
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-black font-thin">
                        Stress Tolerance
                      </p>
                      <p className="text-xs text-gray-900 font-semibold truncate pt-2 pb-5">
                        <StarRatings
                          rating={schedule.feedback[0].stress_tolerance}
                          changeRating={(rate: number) => console.log(rate)}
                          numberOfStars={5}
                          name="rating"
                          starRatedColor="orange"
                          starHoverColor="orange"
                          starDimension="18px"
                          starSpacing="3px"
                        />
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-black font-thin">
                        Attention to Details
                      </p>
                      <p className="text-xs text-gray-900 font-semibold truncate pt-2 pb-5">
                        <StarRatings
                          rating={schedule.feedback[0].attention}
                          changeRating={(rate: number) => {
                            console.log(rate);
                          }}
                          numberOfStars={5}
                          name="rating"
                          starRatedColor="orange"
                          starHoverColor="orange"
                          starDimension="18px"
                          starSpacing="3px"
                        />
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-black font-thin">
                        Cooperation
                      </p>
                      <p className="text-xs text-gray-900 font-semibold truncate pt-2 pb-5">
                        <StarRatings
                          rating={schedule.feedback[0].co_operation}
                          changeRating={(rate: number) => {
                            console.log(rate);
                          }}
                          numberOfStars={5}
                          name="rating"
                          starRatedColor="orange"
                          starHoverColor="orange"
                          starDimension="18px"
                          starSpacing="3px"
                        />
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-black font-thin">
                        Learning Ability
                      </p>
                      <p className="text-xs text-gray-900 font-semibold truncate pt-2 pb-5">
                        <StarRatings
                          rating={schedule.feedback[0].learning_ability}
                          changeRating={(rate: number) => {
                            console.log(rate);
                          }}
                          numberOfStars={5}
                          name="rating"
                          starRatedColor="orange"
                          starHoverColor="orange"
                          starDimension="18px"
                          starSpacing="3px"
                        />
                      </p>
                    </div>
                  </div>

                  <div className="pt-7 border-b border-blue-300 avoid">
                    <h1 className="text-sm font-semibold text-gray-800 mb-2">
                      Others
                    </h1>
                  </div>
                  <div className="pt-4 grid grid-cols-2">
                    <div>
                      <h1 className="text-xs font-bold text-black mt-2">
                        Window Violation
                      </h1>
                      <h1 className="text-xs font-thin text-black">
                        <div className="flex justify-left gap-2">
                          <div className="flex justify-start item-center gap-2">
                            <input
                              type="radio"
                              name="window_violation"
                              value="yes"
                              checked={schedule.feedback[0].window_violation}
                            />
                            <h1 className="text-black text-xs font-bold">
                              Yes
                            </h1>
                          </div>
                          <div className="flex justify-start item-center gap-2">
                            <input
                              type="radio"
                              name="window_violation"
                              value="no"
                              checked={!schedule.feedback[0].window_violation}
                            />
                            <h1 className="text-black text-xs font-bold">No</h1>
                          </div>
                        </div>
                      </h1>
                    </div>
                    <div>
                      <h1 className="text-xs font-bold text-black mt-2">
                        Interviewer Comments
                      </h1>
                      <h1 className="text-xs font-thin text-black">
                        {schedule.feedback[0].comments}
                      </h1>
                    </div>
                  </div>

                  <div className="pt-7 border-b border-blue-300">
                    <h1 className="text-sm font-semibold text-gray-800 mb-2 avoid">
                      Final Recommendation
                    </h1>
                  </div>

                  <div className="grid grid-cols-2 gap-4 py-5">
                    <h1 className="text-xs font-bold text-black col-span-1">
                      ScreenIt Score
                    </h1>
                    <div className="">
                      <div className="flex flex-row gap-1 col-span-1">
                        {numbers.map((number) => (
                          <button
                            type="button"
                            className={
                              number <= schedule["feedback"][0]["sit_score"]
                                ? "flex items-center justify-center w-12 h-9 text-white text-sm font-semibold button-primary border rounded-md mb-3 "
                                : "flex items-center justify-center w-12 h-9 text-white text-sm font-semibold bg-gray-200 border rounded-md mb-3 "
                            }
                          >
                            {number}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="bg-gray-200 rounded p-4 w-32 avoid">
                    <div>
                      <h1 className="text-xs font-normal text-black ">
                        Recommended
                      </h1>
                      <h1 className="text-xs font-normal text-green-700">
                        {schedule.feedback[0].recommended_status}
                      </h1>
                    </div>
                  </div>
                  {/* {schedule["feedback"][0]["url"] !== null &&
                    schedule["feedback"][0]["url"] !== "" &&
                    schedule["feedback"][0]["url"] !== undefined && (
                      <div className="pt-7 border-b border-blue-300 avoid">
                        <h1 className="text-sm font-semibold text-gray-800 mb-2">
                          Profile Pic
                        </h1>
                      </div>
                    )}
                  {schedule["feedback"][0]["url"] !== null &&
                    schedule["feedback"][0]["url"] !== "" &&
                    schedule["feedback"][0]["url"] !== undefined && (
                      <div className="avoid">
                        <div className="h-32 w-32 mr-1 mt-2 bg-gray-100 rounded-full overflow-hidden">
                          <img
                            src={schedule["feedback"][0]["url"]}
                            alt="user_profile_photo"
                            className="h-full w-full"
                          ></img>
                        </div>
                      </div>
                    )} */}
                  {schedule.code && schedule.code.length > 0 && (
                    <div className="pt-7 border-b border-blue-300 avoid">
                      <h1 className="text-sm font-semibold text-gray-800 mb-2">
                        Programming Code
                      </h1>
                    </div>
                  )}

                  {schedule.code.map((x: any) => (
                    <div className="avoid">
                      <h1 className="text-sm font-semibold text-gray-900 pt-2">
                        {x.title}
                      </h1>
                      <pre className="text-sm font-normal text-gray-400 pb-2">
                        {x.code}
                      </pre>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DownloadTechFeedback;
