import React from "react";
import Dashboard from "./Dashboard";
import { Route, Routes } from "react-router-dom";
import UserManagement from "./UserManagement";
// import { useAuth } from "../../utils/UseContext";
import Navbar from "../../component/Layout/Navbar";
import Sidebar from "../../component/Layout/Sidebar";
import JdList from "./UploadCandidate/JdList";
import JdRounds from "./UploadCandidate/JdRounds";
import { CandidateList } from "./UploadCandidate/CandidateList";
import SelectInterviewers from "./UploadCandidate/SelectInterviewers";
import Report from "./Reports/Report";
import InterviewReport from "./Reports/InterviewReport";
import UploadJdLayout from "./UploadJd/UploadJdLayout";
import InterviewerManagement from "./ManageInterviewer/InterviewerManagement";
import JDReport from "./Reports/JDReport";
import InterviewResult from "./InterviewResult";
import TAReport from "./Reports/TAReport";
import SingleDateSchedule from "./SingleDateSchedule";
import ReportList from "./Reports/ReportList";
import OrgProfile from "./Profile/OrgProfile";
import InterviewerGroup from "./ManageInterviewer/InterviewerGroup";

function Layout() {
  const [state, setState] = React.useState("dashboard");
  const [notifyOpen, setNotify] = React.useState(false);
  const [loading, setLoadingOpen] = React.useState(false);
  React.useEffect(() => {}, [state]);
  const notificationOpen = (open: boolean): void => setNotify(open);
  const loadingOpen = (open: boolean): void => setLoadingOpen(open);

  return (
    <>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <div className="flex bg-white max-h-screen min-h-screen overflow-hidden">
        <Sidebar state={state} setState={setState} />
        <div className="flex-grow text-gray-800">
          <Navbar notificationOpen={notificationOpen} />
          <main className="p-6 sm:p-3 space-y-1">
            <Routes>
              <Route
                path="/dashboard"
                element={
                  state === "dashboard" && (
                    <Dashboard notify={notifyOpen} setLoading={loadingOpen} />
                  )
                }
              />
              <Route
                path="/interview_result/:type"
                element={
                  state === "dashboard" && (
                    <InterviewResult setLoading={loadingOpen} />
                  )
                }
              />
              <Route
                path="/signle_date_schedule/:date"
                element={
                  state === "dashboard" && (
                    <SingleDateSchedule setLoading={loadingOpen} />
                  )
                }
              />
              <Route
                path="/job_description"
                element={state === "dashboard" && <JdList setLoading={loadingOpen} />}
              />
              <Route
                path="/upload_candidate/:id"
                element={
                  state === "dashboard" && <JdRounds setLoading={loadingOpen} />
                }
              />
              <Route
                path="/candidate_list/:post_id/:round_id/:level"
                element={
                  state === "dashboard" && (
                    <CandidateList setLoading={loadingOpen} />
                  )
                }
              />
              <Route
                path="/select_interviewers/:post_id/:round_id/:from_page/:int_type"
                element={
                  state === "dashboard" && (
                    <SelectInterviewers setLoading={loadingOpen} />
                  )
                }
              />
              <Route
                path="/upload_jd"
                element={
                  state === "dashboard" && (
                    <UploadJdLayout setLoading={loadingOpen} />
                  )
                }
              />
              <Route
                path="/user_management"
                element={
                  state === "user_management" && (
                    <UserManagement setLoading={loadingOpen} />
                  )
                }
              />
              <Route
                path="/interviewer_management"
                element={
                  state === "interviewer_management" && (
                    <InterviewerManagement setLoading={loadingOpen} />
                  )
                }
              />
              <Route
                path="/interviewer_group"
                element={
                  state === "interviewer_management" && (
                    <InterviewerGroup setLoading={loadingOpen} />
                  )
                }
              />
              <Route
                path="/org_profile"
                element={
                  <OrgProfile setLoading={loadingOpen} />
                }
              />
              <Route
                path="/report_list"
                element={
                  state === "report" && (
                    <ReportList/>
                  )
                }
              />
              <Route
                path="/report"
                element={state === "report" && <Report />}
              />
              <Route
                path="/interview_report"
                element={state === "report" && <InterviewReport setLoading={loadingOpen} />}
              />
              <Route
                path="/jd_report"
                element={state === "report" && <JDReport />}
              />
              <Route
                path="/ta_report"
                element={state === "report" && <TAReport />}
              />
            </Routes>
          </main>
        </div>
      </div>
    </>
  );
}

export default Layout;
