import React from "react";
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";

type StarRatingCompProps = { stars: any; callBack: Function };

const StarRatingComp: React.FC<StarRatingCompProps> = ({
  stars,
  callBack,
}: StarRatingCompProps): JSX.Element => {
  const [starState, setStarState] = React.useState(false);
  const defaultstarList = [
    { fill: false, half: false, rate: 1 },
    { fill: false, half: false, rate: 2 },
    { fill: false, half: false, rate: 3 },
    { fill: false, half: false, rate: 4 },
    { fill: false, half: false, rate: 5 },
  ];
  const [starList, setStars] = React.useState(defaultstarList);
  const [starListD, setStarsD] = React.useState(defaultstarList);

  React.useEffect(() => {
    let d = defaultstarList;
    for (let index = 0; index < Math.floor(stars); index++) {
      d[index].fill = true;
    }
    if (stars % 1 !== 0) {
      d[Math.round(stars) - 1].fill = true;
      d[Math.round(stars) - 1].half = true;
    }
    setStars(d);
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      setStarState(true);
      console.log(starState);
    }, 1000);
  }, []);

  const handleMouseOver = (e: any, rate: number) => {
    const iconRect = e.target.getBoundingClientRect();
    const offsetX = e.clientX - iconRect.left;
    const widthPercentage = (offsetX / iconRect.width) * 100;
    const hoverPercentage = Math.min(Math.max(widthPercentage, 0), 100);
    if (hoverPercentage >= 50) {
      let d = defaultstarList;
      for (let index = 0; index < rate; index++) {
        d[index].fill = true;
      }
      setStars(d);
    } else {
      let d = defaultstarList;
      for (let index = 0; index < rate; index++) {
        d[index].fill = true;
        if (index === rate - 1) {
          d[index].half = true;
        }
      }
      setStars(d);
    }
  };
  const handleClick = () => {
    setStarsD(starList);
    let val;
    if (starList[4]["fill"]) {
      starList[4]["half"] ? (val = 4.5) : (val = 5);
    } else if (starList[3]["fill"]) {
      starList[3]["half"] ? (val = 3.5) : (val = 4);
    } else if (starList[2]["fill"]) {
      starList[2]["half"] ? (val = 2.5) : (val = 3);
    } else if (starList[1]["fill"]) {
      starList[1]["half"] ? (val = 1.5) : (val = 2);
    } else if (starList[0]["fill"]) {
      starList[0]["half"] ? (val = 0.5) : (val = 1);
    }
    callBack(val);
  };

  const handleMouseLeave = () => {
    setStars(starListD);
  };

  return (
    <div className="flex flex-start items-center gap-2">
      {starList.map((item: any) => (
        <>
          <span
            onMouseLeave={() => {
              handleMouseLeave();
            }}
            onMouseOver={(e) => {
              handleMouseOver(e, item.rate);
            }}
            onClick={() => {
              handleClick();
            }}
            className="cursor-pointer"
          >
            {item.fill && !item.half && (
              <BsStarFill key={item.rate} color="orange" />
            )}
            {item.fill && item.half && (
              <BsStarHalf key={item.rate} color="orange" />
            )}
            {!item.fill && <BsStar key={item.rate} />}
          </span>
        </>
      ))}
    </div>
  );
};

export default StarRatingComp;
