import React from "react";
// import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api/auth_api";
import { SVG } from "../../assets/SVG/SVG";
import Carousel from "../../component/Carousel/Carousel";
// const logo = require("../../assets/images/logo-black.png");
import * as slides from "../../component/Carousel/CarousalSlides";
import { Style } from "../../utils/Style";
import { Valid } from "../../utils/Validation";
import { ToastContainer, toast } from "react-toastify";

const SetPassword: React.FC = () => {
  const navigate = useNavigate();
  const { email, key, type } = useParams();
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(true);
  const [isCPasswordVisible, setIsCPasswordVisible] = React.useState(true);

  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState: any) => !prevState);
  }
  function toggleCPasswordVisibility() {
    setIsCPasswordVisible((prevState: any) => !prevState);
  }

  const formik = useFormik({
    initialValues: { password: "", confirmPassword: "" },
    validationSchema: Valid.password,
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (type === "1") {
        const data = { password: values.password };
        const res = await api.setPassword(data, key);
        if (res.data.success === true) {
          localStorage.setItem("user_email", email!);
          localStorage.setItem("user_id", key!);
          localStorage.setItem("user_password", values.password);
          localStorage.setItem("token_dum", res.data.token);
          navigate("/organization_profile_update", { replace: true });
        } else {
          alert(res.data.msg);
        }
      } else if (type === "2") {
        const data = { user_id: key, password: values.password };
        localStorage.clear();
        const res = await api.resetPassword(data, key);
        if (res.data.success === true) {
          navigate("/signin", { replace: true });
        }
      } else if (type === "3") {
        const data = { password: values.password };
        const res = await api.setPassword(data, key);
        if (res.data.success === true) {
          toast("User created successfully");
          navigate("/signin", { replace: true });
        } else {
          alert(res.data.msg);
        }
      } else {
        navigate("/signin", { replace: true });
      }
    },
  });

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      <div className=" flex justify-center items-center max-h-screen">
        <div className="lg:p-24 md:p-52 sm:20 p-8 w-full lg:w-1/2">
          <div className="">
            <SVG.LogoDark />
          </div>
          <h1 className="text-xl mt-6 font-semibold">Create your password</h1>
          <h6 className="text-xs text-gray-400 mb-4">
            When creating a password, it's important to choose a combination of
            letters, numbers, and symbols that are not easy to guess.
          </h6>
          <form onSubmit={formik.handleSubmit}>
            <div className="rounded-t-lg text-xs text-gray-800 w-full flex items-center justify-between border-gray-300">
              <span className="block font-semibold">Password</span>
            </div>

            <div className="mb-1 py-2">
              <div className="relative container mx-auto">
                <input
                  type={!isPasswordVisible ? "text" : "password"}
                  id="password"
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  name="password"
                  className={
                    formik.touched.password && formik.errors.password
                      ? Style.iError
                      : Style.iInput
                  }
                  autoComplete="off"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                  onClick={togglePasswordVisibility}
                >
                  {isPasswordVisible ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                  )}
                </button>
              </div>
              {formik.touched.password && formik.errors.password && (
                <div className="error">{formik.errors.password}</div>
              )}
            </div>

            <div className="rounded-t-lg text-xs text-gray-800 w-full flex items-center justify-between border-gray-300">
              <span className="block font-semibold">Confirm Password</span>
            </div>

            <div className="mb-1 py-2">
              <div className="relative container mx-auto">
                <input
                  type={!isCPasswordVisible ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  className={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                      ? Style.iError
                      : Style.iInput
                  }
                  autoComplete="off"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                  onClick={toggleCPasswordVisibility}
                >
                  {isCPasswordVisible ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                  )}
                </button>
              </div>
              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword && (
                  <div className="error">{formik.errors.confirmPassword}</div>
                )}
            </div>

            <div className="w-full"></div>
            <div className="mt-4">
              <div className="w-full">
                <button
                  className="button-primary text-sm text-white font-semibold rounded-md py-2 px-4 w-full"
                  type="submit"
                >
                  Create Password
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="w-1/2 h-screen hidden lg:block">
          <div className="onboard-box">
            <Carousel slides={slides.slides} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
