import React from "react";
import { Route, Routes } from "react-router-dom";
// import { useAuth } from "../../utils/UseContext";
import ScheduleHistory from "./ScheduleHistory";
import Sidebar from "../../../component/IntterviewerLayout/Sidebar";
import Navbar from "../../../component/IntterviewerLayout/Navbar";
import InterviewerProfile from "./InterviewerProfile";


function InterviewerLayout() {
  const [state, setState] = React.useState("schedule_history");
  const [loading, setLoadingOpen] = React.useState(false);
  React.useEffect(() => {}, [state]);
  
  const loadingOpen = (open: boolean): void => {
    setLoadingOpen(open);
  };
  return (
    <>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <div className="flex bg-white max-h-screen min-h-screen overflow-hidden">
        <Sidebar state={state} setState={setState} />
        <div className="flex-grow text-gray-800">
          <Navbar />
          <main className="p-6 sm:p-3 space-y-1">
            <Routes>
              <Route
                path="/schedule_history"
                element={
                  state === "schedule_history" && (
                    <ScheduleHistory setLoading={loadingOpen} />
                  )
                }
              />
              <Route
                path="/interviewer_profile"
                element={
                  state === "schedule_history" && (
                    <InterviewerProfile setLoading={loadingOpen} />
                  )
                }
              />
            </Routes>
          </main>
        </div>
      </div>
    </>
  );
}

export default InterviewerLayout;
