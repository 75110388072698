import React from "react";

type SwitchCompProps = { resumeType: any; setResumeType: Function, index: number, on: string, off: string };

const SwitchComp: React.FC<SwitchCompProps> = ({
  resumeType,
  setResumeType,
  index,
  on,
  off,
}: SwitchCompProps): JSX.Element => {
  return (
    <h1 className=" col-span-1 text-xs font-normal text-gray-500 ">
      <div
        className="w-11 h-4 flex items-center justify-between bg-gray-200 rounded-full p-1 cursor-pointer"
        onClick={() => {
          const newArray = [...resumeType];
          newArray[index] = !resumeType[index];
          setResumeType(newArray);
        }}
      >
        <p
          className={
            "text-gray-800 font-semibold" +
            (!resumeType[index] ? " transform translate-x-4  " : " ")
          }
          style={{ fontSize: "6px" }}
        >
          {resumeType[index] ? on : off}
        </p>
        <div
          className={
            "h-3 w-3 rounded-full shadow-md transform duration-300 ease-in-out" +
            (resumeType[index]
              ? " bg-blue-500 "
              : " bg-gray-500 transform -translate-x-6")
          }
        ></div>
      </div>
    </h1>
  );
};

export default SwitchComp;
