import React from "react";
import orgApi from "../../../api/org_api";
import { BsArrowLeftShort, BsPencil } from "react-icons/bs";
import { Style } from "../../../utils/Style";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { getCode } from "iso-3166-1-alpha-2";
import { CloseModal } from "../../../component/Layout/CloseModal";
import api from "../../../api/auth_api";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

type OrgProfileProps = { setLoading: Function };
const OrgProfile: React.FC<OrgProfileProps> = ({
  setLoading,
}: OrgProfileProps) => {
  const [userData, setUserData] = React.useState({} as any);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [fileError, setfileError] = React.useState("");
  const [edit1, setEdit1] = React.useState(true);
  const [edit2, setEdit2] = React.useState(true);
  const [orgId, setOrgId] = React.useState("");
  const [isImageSelect, setIsImageSelect] = React.useState(false);
  const [changePass, setChangePass] = React.useState(false);
  const [isPasswordVisible1, setIsPasswordVisible1] = React.useState(true);
  const [isPasswordVisible2, setIsPasswordVisible2] = React.useState(true);
  const [isPasswordVisible3, setIsPasswordVisible3] = React.useState(true);
  const navigate = useNavigate();

  function togglePasswordVisibility1() {
    setIsPasswordVisible1((prevState: any) => !prevState);
  }
  function togglePasswordVisibility2() {
    setIsPasswordVisible2((prevState: any) => !prevState);
  }
  function togglePasswordVisibility3() {
    setIsPasswordVisible3((prevState: any) => !prevState);
  }
  React.useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    const org = JSON.parse(localStorage.getItem("user__details")?.toString()!);
    setOrgId(org.id);
    setLoading(true);
    const res = await orgApi.getOrgDetails(org.id);
    if (res.data.success) {
      setLoading(false);
      setUserData(res.data.org);
      setData(res.data.org);
      localStorage.setItem("user__details", JSON.stringify(res.data.org));
    }
  };

  const setData = async (userData: any) => {
    formik.resetForm();
    if (userData) {
      const countryC = await getCountryCode(userData.country);
      const coun = Country.getCountryByCode(countryC!);
      formik.setFieldValue("country", coun);
      const states = State.getStatesOfCountry(countryC!);
      const state = states.find((state) => state.name === userData.state);
      formik.setFieldValue("state", state);
      const cities = City.getCitiesOfState(countryC!, state?.isoCode!);
      const city = cities.find((city) => city.name === userData.city);
      formik.setFieldValue("city", city);
      setSelectedImage(userData.profile_pic);
      formik.setFieldValue("organization", userData.org_name);
      formik.setFieldValue("orgwebsiteurl", userData.org_web_url);
      formik.setFieldValue("organizationSize", userData.size);
      formik.setFieldValue("postal_code", userData.postal_code);
      formik.setFieldValue("first_name", userData.first_name);
      formik.setFieldValue("last_name", userData.last_name);
      formik.setFieldValue("emailId", userData.email);
      formik.setFieldValue("phoneNumber", userData.mobile);
    }
  };

  const getCountryCode = async (countryName: string) => {
    var code = await getCode(countryName); // Country not found
    return code;
  };

  const handleImageChange = (event: any) => {
    setfileError("");
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        if (file.size <= 2 * 1024 * 1024) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            setSelectedImage(e.target.result);
            setfileError("");
          };
          reader.readAsDataURL(file);
          setIsImageSelect(true);
        } else {
          setfileError(
            "File size exceeds the limit. Please select a smaller file."
          );
        }
      } else {
        setfileError("Please select an image file.");
      }
    }
  };

  const updateProfilePic = async () => {
    const formData = new FormData();
    if (isImageSelect) {
      var file = dataURLtoFile(
        selectedImage,
        Math.random().toString(36).substring(6) + "_name.png"
      );
      formData.append("profile", file);
      setLoading(true);
      const res = await api.updateProfile(formData, orgId);
      if (res.data.success === true) {
        fetchProfileData();
        setIsImageSelect(false);
        setEdit1(true);
        toast("Profile picture successfully updated");
      } else {
        toast(res.data.msg);
      }
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      organization: "",
      organizationSize: "",
      orgwebsiteurl: "",
      country: null,
      state: null,
      city: null,
      postal_code: "",
      first_name: "",
      last_name: "",
      emailId: "",
      phoneNumber: "",
    },
    validationSchema: Yup.object({
      organizationSize: Yup.string().required("Organization Size is required"),
      orgwebsiteurl: Yup.string().url(
        "Organization website url must be a valid URL"
      ),
      country: Yup.object().required("Country is required"),
      state: Yup.object().required("State is required"),
      city: Yup.object().required("City is required"),
      postal_code: Yup.string()
        .required("Postal Code is required")
        .matches(/^(?=.*\d).*$/, "Postal Code must contain Numbers")
        .min(6, "Postal Code must have 6 character")
        .max(6, "Postal Code must have 6 character"),
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required"),
      emailId: Yup.string(),
      phoneNumber: Yup.string(),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("org_web_url", values.orgwebsiteurl);
      formData.append("size", values.organizationSize);
      formData.append("country", values.country?.["name"]!);
      formData.append("state", values.state?.["name"]!);
      formData.append("city", values.city?.["name"]!);
      formData.append("postal_code", values.postal_code);
      formData.append("first_name", values.first_name);
      formData.append("last_name", values.last_name);
      setLoading(true);
      const res = await api.updateProfile(formData, orgId);
      if (res.data.success === true) {
        fetchProfileData();
        setEdit1(true);
        toast("Profile Details successfully updated");
      } else {
        toast(res.data.msg);
      }
      setLoading(false);
    },
  });

  function dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const formik1 = useFormik({
    initialValues: { old_pass: "", new_pass: "", confirm_pass: "" },
    validationSchema: Yup.object({
      old_pass: Yup.string().required("Old Password is required"),
      new_pass: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .max(22, "Password must be maximum 22 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!*]).*$/,
          "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
        )
        .required("New Password is required"),
      confirm_pass: Yup.string()
        .oneOf([Yup.ref("new_pass"), ""], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      setLoading(true)
      const user_id = localStorage.getItem("user_id")?.toString()!;
      var data = {
        old_password : values.old_pass,
        new_password : values.new_pass,
      }
      const res = await api.changePassword(data, user_id);
      if (res.data.success === true) {
        toast(res.data.msg)
        setChangePass(false)
        formik1.resetForm()
      } else {
        toast(res.data.msg)
      }
      setLoading(false)
    },
  });

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>

      <div className="overflow-y-auto" style={{ height: "86vh" }}>
        {userData && (
          <div className="flex flex-col items-start overflow-y-auto p-10 w-full">
            <p className="mb-3 border-b-primary w-full flex justify-between item-center">
              <p className="pb-2 font-semibold text-lg text-gray-900">
                Admin Profile
              </p>
              <button
                type="button"
                className="text-primary text-sm font-semibold py-1 px-2 flex items-center"
                onClick={() => navigate(-1)}
              >
                <BsArrowLeftShort /> Back
              </button>
            </p>
            <div className="p-5 border rounded-md w-full bg-gray-50 my-2">
              <div className="flex flex-row justify-between items-center mb-4">
                <p className="font-semibold text-sm text-gray-900">
                  Company Profile
                </p>
                <div className="flex flex-row justify-end items-center gap-1">
                  {edit1 && (
                    <button
                      type="button"
                      onClick={() => setEdit1(false)}
                      className="rounded text-gray-700 bg-white text-xs font-normal flex flex-row items-center gap-1 border px-2 py-1.5"
                    >
                      <BsPencil />
                      Edit
                    </button>
                  )}
                  {!edit1 && (
                    <button
                      type="button"
                      onClick={() => setEdit1(true)}
                      className="rounded text-gray-700 bg-white text-xs font-normal flex flex-row items-center gap-1 border px-2 py-1.5"
                    >
                      <CloseModal />
                      Cancel
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={() => updateProfilePic()}
                    className="rounded text-white bg-primary text-xs font-normal px-2 py-1.5"
                  >
                    Save
                  </button>
                </div>
              </div>
              <div className="flex flex-col items-start gap-3 w-1/2  m-3">
                <div className="flex items-center gap-1 grid grid-cols-3 ">
                  {selectedImage && (
                    <div className="w-32 h-32 mr-4 flex justify-center items-center overflow-hidden border bg-gray-100 rounded-full col-span-1">
                      <img
                        className="w-32 h-32 object-cover"
                        src={selectedImage}
                        alt="Avatar Upload"
                      ></img>
                    </div>
                  )}
                  <div
                    className={
                      edit1
                        ? "w-full disabled-div col-span-2 "
                        : "w-full col-span-2 "
                    }
                  >
                    <label className="cursor-pointer p-2 w-full">
                      <div className="flex flex-col justify-start gap-1 border-primary bg-blue-50 rounded p-3 w-full">
                        <div className="font-semibold text-xs text-gray-700">
                          Upload logo{" "}
                        </div>
                        <div className="font-thin text-xs text-gray-400">
                          Images with format of jpeg, png & jpg are acceptable
                        </div>
                      </div>
                      <input
                        type="file"
                        className="hidden"
                        onChange={handleImageChange}
                      ></input>
                    </label>
                  </div>
                </div>
                {fileError !== "" ? (
                  <div className="error">{fileError}</div>
                ) : null}
                <div className="w-full mt-3 grid grid-cols-3 ">
                  <div className="col-span-2">
                    <label className={Style.label}>
                      Organization Name<span title="required">*</span>
                    </label>
                    <input
                      disabled
                      placeholder="Organization Name"
                      type="text"
                      id="organization"
                      name="organization"
                      value={formik.values.organization}
                      className={Style.iInput}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-5 border rounded-md w-full bg-gray-50 my-2">
              <form onSubmit={formik1.handleSubmit}>
                <div className="flex flex-row justify-between items-center mb-4">
                  <p className="font-semibold text-sm text-gray-900">
                    Change Password
                  </p>
                  <div className="flex flex-row justify-end items-center gap-1">
                    {!changePass && (
                      <button
                        type="button"
                        onClick={() => {setChangePass(true); formik1.resetForm();}}
                        className="rounded text-white bg-primary text-xs font-normal px-2 py-1.5"
                      >
                        Change Password
                      </button>
                    )}
                    {changePass && (
                      <>
                        <button
                          type="button"
                          onClick={() => {setChangePass(false); formik1.resetForm();}}
                          className="rounded text-gray-700 border bg-white text-xs font-normal px-2 py-1.5"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="rounded text-white bg-primary text-xs font-normal px-2 py-1.5"
                        >
                          Save
                        </button>
                      </>
                    )}
                  </div>
                </div>
                {changePass && (
                  <div className="flex flex-col items-start gap-3 w-1/2  m-3">
                    <div className="w-2/3">
                      <label className={Style.label}>
                        Old Password<span title="required">*</span>
                      </label>
                      <div className="relative container mx-auto w-full">
                        <input
                          type={!isPasswordVisible1 ? "text" : "password"}
                          placeholder="Enter Old Password"
                          id="old_pass"
                          name="old_pass"
                          value={formik1.values.old_pass}
                          onBlur={formik1.handleBlur}
                          onChange={formik1.handleChange}
                          className={
                            formik1.touched.old_pass && formik1.errors.old_pass
                              ? Style.iError
                              : Style.iInput
                          }
                        ></input>
                        <button
                          type="button"
                          className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                          onClick={togglePasswordVisibility1}
                        >
                          {isPasswordVisible1 ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                            </svg>
                          )}
                        </button>
                      </div>
                      {formik1.touched.old_pass && formik1.errors.old_pass && (
                        <div className="error">{formik1.errors.old_pass}</div>
                      )}
                    </div>
                    <div className="w-2/3">
                      <label className={Style.label}>
                        New Password<span title="required">*</span>
                      </label>
                      <div className="relative container mx-auto w-full">
                        <input
                          type={!isPasswordVisible2 ? "text" : "password"}
                          placeholder="Enter New Password"
                          id="new_pass"
                          name="new_pass"
                          value={formik1.values.new_pass}
                          onBlur={formik1.handleBlur}
                          onChange={formik1.handleChange}
                          className={
                            formik1.touched.new_pass && formik1.errors.new_pass
                              ? Style.iError
                              : Style.iInput
                          }
                        ></input>
                        <button
                          type="button"
                          className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                          onClick={togglePasswordVisibility2}
                        >
                          {isPasswordVisible2 ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                            </svg>
                          )}
                        </button>
                      </div>
                      {formik1.touched.new_pass && formik1.errors.new_pass && (
                        <div className="error">{formik1.errors.new_pass}</div>
                      )}
                    </div>
                    <div className="w-2/3">
                      <label className={Style.label}>
                        Confirm Password<span title="required">*</span>
                      </label>
                      <div className="relative container mx-auto w-full">
                        <input
                          type={!isPasswordVisible3 ? "text" : "password"}
                          placeholder="Enter Confirm Password"
                          id="confirm_pass"
                          name="confirm_pass"
                          value={formik1.values.confirm_pass}
                          onBlur={formik1.handleBlur}
                          onChange={formik1.handleChange}
                          className={
                            formik1.touched.confirm_pass &&
                            formik1.errors.confirm_pass
                              ? Style.iError
                              : Style.iInput
                          }
                        ></input>
                        <button
                          type="button"
                          className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                          onClick={togglePasswordVisibility3}
                        >
                          {isPasswordVisible3 ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                            </svg>
                          )}
                        </button>
                      </div>
                      {formik1.touched.confirm_pass &&
                        formik1.errors.confirm_pass && (
                          <div className="error">
                            {formik1.errors.confirm_pass}
                          </div>
                        )}
                    </div>
                  </div>
                )}
              </form>
            </div>
            <div className="p-5 border rounded-md w-full bg-gray-50 my-2">
              <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-row justify-between items-center mb-4">
                  <p className="font-semibold text-sm text-gray-900">
                    Organization Details
                  </p>
                  <div className="flex flex-row justify-end items-center gap-1">
                    {edit2 ? (
                      <button
                        type="button"
                        onClick={() => setEdit2(false)}
                        className="rounded text-gray-700 bg-white text-xs font-normal flex flex-row items-center gap-1 border px-2 py-1.5"
                      >
                        <BsPencil />
                        Edit
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => setEdit2(true)}
                        className="rounded text-gray-700 bg-white text-xs font-normal flex flex-row items-center gap-1 border px-2 py-1.5"
                      >
                        <CloseModal />
                        Cancel
                      </button>
                    )}

                    <button
                      type="submit"
                      className="rounded text-white bg-primary text-xs font-normal px-2 py-1.5"
                    >
                      Save
                    </button>
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-2">
                  <div className="col-span-1 mx-3">
                    <label className={Style.label}>
                      Organization Size<span title="required">*</span>
                    </label>
                    <select
                      disabled={edit2}
                      id="organizationSize"
                      name="organizationSize"
                      value={formik.values.organizationSize}
                      onBlur={(event: any) => {
                        formik.handleBlur(event);
                      }}
                      onChange={formik.handleChange}
                      className={
                        formik.touched.organizationSize &&
                        formik.errors.organizationSize
                          ? "w-full border text-xs text-gray-700 py-2 px-3 focus:outline-none inputError"
                          : "w-full border text-xs text-gray-700 border-gray-300  py-2 px-3 focus:outline-none focus:border-blue-500"
                      }
                    >
                      <option value="" disabled>
                        Organization Size*
                      </option>
                      <option value="<10"> {"<"} 10 </option>
                      <option value="11-50">11 to 50</option>
                      <option value="51-200">51 to 200</option>
                      <option value="201-500">201 to 500</option>
                      <option value="501-1000">501 to 1000</option>
                      <option value=">1000 ">{">"} 1000 </option>
                    </select>
                    {formik.touched.organizationSize &&
                    formik.errors.organizationSize ? (
                      <div className="error">
                        {formik.errors.organizationSize}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-1 mx-3">
                    <label className={Style.label}>Organization Website</label>
                    <input
                      disabled={edit2}
                      placeholder="Organization Website"
                      type="text"
                      id="orgwebsiteurl"
                      name="orgwebsiteurl"
                      value={formik.values.orgwebsiteurl}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      className={
                        formik.touched.orgwebsiteurl &&
                        formik.errors.orgwebsiteurl
                          ? Style.iError
                          : Style.iInput
                      }
                    ></input>
                    {formik.touched.orgwebsiteurl &&
                    formik.errors.orgwebsiteurl ? (
                      <div className="error">{formik.errors.orgwebsiteurl}</div>
                    ) : null}
                  </div>
                  <div className="col-span-1"></div>
                  <div className="col-span-1 mx-3">
                    <label className={Style.label}>
                      Country <span title="required">*</span>
                    </label>
                    <Select
                      isDisabled={edit2}
                      placeholder="Select Country"
                      options={Country.getAllCountries()}
                      getOptionLabel={(options: any) => {
                        return options["name"];
                      }}
                      getOptionValue={(options: any) => {
                        return options["name"];
                      }}
                      id="country"
                      name="country"
                      value={formik.values.country}
                      onBlur={formik.handleBlur}
                      onChange={(item: any) => {
                        formik.setFieldValue("country", item);
                      }}
                      className={
                        formik.touched.country && formik.errors.country
                          ? "text-xs inputError"
                          : "text-xs"
                      }
                    />
                    {formik.touched.country && formik.errors.country ? (
                      <div className="error">{formik.errors.country}</div>
                    ) : null}
                  </div>
                  <div className="col-span-1 mx-3">
                    <label className={Style.label}>
                      State<span title="required">*</span>
                    </label>
                    <Select
                      isDisabled={edit2}
                      placeholder="Select State"
                      options={State?.getStatesOfCountry(
                        formik.values.country?.["isoCode"]
                      )}
                      getOptionLabel={(options: any) => {
                        return options["name"];
                      }}
                      getOptionValue={(options: any) => {
                        return options["name"];
                      }}
                      id="state"
                      name="state"
                      value={formik.values.state}
                      onBlur={formik.handleBlur}
                      onChange={(item: any) => {
                        formik.setFieldValue("state", item);
                      }}
                      className={
                        formik.touched.state && formik.errors.state
                          ? "inputError text-xs"
                          : "text-xs"
                      }
                    />
                    {formik.touched.state && formik.errors.state && (
                      <div className="error">{formik.errors.state}</div>
                    )}
                  </div>
                  <div className="col-span-1"></div>
                  <div className="col-span-1 mx-3">
                    <label className={Style.label}>
                      City<span title="required">*</span>
                    </label>
                    <Select
                      isDisabled={edit2}
                      placeholder="Select City"
                      options={City.getCitiesOfState(
                        formik.values.state?.["countryCode"]!,
                        formik.values.state?.["isoCode"]!
                      )}
                      getOptionLabel={(options: any) => {
                        return options["name"];
                      }}
                      getOptionValue={(options: any) => {
                        return options["name"];
                      }}
                      id="city"
                      name="city"
                      value={formik.values.city}
                      onBlur={formik.handleBlur}
                      onChange={(item: any) => {
                        formik.setFieldValue("city", item);
                      }}
                      className={
                        formik.touched.city && formik.errors.city
                          ? "inputError text-xs"
                          : "text-xs"
                      }
                    />
                    {formik.touched.city && formik.errors.city && (
                      <div className="error">{formik.errors.city}</div>
                    )}
                  </div>
                  <div className="col-span-1 mx-3">
                    <label className={Style.label}>
                      Postal Code<span title="required">*</span>
                    </label>
                    <input
                      disabled={edit2}
                      placeholder="Postal Code"
                      type="text"
                      id="postal_code"
                      name="postal_code"
                      value={formik.values.postal_code}
                      onBlur={(event: any) => {
                        formik.handleBlur(event);
                      }}
                      onChange={(event: any) => {
                        formik.handleChange(event);
                      }}
                      className={
                        formik.touched.postal_code && formik.errors.postal_code
                          ? Style.iError
                          : Style.iInput
                      }
                    ></input>
                    {formik.touched.postal_code &&
                      formik.errors.postal_code && (
                        <div className="error">{formik.errors.postal_code}</div>
                      )}
                  </div>
                  <div className="col-span-1"></div>
                  <div className="col-span-1 mx-3">
                    <label className={Style.label}>
                      First Name<span title="required">*</span>
                    </label>
                    <input
                      disabled={edit2}
                      placeholder="First Name"
                      type="text"
                      id="first_name"
                      name="first_name"
                      value={formik.values.first_name}
                      onBlur={(event: any) => {
                        formik.handleBlur(event);
                      }}
                      onChange={(event: any) => {
                        formik.handleChange(event);
                      }}
                      className={
                        formik.touched.first_name && formik.errors.first_name
                          ? Style.iError
                          : Style.iInput
                      }
                    ></input>
                    {formik.touched.first_name && formik.errors.first_name && (
                      <div className="error">{formik.errors.first_name}</div>
                    )}
                  </div>
                  <div className="col-span-1 mx-3">
                    <label className={Style.label}>
                      Last Name<span title="required">*</span>
                    </label>
                    <input
                      disabled={edit2}
                      placeholder="Last Name"
                      type="text"
                      id="last_name"
                      name="last_name"
                      value={formik.values.last_name}
                      onBlur={(event: any) => {
                        formik.handleBlur(event);
                      }}
                      onChange={(event: any) => {
                        formik.handleChange(event);
                      }}
                      className={
                        formik.touched.last_name && formik.errors.last_name
                          ? Style.iError
                          : Style.iInput
                      }
                    ></input>
                    {formik.touched.last_name && formik.errors.last_name && (
                      <div className="error">{formik.errors.last_name}</div>
                    )}
                  </div>
                  <div className="col-span-1"></div>
                  <div className="col-span-1 mx-3">
                    <label className={Style.label}>Business mail</label>
                    <input
                      disabled
                      placeholder="Business mail"
                      type="text"
                      id="emailId"
                      name="emailId"
                      value={formik.values.emailId}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      className={
                        formik.touched.emailId && formik.errors.emailId
                          ? Style.iError
                          : Style.iInput
                      }
                    ></input>
                    {formik.touched.emailId && formik.errors.emailId && (
                      <div className="error">{formik.errors.emailId}</div>
                    )}
                  </div>
                  <div className="col-span-1 mx-3">
                    <label className={Style.label}>Phone</label>
                    <input
                      disabled
                      placeholder="Phone Number"
                      type="text"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={formik.values.phoneNumber}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      className={
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                          ? Style.iError
                          : Style.iInput
                      }
                    ></input>
                    {formik.touched.phoneNumber &&
                      formik.errors.phoneNumber && (
                        <div className="error">{formik.errors.phoneNumber}</div>
                      )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OrgProfile;
