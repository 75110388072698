import React from "react";
import { CloseModal } from "./Layout/CloseModal";

type ImageViewProps = {
  imgSrc: string;
  close: Function;
};

const ImageView: React.FC<ImageViewProps> = ({
  imgSrc,
  close,
}: ImageViewProps): JSX.Element => {
  return (
    <>
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div
          className="fixed inset-0 w-full h-full bg-black opacity-40"
          onClick={() => close(false)}
        ></div>
        <div className="flex items-center min-h-screen px-4 py-8">
          <div className="relative w-full max-w-lg p-4 mx-auto bg-white shadow-lg rounded-sm rounded">
            <div className="sm:flex">
              <div className="mt-1 text-center sm:text-left w-full">
                <div className="flex items-center justify-between">
                  <h6 className="text-sm font-bold text-gray-800"></h6>
                  <button
                    onClick={() => close(false)}
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    <CloseModal />
                  </button>
                </div>
                <div className=" w-full mt-1 max-w-lg text-xs text-gray-500 font-normal">
                  <img src={imgSrc} className="w-full object-contain"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageView;
