// // ** LOCAL ** 
// export const BaseUrl = "http://localhost:3001";
// export const feedbackUrl = "#";

// // ** DEV ** 
// export const BaseUrl = "https://dev.screenit.io/api";
// export const feedbackUrl = "#";

// ** DEMO ** 
export const BaseUrl = "https://demo.screenit.io/api";
export const feedbackUrl = "#";

// // ** ORG ** 
// export const BaseUrl = "https://org.screenit.io/v2/api";
// export const feedbackUrl = "https://org.screenit.io/v2";














// index.tsx
// ORG
// <BrowserRouter basename="/v2">
//     <AuthProvider>
//     <App />
//     </AuthProvider>
// </BrowserRouter>

// DEMO
// <HashRouter>
//     <AuthProvider>
//     <App />
//     </AuthProvider>
// </HashRouter>


// package.json
// ORG
// "homepage": "/v2",
// DEMO
// "homepage": "https://dev.screenit.io/",