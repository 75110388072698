import React from "react";

type BarProps = { height: number; length: number; schedulePage: Function, date: string; };

const Bar: React.FC<BarProps> = ({
  height,
  length,
  schedulePage,
  date,
}: BarProps): JSX.Element => {
  return (
    <div
      onClick={() => (length > 0 ? schedulePage(date) : null)}
      className={
        length === 7
          ? "w-8 sm:w-[20px] bg-blue-100 group-hover:bg-light-red rounded-t flex items-center"
          : "w-8 sm:w-[24px] bg-blue-100 group-hover:bg-light-red rounded-t  flex items-center"
      }
      style={{ height: `${height / 10}vh` }}
    >
      <div
        className={
          length === 7
            ? "w-8 sm:w-[20px] hover:bg-blue-500 rounded-t group-hover:bg-light-red "
            : "w-8 sm:w-[24px] hover:bg-blue-500 rounded-t group-hover:bg-light-red "
        }
        style={{ height: `${height / 10}vh` }}
      ></div>
    </div>
  );
};

export default Bar;
