import React from "react";
import { useNavigate } from "react-router-dom";

const ReportList: React.FC = () => {
    const navigate = useNavigate()
    const gotoReports = (type: any) => {
        navigate(`/${type}`);
    }
  return (
    <>
      <div className="flex justify-center">
        <div className="w-3/4 mt-5">
          <div className="grid grid-cols-4 gap-2">
            <div
              className="bg-gray-100 hover:bg-blue-50 hover-border rounded flex flex-col  gap-3 h-32 cursor-pointer"
              onClick={() => {
                gotoReports("report")
              }}
            >
                <p className="text-md font-semibold text-primary flex flex-col items-center h-32 justify-center m-2">Schedule Report</p>
            </div>
            <div
              className="bg-gray-100 hover:bg-blue-50 hover-border rounded flex flex-col  gap-3 h-32 cursor-pointer"
              onClick={() => {
                gotoReports("interview_report")
              }}
            >
                <p className="text-md font-semibold text-primary flex flex-col items-center h-32 justify-center m-2">Interview Report</p>
            </div>
            <div
              className="bg-gray-100 hover:bg-blue-50 hover-border rounded flex flex-col  gap-3 h-32 cursor-pointer"
              onClick={() => {
                gotoReports("jd_report")
              }}
            >
                <p className="text-md font-semibold text-primary flex flex-col items-center h-32 justify-center m-2">JD Report</p>
            </div>
            <div
              className="bg-gray-100 hover:bg-blue-50 hover-border rounded flex flex-col  gap-3 h-32 cursor-pointer"
              onClick={() => {
                gotoReports("ta_report")
              }}
            >
                <p className="text-md font-semibold text-primary flex flex-col items-center h-32 justify-center m-2">TA Report</p>
            </div>
            {/* <div
              className="bg-gray-100 hover:bg-blue-50 hover-border rounded flex flex-col  gap-3 h-32 cursor-pointer"
              onClick={() => {
                gotoReports("admin_report")
              }}
            >
                <p className="text-md font-semibold text-primary flex flex-col items-center h-32 justify-center m-2">Admin Report</p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportList;
