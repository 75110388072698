import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  BsBookmarkStarFill,
  BsFillPersonFill,
  BsPencil,
  BsPlus,
  BsRobot,
  BsUpload,
} from "react-icons/bs";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Style } from "../../../utils/Style";
import orgApi from "../../../api/org_api";
import SelectFavourites from "../UploadJd/SelectFavourites";
import { CloseModal } from "../../../component/Layout/CloseModal";
import S from "../../../Services/Services";
import SelectGroups from "../UploadJd/SelectGroup";
import SwitchComp from "../../../component/Switch";

type JdRoundsProps = { setLoading: Function };

const JdRounds: React.FC<JdRoundsProps> = ({ setLoading }: JdRoundsProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showAddRoundModal, setShowAddRoundModal] = React.useState(false);
  const [uploadResumeModal, setUploadResumeModal] = React.useState(false);
  const [addInterviewerModal, setAddInterviewerModal] = React.useState(false);
  const [addGroupModal, setAddGroupModal] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState<File[]>([]);
  const [resumeType, setResumeType] = React.useState([] as any);
  const [roundType, setRoundType] = React.useState("add");
  const [fileError, setfileError] = React.useState("");
  const [jdDetail, setJdDetail] = React.useState();
  const [roundId, setRoundId] = React.useState("");
  const [roundMode, setRoundMode] = React.useState("Manual");
  const [intType, setIntType] = React.useState("other");
  const [inhouse, setInhouse] = React.useState(true);
  const [external, setExternal] = React.useState(false);
  const [market, setMarket] = React.useState(true);
  const [favCount, setFavCount] = React.useState(0);
  const [fromType, setFromType] = React.useState("");
  const [isDisabled, setisDisabled] = React.useState(false);
  const [groups, setgroups] = React.useState([] as any);
  // const goBack = () => navigate(-1);
  const org = JSON.parse(localStorage.getItem("user__details")?.toString()!);
  const orgId = org.id;
  const masterId = org.master_id !== null ? org.master_id : org.id;

  React.useEffect(() => {}, [selectedFile]);
  React.useEffect(() => {
    fetchJdDetails();
  }, []);

  const formik = useFormik({
    initialValues: { name: "", type: "", mode: "Manual" },
    validationSchema: Yup.object({
      name: Yup.string().required("Round name is required"),
      type: Yup.string().required("Select Type"),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (intType === "other") {
        if (!inhouse && !external && !market) {
          toast("Select Interviewers");
          return;
        }
      } else {
        setInhouse(false);
        setExternal(false);
        setMarket(false);
      }
      setLoading(true);
      setisDisabled(true);
      if (roundType === "add") {
        var reqData = {
          name: values.name,
          type: values.type,
          mode: values.mode,
          level: jdDetail ? (jdDetail["round"] as any).length : 1,
          post_id: id,
          fav: intType === "fav" ? true : false,
          house: intType !== "fav" && intType !== "group" ? inhouse : false,
          market: intType !== "fav" && intType !== "group" ? market : false,
          external: intType !== "fav" && intType !== "group" ? external : false,
          group: intType === "group" ? true : false,
          i_group: groups,
        };
        const res = await orgApi.addRound(reqData);
        if (res.data.success) {
          setShowAddRoundModal(false);
          toast(res.data.msg);
          fetchJdDetails();
        } else {
          toast(res.data.msg);
        }
        setisDisabled(false);
        setLoading(false);
      } else if (roundType === "edit") {
        var reqData1 = {
          name: values.name,
          type: values.type,
          mode: values.mode,
          round_id: roundId,
          fav: intType === "fav" ? true : false,
          house: intType !== "fav" && intType !== "group" ? inhouse : false,
          market: intType !== "fav" && intType !== "group" ? market : false,
          external: intType !== "fav" && intType !== "group" ? external : false,
          group: intType === "group" ? true : false,
        };
        const res = await orgApi.editRound(reqData1);
        if (res.data.success) {
          setShowAddRoundModal(false);
          toast(res.data.msg);
          fetchJdDetails();
        } else {
          toast(res.data.msg);
        }
        setisDisabled(false);
        setLoading(false);
      }
    },
  });
  const fetchJdDetails = async () => {
    setLoading(true);
    if (
      S.getOrgDomain() === "sightspectrum.com" ||
      S.getOrgDomain() === "vegaintellisoft.com" ||
      S.getOrgDomain() === "nam-it.com" ||
      S.getOrgDomain() === "infinitisoftware.net"
    ) {
      setMarket(false);
    }
    const res = await orgApi.getSingleJdDetail(id);
    if (res.data.success) {
      res.data.post.round.length !== 0 &&
        res.data.post.round.push({ isNew: true });
      setJdDetail(res.data.post);
      setFavCount(res.data.post.fav_count);
    } else {
      setJdDetail(undefined);
    }
    setLoading(false);
  };
  const openUploadResume = () => {
    setSelectedFile([]);
    setUploadResumeModal(true);
  };
  const addRound = () => {
    formik.values.name = "";
    formik.values.type = "";
    formik.values.mode = "Manual";
    setRoundMode("Manual");
    setRoundId("");
    formik.resetForm();
    setRoundType("add");
    setIntType("other");
    setInhouse(true);
    setMarket(true);
    if (
      S.getOrgDomain() === "sightspectrum.com" ||
      S.getOrgDomain() === "vegaintellisoft.com" ||
      S.getOrgDomain() === "nam-it.com" ||
      S.getOrgDomain() === "infinitisoftware.net"
    ) {
      setMarket(false);
    }
    setShowAddRoundModal(true);
  };
  const editRound = (val: any) => {
    formik.resetForm();
    formik.values.name = val.name;
    formik.values.type = val.type;
    formik.values.mode = val.mode;
    setRoundMode(val.mode);
    setRoundId(val.id);
    setExternal(val.external);
    setInhouse(val.house);
    setMarket(val.market);
    val.external || val.house || val.market
      ? setIntType("other")
      : val.group
      ? setIntType("group")
      : setIntType("fav");
    setRoundType("edit");
    setShowAddRoundModal(true);
  };
  const handleImageChange = async (event: any) => {
    const ds: any = await S.handleUploadResume(event.target.files);
    setfileError(ds["error"]);
    setSelectedFile(ds["resumefiles"]);
    setResumeType(ds["resumeType"]);
  };
  const getDate = (dateStr: string) => {
    const formattedDate = convert(dateStr!);
    return formattedDate;
  };
  function convert(str: string) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  const getSubscription = async (resLen: any, roundTag: any) => {
    setLoading(true);
    let userInfo = JSON.parse(
      localStorage.getItem("user__details")?.toString()!
    );
    let orgId = userInfo.master_id !== null ? userInfo.master_id : userInfo.id;
    const res = await orgApi.getSubs(orgId);
    if (res.data.success) {
      setLoading(false);
      let subscription = res.data.org;
      if (roundTag === "external" && subscription.e_available < resLen) {
        toast(
          `You can upload a maximum of ${subscription.e_available} resumes.`
        );
        return false;
      } else if (roundTag === "internal" && subscription.i_available < resLen) {
        toast(
          `You can upload a maximum of ${subscription.i_available} resumes.`
        );
        return false;
      } else if (roundTag === "combination") {
        let min =
          subscription.i_available >= subscription.e_available
            ? subscription.e_available
            : subscription.i_available;
        if (min < resLen) {
          toast(`You can upload a maximum of ${min} resumes.`);
          return false;
        }
      }
    } else {
      setLoading(false);
      return true;
    }
    return true;
  };
  const uploadResume = async () => {
    let roundTag = "";
    if (jdDetail) {
      let d = jdDetail["round"][0];
      if (
        d["fav"] ||
        (d["house"] && d["market"] && !d["external"]) ||
        (!d["house"] && d["market"] && d["external"]) ||
        (d["house"] && d["market"] && d["external"])
      ) {
        roundTag = "combination";
      } else if (!d["house"] && d["market"] && !d["external"]) {
        roundTag = "external";
      } else if (
        (d["house"] && !d["market"] && !d["external"]) ||
        (!d["house"] && !d["market"] && d["external"]) ||
        (d["house"] && !d["market"] && d["external"])
      ) {
        roundTag = "internal";
      }
    }
    let scope = [] as any;
    resumeType.forEach((r_type: any) => {
      scope.push({type : r_type ? 'sit' : 'others'})
    });
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formData = new FormData();
    formData.append("tz", timezone);
    formData.append("org_id", orgId);
    formData.append("master_id", masterId);
    formData.append("tag", roundTag);
    formData.append("scope", JSON.stringify(scope));
    let mv: Boolean | undefined = await getSubscription(
      selectedFile.length,
      roundTag
    );
    if (!mv) {
      return;
    }
    for (let k = 0; k < selectedFile.length; k++) {
      formData.append("resume", selectedFile[k]);
    }
    setisDisabled(true);
    setLoading(true);
    const res = await orgApi.uploadCandidate(formData, id);
    if (res.data.success === true) {
      toast(res.data.msg);
      fetchJdDetails();
      setUploadResumeModal(false);
    } else {
      toast(res.data.msg);
    }
    setisDisabled(false);
    setLoading(false);
  };
  const removeResume = async (index: number) => {
    setSelectedFile((prevState) => prevState.filter((_, ind) => ind !== index));
    setResumeType((prevState: any) => prevState.filter((_ : any, ind : number) => ind !== index));
  };
  const setCallback = () => {
    fetchJdDetails();
    setAddInterviewerModal(false);
  };
  const setGroupCallback = (grp: any) => {
    if (roundType === "edit") {
      setAddGroupModal(false);
    } else {
      setgroups(grp);
      setAddGroupModal(false);
    }
  };
  const setfromType = (type: any) => {
    setFromType(type);
    setAddInterviewerModal(true);
  };
  const intGroup = () => {
    setAddGroupModal(true);
  };
  // const gotoInterviewerList = () =>
  //   navigate("/select_interviewers/" + id + "/" + null + "/rounds/interviewer");
  const gotoCandidateList = (
    roundId: any,
    level: number,
    roundIndex: number,
    status: any
  ) => {
    var nextRound = false;
    if (jdDetail && (jdDetail["round"] as any).length - 1 > roundIndex + 1) {
      nextRound = true;
    }
    console.log(status);
    navigate(
      "/candidate_list/" + id + "/" + roundId + "/" + level,
      {
        state: { nextRound: nextRound },
      }
    );
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      {showAddRoundModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-lg p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
              <div className="sm:flex">
                <div className="mt-1 text-center sm:text-left w-full">
                  <div className="flex items-center justify-between border-b">
                    <h6 className="text-sm font-bold text-gray-800 mb-2">
                      {roundType === "add" ? "Add" : "Edit"} Round
                    </h6>
                  </div>
                  <div className="mt-1">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="grid md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 ">
                        <div className="my-2 lg:mx-5 md:mx-5 xl:mx-5">
                          <div className="flex flex-row item-center justify-center mb-3 gap-5">
                            <div className="flex items-center gap-2">
                              <input
                                checked={roundMode === "Manual"}
                                id="default-radio-1"
                                type="radio"
                                name="default-radio"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                onChange={() => {
                                  formik.values.mode = "Manual";
                                  setRoundMode("Manual");
                                }}
                              ></input>
                              <label className={Style.label}>Manual</label>
                            </div>
                            <div className="flex items-center gap-2 disabled-div">
                              <input
                                checked={roundMode === "AI"}
                                id="default-radio-2"
                                type="radio"
                                // value="AI"
                                name="default-radio"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                onChange={() => {
                                  formik.values.mode = "AI";
                                  setRoundMode("AI");
                                }}
                              ></input>
                              <label className={Style.label}>AI</label>
                            </div>
                          </div>
                          <label className={Style.label}>Title</label>
                          <input
                            placeholder="Round Name"
                            type="text"
                            id="name"
                            name="name"
                            value={formik.values.name}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            className={
                              formik.touched.name && formik.errors.name
                                ? Style.iError
                                : Style.iInput
                            }
                          ></input>
                          {formik.touched.name && formik.errors.name && (
                            <div className="error">{formik.errors.name}</div>
                          )}
                        </div>
                        <div className="my-2 lg:mx-5 md:mx-5 xl:mx-5">
                          <label className={Style.label}>Type</label>
                          <select
                            id="type"
                            name="type"
                            value={formik.values.type}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            className={
                              formik.touched.type && formik.errors.type
                                ? Style.iError
                                : Style.iInput
                            }
                          >
                            <option value="" disabled>
                              Type
                            </option>
                            <option value="Technical">Technical</option>
                            <option value="HR">HR</option>
                          </select>
                          {formik.touched.type && formik.errors.type && (
                            <div className="error">{formik.errors.type}</div>
                          )}
                        </div>
                        <div className="my-2 lg:mx-5 md:mx-5 xl:mx-5">
                          <label className={Style.label}>Interviewer</label>
                          <div className="flex flex-row item-center justify-between mb-3 mt-2 gap-5 grid grid-cols-3">
                            <div className="flex items-center gap-2">
                              <input
                                checked={intType === "other"}
                                id="default-radio-2"
                                type="radio"
                                value={intType}
                                name="default-radio-2"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                onChange={() => setIntType("other")}
                              ></input>
                              <label className="text-gray-500 text-xs font-normal">
                                Interviewer type
                              </label>
                            </div>
                            <div className="flex items-center gap-2">
                              <input
                                checked={intType === "fav"}
                                id="default-radio-3"
                                value={intType}
                                type="radio"
                                name="default-radio-3"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                onChange={() => setIntType("fav")}
                              ></input>
                              <label className="text-gray-500 text-xs font-normal">
                                Favourites
                              </label>
                            </div>
                            <div className="flex items-center gap-2">
                              <input
                                checked={intType === "group"}
                                id="default-radio-3"
                                value={intType}
                                type="radio"
                                name="default-radio-3"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                onChange={() => setIntType("group")}
                              ></input>
                              <label className="text-gray-500 text-xs font-normal">
                                Group
                              </label>
                            </div>
                          </div>
                          {intType === "other" && (
                            <div className="flex flex-col items-start">
                              <div
                                className="inline-flex items-center"
                                onClick={() => setInhouse(!inhouse)}
                              >
                                <label className="relative flex items-center p-3 rounded-full cursor-pointer">
                                  <input
                                    type="checkbox"
                                    checked={inhouse ? true : false}
                                    className="before:content[''] peer relative h-3 w-3 cursor-pointer appearance-none rounded-sm border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-600 hover:before:opacity-10"
                                    id="check"
                                  />
                                  <span className="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-3.5 w-3.5"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      stroke="currentColor"
                                      stroke-width="1"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clip-rule="evenodd"
                                      ></path>
                                    </svg>
                                  </span>
                                </label>
                                <label
                                  className="mt-px font-light text-gray-700 text-xs cursor-pointer select-none"
                                  htmlFor="check"
                                >
                                  In-House
                                </label>
                              </div>
                              <div
                                className="inline-flex items-center"
                                onClick={() => setExternal(!external)}
                              >
                                <label className="relative flex items-center p-3 rounded-full cursor-pointer">
                                  <input
                                    type="checkbox"
                                    checked={external ? true : false}
                                    className="before:content[''] peer relative h-3 w-3 cursor-pointer appearance-none rounded-sm border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-600 hover:before:opacity-10"
                                    id="check1"
                                  />
                                  <span className="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-3.5 w-3.5"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      stroke="currentColor"
                                      stroke-width="1"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clip-rule="evenodd"
                                      ></path>
                                    </svg>
                                  </span>
                                </label>
                                <label
                                  className="mt-px font-light text-gray-700 text-xs cursor-pointer select-none"
                                  htmlFor="check1"
                                >
                                  External
                                </label>
                              </div>
                              <div
                                className="inline-flex items-center"
                                onClick={() => setMarket(!market)}
                              >
                                <label className="relative flex items-center p-3 rounded-full cursor-pointer">
                                  <input
                                    type="checkbox"
                                    checked={market ? true : false}
                                    className="before:content[''] peer relative h-3 w-3 cursor-pointer appearance-none rounded-sm border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-600 hover:before:opacity-10"
                                    id="check2"
                                  />
                                  <span className="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-3.5 w-3.5"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                      stroke="currentColor"
                                      stroke-width="1"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clip-rule="evenodd"
                                      ></path>
                                    </svg>
                                  </span>
                                </label>
                                <label
                                  className="mt-px font-light text-gray-700 text-xs cursor-pointer select-none"
                                  htmlFor="check2"
                                >
                                  Market place
                                </label>
                              </div>
                            </div>
                          )}
                          {intType === "fav" && (
                            <>
                              <div className="gap-2 flex flex-col items-center gap-2">
                                <button
                                  type="button"
                                  className="mt-2 py-1.5 px-2 text-gray-500 outline-none border text-xs rounded w-1/3 flex flex-row items-center justify-center gap-2"
                                  onClick={() => setfromType("viewFav")}
                                >
                                  <span className="flex items-center gap-2">
                                    <BsBookmarkStarFill /> {favCount}{" "}
                                  </span>
                                  Favourites
                                </button>
                                <button
                                  type="button"
                                  className={
                                    "mt-2 py-1.5 px-2 text-gray-500 outline-none border text-xs rounded w-1/3 flex flex-row items-center justify-center gap-2"
                                  }
                                  onClick={() => setfromType("addFav")}
                                >
                                  <BsPlus />
                                  Add Favourites
                                </button>
                              </div>
                            </>
                          )}
                          {intType === "group" && (
                            <>
                              <div className="gap-2 flex flex-col items-end gap-2">
                                <button
                                  type="button"
                                  className={
                                    "mt-2 py-1.5 px-2 text-gray-500 outline-none border text-xs rounded w-1/3 flex flex-row items-center justify-center gap-2"
                                  }
                                  onClick={() => intGroup()}
                                >
                                  <BsPlus />
                                  {roundType === "add" && groups.length}{" "}
                                  Interviewer Group
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className=" gap-2 mt-3 sm:flex md:flex lg:flex justify-center">
                        <button
                          className=" mt-2 py-1.5 px-2.5 text-gray-800 outline-none border bg-gray-100 text-xs rounded-md"
                          onClick={() => setShowAddRoundModal(false)}
                          type="button"
                        >
                          Cancel
                        </button>
                        <button
                          className={
                            !isDisabled
                              ? "mt-2 py-1.5 px-2.5 text-white button-primary outline-none text-xs rounded-md"
                              : "mt-2 py-1.5 px-2.5 text-white button-primary-tint outline-none text-xs rounded-md"
                          }
                          type="submit"
                          disabled={isDisabled}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {addInterviewerModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex items-center max-h-screen px-1 py-1">
            <div className="relative w-full max-w-full p-1 mx-auto bg-white shadow-lg">
              <div className="sm:flex">
                <div className="mt-1 text-center sm:text-left w-full">
                  <div className="flex items-center justify-between border-b">
                    <h6 className="text-sm font-bold text-gray-800 mb-2">
                      Add Favourite Interviewers
                    </h6>
                  </div>
                  <div className="mt-1">
                    <SelectFavourites
                      callBack={setCallback}
                      postId={id}
                      from_type={fromType}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {uploadResumeModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-lg p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
              <div className="sm:flex">
                <div className="mt-1 text-center sm:text-left w-full">
                  <div className="flex items-center justify-between border-b">
                    <h6 className="text-sm font-bold text-gray-800 mb-2">
                      Upload Resume
                    </h6>
                  </div>
                  <div className="mt-1">
                    <form className="max-w-lg mx-auto mt-4">
                      <div className="flex items-center w-full border-primary rounded ">
                        <div className="cursor-pointer w-full">
                          <label className="cursor-pointer w-full flex justify-start">
                            {selectedFile.length > 0 && (
                              <div className="flex justify-center items-center border-r overflow-hidden font-semibold text-xs p-2 bg-primary text-white w-1/4">
                                <BsUpload color="white" size={10} /> &nbsp;
                                Change
                              </div>
                            )}
                            {selectedFile.length === 0 && (
                              <div className="flex justify-center items-center border-r  overflow-hidden font-semibold text-xs p-2 bg-primary text-white w-1/4">
                                <BsUpload color="white" size={10} /> &nbsp;
                                Upload
                              </div>
                            )}
                            <div className="flex justify-start items-center w-2/4 ">
                              &nbsp;
                              {selectedFile.length !== 0 && (
                                <div className="text-sm px-3 text-gray-400 truncate">
                                  {selectedFile[0]?.name}
                                </div>
                              )}
                              <input
                                type="file"
                                className="hidden"
                                multiple={true}
                                onChange={handleImageChange}
                              ></input>
                            </div>
                          </label>
                        </div>
                      </div>
                      {fileError !== "" && (
                        <div className="error mb-2">{fileError}</div>
                      )}
                      <div
                        className="mt-1 text-xs text-gray-500"
                        id="user_avatar_help"
                      >
                        Supported format (.pdf, .docx), Size (Max 3MB)
                      </div>
                      {selectedFile.length > 0 && (
                        <>
                          {selectedFile.map((resume: any, index: number) => (
                            <>
                              <div
                                className="flex flex-row justify-between my-2"
                                key={index}
                              >
                                <div className="flex flex-row items-center gap-2 justify-start">
                                  <SwitchComp resumeType={resumeType} setResumeType={setResumeType} index={index} on={'Auto'} off={'Manual'}></SwitchComp>
                                  <span className=" text-sm font-medium text-gray-600">
                                    {resume.name}
                                  </span>
                                </div>
                                <button
                                  onClick={() => removeResume(index)}
                                  type="button"
                                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                  <CloseModal />
                                </button>
                              </div>
                            </>
                          ))}
                        </>
                      )}
                    </form>

                    <div className=" gap-2 mt-3 sm:flex md:flex lg:flex flex-row justify-end">
                      <button
                        className=" mt-2 py-1 px-2.5 text-gray-800 outline-none border text-sm rounded-md"
                        onClick={() => setUploadResumeModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className={
                          selectedFile.length > 0
                            ? " button-primary mt-2 py-1 px-2.5 text-white outline-none text-sm rounded-md"
                            : " bg-indigo-300 mt-2 py-1 px-2.5 text-white outline-none text-sm rounded-md"
                        }
                        onClick={() => uploadResume()}
                        disabled={
                          selectedFile.length > 0 && !isDisabled ? false : true
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {addGroupModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex items-center max-h-screen px-1 py-1">
            <div className="relative w-full max-w-4xl p-1 mx-auto bg-white shadow-lg">
              <div className="sm:flex">
                <div className="mt-1 text-center sm:text-left w-full p-3">
                  <div className="flex items-center justify-between border-b">
                    <h6 className="text-sm font-bold text-gray-800 mb-2 py-1">
                      Add Interviewer Groups
                    </h6>
                  </div>
                  <div className="mt-1">
                    <SelectGroups
                      callBack={setGroupCallback}
                      selectedGroups={groups}
                      roundId={roundType === "add" ? null : roundId}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {jdDetail && (
        <div className="overflow-y-auto" style={{ height: "82vh" }}>
          <div className="flex justify-center">
            <div className="w-3/4">
              <div className="flex items-center justify-between border-b">
                <h6 className="text-sm font-bold text-gray-800 mb-2">
                  <div className="flex flex-row items-center">
                    <p
                      className="cursor-pointer text-primary"
                      onClick={() => navigate(-2)}
                    >
                      {"Dashboard "}
                    </p>
                    <p>&nbsp;{" / "}&nbsp;</p>
                    <p
                      className="cursor-pointer text-primary"
                      onClick={() => navigate(-1)}
                    >
                      {" Job Description"}
                    </p>
                    <p>&nbsp;{" / "}&nbsp;</p>
                    <p>{" Upload Candidates"}</p>
                  </div>
                </h6>
              </div>
              <div className="bg-gray-100 p-4 flex flex-row gap-4 items-center h-20 px-5 mb-3">
                <div className="w-1/4">
                  <h6 className="text-xs font-semibold text-gray-600 truncate mr-4">
                    {jdDetail["job_title"]}
                  </h6>
                </div>
                <div className="text-xs font-semibold text-gray-800 w-1/4">
                  {jdDetail["profile"]}
                </div>
                <div className="w-1/4 text-xs font-semibold text-gray-500">
                  ID: {jdDetail["unique_id"]}
                </div>
                <div className="w-1/4 text-xs font-semibold text-gray-500">
                  Date : {getDate(jdDetail["post_validity"])}
                </div>
              </div>
              <div className="flex flex-row justify-end mt-2 mb-4 gap-2">
                {/* <button
                  type="button"
                  onClick={() => gotoInterviewerList()}
                  className="mt-2 py-1.5 px-3.5 text-red-500 outline-none border border-red-500 text-xs rounded-md flex gap-2 items-center"
                >
                  <BsBookmarkStarFill />
                  Interviewer(s)
                  <span className="bg-red-500 rounded-full px-1.5 py-0.5 text-white text-xs">
                    {jdDetail["fav_count"]}
                  </span>
                </button> */}
                <button
                  type="button"
                  className="mt-2 py-2 px-4 text-primary outline-none border-primary text-xs rounded-md flex flex-row gap-2 text-xs font-medium	"
                  onClick={() => openUploadResume()}
                >
                  <BsUpload /> Candidate(s)
                </button>
              </div>
              <div className=" grid grid-cols-3 gap-4 ">
                {/* (jdDetail['round'] as any) */}
                {(jdDetail["round"] as any).map((value: any, index: number) => (
                  <>
                    {(jdDetail["round"] as any).length - 1 !== index && (
                      <div
                        key={value.id}
                        className="bg-gray-100 p-4 flex flex-col gap-1 h-60 rounded-lg cursor-pointer"
                      >
                        <div className="flex items-start justify-between border-b py-1 mb-3">
                          <div className="flex items-start justify-start gap-2 mr-4 text-xs">
                            {value.mode === "Manual" && (
                              <BsFillPersonFill color="blue" size={14} />
                            )}
                            {value.mode === "AI" && (
                              <BsRobot color="blue" size={14} />
                            )}
                            <div
                              className="flex flex-col gap-1 w-3/4"
                              onClick={() =>
                                gotoCandidateList(
                                  value.id,
                                  value.level,
                                  index,
                                  null
                                )
                              }
                            >
                              <h6 className=" font-semibold text-gray-600">
                                {value.name}
                              </h6>
                              <div className="text-xs font-normal text-gray-400">
                                {value.type}
                              </div>
                            </div>
                          </div>
                          <button
                            type="button"
                            className="mt-2 py-0.5 px-1 text-gray-800 bg-white outline-none border rounded-sm flex flex-row item-center gap-2 text-xs font-normal"
                            onClick={() => editRound(value)}
                          >
                            <BsPencil />
                          </button>
                        </div>
                        <div
                          className="flex flex-col gap-1"
                          onClick={() =>
                            gotoCandidateList(
                              value.id,
                              value.level,
                              index,
                              null
                            )
                          }
                        >
                          <div className="flex flex-row items-center">
                            <div
                              className="text-xs font-normal text-gray-400"
                              onClick={(event: any) => {
                                event.stopPropagation();
                                gotoCandidateList(
                                  value.id,
                                  value.level,
                                  index,
                                  "New"
                                );
                              }}
                            >
                              New : &nbsp;
                            </div>
                            <div
                              className="text-xs font-semibold text-gray-800"
                              onClick={(event: any) => {
                                event.stopPropagation();
                                gotoCandidateList(
                                  value.id,
                                  value.level,
                                  index,
                                  "New"
                                );
                              }}
                            >
                              {value.new}
                            </div>
                          </div>
                          <div className="flex flex-row items-center">
                            <div
                              className="text-xs font-normal text-gray-400"
                              onClick={(event: any) => {
                                event.stopPropagation();
                                gotoCandidateList(
                                  value.id,
                                  value.level,
                                  index,
                                  "Scheduled"
                                );
                              }}
                            >
                              Scheduled : &nbsp;
                            </div>
                            <div
                              className="text-xs font-semibold text-gray-800"
                              onClick={(event: any) => {
                                event.stopPropagation();
                                gotoCandidateList(
                                  value.id,
                                  value.level,
                                  index,
                                  "Scheduled"
                                );
                              }}
                            >
                              {value.scheduled}
                            </div>
                          </div>
                          <div className="flex flex-row items-center">
                            <div
                              className="text-xs font-normal text-gray-400"
                              onClick={(event: any) => {
                                event.stopPropagation();
                                gotoCandidateList(
                                  value.id,
                                  value.level,
                                  index,
                                  "Completed"
                                );
                              }}
                            >
                              Completed : &nbsp;
                            </div>
                            <div
                              className="text-xs font-semibold text-gray-800"
                              onClick={(event: any) => {
                                event.stopPropagation();
                                gotoCandidateList(
                                  value.id,
                                  value.level,
                                  index,
                                  "Completed"
                                );
                              }}
                            >
                              {value.completed}
                            </div>
                          </div>
                          <div className="flex flex-row items-center">
                            <div
                              className="text-xs font-normal text-gray-400"
                              onClick={(event: any) => {
                                event.stopPropagation();
                                gotoCandidateList(
                                  value.id,
                                  value.level,
                                  index,
                                  "Selected"
                                );
                              }}
                            >
                              Selected : &nbsp;
                            </div>
                            <div
                              className="text-xs font-semibold text-gray-800"
                              onClick={(event: any) => {
                                event.stopPropagation();
                                gotoCandidateList(
                                  value.id,
                                  value.level,
                                  index,
                                  "Selected"
                                );
                              }}
                            >
                              {value.selected}
                            </div>
                          </div>
                          <div className="flex flex-row justify-between items-center mt-1 mb-1">
                            <div className="flex flex-col items-start">
                              <h1 className="text-xs font-semibold text-gray-700">
                                Interviewers :
                              </h1>
                              {value.fav && (
                                <h1 className="text-xs font-normal text-gray-500">
                                  Favourites
                                </h1>
                              )}
                              {value.group && (
                                <h1 className="text-xs font-normal text-gray-500">
                                  Groups
                                </h1>
                              )}
                              {value.external && (
                                <h1 className="text-xs font-normal text-gray-500">
                                  External
                                </h1>
                              )}
                              {value.house && (
                                <h1 className="text-xs font-normal text-gray-500">
                                  In House
                                </h1>
                              )}
                              {value.market && (
                                <h1 className="text-xs font-normal text-gray-500">
                                  Market Place
                                </h1>
                              )}
                            </div>
                            <div className="text-6xl font-semibold text-gray-700">
                              {value.new +
                                value.scheduled +
                                value.completed +
                                value.selected}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {(jdDetail["round"] as any).length - 1 === index && (
                      <div
                        key={value}
                        className="bg-primary p-4 flex flex-col justify-center items-center h-60 rounded-lg cursor-pointer"
                        onClick={() => addRound()}
                      >
                        <div className="text-6xl font-semibold text-white">
                          +
                        </div>
                        <div className="text-md font-semibold text-white">
                          Add
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JdRounds;
