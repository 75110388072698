import React from "react";

type ProgressProps = { total: number; completed: number };

const Progress: React.FC<ProgressProps> = ({
  total,
  completed,
}: ProgressProps): JSX.Element => {
  const [percent, setPercent] = React.useState(0);
  React.useEffect(() => {
    setPercent((completed / total) * 100);
  }, []);
  return (
    <>
      <div className=" flex justify-end items-center text-xs font-semibold text-gray-600 w-2/4 mb-1">
        <p>Openings : </p>
        <p>{total}</p>
      </div>
      <div className="h-2 bg-neutral-200 dark:bg-neutral-600 w-2/4 rounded-xl">
        <div
          className="h-2 bg-primary rounded-xl"
          style={{ width: `${percent}%` }}
        ></div>
        <div
          className="flex flex-col justify-center items-center text-xs font-normal text-gray-400"
          style={{ width: `${percent}%` }}
        >   
          <p className="text-gray-600">{completed}</p>
          <p style={{fontSize: '10px'}}>Offered</p>
        </div>
      </div>
    </>
  );
};

export default Progress;
