import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Style } from "../../../utils/Style";
import orgApi from "../../../api/org_api";
import S from "../../../Services/Services";
import { ToastContainer, toast } from "react-toastify";
import {
  BsBookmarkStarFill,
  BsFillPersonFill,
  BsPencil,
  BsPlus,
  BsRobot,
} from "react-icons/bs";
import SelectFavourites from "./SelectFavourites";
import SelectGroups from "./SelectGroup";

type SetupInterviewProps = {
  setLoading: Function;
  setLevel: Function;
  setBackUploadJd: Function;
  fromPage: String;
};

const SetupInterview: React.FC<SetupInterviewProps> = ({
  setLoading,
  setLevel,
  setBackUploadJd,
  fromPage,
}: SetupInterviewProps) => {
  const [showAddRoundModal, setShowAddRoundModal] = React.useState(false);
  const [roundType, setRoundType] = React.useState("add");
  const [jdDetail, setJdDetail] = React.useState();
  const [roundId, setRoundId] = React.useState("");
  const [roundMode, setRoundMode] = React.useState("Manual");
  const [intType, setIntType] = React.useState("other");
  const [inhouse, setInhouse] = React.useState(true);
  const [external, setExternal] = React.useState(false);
  const [market, setMarket] = React.useState(true);
  const [favCount, setFavCount] = React.useState(0);
  const [isFirst, setIsFirst] = React.useState(true);
  const [fromType, setFromType] = React.useState("");
  const [isDisabled, setisDisabled] = React.useState(false);
  const [showAddInterviewerModal, setShowAddInterviewerModal] =
    React.useState(false);
  const [addGroupModal, setAddGroupModal] = React.useState(false);
  const [groups, setgroups] = React.useState([] as any);

  React.useEffect(() => {
    setIsFirst(true);
    fetchJdDetails();
  }, []);

  const fetchJdDetails = async () => {
    var pId = localStorage.getItem("createPostId");
    setLoading(true);
    if (
      S.getOrgDomain() === "sightspectrum.com" ||
      S.getOrgDomain() === "vegaintellisoft.com" ||
      S.getOrgDomain() === "nam-it.com" ||
      S.getOrgDomain() === "infinitisoftware.net"
    ) {
      setMarket(false);
    }
    const res = await orgApi.getSingleJdDetail(pId);
    if (res.data.success) {
      setFavCount(res.data.post.fav_count);
      if (res.data.post.round.length === 1 && isFirst && fromPage === "jd") {
        var d = res.data.post.round[0];
        if (d.new + d.scheduled + d.completed + d.selected === 0) {
          editRound(d);
        }
      }
      res.data.post.round.length !== 0 &&
        res.data.post.round.push({ isNew: true });
      setJdDetail(res.data.post);
    } else {
      setJdDetail(undefined);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: { name: "", type: "", mode: "Manual" },
    validationSchema: Yup.object({
      name: Yup.string().required("Round name is required"),
      type: Yup.string().required("Select Type"),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (intType === "other") {
        if (!inhouse && !external && !market) {
          toast("Select Interviewers");
          return;
        }
      } else {
        setInhouse(false);
        setExternal(false);
        setMarket(false);
      }

      setLoading(true);
      setisDisabled(true);
      if (roundType === "add") {
        var reqData = {
          name: values.name,
          type: values.type,
          mode: values.mode,
          level: jdDetail ? (jdDetail["round"] as any).length : 1,
          post_id: localStorage.getItem("createPostId")!,
          fav: intType === "fav" ? true : false,
          house: intType !== "fav" && intType !== "group" ? inhouse : false,
          market: intType !== "fav" && intType !== "group" ? market : false,
          external: intType !== "fav" && intType !== "group" ? external : false,
          group: intType === "group" ? true : false,
          i_group: groups,
        };
        const res = await orgApi.addRound(reqData);
        if (res.data.success) {
          setShowAddRoundModal(false);
          toast(res.data.msg);
          fetchJdDetails();
        } else {
          toast(res.data.msg);
        }
        setisDisabled(false);
        setLoading(false);
      } else if (roundType === "edit") {
        var reqData1 = {
          name: values.name,
          type: values.type,
          mode: values.mode,
          round_id: roundId,
          fav: intType === "fav" ? true : false,
          house: intType !== "fav" && intType !== "group" ? inhouse : false,
          market: intType !== "fav" && intType !== "group" ? market : false,
          external: intType !== "fav" && intType !== "group" ? external : false,
          group: intType === "group" ? true : false,
        };
        const res = await orgApi.editRound(reqData1);
        if (res.data.success) {
          setShowAddRoundModal(false);
          toast(res.data.msg);
          fetchJdDetails();
        } else {
          toast(res.data.msg);
        }
        setisDisabled(false);
        setLoading(false);
      }
    },
  });

  const addRound = () => {
    formik.values.name = "";
    formik.values.type = "";
    formik.values.mode = "Manual";
    setRoundMode("Manual");
    setRoundId("");
    formik.resetForm();
    setRoundType("add");
    setIntType("other");
    setInhouse(true);
    setMarket(true);
    if (
      S.getOrgDomain() === "sightspectrum.com" ||
      S.getOrgDomain() === "vegaintellisoft.com" ||
      S.getOrgDomain() === "nam-it.com" ||
      S.getOrgDomain() === "infinitisoftware.net"
    ) {
      setMarket(false);
    }
    setShowAddRoundModal(true);
  };

  const editRound = (val: any) => {
    formik.resetForm();
    formik.values.name = val.name;
    formik.values.type = val.type;
    formik.values.mode = val.mode;
    setRoundMode(val.mode);
    setRoundId(val.id);
    setExternal(val.external);
    setInhouse(val.house);
    setMarket(val.market);

    if (val.external || val.house || val.market) {
      setIntType("other");
    } else {
      setIntType("fav");
    }
    setRoundType("edit");
    setShowAddRoundModal(true);
    setIsFirst(false);
  };

  const moveNextLevel = () => {
    if (jdDetail) {
      setLevel(jdDetail["round"][0]);
    }
  };

  const setCallback = () => {
    fetchJdDetails();
    setShowAddInterviewerModal(false);
  };
  const setGroupCallback = (grp: any) => {
    if (roundType === "edit") {
      setAddGroupModal(false);
    } else {
      setgroups(grp);
      setAddGroupModal(false);
    }
  };
  const setfromType = (type: any) => {
    setFromType(type);
    setShowAddInterviewerModal(true);
  };
  const intGroup = () => {
    setAddGroupModal(true);
  };
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      {showAddRoundModal && (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
                <div className="sm:flex">
                  <div className="mt-1 text-center sm:text-left w-full">
                    <div className="flex items-center justify-between border-b">
                      <h6 className="text-sm font-bold text-gray-800 mb-2">
                        {roundType === "add" ? "Add" : "Edit"} Round
                      </h6>
                    </div>
                    <div className="mt-1">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="grid md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 ">
                          <div className="my-2 lg:mx-5 md:mx-5 xl:mx-5">
                            <div className="flex flex-row item-center justify-center mb-3 gap-5">
                              <div className="flex items-center gap-2">
                                <input
                                  checked={roundMode === "Manual"}
                                  id="default-radio-1"
                                  type="radio"
                                  value="Manual"
                                  name="default-radio"
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                  onChange={() => {
                                    formik.values.mode = "Manual";
                                    setRoundMode("Manual");
                                  }}
                                ></input>
                                <label className={Style.label}>Manual</label>
                              </div>
                              <div className="flex items-center gap-2 disabled-div">
                                <input
                                  checked={roundMode === "AI"}
                                  id="default-radio-4"
                                  type="radio"
                                  value="AI"
                                  name="default-radio-4"
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                  onChange={() => {
                                    formik.values.mode = "AI";
                                    setRoundMode("AI");
                                  }}
                                ></input>
                                <label className={Style.label}>AI</label>
                              </div>
                            </div>
                            <label className={Style.label}>Title</label>
                            <input
                              placeholder="Round Name"
                              type="text"
                              id="name"
                              name="name"
                              value={formik.values.name}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              className={
                                formik.touched.name && formik.errors.name
                                  ? Style.iError
                                  : Style.iInput
                              }
                            ></input>
                            {formik.touched.name && formik.errors.name ? (
                              <div className="error">{formik.errors.name}</div>
                            ) : null}
                          </div>
                          <div className="my-2 lg:mx-5 md:mx-5 xl:mx-5">
                            <label className={Style.label}>Type</label>
                            <select
                              id="type"
                              name="type"
                              value={formik.values.type}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              className={
                                formik.touched.type && formik.errors.type
                                  ? Style.iError
                                  : Style.iInput
                              }
                            >
                              <option value="" disabled>
                                Type
                              </option>
                              <option value="Technical">Technical</option>
                              <option value="HR">HR</option>
                            </select>
                            {formik.touched.type && formik.errors.type ? (
                              <div className="error">{formik.errors.type}</div>
                            ) : null}
                          </div>
                          <div className="my-2 lg:mx-5 md:mx-5 xl:mx-5">
                            <label className={Style.label}>Interviewer</label>
                            <div className="flex flex-row item-center justify-between mb-3 mt-2 gap-5 grid grid-cols-3">
                              <div className="flex items-center gap-2">
                                <input
                                  checked={intType === "other"}
                                  id="default-radio-2"
                                  type="radio"
                                  value={intType}
                                  name="default-radio-2"
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                  onChange={() => setIntType("other")}
                                ></input>
                                <label className="text-gray-500 text-xs font-normal">
                                  Interviewer type
                                </label>
                              </div>
                              <div className="flex items-center gap-2">
                                <input
                                  checked={intType === "fav"}
                                  id="default-radio-3"
                                  value={intType}
                                  type="radio"
                                  name="default-radio-3"
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                  onChange={() => setIntType("fav")}
                                ></input>
                                <label className="text-gray-500 text-xs font-normal">
                                  Favourites
                                </label>
                              </div>
                              <div className="flex items-center gap-2">
                                <input
                                  checked={intType === "group"}
                                  id="default-radio-3"
                                  value={intType}
                                  type="radio"
                                  name="default-radio-3"
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                  onChange={() => setIntType("group")}
                                ></input>
                                <label className="text-gray-500 text-xs font-normal">
                                  Group
                                </label>
                              </div>
                            </div>
                            {intType === "other" && (
                              <div className="flex flex-col items-start">
                                <div
                                  className="inline-flex items-center"
                                  onClick={() => setInhouse(!inhouse)}
                                >
                                  <label className="relative flex items-center p-3 rounded-full cursor-pointer">
                                    <input
                                      type="checkbox"
                                      checked={inhouse ? true : false}
                                      className="before:content[''] peer relative h-3 w-3 cursor-pointer appearance-none rounded-sm border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-600 hover:before:opacity-10"
                                      id="check"
                                    />
                                    <span className="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-3.5 w-3.5"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        stroke="currentColor"
                                        stroke-width="1"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                          clip-rule="evenodd"
                                        ></path>
                                      </svg>
                                    </span>
                                  </label>
                                  <label
                                    className="mt-px font-light text-gray-700 text-xs cursor-pointer select-none"
                                    htmlFor="check"
                                  >
                                    In-House
                                  </label>
                                </div>
                                <div
                                  className="inline-flex items-center"
                                  onClick={() => setExternal(!external)}
                                >
                                  <label className="relative flex items-center p-3 rounded-full cursor-pointer">
                                    <input
                                      type="checkbox"
                                      checked={external ? true : false}
                                      className="before:content[''] peer relative h-3 w-3 cursor-pointer appearance-none rounded-sm border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-600 hover:before:opacity-10"
                                      id="check1"
                                    />
                                    <span className="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-3.5 w-3.5"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        stroke="currentColor"
                                        stroke-width="1"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                          clip-rule="evenodd"
                                        ></path>
                                      </svg>
                                    </span>
                                  </label>
                                  <label
                                    className="mt-px font-light text-gray-700 text-xs cursor-pointer select-none"
                                    htmlFor="check1"
                                  >
                                    External
                                  </label>
                                </div>
                                <div
                                  className="inline-flex items-center"
                                  onClick={() => setMarket(!market)}
                                >
                                  <label className="relative flex items-center p-3 rounded-full cursor-pointer">
                                    <input
                                      type="checkbox"
                                      checked={market ? true : false}
                                      className="before:content[''] peer relative h-3 w-3 cursor-pointer appearance-none rounded-sm border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-600 hover:before:opacity-10"
                                      id="check2"
                                    />
                                    <span className="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-3.5 w-3.5"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        stroke="currentColor"
                                        stroke-width="1"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                          clip-rule="evenodd"
                                        ></path>
                                      </svg>
                                    </span>
                                  </label>
                                  <label
                                    className="mt-px font-light text-gray-700 text-xs cursor-pointer select-none"
                                    htmlFor="check2"
                                  >
                                    Market place
                                  </label>
                                </div>
                              </div>
                            )}
                            {intType === "fav" && (
                              <>
                                <div className="gap-2 flex flex-col items-center gap-2">
                                  <button
                                    type="button"
                                    className={
                                      "mt-2 py-1.5 px-2 text-gray-500 outline-none border text-xs rounded w-1/3 flex flex-row items-center justify-center gap-2"
                                    }
                                    onClick={() => setfromType("viewFav")}
                                  >
                                    <span className="flex items-center gap-2">
                                      <BsBookmarkStarFill /> {favCount}{" "}
                                    </span>
                                    Favourites
                                  </button>
                                  <button
                                    type="button"
                                    className={
                                      "mt-2 py-1.5 px-2 text-gray-500 outline-none border text-xs rounded w-1/3 flex flex-row items-center justify-center gap-2"
                                    }
                                    onClick={() => setfromType("addFav")}
                                  >
                                    <BsPlus />
                                    Add Favourites
                                  </button>
                                </div>
                              </>
                            )}
                            {intType === "group" && (
                              <>
                                <div className="gap-2 flex flex-col items-end gap-2">
                                  <button
                                    type="button"
                                    className={
                                      "mt-2 py-1.5 px-2 text-gray-500 outline-none border text-xs rounded w-1/3 flex flex-row items-center justify-center gap-2"
                                    }
                                    onClick={() => intGroup()}
                                  >
                                    <BsPlus />
                                    {roundType === "add" && groups.length}{" "}
                                    Interviewer Group
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className=" gap-2 mt-3 sm:flex md:flex lg:flex justify-center">
                          <button
                            className=" mt-2 py-1.5 px-2.5 text-gray-800 outline-none border bg-gray-100 text-xs"
                            onClick={() => setShowAddRoundModal(false)}
                            type="button"
                          >
                            Cancel
                          </button>
                          <button
                            className={
                              !isDisabled
                                ? "mt-2 py-1.5 px-2.5 text-white button-primary outline-none text-xs"
                                : "mt-2 py-1.5 px-2.5 text-white button-primary-tint outline-none text-xs"
                            }
                            type="submit"
                            disabled={isDisabled}
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showAddInterviewerModal && (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowAddInterviewerModal(false)}
            ></div>
            <div className="flex items-center max-h-screen px-1 py-1">
              <div className="relative w-full max-w-full p-1 mx-auto bg-white shadow-lg">
                <div className="sm:flex">
                  <div className="mt-1 text-center sm:text-left w-full">
                    <div className="flex items-center justify-between border-b">
                      <h6 className="text-sm font-bold text-gray-800 mb-2">
                        Add Favourite Interviewers
                      </h6>
                      <button
                        onClick={() => setShowAddInterviewerModal(false)}
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        <svg
                          className="w-2 h-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span className="sr-only">Close modal</span>
                      </button>
                    </div>
                    <div className="mt-1">
                      <SelectFavourites
                        callBack={setCallback}
                        postId={localStorage.getItem("createPostId")!}
                        from_type={fromType}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {addGroupModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex items-center max-h-screen px-1 py-1">
            <div className="relative w-full max-w-4xl p-1 mx-auto bg-white shadow-lg">
              <div className="sm:flex">
                <div className="mt-1 text-center sm:text-left w-full p-3">
                  <div className="flex items-center justify-between border-b">
                    <h6 className="text-sm font-bold text-gray-800 mb-2 py-1">
                      Add Interviewer Groups
                    </h6>
                  </div>
                  <div className="mt-1">
                    <SelectGroups
                      callBack={setGroupCallback}
                      selectedGroups={groups}
                      roundId={roundType === "add" ? null : roundId}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {jdDetail && (
        <div
          className="overflow-y-auto flex flex-col content-between"
          style={{ height: "100%" }}
        >
          <div className="flex justify-center w-full">
            <div className=" grid grid-cols-3 gap-4 w-full">
              {(jdDetail["round"] as any).map((value: any, index: number) => (
                <>
                  {(jdDetail["round"] as any).length - 1 !== index && (
                    <div
                      key={value.id}
                      className="bg-gray-100 p-4 flex flex-col gap-1 h-60 rounded-lg cursor-pointer"
                    >
                      <div className="flex items-start justify-between border-b py-1 mb-3">
                        <div className="flex items-start justify-start gap-2">
                          {value.mode === "Manual" && (
                            <BsFillPersonFill color="blue" />
                          )}
                          {value.mode === "AI" && <BsRobot color="blue" />}
                          <div className="flex flex-col gap-1">
                            <h6 className="text-xs font-semibold text-gray-600 truncate mr-4">
                              {value.name}
                            </h6>
                            <div className="text-xs font-normal text-gray-400 truncate mr-4">
                              {value.type}
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="mt-2 py-0.5 px-1 text-gray-800 bg-white outline-none border rounded-sm flex flex-row item-center gap-2 text-xs font-normal"
                          onClick={() => editRound(value)}
                        >
                          <BsPencil /> Edit
                        </button>
                      </div>
                      <div className="flex flex-col gap-1">
                        <div className="flex flex-row items-center">
                          <div className="text-xs font-normal text-gray-400">
                            New : &nbsp;
                          </div>
                          <div className="text-xs font-semibold text-gray-800">
                            {value.new}
                          </div>
                        </div>
                        <div className="flex flex-row items-center">
                          <div className="text-xs font-normal text-gray-400">
                            Scheduled : &nbsp;
                          </div>
                          <div className="text-xs font-semibold text-gray-800">
                            {value.scheduled}
                          </div>
                        </div>
                        <div className="flex flex-row items-center">
                          <div className="text-xs font-normal text-gray-400">
                            Completed : &nbsp;
                          </div>
                          <div className="text-xs font-semibold text-gray-800">
                            {value.completed}
                          </div>
                        </div>
                        <div className="flex flex-row items-center">
                          <div className="text-xs font-normal text-gray-400">
                            Selected : &nbsp;
                          </div>
                          <div className="text-xs font-semibold text-gray-800">
                            {value.selected}
                          </div>
                        </div>
                        <div className="flex flex-row justify-between items-center mt-1 mb-1">
                          <div className="flex flex-col items-start">
                            <h1 className="text-xs font-semibold text-gray-700">
                              Interviewers :
                            </h1>
                            {value.fav && (
                              <h1 className="text-xs font-normal text-gray-500">
                                Favourites
                              </h1>
                            )}
                            {value.group && (
                              <h1 className="text-xs font-normal text-gray-500">
                                Groups
                              </h1>
                            )}
                            {value.external && (
                              <h1 className="text-xs font-normal text-gray-500">
                                External
                              </h1>
                            )}
                            {value.house && (
                              <h1 className="text-xs font-normal text-gray-500">
                                In House
                              </h1>
                            )}
                            {value.market && (
                              <h1 className="text-xs font-normal text-gray-500">
                                Market Place
                              </h1>
                            )}
                          </div>
                          <div className="text-6xl font-semibold text-gray-700">
                            {value.new +
                              value.scheduled +
                              value.completed +
                              value.selected}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {(jdDetail["round"] as any).length - 1 === index && (
                    <div
                      key={value}
                      className="bg-primary p-4 flex flex-col justify-center items-center h-60 rounded-lg cursor-pointer"
                      onClick={() => addRound()}
                    >
                      <div className="text-6xl font-semibold text-white">+</div>
                      <div className="text-md font-semibold text-white">
                        Add
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
          <div className="flex flex-row justify-end gap-2">
            <button
              className="mt-2 py-1.5 px-2.5 text-primary border-primary text-xs"
              type="button"
              onClick={() => setBackUploadJd()}
            >
              Back
            </button>
            <button
              className="mt-2 py-1.5 px-2.5 text-white button-primary outline-none text-xs"
              type="button"
              onClick={() => moveNextLevel()}
            >
              Save & Next
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default SetupInterview;
