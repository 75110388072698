import { useFormik } from "formik";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import { Style } from "../../../utils/Style";
import { BsCardImage, BsUpload } from "react-icons/bs";
import StarRatings from "react-star-ratings";
import intApi from "../../../api/int_api";
import Select from "react-select";
import PhoneInput from "react-phone-number-input";
import { parsePhoneNumberFromString, CountryCode } from "libphonenumber-js";

type InterviewerProfileProps = { setLoading: Function };

const InterviewerProfile: React.FC<InterviewerProfileProps> = ({
  setLoading,
}: InterviewerProfileProps): JSX.Element => {
  const [defaultPage, setDefaultPage] = React.useState("profile");
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [fileError, setfileError] = React.useState("");
  const year_options = Array.from({ length: 39 }, (_, index) => index + 2);
  const month_options = Array.from({ length: 10 }, (_, index) => index + 2);
  const [selectedResumeFile, setSelectedResumeFile] = React.useState<File>();
  const [resumeFileError, setResumeFileError] = React.useState("");
  const [selectedVideoFile, setSelectedVideoFile] = React.useState<File>();
  const [videoFileError, setVideofileError] = React.useState("");
  const [skillList, setSkillList] = React.useState([]);
  const [showAddSkillModal, setShowAddSkillModal] = React.useState(false);
  const [newSkill, setNewSkill] = React.useState({} as any);
  const [newSkillRate, setNewSkillRate] = React.useState(0);
  const [profile, setProfile] = React.useState({} as any);
  const [phone, setPhone] = React.useState("");
  const [defaultCC, setDefaultCC] = React.useState<CountryCode>("IN");
  const [isDisabled, setisDisabled] = React.useState(false);

  React.useEffect(() => {
    getProfile();
    getSkills();
  }, []);

  const getSkills = async () => {
    var cand = JSON.parse(localStorage.getItem("candidate")!);
    var canId = cand["id"];
    const data = { search: "" };
    const res = await intApi.skills(data, canId);
    setSkillList(res.data.skill);
  };

  const getProfile = async () => {
    setProfile({});
    setLoading(true);
    var cand = JSON.parse(localStorage.getItem("candidate")!);
    var canId = cand["id"];
    const res = await intApi.getProfile(canId);
    localStorage.setItem("candidate", JSON.stringify(res.data.candidate));
    setData(res.data.candidate);
  };

  const setData = (profile1: any) => {
    if (profile.profile_pic != null) {
      setSelectedImage(profile1.profile_pic);
    }
    formik1.setFieldValue("firstName", profile1.first_name);
    formik1.setFieldValue("lastName", profile1.last_name);
    formik2.setFieldValue("designation", profile1.designation);
    formik2.setFieldValue("exp_year", profile1.year_of_experience);
    formik2.setFieldValue("exp_month", profile1.month_of_experience);
    if (profile1.interviewer) {
      formik2.setFieldValue("role", profile1.interviewer.type);
    }
    setProfile(profile1);
    setPhone(`+${profile1.mobile_country_code}${profile1.mobile}`);
    const countryShortName: CountryCode = getCountryShortNameByCallingCode(
      `${profile1.mobile_country_code}${profile1.mobile}`
    );
    setDefaultCC(countryShortName);
    setLoading(false);
  };

  function getCountryShortNameByCallingCode(callingCode: any) {
    try {
      const phoneNumber = parsePhoneNumberFromString(`+${callingCode}`);
      if (!phoneNumber) {
        throw new Error("Invalid calling code");
      }

      const countryShortName = phoneNumber.country;
      return countryShortName || ("Unknown" as CountryCode);
    } catch (error) {
      console.error("Error parsing calling code:", error);
      return "Error" as CountryCode;
    }
  }

  const handleImageChange = (event: any) => {
    setfileError("");
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        if (file.size <= 2 * 1024 * 1024) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            setSelectedImage(e.target.result);
            setfileError("");
          };
          reader.readAsDataURL(file);
        } else {
          setfileError(
            "File size exceeds the limit. Please select a smaller file."
          );
        }
      } else {
        setfileError("Please select an image file.");
      }
    }
  };

  const handleResumeChange = async (event: any) => {
    setfileError("");
    const file = event.target.files[0];
    if (file) {
      if (
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/pdf"
      ) {
        if (file.size <= 3 * 1024 * 1024) {
          setSelectedResumeFile(file);
          setResumeFileError("");
        } else {
          setResumeFileError("Please upload the file with size <3 MB");
          setSelectedResumeFile(undefined);
        }
      } else {
        setResumeFileError("Please upload only pdf or docx format");
        setSelectedResumeFile(undefined);
      }
    }
  };

  const handleVideoChange = async (event: any) => {
    setfileError("");
    const file = event.target.files[0];
    if (file) {
      if (file.type === "video/mp4") {
        if (file.size <= 3 * 1024 * 1024) {
          setSelectedVideoFile(file);
          setVideofileError("");
        } else {
          setVideofileError("Please upload the video with size <3 MB");
          setSelectedVideoFile(undefined);
        }
      } else {
        setVideofileError("Please upload only mp4 format");
        setSelectedVideoFile(undefined);
      }
    }
  };

  function dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const formik1: any = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
    }),
    validateOnBlur: true,
    onSubmit: async (values: any) => {
      setfileError("");
      var canId = profile.id;
      var reqData = {
        first_name: values.firstName,
        last_name: values.lastName,
      };
      setLoading(true);
      const res = await intApi.onboard(
        canId,
        reqData,
        localStorage.getItem("int_token")!
      );
      if (res.data.success === true) {
        toast(res.data.msg);
        if (selectedImage !== null) {
          var file = dataURLtoFile(
            selectedImage,
            Math.random().toString(36).substring(6) + "_name.png"
          );
          var formData = new FormData();
          formData.append("employed", "true");
          formData.append("profile", file);
          const res1 = await intApi.updateProfile(formData, canId);
          if (res1.data.success === true) {
            toast(res1.data.msg);
          } else {
            toast(res1.data.msg);
          }
        }
        getProfile();
      } else {
        toast(res.data.msg);
      }
      setLoading(false);
    },
  });

  const formik2 = useFormik({
    initialValues: { role: "", designation: "", exp_year: 0, exp_month: 0 },
    validationSchema: Yup.object({
      designation: Yup.string().required("Designation is required"),
      exp_year: Yup.number().required("Experience is required"),
      exp_month: Yup.number().required("Experience is required"),
      role: Yup.string().required("Role is required"),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      var reqData = {
        type: values.role,
        designation: values.designation,
        year_of_experience: values.exp_year,
        month_of_experience: values.exp_month,
      };
      var canId = profile.id;
      setLoading(true);
      const res = await intApi.onboard(
        canId,
        reqData,
        localStorage.getItem("int_token")!
      );
      if (res.data.success === true) {
        toast(res.data.msg);
        getProfile();
      } else {
        toast(res.data.msg);
      }
      setLoading(false);
    },
  });

  const changePage = (page: any) => {
    setDefaultPage(page);
  };
  const openAddSkill = () => {
    setNewSkill({});
    setNewSkillRate(0);
    setShowAddSkillModal(true);
  };

  React.useEffect(() => {
    setLoading(true);
    setInterval(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const addNewSkill = async () => {
    var canId = profile.id;
    var data = {
      skill_id: newSkill.id,
      year: 0,
      month: 0,
      rating: newSkillRate,
    };
    setisDisabled(true);
    setLoading(true);
    const res = await intApi.updateSkill(data, canId);
    if (res.data.success) {
      getProfile();
      setLoading(false);
      setisDisabled(false);
      setShowAddSkillModal(false);
    } else {
      toast(res.data.msg);
    }
  };

  const removeSkill = async (id: any) => {
    setLoading(true);
    const res = await intApi.deleteSkill(id);
    if (res.data.success) {
      setLoading(false);
      toast(res.data.msg);
      getProfile();
    } else {
      toast(res.data.msg);
    }
  };

  const updatePersonalInfo = async () => {
    var canId = profile.id;
    if (selectedResumeFile) {
      const formData1 = new FormData();
      formData1.append("resume", selectedResumeFile!);
      const res1 = await intApi.uploadResume(canId, formData1, localStorage.getItem("int_token")!);
      if (res1.data.success) {
        toast(res1.data.msg);
      } else {
        toast(res1.data.msg);
      }
    }

    if (selectedVideoFile) {
      const formData2 = new FormData();
      formData2.append("video", selectedVideoFile!);
      const res2 = await intApi.uploadProfileVideo(canId, formData2);
      if (res2.data.success) {
        toast(res2.data.msg);
      } else {
        toast(res2.data.msg);
      }
    }

    getProfile();
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      {showAddSkillModal ? (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowAddSkillModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white shadow-lg">
                <div className="sm:flex">
                  <div className="mt-1 text-center sm:text-left w-full">
                    <div className="flex items-center justify-between border-b">
                      <h6 className="text-sm font-bold text-gray-800 mb-2">
                        Add Skills
                      </h6>
                      <button
                        onClick={() => setShowAddSkillModal(false)}
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        <svg
                          className="w-2 h-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span className="sr-only">Close modal</span>
                      </button>
                    </div>
                    <div className=" w-full mt-2 max-w-lg">
                      <div className="my-2">
                        <label className={Style.label}>Select Skill</label>
                        <Select
                          options={skillList}
                          getOptionLabel={(options: any) => {
                            return options["name"];
                          }}
                          getOptionValue={(options: any) => {
                            return options["name"];
                          }}
                          id="skill"
                          name="skill"
                          value={newSkill}
                          onChange={(item: any) => {
                            setNewSkill(item);
                          }}
                          className={"text-xs"}
                        />
                      </div>
                      <div className="my-2 flex items-center gap-3">
                        <label className={Style.label}>Rating</label>
                        <StarRatings
                          rating={newSkillRate}
                          changeRating={(rate: number) => {
                            setNewSkillRate(rate);
                          }}
                          numberOfStars={5}
                          name="rating"
                          starRatedColor="blue"
                          starHoverColor="blue"
                          starDimension="18px"
                          starSpacing="5px"
                        />
                      </div>
                    </div>

                    <div className=" gap-2 mt-3 sm:flex md:flex lg:flex flex-row-reverse">
                      <button
                        onClick={() => addNewSkill()}
                        disabled={isDisabled}
                        className={!isDisabled ? "mt-2 py-1 px-2.5 text-white button-primary outline-none text-sm" : "mt-2 py-1 px-2.5 text-white button-primary-tint outline-none text-sm"}
                      >
                        Submit
                      </button>
                      <button
                        className=" mt-2 py-1 px-2.5 text-gray-800 outline-none border text-sm"
                        onClick={() => setShowAddSkillModal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
        <ul
          className="flex justify-center -mb-px"
          id="default-tab"
          data-tabs-toggle="#default-tab-content"
          role="tablist"
        >
          <li className="me-2">
            <a
              onClick={() => changePage("profile")}
              className={
                defaultPage === "profile"
                  ? "inline-flex items-center justify-center  p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                  : "inline-flex items-center justify-center  p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              }
            >
              <svg
                className="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
              </svg>
              Profile
            </a>
          </li>
          <li className="me-2">
            <a
              onClick={() => changePage("exp")}
              className={
                defaultPage === "exp"
                  ? "inline-flex items-center justify-center  p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                  : "inline-flex items-center justify-center  p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              }
            >
              <svg
                className="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M5 11.424V1a1 1 0 1 0-2 0v10.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.228 3.228 0 0 0 0-6.152ZM19.25 14.5A3.243 3.243 0 0 0 17 11.424V1a1 1 0 0 0-2 0v10.424a3.227 3.227 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.243 3.243 0 0 0 2.25-3.076Zm-6-9A3.243 3.243 0 0 0 11 2.424V1a1 1 0 0 0-2 0v1.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0V8.576A3.243 3.243 0 0 0 13.25 5.5Z" />
              </svg>
              Work Experience
            </a>
          </li>
          <li className="me-2">
            <a
              onClick={() => changePage("skills")}
              className={
                defaultPage === "skills"
                  ? "inline-flex items-center justify-center  p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                  : "inline-flex items-center justify-center  p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              }
            >
              <svg
                className="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 20"
              >
                <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
              </svg>
              Skills
            </a>
          </li>
          <li className="me-2">
            <a
              onClick={() => changePage("info")}
              className={
                defaultPage === "info"
                  ? "inline-flex items-center justify-center  p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                  : "inline-flex items-center justify-center  p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              }
            >
              <svg
                className="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
              </svg>
              Personal Info
            </a>
          </li>
        </ul>
      </div>
      {profile && (
        <div id="default-tab-content">
          {defaultPage === "profile" && (
            <div className="flex justify-center">
              <div
                className="p-4 rounded-lg bg-gray-50 dark:bg-gray-800 w-3/5"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <form onSubmit={formik1.handleSubmit}>
                  <div className="grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-2 mb-2 gap-3">
                    <div className="mb-2">
                      <label className={Style.label}>Profile Pic</label>

                      <label className="cursor-pointer p-2">
                        <div className="flex items-center w-full border-primary rounded h-24 gap-2">
                          {selectedImage ? (
                            <div className="w-24 h-24 mr-4 flex justify-center items-center border-r overflow-hidden">
                              <img
                                className="w-20 h-20 object-cover"
                                src={selectedImage}
                                alt="Avatar Upload"
                              ></img>
                            </div>
                          ) : profile.profile_pic != null ? (
                            <div className="w-24 h-24 mr-4 flex justify-center items-center border-r overflow-hidden">
                              <img
                                className="w-20 h-20 object-cover"
                                src={profile.profile_pic}
                                alt="Avatar Upload"
                              ></img>
                            </div>
                          ) : (
                            <></>
                          )}
                          {!selectedImage && profile.profile_pic == null && (
                            <div className="w-24 h-24 flex justify-center items-center border-r  overflow-hidden ">
                              <BsCardImage className="text-primary" size={24} />
                            </div>
                          )}
                          <div className="flex flex-col justify-start gap-1">
                            <div className="font-semibold text-xs text-gray-700">
                              Upload
                            </div>
                            <div className="font-thin text-xs text-gray-400">
                              jpeg, png & jpg format are acceptable
                            </div>
                          </div>
                          <input
                            type="file"
                            className="hidden"
                            onChange={handleImageChange}
                          ></input>
                        </div>
                      </label>

                      {fileError !== "" ? (
                        <div className="error">{fileError}</div>
                      ) : null}
                    </div>
                    <div></div>
                    <div>
                      <label className={Style.label}>First Name</label>
                      <input
                        placeholder="First Name"
                        type="text"
                        id="firstName"
                        name="firstName"
                        defaultValue={profile.first_name}
                        value={formik1.values.firstName}
                        onBlur={formik1.handleBlur}
                        onChange={formik1.handleChange}
                        className={
                          formik1.touched.firstName && formik1.errors.firstName
                            ? Style.iError
                            : Style.iInput
                        }
                      ></input>
                      {formik1.touched.firstName && formik1.errors.firstName ? (
                        <div className="error">{formik1.errors.firstName}</div>
                      ) : null}
                    </div>
                    <div>
                      <label className={Style.label}>Last Name</label>
                      <input
                        placeholder="Last Name"
                        type="text"
                        id="lastName"
                        name="lastName"
                        defaultValue={profile.last_name}
                        value={formik1.values.lastName}
                        onBlur={formik1.handleBlur}
                        onChange={formik1.handleChange}
                        className={
                          formik1.touched.lastName && formik1.errors.lastName
                            ? Style.iError
                            : Style.iInput
                        }
                      ></input>
                      {formik1.touched.lastName && formik1.errors.lastName ? (
                        <div className="error">{formik1.errors.lastName}</div>
                      ) : null}
                    </div>
                    <div>
                      <label className={Style.label}>Email</label>
                      <input
                        placeholder="Email"
                        disabled={true}
                        type="text"
                        id="email"
                        name="email"
                        defaultValue={profile.email}
                        className={Style.iInput}
                      ></input>
                    </div>
                    <div>
                      <label className={Style.label}>Mobile</label>
                      <PhoneInput
                        defaultCountry={defaultCC}
                        disabled={true}
                        international
                        countryCallingCodeEditable={false}
                        initialValueFormat="national"
                        name="phone"
                        value={phone}
                        onChange={() => null}
                        className={Style.iInput}
                        autoComplete="off"
                      />
                      {/* <input
                        placeholder="Phone"
                        disabled={true}
                        type="text"
                        id="phone"
                        name="phone"
                        defaultValue={profile.mobile}
                        className={Style.iInput}
                      ></input> */}
                    </div>
                  </div>

                  <div className="grid justify-items-stretch mt-6">
                    <button className="button-primary text-sm justify-self-end hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {defaultPage === "exp" && (
            <div className="flex justify-center">
              <div
                className="p-4 rounded-lg bg-gray-50 dark:bg-gray-800 w-3/5"
                id="exp"
                role="tabpanel"
                aria-labelledby="exp-tab"
              >
                <form onSubmit={formik2.handleSubmit}>
                  <div className="grid grid-cols-2 mb-2 gap-3">
                    {profile.interviewer && (
                      <div className=" ">
                        <label className={Style.label}>Role</label>
                        <select
                          id="role"
                          name="role"
                          defaultValue={profile.interviewer.type}
                          value={formik2.values.role}
                          onBlur={formik2.handleBlur}
                          onChange={formik2.handleChange}
                          className={
                            formik2.touched.role && formik2.errors.role
                              ? Style.iError
                              : Style.iInputD
                          }
                        >
                          <option value="" disabled>
                            Select Role *
                          </option>
                          <option value="HR">HR</option>
                          <option value="Technical">Tech</option>
                        </select>

                        {formik2.touched.role && formik2.errors.role ? (
                          <div className="error">{formik2.errors.role}</div>
                        ) : null}
                      </div>
                    )}
                    <div className="">
                      <label className={Style.label}>Designation</label>
                      <input
                        placeholder="Designation"
                        type="text"
                        id="designation"
                        name="designation"
                        defaultValue={profile.designation}
                        value={formik2.values.designation}
                        onBlur={formik2.handleBlur}
                        onChange={formik2.handleChange}
                        className={
                          formik2.touched.designation &&
                          formik2.errors.designation
                            ? Style.iError
                            : Style.iInput
                        }
                      ></input>
                      {formik2.touched.designation &&
                      formik2.errors.designation ? (
                        <div className="error">
                          {formik2.errors.designation}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="">
                    <label className={Style.label}>Overall Experience</label>
                    <div className="grid grid-cols-2 mb-2 gap-3">
                      <div>
                        <select
                          id="exp_year"
                          name="exp_year"
                          defaultValue={profile.year_of_experience}
                          value={formik2.values.exp_year}
                          onBlur={formik2.handleBlur}
                          onChange={formik2.handleChange}
                          className={
                            formik2.touched.exp_year && formik2.errors.exp_year
                              ? Style.iError
                              : Style.iInput
                          }
                        >
                          <option value="" disabled>
                            Select Year *
                          </option>
                          <option value="1">1 year</option>
                          {year_options.map((number) => (
                            <option key={number} value={number}>
                              {number} years
                            </option>
                          ))}
                        </select>

                        {formik2.touched.exp_year && formik2.errors.exp_year ? (
                          <div className="error">{formik2.errors.exp_year}</div>
                        ) : null}
                      </div>
                      <div>
                        <select
                          id="exp_month"
                          name="exp_month"
                          defaultValue={profile.month_of_experience}
                          value={formik2.values.exp_month}
                          onBlur={formik2.handleBlur}
                          onChange={formik2.handleChange}
                          className={
                            formik2.touched.exp_month &&
                            formik2.errors.exp_month
                              ? Style.iError
                              : Style.iInput
                          }
                        >
                          <option value="" disabled>
                            Select Month *
                          </option>
                          <option value="0">0 month</option>
                          <option value="1">1 month</option>
                          {month_options.map((number) => (
                            <option key={number} value={number}>
                              {number} months
                            </option>
                          ))}
                        </select>

                        {formik2.touched.exp_month &&
                        formik2.errors.exp_month ? (
                          <div className="error">
                            {formik2.errors.exp_month}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="grid justify-items-stretch mt-6">
                    <button
                      type="submit"
                      className="button-primary text-sm justify-self-end hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
          {defaultPage === "skills" && (
            <div className="flex justify-center">
              {profile.u_p_skill && (
                <div
                  className="p-4 rounded-lg bg-gray-50 dark:bg-gray-800 w-3/5  overflow-y-auto "
                  style={{ height: "75vh" }}
                  id="skill"
                  role="tabpanel"
                  aria-labelledby="skill-tab"
                >
                  <>
                    <div className="flex items-center justify-end my-2">
                      <button
                        type="button"
                        className="text-xs text-white bg-primary rounded-sm py-1 px-2"
                        onClick={() => openAddSkill()}
                      >
                        + Add Skill
                      </button>
                    </div>
                    {profile.u_p_skill.map((v: any) => (
                      <div className="grid grid-cols-3 border-b py-2 px-2">
                        <p className="text-sm text-gray-500">{v.skill.name}</p>
                        <div className="flex items-center justify-end">
                          <StarRatings
                            rating={v.self_rating}
                            changeRating={(rate: number) => {
                              console.log(rate);
                            }}
                            numberOfStars={5}
                            name="rating"
                            starRatedColor="blue"
                            starHoverColor="blue"
                            starDimension="18px"
                            starSpacing="5px"
                          />
                        </div>
                        <div className="flex items-center justify-end gap-2">
                          <button
                            type="button"
                            className="text-xs text-white bg-red-500 rounded-sm py-1 px-2"
                            onClick={() => removeSkill(v.id)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}
                  </>
                </div>
              )}
            </div>
          )}
          {defaultPage === "info" && (
            <div className="flex justify-center">
              <div
                className="p-4 rounded-lg bg-gray-50 dark:bg-gray-800 w-3/5  overflow-y-auto "
                style={{ height: "75vh" }}
                id="exp"
                role="tabpanel"
                aria-labelledby="exp-tab"
              >
                <form>
                  {profile.resume && profile.resume.length !== 0 && (
                    <div className="my-2 flex items-center gap-4">
                      <label className={Style.label}>Your Resume</label>
                      <a
                        href={profile.resume[0].url}
                        target="_blank"
                        className="text-blue-500 font-semibold text-sm underline cursor-pointer"
                      >
                        View Resume
                      </a>
                    </div>
                  )}
                  <div className="my-2">
                    <label className={Style.label}>Change Resume</label>
                    <div className="flex items-center w-full border-primary rounded ">
                      <div className="cursor-pointer  w-full">
                        <label className="cursor-pointer w-full flex justify-start">
                          {selectedResumeFile && (
                            <div className="flex justify-center items-center border-r overflow-hidden font-semibold text-xs p-2 bg-primary text-white w-2/4">
                              <BsUpload color="white" size={10} /> &nbsp; Change
                            </div>
                          )}
                          {!selectedResumeFile && (
                            <div className="flex justify-center items-center border-r  overflow-hidden font-semibold text-xs p-2 bg-primary text-white w-2/4">
                              <BsUpload color="white" size={10} /> &nbsp; Change
                            </div>
                          )}
                          <div className="flex justify-start items-center w-2/4 ">
                            &nbsp;
                            {selectedResumeFile && (
                              <div className="text-sm px-3 text-gray-400 truncate">
                                {selectedResumeFile?.name}
                              </div>
                            )}
                            <input
                              type="file"
                              className="hidden"
                              onChange={handleResumeChange}
                              accept=".pdf, .docx"
                            ></input>
                          </div>
                        </label>
                      </div>
                    </div>
                    {resumeFileError !== "" ? (
                      <div className="error mb-2">{resumeFileError}</div>
                    ) : null}
                    <div
                      className="mt-1 text-xs text-gray-500"
                      id="user_avatar_help"
                    >
                      Supported format (.pdf, .docx), Size (Max 3MB)
                    </div>
                  </div>
                  {profile.profile_video !== null && (
                    <div className="my-2 flex items-center gap-4">
                      <label className={Style.label}>Inro Video</label>
                      <video width="300" height="200" controls autoPlay={false}>
                        <source src={profile.profile_video} type="video/mp4" />
                      </video>
                    </div>
                  )}
                  <div className="my-2">
                    <label className={Style.label}>Change Intro Video</label>
                    <div className="flex items-center w-full border-primary rounded ">
                      <div className="cursor-pointer w-full">
                        <label className="cursor-pointer w-full flex justify-start">
                          {selectedVideoFile && (
                            <div className="flex justify-center items-center border-r overflow-hidden font-semibold text-xs p-2 bg-primary text-white w-2/4">
                              <BsUpload color="white" size={10} /> &nbsp; Change
                            </div>
                          )}
                          {!selectedVideoFile && (
                            <div className="flex justify-center items-center border-r  overflow-hidden font-semibold text-xs p-2 bg-primary text-white w-2/4">
                              <BsUpload color="white" size={10} /> &nbsp; Change
                            </div>
                          )}
                          <div className="flex justify-start items-center w-2/4 ">
                            &nbsp;
                            {selectedVideoFile && (
                              <div className="text-sm px-3 text-gray-400 truncate">
                                {selectedVideoFile?.name}
                              </div>
                            )}
                            <input
                              type="file"
                              className="hidden"
                              onChange={handleVideoChange}
                              accept=".mp4"
                            ></input>
                          </div>
                        </label>
                      </div>
                    </div>
                    {fileError !== "" ? (
                      <div className="error mb-2">{videoFileError}</div>
                    ) : null}
                    <div
                      className="mt-1 text-xs text-gray-500"
                      id="user_avatar_help"
                    >
                      Supported format (.mp4), Size (Max 2MB)
                    </div>
                  </div>
                  <div className="grid justify-items-stretch mt-6">
                    <button
                      onClick={() => updatePersonalInfo()}
                      type="button"
                      className="button-primary text-sm justify-self-end hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default InterviewerProfile;
