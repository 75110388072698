import React from "react";
import { BsDownload, BsUpload } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import orgApi from "../../../api/org_api";
import { Style } from "../../../utils/Style";
import { useNavigate } from "react-router-dom";
import S from "../../../Services/Services";
import SwitchComp from "../../../component/Switch";

const nodata = require("../../../assets/images/nodata.png");

type UploadResumesProps = {
  setLoading: Function;
  resumeList: any;
  setBackJdRound: Function;
  roundData: any;
};

const UploadResumes: React.FC<UploadResumesProps> = ({
  setLoading,
  resumeList,
  setBackJdRound,
  roundData,
}: UploadResumesProps) => {
  const [showUploadResumeModal, setShowUploadResumeModal] =
    React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState<File[]>([]);
  const [resumes, setResumes] = React.useState([] as any);
  const [fileError, setfileError] = React.useState("");
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = React.useState(false);
  const [isDisabled, setisDisabled] = React.useState(false);
  const [roundTag, setRoundTag] = React.useState("external");
  const org = JSON.parse(localStorage.getItem("user__details")?.toString()!);
  const [resumeType, setResumeType] = React.useState([] as any);
  const orgId = org.id;
  const masterId = org.master_id !== null ? org.master_id : org.id;

  React.useEffect(() => {
    if (resumeList && resumeList.length !== 0) {
      setResumes(resumeList);
    }
    setRoundData();
  }, []);

  const setRoundData = () => {
    let roundTag1 = "";
    if (roundData != null) {
      let d = roundData;
      if (
        d["fav"] ||
        (d["house"] && d["market"] && !d["external"]) ||
        (!d["house"] && d["market"] && d["external"]) ||
        (d["house"] && d["market"] && d["external"])
      ) {
        roundTag1 = "combination";
      } else if (!d["house"] && d["market"] && !d["external"]) {
        roundTag1 = "external";
      } else if (
        (d["house"] && !d["market"] && !d["external"]) ||
        (!d["house"] && !d["market"] && d["external"]) ||
        (d["house"] && !d["market"] && d["external"])
      ) {
        roundTag1 = "internal";
      }
      setRoundTag(roundTag1);
    }
  };

  const getSubscription = async (resLen: any) => {
    setLoading(true);
    let userInfo = JSON.parse(
      localStorage.getItem("user__details")?.toString()!
    );
    let orgId = userInfo.master_id !== null ? userInfo.master_id : userInfo.id;
    const res = await orgApi.getSubs(orgId);
    if (res.data.success) {
      setLoading(false);
      let subscription = res.data.org;
      if (roundTag === "external" && subscription.e_available < resLen) {
        toast(
          `You can upload a maximum of ${subscription.e_available} resumes.`
        );
        return false;
      } else if (roundTag === "internal" && subscription.i_available < resLen) {
        toast(
          `You can upload a maximum of ${subscription.i_available} resumes.`
        );
        return false;
      } else if (roundTag === "combination") {
        let min =
          subscription.i_available >= subscription.e_available
            ? subscription.e_available
            : subscription.i_available;
        if (min < resLen) {
          toast(`You can upload a maximum of ${min} resumes.`);
          return false;
        }
      }
    } else {
      setLoading(false);
      return true;
    }
    return true;
  };

  const handleImageChange = async (event: any) => {
    const ds: any = await S.handleUploadResume(event.target.files);
    setfileError(ds["error"]);
    setSelectedFile(ds["resumefiles"]);
  };

  const removeResume = async (indexToRemove: number) => {
    setResumes(
      resumes.filter((_: any, index: number) => index !== indexToRemove)
    );
    setResumeType((prevState: any) =>
      prevState.filter((_: any, ind: number) => ind !== indexToRemove)
    );
  };

  const removeResume1 = async (index: number) => {
    setSelectedFile((prevState) => prevState.filter((_, ind) => ind !== index));
  };

  const uploadResume = async () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formData = new FormData();
    formData.append("tz", timezone);

    for (let k = 0; k < resumes.length; k++) {
      formData.append("resume", resumes[k]?.resume);
    }
    var candy = [] as any;
    let flag = 0;
    for (let k = 0; k < resumes.length; k++) {
      if (resumes[k]?.aadhar_error !== "" || resumes[k]?.ssn_error !== "") {
        flag++;
        break;
      }
      if (
        resumes[k]?.email === "" ||
        resumes[k]?.email === null ||
        resumes[k]?.email === undefined
      ) {
        resumes[k].email_error = true;
        setResumes((prevItems: any) =>
          prevItems.map((item: any, i: number) =>
            i === k ? { ...item, email_error: true } : item
          )
        );
        flag++;
        break;
      }
      candy.push({
        name: resumes[k]?.name,
        last_name: resumes[k]?.last_name,
        email: resumes[k]?.email,
        mobile: resumes[k]?.mobile,
        mcc: resumes[k]?.mcc,
        aadhar: resumes[k]?.aadhar !== "" ? Number(resumes[k]?.aadhar) : 0,
        ssn: resumes[k]?.ssn !== "" ? Number(resumes[k]?.ssn) : 0,
      });
    }
    if (flag) {
      return;
    }
    let scope = [] as any;
    resumeType.forEach((r_type: any) => {
      scope.push({type : r_type ? 'sit' : 'others'})
    });
    formData.append("org_id", orgId);
    formData.append("master_id", masterId);
    formData.append("tag", roundTag);
    formData.append("candy", JSON.stringify(candy));
    formData.append("scope", JSON.stringify(scope));
    let mv: Boolean | undefined = await getSubscription(resumes.length);
    if (!mv) {
      return;
    }
    setisDisabled(true);
    setLoading(true);
    const res = await orgApi.uploadCandidate(
      formData,
      localStorage.getItem("createPostId")!
    );
    if (res.data.success === true) {
      localStorage.setItem("createPostId", "");
      toast(res.data.msg);
      setTimeout(() => {
        setLoading(false);
        setisDisabled(false);
        navigate("/job_description", { replace: true });
      }, 1500);
    } else {
      setLoading(false);
      setisDisabled(false);
      toast(res.data.msg);
    }
  };

  const addresumes = async () => {
    const formData = new FormData();
    for (let k = 0; k < selectedFile.length; k++) {
      formData.append("resume", selectedFile[k]);
    }
    setLoading(true);
    const res = await orgApi.extractResumes(
      formData,
      localStorage.getItem("createPostId")!
    );
    if (res.data.success === true) {
      setShowUploadResumeModal(false);
      var d = [] as any;
      d = res.data.candy;
      for (let i = 0; i < d.length; i++) {
        d[i]["resume"] = selectedFile[i];
        d[i]["email_error"] = false;
        d[i]["last_name"] = "";
        d[i]["aadhar"] = "";
        d[i]["aadhar_error"] = "";
        d[i]["ssn"] = "";
        d[i]["ssn_error"] = "";
        setResumes((previous: any) => {
          return [...previous, d[i]];
        });
        setResumeType((previous: any) => {
          return [...previous, true];
        });
      }
      setSelectedFile([]);
    } else {
      toast(res.data.msg);
    }
    setLoading(false);
  };

  const openUploadResume = () => {
    setSelectedFile([]);
    setShowUploadResumeModal(true);
  };

  const openFileInNewTab = (file: File) => {
    if (selectedFile) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      {showUploadResumeModal && (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowUploadResumeModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
                <div className="sm:flex">
                  <div className="mt-1 text-center sm:text-left w-full">
                    <div className="flex items-center justify-between border-b">
                      <h6 className="text-sm font-bold text-gray-800 mb-2">
                        Upload Resume
                      </h6>
                    </div>
                    <div className="mt-1">
                      <form className="max-w-lg mx-auto mt-4">
                        <div className="flex items-center w-full border-primary rounded ">
                          <div className="cursor-pointer  w-full">
                            <label className="cursor-pointer w-full flex justify-start">
                              {selectedFile.length > 0 && (
                                <div className="flex justify-center items-center border-r overflow-hidden font-semibold text-xs p-2 bg-primary text-white w-1/4">
                                  <BsUpload color="white" size={10} /> &nbsp;
                                  Change
                                </div>
                              )}
                              {selectedFile.length === 0 && (
                                <div className="flex justify-center items-center border-r  overflow-hidden font-semibold text-xs p-2 bg-primary text-white w-1/4">
                                  <BsUpload color="white" size={10} /> &nbsp;
                                  Upload
                                </div>
                              )}
                              <div className="flex justify-start items-center w-2/4 ">
                                &nbsp;
                                {selectedFile.length !== 0 && (
                                  <div className="text-sm px-3 text-gray-400 truncate">
                                    {selectedFile[0]?.name}
                                  </div>
                                )}
                                <input
                                  type="file"
                                  className="hidden"
                                  multiple={true}
                                  onChange={handleImageChange}
                                ></input>
                              </div>
                            </label>
                          </div>
                        </div>
                        {fileError !== "" ? (
                          <div className="error mb-2">{fileError}</div>
                        ) : null}
                        <div
                          className="mt-1 text-xs text-gray-500"
                          id="user_avatar_help"
                        >
                          Supported format (.pdf, .docx), Size (Max 3MB)
                        </div>
                        {selectedFile.length > 0 && (
                          <>
                            {selectedFile.map((resume: any, index: number) => (
                              <>
                                <div
                                  className="flex flex-row justify-between my-2"
                                  key={index}
                                >
                                  <div>
                                    <span className=" text-sm font-medium text-gray-600">
                                      {resume.name}
                                    </span>
                                  </div>
                                  <button
                                    onClick={() => removeResume1(index)}
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                  >
                                    <svg
                                      className="w-2 h-2"
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 14 14"
                                    >
                                      <path
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                      />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                  </button>
                                </div>
                              </>
                            ))}
                          </>
                        )}
                      </form>

                      <div className=" gap-2 mt-3 sm:flex md:flex lg:flex flex-row-reverse">
                        <button
                          className={
                            selectedFile.length > 0
                              ? " button-primary mt-2 py-1 px-2.5 text-white outline-none text-sm"
                              : " bg-indigo-300 mt-2 py-1 px-2.5 text-white outline-none text-sm"
                          }
                          onClick={() => addresumes()}
                          disabled={selectedFile.length > 0 ? false : true}
                        >
                          Save
                        </button>
                        <button
                          className=" mt-2 py-1 px-2.5 text-gray-800 outline-none border text-sm"
                          onClick={() => setShowUploadResumeModal(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showAlert && (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
                <div className="sm:flex">
                  <div className="mt-1 text-center sm:text-left w-full">
                    <div className=" w-full mt-4 max-w-lg text-xs text-gray-500 font-normal">
                      You have not uploaded a candidate profile yet. Are you
                      sure you want to exit without uploading?
                    </div>

                    <div className=" gap-2 mt-3 sm:flex md:flex lg:flex flex-row-reverse">
                      <button
                        className="mt-2 py-1 px-2.5 text-white button-primary outline-none text-sm"
                        onClick={() => {
                          setShowAlert(false);
                          localStorage.setItem("createPostId", "");
                          navigate("/job_description", { replace: true });
                        }}
                      >
                        Yes
                      </button>
                      <button
                        className=" mt-2 py-1 px-2.5 text-gray-800 outline-none border text-sm"
                        onClick={() => setShowAlert(false)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div
        className="overflow-y-auto flex flex-col justify-between"
        style={{ height: "60vh" }}
      >
        <div>
          <div className=" w-full">
            <div className="flex flex-row justify-end mt-2 mb-4 gap-2">
              <button
                type="button"
                className="mt-2 py-1.5 px-4 text-primary outline-none border-primary text-xs rounded-sm flex flex-row gap-2 text-xs font-medium	"
                onClick={() => openUploadResume()}
              >
                <BsUpload /> Upload
              </button>
            </div>
          </div>
          <div>
            {resumes.length > 0 ? (
              <>
                <table className="table-fixed text-xs w-full">
                  <tbody className="text-gray-700">
                    {resumes.map((res: any, index: number) => (
                      <tr className="">
                        <td
                          style={{ verticalAlign: "top", textAlign: "center" }}
                        >
                          <div className="flex flex-col items-start gap-1 mx-2">
                            <label className={Style.label}>First Name</label>
                            <input
                              placeholder="First Name"
                              type="text"
                              id="name"
                              name="name"
                              value={res?.name}
                              onChange={(event: any) => {
                                setResumes((prevItems: any) =>
                                  prevItems.map((item: any, ind: number) =>
                                    ind === index
                                      ? { ...item, name: event.target.value }
                                      : item
                                  )
                                );
                              }}
                              className={Style.iInput}
                            ></input>
                          </div>
                        </td>
                        <td
                          style={{ verticalAlign: "top", textAlign: "center" }}
                        >
                          <div className="flex flex-col items-start gap-1 mx-2">
                            <label className={Style.label}>Last Name</label>
                            <input
                              placeholder="Last Name"
                              type="text"
                              id="last_name"
                              name="last_name"
                              value={res?.last_name}
                              onChange={(event: any) => {
                                setResumes((prevItems: any) =>
                                  prevItems.map((item: any, ind: number) =>
                                    ind === index
                                      ? {
                                          ...item,
                                          last_name: event.target.value,
                                        }
                                      : item
                                  )
                                );
                              }}
                              className={Style.iInput}
                            ></input>
                          </div>
                        </td>
                        <td
                          style={{
                            width: "60px",
                            verticalAlign: "top",
                            textAlign: "center",
                          }}
                        >
                          <div className="flex flex-col items-start gap-1 mx-2">
                            <label className={Style.label}>Resume</label>
                            <button
                              type="button"
                              onClick={() => openFileInNewTab(res?.resume)}
                              className="py-2 px-4 text-primary text-xs rounded-sm flex flex-row gap-2 text-xs font-medium	"
                            >
                              <BsDownload size={17} />
                            </button>
                          </div>
                        </td>
                        <td
                          style={{ verticalAlign: "top", textAlign: "center" }}
                        >
                          <div className="flex flex-col items-start gap-1 mx-2">
                            <label className={Style.label}>Email ID</label>
                            <input
                              placeholder="Email ID"
                              type="text"
                              id="email"
                              name="email"
                              value={res?.email}
                              onChange={(event: any) => {
                                setResumes((prevItems: any) =>
                                  prevItems.map((item: any, ind: number) =>
                                    ind === index
                                      ? { ...item, email: event.target.value }
                                      : item
                                  )
                                );
                              }}
                              className={
                                res?.email_error ? Style.iError : Style.iInput
                              }
                            ></input>
                          </div>
                        </td>
                        <td
                          style={{ verticalAlign: "top", textAlign: "center" }}
                        >
                          <div className="flex flex-col items-start gap-1 mx-2">
                            <label className={Style.label}>
                              Mobile
                            </label>
                            <input
                              placeholder="Contact Number"
                              type="text"
                              id="mobile"
                              name="mobile"
                              value={res?.mobile}
                              onChange={(event: any) => {
                                setResumes((prevItems: any) =>
                                  prevItems.map((item: any, ind: number) =>
                                    ind === index
                                      ? { ...item, mobile: event.target.value }
                                      : item
                                  )
                                );
                              }}
                              className={Style.iInput}
                            ></input>
                          </div>
                        </td>
                        <td
                          style={{ verticalAlign: "top", textAlign: "center" }}
                        >
                          <div className="flex flex-col items-start gap-1 mx-2">
                            <label className={Style.label}>Aadhar</label>
                            <input
                              placeholder="Last 4 Digits"
                              disabled={res?.ssn === "" ? false : true}
                              type="text"
                              id="aadhar"
                              name="aadhar"
                              value={res?.aadhar}
                              onChange={(event: any) => {
                                setResumes((prevItems: any) =>
                                  prevItems.map((item: any, ind: number) =>
                                    ind === index
                                      ? { ...item, aadhar: event.target.value }
                                      : item
                                  )
                                );
                                setResumes((prevItems: any) =>
                                  prevItems.map((item: any, ind: number) =>
                                    ind === index
                                      ? { ...item, aadhar_error: "" }
                                      : item
                                  )
                                );
                                if (event.target.value !== "") {
                                  if (!isNaN(Number(event.target.value))) {
                                    if (event.target.value.length !== 4) {
                                      setResumes((prevItems: any) =>
                                        prevItems.map(
                                          (item: any, ind: number) =>
                                            ind === index
                                              ? {
                                                  ...item,
                                                  aadhar_error:
                                                    "Must have 4 digits",
                                                }
                                              : item
                                        )
                                      );
                                    }
                                  }
                                }
                              }}
                              className={
                                res?.ssn === ""
                                  ? Style.iInput
                                  : Style.iInputDisable
                              }
                            ></input>
                            {res?.aadhar_error !== "" && (
                              <div
                                className="error"
                                style={{ fontSize: "9px" }}
                              >
                                {res?.aadhar_error}
                              </div>
                            )}
                          </div>
                        </td>
                        <td
                          style={{ verticalAlign: "top", textAlign: "center" }}
                        >
                          <div className="flex flex-col items-start gap-1 mx-2">
                            <label className={Style.label}>SSN</label>
                            <input
                              disabled={res?.aadhar === "" ? false : true}
                              placeholder="Last 4 Digits"
                              type="text"
                              id="ssn"
                              name="ssn"
                              value={res?.ssn}
                              onChange={(event: any) => {
                                setResumes((prevItems: any) =>
                                  prevItems.map((item: any, ind: number) =>
                                    ind === index
                                      ? { ...item, ssn: event.target.value }
                                      : item
                                  )
                                );
                                setResumes((prevItems: any) =>
                                  prevItems.map((item: any, ind: number) =>
                                    ind === index
                                      ? { ...item, ssn_error: "" }
                                      : item
                                  )
                                );
                                if (event.target.value !== "") {
                                  if (!isNaN(Number(event.target.value))) {
                                    if (event.target.value.length !== 4) {
                                      setResumes((prevItems: any) =>
                                        prevItems.map(
                                          (item: any, ind: number) =>
                                            ind === index
                                              ? {
                                                  ...item,
                                                  ssn_error:
                                                    "Must have 4 digits",
                                                }
                                              : item
                                        )
                                      );
                                    }
                                  }
                                }
                              }}
                              className={
                                res?.aadhar === ""
                                  ? Style.iInput
                                  : Style.iInputDisable
                              }
                            ></input>
                            {res?.ssn_error !== "" && (
                              <div
                                className="error"
                                style={{ fontSize: "9px" }}
                              >
                                {res?.ssn_error}
                              </div>
                            )}
                          </div>
                        </td>
                        <td
                          style={{
                            width: "60px",
                            verticalAlign: "center",
                            textAlign: "center",
                          }}
                        >
                          <div className="flex flex-col items-start gap-1 mx-2">
                            <label className={Style.label}>Type</label>
                            <SwitchComp
                              resumeType={resumeType}
                              setResumeType={setResumeType}
                              index={index}
                              on={"Auto"}
                              off={"Manual"}
                            ></SwitchComp>{" "}
                          </div>
                        </td>
                        <td
                          style={{
                            width: "60px",
                            verticalAlign: "top",
                            textAlign: "center",
                          }}
                        >
                          <div className="flex flex-col items-end gap-1 mx-2">
                            <label className={Style.label}>Delete</label>
                            <button
                              onClick={() => removeResume(index)}
                              type="button"
                              className="text-gray-400 bg-transparent rounded-sm text-sm w-8 h-8 ms-auto inline-flex justify-center items-center border"
                            >
                              <svg
                                className="w-2 h-2"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                              >
                                <path
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                />
                              </svg>
                              <span className="sr-only">Close modal</span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <div className="flex flex-col justify-center items-center gap-1">
                <img
                  src={nodata}
                  alt="user_profile_photo"
                  className="h-32 w-32 object-cover"
                ></img>
                <p className="text-xs font-thin text-gray-400">
                  No Profile Uploaded.
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-row justify-end gap-1">
          <button
            className="mt-2 py-1.5 px-2.5 text-primary border-primary text-xs"
            type="button"
            onClick={() => {
              setBackJdRound(resumes);
            }}
          >
            Back
          </button>
          {resumes.length == 0 && (
            <button
              className="mt-2 py-1.5 px-2.5 text-white button-primary outline-none text-xs"
              type="button"
              onClick={() => {
                setShowAlert(true);
              }}
            >
              Done
            </button>
          )}
          {resumes.length > 0 && (
            <button
              className={
                !isDisabled
                  ? "mt-2 py-1.5 px-2.5 text-white button-primary outline-none text-xs"
                  : "mt-2 py-1.5 px-2.5 text-white button-primary-tint outline-none text-xs"
              }
              type="button"
              disabled={isDisabled}
              onClick={() => uploadResume()}
            >
              Done
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default UploadResumes;
