import { SVG } from "../../assets/SVG/SVG";
import { Slide } from "./Slide";
// const bannerImage = require("../../assets/images/login_image.jpg");

export const slides = Slide.map((e) => {
  return (
    <div className="h-full flex flex-col gap-6 items-center p-6 m-6">
      <div className="carousal-icon m-0">
        <SVG.CarComma />
      </div>
      <span className="text-sm italic text-gray-200 font-thin pb-4 h-32">
        {e.msg}
      </span>
      <div className="flex flex-start items-center gap-4 w-full">
        <div
          className="img-circle"
          style={{ backgroundImage: "url(" + e.iamge + ")" }}
        ></div>
        <div className="flex items-start flex-col gap-1">
          {/* <span className="text-md font-thin text-gray-300">{e.name}</span> */}
          <span className="text-sm font-semibold text-gray-50">
            {e.company}
          </span>
        </div>
      </div>
      <div className="mt-6">
        <span className="text-lg font-semibold text-white">
          Brands That Trust Us
        </span>
      </div>
    </div>
  );
});
