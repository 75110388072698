import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { BsFillGridFill, BsFillHddStackFill, BsPencil } from "react-icons/bs";
import PaginationButtons from "../../../component/PaginationButtons";
import orgApi from "../../../api/org_api";
import "react-datepicker/dist/react-datepicker.css";
import Progress from "../../../component/Progress";
const nodata = require("../../../assets/images/nodata.png");

type JdListProps = { setLoading: Function };

const JdList: React.FC<JdListProps> = ({ setLoading }: JdListProps) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const resumeData = state?.resumes || [];
  const resumeType = state?.resumeType || [];
  const [view, setView] = React.useState<string>("grid");
  const [totalPages, setTotalPages] = React.useState(
    Number(localStorage.getItem("jd_totalpage")!)
  );
  const [pages, setPages] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(
    Number(localStorage.getItem("jd_currentpage")!)
  );
  const [selectedJd, setSelectedJd] = React.useState("");
  const [showResumeUpload, setShowResumeUpload] = React.useState(false);
  const [totalPost, setTotal] = React.useState(1);
  const [isDisabled, setisDisabled] = React.useState(false);
  const [roundTag, setRoundTag] = React.useState("external");
  const [search, setSearch] = React.useState("");
  const org = JSON.parse(localStorage.getItem("user__details")?.toString()!);
  const orgId = org.id;
  const masterId = org.master_id !== null ? org.master_id : org.id;
  React.useEffect(() => {
    fetchData();
  }, [currentPage]);

  React.useEffect(() => {
    if (search.length > 2 || search.length === 0) {
      fetchData();
    }
  }, [search]);

  const fetchData = async () => {
    const page = totalPages === 0 ? 1 : Math.min(currentPage + 1, totalPages);
    localStorage.setItem("jd_currentpage", currentPage.toString());
    setLoading(true);
    const reqData = { search: search, page: page, take: 10 };
    const res = await orgApi.getJdList(org.id, reqData);
    if (res.data.success) {
      setTotal(res.data.total_post);
      var t = Math.ceil(res.data.total_post / 10);
      setTotalPages(t);
      localStorage.setItem("jd_totalpage", t.toString());
      setPages(res.data.post);
    } else {
      setPages([]);
      setTotal(0);
    }
    setLoading(false);
  };

  const getDate = (dateStr: string) => {
    const formattedDate = convert(dateStr!);
    return formattedDate;
  };
  function convert(str: string) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  const gotoUploadCandidate = (id: any) => navigate("/upload_candidate/" + id);
  const openResumeUpload = (id: any) => {
    setSelectedJd(id);
    setShowResumeUpload(true);
  };
  const setRoundData = async (post_id: any) => {
    let roundTag1 = "";
    setLoading(true);
    const res = await orgApi.getRound(post_id);
    if (res.data.success) {
      let roundData = res.data.round;
      if (roundData != null) {
        let d = roundData;
        if (
          d["fav"] ||
          (d["house"] && d["market"] && !d["external"]) ||
          (!d["house"] && d["market"] && d["external"]) ||
          (d["house"] && d["market"] && d["external"])
        ) {
          roundTag1 = "combination";
        } else if (!d["house"] && d["market"] && !d["external"]) {
          roundTag1 = "external";
        } else if (
          (d["house"] && !d["market"] && !d["external"]) ||
          (!d["house"] && !d["market"] && d["external"]) ||
          (d["house"] && !d["market"] && d["external"])
        ) {
          roundTag1 = "internal";
        }
        setRoundTag(roundTag1);
      }
    }
    setLoading(false);
    return roundTag1;
  };
  const getSubscription = async (resLen: any, post_id: any) => {
    let roundTag1 = await setRoundData(post_id);
    setLoading(true);
    let userInfo = JSON.parse(
      localStorage.getItem("user__details")?.toString()!
    );
    let orgId = userInfo.master_id !== null ? userInfo.master_id : userInfo.id;
    const res = await orgApi.getSubs(orgId);
    if (res.data.success) {
      setLoading(false);
      let subscription = res.data.org;
      if (roundTag1 === "external" && subscription.e_available < resLen) {
        toast(
          `You can upload a maximum of ${subscription.e_available} resumes.`
        );
        return false;
      } else if (
        roundTag1 === "internal" &&
        subscription.i_available < resLen
      ) {
        toast(
          `You can upload a maximum of ${subscription.i_available} resumes.`
        );
        return false;
      } else if (roundTag1 === "combination") {
        let min =
          subscription.i_available >= subscription.e_available
            ? subscription.e_available
            : subscription.i_available;
        if (min < resLen) {
          toast(`You can upload a maximum of ${min} resumes.`);
          return false;
        }
      }
    } else {
      setLoading(false);
      return true;
    }
    return true;
  };
  const uploadResume = async () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let mv: Boolean | undefined = await getSubscription(
      resumeData.length,
      selectedJd
    );
    if (!mv) {
      return;
    }
    let scope = [] as any;
    resumeType.forEach((r_type: any) => {
      scope.push({type : r_type ? 'sit' : 'others'})
    });
    const formData = new FormData();
    formData.append("org_id", orgId);
    formData.append("tz", timezone);
    formData.append("master_id", masterId);
    formData.append("tag", roundTag);
    formData.append("scope", JSON.stringify(scope));
    for (let k = 0; k < resumeData.length; k++) {
      formData.append("resume", resumeData[k]);
    }
    setLoading(true);
    setisDisabled(true);
    const res = await orgApi.uploadCandidate(formData, selectedJd);
    if (res.data.success === true) {
      toast(res.data.msg);
      setShowResumeUpload(false);
      navigate("/upload_candidate/" + selectedJd, { replace: true });
    } else {
      toast(res.data.msg);
    }
    setisDisabled(false);
    setLoading(false);
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      {showResumeUpload && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-lg p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
              <div className="sm:flex">
                <div className="mt-1 text-center sm:text-left w-full">
                  <div className="flex items-center justify-between border-b">
                    <h6 className="text-sm font-bold text-gray-800 mb-2">
                      Upload candidate
                    </h6>
                  </div>
                  <div className=" w-full mt-4 max-w-lg text-xs text-gray-500 font-normal">
                    Are you sure you want to upload the selected candidates to
                    this JD
                  </div>
                  <div className=" gap-2 mt-3 sm:flex md:flex lg:flex flex-row-reverse">
                    <button
                      className={
                        !isDisabled
                          ? "mt-2 py-1 px-2.5 text-white button-primary outline-none text-sm rounded-md"
                          : "mt-2 py-1 px-2.5 text-white button-primary-tint outline-none text-sm rounded-md"
                      }
                      onClick={() => uploadResume()}
                      disabled={isDisabled}
                    >
                      Confirm
                    </button>
                    <button
                      className=" mt-2 py-1 px-2.5 text-gray-800 outline-none border text-sm rounded-md"
                      onClick={() => setShowResumeUpload(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="overflow-y-auto" style={{ height: "86vh" }}>
        <div className="flex justify-center">
          <div className="w-3/4">
            <div className="flex items-center justify-between border-b">
              <h6 className="text-sm font-bold text-gray-800 mb-2">
                {resumeData.length === 0 ? (
                  <div className="flex flex-row items-center">
                    <p
                      className="cursor-pointer text-primary"
                      onClick={() => navigate(-1)}
                    >
                      {"Dashboard "}
                    </p>
                    <p>&nbsp;{" / "}&nbsp;</p>
                    <p>{" Job Description"}</p>
                  </div>
                ) : (
                  "Select JD to add selected candidates"
                )}
              </h6>
            </div>
            <div className="flex flex-row justify-between items-center mt-2 mb-4">
              <form className="searchForm">
                <input
                  placeholder="Search.."
                  type="search"
                  id="search"
                  name="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className={"w-full border py-2 px-3 outline-none rounded"}
                ></input>
                <button className="searchButton" type="button">
                  Search
                </button>
              </form>
              <div className="flex flex-row justify-end items-center mt-2 mb-4">
                <button
                  type="button"
                  className={
                    view === "grid"
                      ? "mt-2 py-1.5 px-3.5 text-primary outline-none border-primary text-xs rounded-l-md"
                      : "mt-2 py-1.5 px-3.5 text-gray-300 outline-none border text-xs rounded-l-md"
                  }
                  onClick={() => setView("grid")}
                >
                  <BsFillGridFill />
                </button>
                <button
                  type="button"
                  className={
                    view === "list"
                      ? "mt-2 py-1.5 px-3.5 text-primary outline-none border-primary text-xs rounded-r-md"
                      : "mt-2 py-1.5 px-3.5 text-gray-300 outline-none border text-xs rounded-r-md"
                  }
                  onClick={() => setView("list")}
                >
                  <BsFillHddStackFill />
                </button>
              </div>
            </div>
            {totalPost === 0 && (
              <div className="flex flex-col justify-center items-center my-16 gap-1">
                <img
                  src={nodata}
                  alt="user_profile_photo"
                  className="h-32 w-32 object-cover"
                ></img>
                <p className="text-xs font-thin text-gray-400">
                  No JD Created.
                </p>
              </div>
            )}
            {view === "grid" && (
              <div className="grid grid-cols-3 gap-4">
                {pages.map((e: any) => (
                  <div
                    key={e.id}
                    className="bg-gray-100 p-4 flex flex-col gap-3 h-40 cursor-pointer"
                    onClick={() => {
                      if (e.profile === 0 || e.profile === 0) {
                        navigate("/upload_jd", { state: { postId: e.id } });
                      } else {
                        if (resumeData.length === 0) {
                          gotoUploadCandidate(e.id);
                        } else {
                          openResumeUpload(e.id);
                        }
                      }
                    }}
                  >
                    <div className="flex items-center justify-between border-b-primary py-1 mb-3">
                      <div className="flex flex-row justify-start items-center truncate">
                        <h6 className="text-xs font-semibold text-gray-600 truncate">
                          {e.job_title}
                        </h6>
                        <button
                          type="button"
                          className="py-2 px-2 text-primary flex rounded-full item-center gap-2 text-sm font-bold font-normal mr-1 hover:bg-gray-200"
                          onClick={(event: any) => {
                            event.stopPropagation();
                            // openIdForm(e.id);
                            navigate("/upload_jd", { state: { postId: e.id } });
                          }}
                        >
                          <BsPencil />
                        </button>
                      </div>
                      <h6 className="text-xs font-semibold text-gray-800">
                        {e.profile}
                      </h6>
                    </div>

                    <div className="text-xs font-semibold text-gray-500">
                      ID: {e.unique_id}
                    </div>
                    <div className="text-xs font-semibold text-gray-500">
                      Date : {getDate(e.post_validity)}
                    </div>
                    {(e.profile === 0 || e.profile === 0) && (
                      <div className="text-xs font-normal text-red-400 flex justify-end mt-1">
                        Draft
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            {view === "list" && (
              <div className="flex flex-col gap-4 ">
                {pages.map((e: any) => (
                  <div
                    key={e.id}
                    className="bg-gray-100 p-4 flex flex-row gap-4 items-center cursor-pointer grid grid-cols-7"
                    onClick={() => {
                      if (e.profile === 0 || e.profile === 0) {
                        navigate("/upload_jd", { state: { postId: e.id } });
                      } else {
                        if (resumeData.length === 0) {
                          gotoUploadCandidate(e.id);
                        } else {
                          openResumeUpload(e.id);
                        }
                      }
                    }}
                  >
                    <div className="col-span-3 flex flex-col items-start gap-1">
                      <div className="flex flex-row justify-start items-center">
                        <h6 className="text-xs font-semibold text-gray-700">
                          {e.job_title}
                        </h6>
                        <button
                          type="button"
                          className="py-2 px-2 text-primary flex rounded-full item-center gap-2 text-sm font-bold font-normal mr-1 hover:bg-gray-200"
                          onClick={(event: any) => {
                            event.stopPropagation();
                            navigate("/upload_jd", { state: { postId: e.id } });
                          }}
                        >
                          <BsPencil />
                        </button>
                      </div>
                      <p className="text-xs font-semibold text-gray-500">
                        ID: {e.unique_id}
                      </p>
                      <p className="text-xs font-semibold text-gray-500">
                        Date : {getDate(e.post_validity)}
                      </p>
                    </div>

                    <div className="flex flex-col col-span-1">
                      <div className="text-xs font-semibold text-gray-800 mb-1">
                        {e["profile"]}
                      </div>
                      <div className="text-xs font-normal text-gray-400">
                        Profiles
                      </div>
                    </div>
                    <div className="flex flex-col justify-between items-end col-span-3">
                      {e.profile !== 0 && e.profile !== 0 && (
                        <Progress
                          total={e.no_of_candidate}
                          completed={e.offered}
                        />
                      )}
                      {(e.profile === 0 || e.profile === 0) && (
                        <div className="text-xs font-normal text-red-400 relative top-5 right-1 ">
                          Draft
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}

            {totalPost > 10 && (
              <PaginationButtons
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default JdList;
