import { useState, useEffect, useRef } from "react";

type CarouselProps = { slides: any[] };

const Carousel: React.FC<CarouselProps> = ({
  slides,
}: CarouselProps): JSX.Element => {
  const [curr, setCurr] = useState(0);
  const [stop, setStop] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!stop) {
      const slideInterval = setInterval(
        () => setCurr((curr) => (curr === slides.length - 1 ? 0 : curr + 1)),
        3000
      );
      return () => clearInterval(slideInterval);
    }
  }, [slides, stop]);

  function onDoubleClick() {
    setStop(true);
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setStop(false);
    }
  };

  return (
    <div
      ref={wrapperRef}
      className="overflow-hidden relative h-full"
      onDoubleClick={onDoubleClick}
    >
      <div className="flex transition-transform ease-out duration-500">
        {slides[curr]}
      </div>

      <div className="absolute bottom-8 right-8 left-8">
        <div className="flex items-center justify-center gap-2">
          {slides.map((_: any, i: any) => (
            <div
              className={`
              transition-all w-12 h-1 bg-white rounded
              ${curr === i ? "p-" : "bg-opacity-50"}
            `}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
