import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import Select from "react-select";
import { Country, State, City } from "country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { BsCardImage } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import api from "../../api/auth_api";
import { Style } from "../../utils/Style";
import { SVG } from "../../assets/SVG/SVG";
const dash = require("../../assets/images/dash.png");

const OrganizationProfileUpdate: React.FC = () => {
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [fileError, setfileError] = React.useState("");
  const [page, setPage] = React.useState(1);
  const navigate = useNavigate();
  const [datas, setdatas] = React.useState({
    organization: "",
    orgwebsiteurl: "",
    organizationType: "",
    organizationSize: "",
    country: null,
    state: null,
    city: null,
    postal_code: "",
  });
  const formData = new FormData();

  const handleImageChange = (event: any) => {
    setfileError("");
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        if (file.size <= 2 * 1024 * 1024) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            setSelectedImage(e.target.result);
            setfileError("");
          };
          reader.readAsDataURL(file);
        } else {
          setfileError(
            "File size exceeds the limit. Please select a smaller file."
          );
        }
      } else {
        setfileError("Please select an image file.");
      }
    }
  };

  function dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  function SetOne() {
    const formik = useFormik({
      initialValues: {
        organization: datas.organization,
        orgwebsiteurl: datas.orgwebsiteurl,
        organizationSize: datas.organizationSize,
      },
      validationSchema: Yup.object({
        organization: Yup.string()
          .max(64, "Organization name must be maximum 64 characters")
          .required("Organization name is required"),
        orgwebsiteurl: Yup.string().url(
          "Organization website url must be a valid URL"
        ),
        organizationSize: Yup.string().required(
          "Organization Size is required"
        ),
      }),
      validateOnBlur: true,
      onSubmit: async (values) => {
        setfileError("");
        if (selectedImage === null) {
          setfileError("Please attach the Organization Logo");
          return;
        }
        setdatas({
          ...datas,
          organization: values.organization,
          orgwebsiteurl: values.orgwebsiteurl,
          organizationType: "IT",
          organizationSize: values.organizationSize,
          country: datas.country,
          state: datas.state,
          city: datas.city,
          postal_code: datas.postal_code,
        });
        setPage(2);
      },
    });

    return (
      <form onSubmit={formik.handleSubmit}>
        <label className={Style.label}>Organization Logo</label>

        <label className="cursor-pointer p-2">
          <div className="flex items-center w-full border-primary rounded h-16 gap-1">
            {selectedImage && (
              <div className="w-16 h-16 mr-4 flex justify-center items-center border-r overflow-hidden">
                <img
                  className="w-12 h-12 object-cover"
                  src={selectedImage}
                  alt="Avatar Upload"
                ></img>
              </div>
            )}
            {!selectedImage && (
              <div className="w-16 h-16 flex justify-center items-center border-r  overflow-hidden ">
                <BsCardImage className="text-primary" size={24} />
              </div>
            )}
            <div className="flex flex-col justify-start gap-1">
              <div className="font-semibold text-xs text-gray-700">
                Upload logo{" "}
              </div>
              <div className="font-thin text-xs text-gray-400">
                Images with format of jpeg, png & jpg are acceptable
              </div>
            </div>
            <input
              type="file"
              className="hidden"
              onChange={handleImageChange}
            ></input>
          </div>
        </label>

        {fileError !== "" ? <div className="error">{fileError}</div> : null}
        <div className="mb-2"></div>
        <label className={Style.label}>
          Organization Name<span title="required">*</span>
        </label>
        <input
          placeholder="Organization Name"
          type="text"
          id="organization"
          name="organization"
          value={formik.values.organization}
          onBlur={(event: any) => {
            formik.handleBlur(event);
            setdatas((prevItems) => ({
              ...prevItems,
              organization: event.target.value,
            }));
          }}
          onChange={formik.handleChange}
          className={
            formik.touched.organization && formik.errors.organization
              ? Style.iError
              : Style.iInput
          }
        ></input>
        {formik.touched.organization && formik.errors.organization ? (
          <div className="error">{formik.errors.organization}</div>
        ) : null}
        <div className="mb-2"></div>
        <label className={Style.label}>
          Organization Size<span title="required">*</span>
        </label>
        <select
          id="organizationSize"
          name="organizationSize"
          value={formik.values.organizationSize}
          onBlur={(event: any) => {
            formik.handleBlur(event);
            setdatas((prevItems) => ({
              ...prevItems,
              organizationSize: event.target.value,
            }));
          }}
          onChange={formik.handleChange}
          className={
            formik.touched.organizationSize && formik.errors.organizationSize
              ? "w-full border text-xs text-gray-700 py-2 px-3 focus:outline-none inputError"
              : "w-full border text-xs text-gray-700 border-gray-300  py-2 px-3 focus:outline-none focus:border-blue-500"
          }
        >
          <option value="" disabled>
            Organization Size*
          </option>
          <option value="<10"> {"<"} 10 </option>
          <option value="11-50">11 to 50</option>
          <option value="51-200">51 to 200</option>
          <option value="201-500">201 to 500</option>
          <option value="501-1000">501 to 1000</option>
          <option value=">1000 ">{">"} 1000 </option>
        </select>
        {formik.touched.organizationSize && formik.errors.organizationSize ? (
          <div className="error">{formik.errors.organizationSize}</div>
        ) : null}
        <div className="grid justify-items-stretch mt-6">
          <button type="submit" className="button-primary text-sm justify-self-end text-white font-bold py-2 px-4 rounded">
            Next
          </button>
        </div>
      </form>
    );
  }
  function SetTwo() {
    const [country, setcountry] = React.useState(datas.country);
    const [state, setstate] = React.useState(datas.state);
    const [city, setCity] = React.useState(datas.city);
    const formik1 = useFormik({
      initialValues: {
        country: datas.country,
        state: datas.state,
        city: datas.city,
        postal_code: datas.postal_code,
      },
      validationSchema: Yup.object({
        country: Yup.object().required("Country is required"),
        state: Yup.object().required("State is required"),
        city: Yup.object().required("City is required"),
        postal_code: Yup.string()
          .required("Postal Code is required")
          .matches(
            /^(?=.*\d).*$/,
            "Postal Code must contain Numbers"
          )
          .min(6, "Postal Code must have 6 character")
          .max(6, "Postal Code must have 6 character"),
      }),
      validateOnBlur: true,
      onSubmit: async (values) => {
        setfileError("");
        var a = "a",
          b = "b";
        if (a === b) {
          console.log(country);
          console.log(state);
          console.log(city);
        }
        formData.append("org_name", datas.organization);
        formData.append("org_web_url", datas.orgwebsiteurl);
        formData.append("type", datas.organizationType);
        formData.append("size", datas.organizationSize);
        var file = dataURLtoFile(
          selectedImage,
          Math.random().toString(36).substring(6) + "_name.png"
        );
        formData.append("profile", file);
        formData.append("country", values.country?.["name"]!);
        formData.append("state", values.state?.["name"]!);
        formData.append("city", values.city?.["name"]!);
        // formData.append("country_id", values.country?.["phonecode"]!);
        // formData.append("state_id", values.state?.["isoCode"]!);
        // formData.append("city_id", values.city?.["name"]!);
        formData.append("postal_code", values.postal_code);
        var userId = localStorage.getItem("user_id");
        const res = await api.register(formData, userId);
        if (res.data.success === true) {
          localStorage.setItem("user__details", JSON.stringify(res.data.org));
          localStorage.setItem("emailId", res.data.org.email);
          localStorage.setItem(
            "phoneNumber",
            "+" + res.data.org.mobile_country_code + "-" + res.data.org.mobile
          );
          navigate("/profile_name_update", { replace: true });
        }
      },
    });
    return (
      <form onSubmit={formik1.handleSubmit}>
        <label className={Style.label}>
          Country <span title="required">*</span>
        </label>
        <Select
          placeholder="Select Country"
          options={Country.getAllCountries()}
          getOptionLabel={(options: any) => {
            return options["name"];
          }}
          getOptionValue={(options: any) => {
            return options["name"];
          }}
          id="country"
          name="country"
          value={formik1.values.country}
          onBlur={formik1.handleBlur}
          onChange={(item: any) => {
            setcountry(item.name);
            formik1.values.country = item;
            setdatas((prevItems) => ({
              ...prevItems,
              country: item,
            }));
          }}
          className={
            formik1.touched.country && formik1.errors.country
              ? "text-xs inputError"
              : "text-xs"
          }
        />
        {formik1.touched.country && formik1.errors.country ? (
          <div className="error">{formik1.errors.country}</div>
        ) : null}
        <div className="mb-2"></div>
        <label className={Style.label}>
          State<span title="required">*</span>
        </label>
        <Select
          placeholder="Select State"
          options={State?.getStatesOfCountry(
            formik1.values.country?.["isoCode"]
          )}
          getOptionLabel={(options: any) => {
            return options["name"];
          }}
          getOptionValue={(options: any) => {
            return options["name"];
          }}
          id="state"
          name="state"
          value={formik1.values.state}
          onBlur={formik1.handleBlur}
          onChange={(item: any) => {
            formik1.values.state = item;
            setstate(item.name);
            setdatas((prevItems) => ({
              ...prevItems,
              state: item,
            }));
          }}
          className={
            formik1.touched.state && formik1.errors.state
              ? "inputError text-xs"
              : "text-xs"
          }
        />
        {formik1.touched.state && formik1.errors.state && (
          <div className="error">{formik1.errors.state}</div>
        )}
        <div className="mb-2"></div>
        <label className={Style.label}>
          City<span title="required">*</span>
        </label>
        <Select
        placeholder="Select City"
          options={City.getCitiesOfState(
            formik1.values.state?.["countryCode"]!,
            formik1.values.state?.["isoCode"]!
          )}
          getOptionLabel={(options: any) => {
            return options["name"];
          }}
          getOptionValue={(options: any) => {
            return options["name"];
          }}
          id="city"
          name="city"
          value={formik1.values.city}
          onBlur={formik1.handleBlur}
          onChange={(item: any) => {
            formik1.values.city = item;
            setCity(item.name);
            setdatas((prevItems) => ({
              ...prevItems,
              city: item,
            }));
          }}
          className={
            formik1.touched.city && formik1.errors.city
              ? "inputError text-xs"
              : "text-xs"
          }
        />
        {formik1.touched.city && formik1.errors.city && (
          <div className="error">{formik1.errors.city}</div>
        )}
        <div className="mb-2"></div>
        <label className={Style.label}>
          Postal Code<span title="required">*</span>
        </label>
        <input
          placeholder="Postal Code"
          type="text"
          id="postal_code"
          name="postal_code"
          value={formik1.values.postal_code}
          onBlur={(event: any) => {
            formik1.handleBlur(event);
            setdatas((prevItems) => ({
              ...prevItems,
              postal_code: event.target.value,
            }));
          }}
          onChange={(event: any) => {
            formik1.handleChange(event);
          }}
          className={
            formik1.touched.postal_code && formik1.errors.postal_code
              ? Style.iError
              : Style.iInput
          }
        ></input>
        {formik1.touched.postal_code && formik1.errors.postal_code && (
          <div className="error">{formik1.errors.postal_code}</div>
        )}

        <div className="flex items-center justify-between mt-6">
          <button
            className="bg-transparent text-sm text-primary font-semibold py-2 px-4 border border-primary  rounded"
            onClick={() => setPage(1)}
          >
            Prev
          </button>
          <button type="submit" className="button-primary text-sm hover:button-primary text-white font-bold py-2 px-4 rounded">
            Submit
          </button>
        </div>
      </form>
    );
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="lg:p-24 md:p-52 sm:20 p-8 w-full lg:w-1/2">
        <div className="">
          <SVG.LogoDark />
        </div>
        <h1 className="text-lg mt-6 mb-4 font-semibold">
          Organization Details
        </h1>

        {page === 1 ? <SetOne /> : <SetTwo />}
      </div>
      <div className="w-1/2 h-screen hidden lg:block button-primary ">
        <div className="dashimg h-4/5 my-14">
          <img className="" src={dash} alt="dashboard"></img>
        </div>
      </div>
    </div>
  );
};
export default OrganizationProfileUpdate;
