import React from "react";
import Carousel from "../../../component/Carousel/Carousel";
import { slides } from "../../../component/Carousel/CarousalSlides";
import { SVG } from "../../../assets/SVG/SVG";
// import { useParams } from "react-router-dom";
// import orgApi from "../../../api/org_api";
import StarRatings from "react-star-ratings";

const ViewCandidateRate = () => {
  //   const { schedule_id } = useParams();
  //   const [post, setPost] = React.useState({} as any);
  const [primary, setPrimary] = React.useState([] as any);
  const [secondary, setSecondary] = React.useState([] as any);
  React.useEffect(() => {
    fetchPostDetails();
  }, []);
  const fetchPostDetails = async () => {
    // const res = await orgApi.fetchPostDetails(post_id);
    // setPost(res.data.post);
    setPrimary([
      { name: "Core Java", skill_id: "1", rating: 0, rate: false },
      { name: "React Js", skill_id: "2", rating: 0, rate: false },
      { name: "Flutter", skill_id: "3", rating: 0, rate: false },
      { name: "Node Js", skill_id: "4", rating: 0, rate: false },
      { name: "PostgreSQL", skill_id: "5", rating: 0, rate: false },
      { name: "PHP", skill_id: "6", rating: 0, rate: false },
    ]);

    setSecondary([
      { name: "Photoshop", skill_id: "1", rating: 0, rate: false },
      { name: "Communication Skill", skill_id: "2", rating: 0, rate: false },
      { name: "Problem Solving", skill_id: "3", rating: 0, rate: false },
    ]);
  };

  return (
    <>
      <div>
        <div className=" flex justify-center items-center max-h-screen overflow-hidden">
          <div className="lg:p-24 md:p-52 sm:20 p-8 w-full lg:w-1/2 ">
            <div>
              <SVG.LogoDark />
            </div>
            <h1 className="text-sm mt-5 font-semibold mb-2">
              Candidate Self Evaluation
            </h1>
            <div className="w-full overflow-y-auto" style={{ height: "25rem" }}>
              <h1 className="text-xs font-semibold mb-2">
                Required Technical Skills
              </h1>
              {primary.map((val: any) => {
                return (
                  <div className="flex flex-row justify-between items-center w-full mb-1 border-b">
                    <span className=" text-xs font-medium">{val.name}</span>
                    <StarRatings
                      rating={val.rating}
                      changeRating={(rate: number) => {
                        console.log(rate);
                      }}
                      numberOfStars={5}
                      name="rating"
                      starRatedColor="orange"
                      starHoverColor="orange"
                      starDimension="15px"
                      starSpacing="2px"
                    />
                  </div>
                );
              })}
              <h1 className="text-xs mt-6 font-semibold mb-5">
                Preferred Technical Skills
              </h1>
              {secondary.map((val: any) => {
                return (
                  <div className="flex flex-row justify-between items-center w-full mb-1 border-b">
                    <span className=" text-xs font-medium">{val.name}</span>
                    <StarRatings
                      rating={val.rating}
                      changeRating={(rate: number) => {
                        console.log(rate);
                      }}
                      numberOfStars={5}
                      name="rating"
                      starRatedColor="orange"
                      starHoverColor="orange"
                      starDimension="15px"
                      starSpacing="2px"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-1/2 h-screen hidden lg:block">
            <div className="onboard-box">
              <Carousel slides={slides} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCandidateRate;
