const fantabee = require("../../assets/images/testimonials/fantabee.png");
const hakuna = require("../../assets/images/testimonials/hakuna.png");
const kanini = require("../../assets/images/testimonials/kanini.png");
const mobilution = require("../../assets/images/testimonials/mobilution.png");
const newt = require("../../assets/images/testimonials/newt.png");

export const Slide = [
  {
    iamge: hakuna,
    company: `Hakuna Matata solutions Pvt Ltd`,
    msg: `Using Screenit for a year, we've seen notable improvements and seamless feedback. The Screenit team has provided excellent support in addressing any concerns`,
  },
  {
    iamge: kanini,
    company: `Kanini Software Solutions`,
    msg: `Screenit impresses with its accurate, speedy services. Quick candidate screening turnaround and efficient interviews make it a reliable choice for recruitment. Highly recommended for interview services.`,
  },
  {
    iamge: mobilution,
    company: `MobilutionIT`,
    msg: `For over a year, we've utilized the platform's interview-as-a-service for efficient profile screening, with valuable quick access to panel slots and quality service. Highly recommended for initial screening`,
  },
  {
    iamge: fantabee,
    company: `Fantabee IoE Labs Private Limited`,
    msg: `Prior to discovering Screenit, interview scheduling was challenging. Their user-friendly platform streamlined the process, helping us find the perfect fit. We highly recommend Screenit for seamless interviews`,
  },
  {
    iamge: newt,
    company: `NewtGlobal Corporation`,
    msg: `For over a year, we've relied on the platform's panel slots service for efficient and high-quality profile screening. It's a valuable choice for initial candidate screening`,
  },
];
