import React from "react";
import { ToastContainer } from "react-toastify";
import intApi from "../../../api/int_api";
import { format } from "date-fns";
const nodata = require("../../../assets/images/noschedules.png");

type ScheduleHistoryProps = { setLoading: Function };

const ScheduleHistory: React.FC<ScheduleHistoryProps> = ({
  setLoading,
}: ScheduleHistoryProps): JSX.Element => {
  const [schedule, setSchedule] = React.useState([]);
  React.useEffect(() => {
    getSchedule();
  }, []);

  const getSchedule = async () => {
    setLoading(true);
    var cand = JSON.parse(localStorage.getItem("candidate")!);
    var canId = cand["interviewer"]["id"];
    var data = {
      from: "",
      to: "",
      interviewer_id: canId,
      status: null,
    };
    const res = await intApi.scheduleList(data);
    res.data.success ? setSchedule(res.data.schedule) : setSchedule([]);
    setLoading(false);
  };

  const timeConvert = (t1: any, t2: any) => {
    return format(t1, "hh:mm a") + " - " + format(t2, "hh:mm a");
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      <div className="overflow-y-auto" style={{ height: "80vh" }}>
        <h1 className="text-center font-bold">Schedules</h1>
        {
              schedule.length === 0 &&
              <div className="flex flex-col justify-center items-center my-16 gap-1">
                <img
                  src={nodata}
                  alt="user_profile_photo"
                  className="h-32 w-32 object-cover"
                ></img>
                {/* <p className="text-xs font-thin text-gray-400">No JD Created.</p> */}
              </div>
            }
        {schedule.length !== 0 && <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 overflow-y-auto table-fixed">
          <thead className="text-xs text-white bg-primary">
            <tr>
              <th className="p-2">S.No</th>
              <th className="p-2">Post Title</th>
              <th className="p-2">Client Name</th>
              <th className="p-2">Status</th>
              {/* <th className="p-2">Interviewer Name</th> */}
              <th className="p-2">Interview Date</th>
              <th className="p-2">Interview Time</th>
              <th className="p-2">Meeting ID</th>
              <th className="p-2">Candidate Name</th>
              {/* <th className="p-2">Candidate Email</th> */}
            </tr>
          </thead>
          <tbody>
            {schedule.map((x: any, index: any) => (
              <tr className="odd:bg-white even:bg-gray-100 border-b text-gray-700">
                <th className="p-2 text-xs 2xl:text-md font-medium text-gray-700">
                  {index + 1}
                </th>
                <td
                  className="p-2 text-xs 2xl:text-md truncate"
                  title={x.post.job_title}
                >
                  {x.post.job_title}
                </td>
                <td className="p-2 text-xs 2xl:text-md">
                  {x.post.client_name ? x.post.client_name : "-"}
                </td>
                <td className="p-2 text-xs 2xl:text-md">{x.status}</td>
                {/* <td className="p-2 text-xs 2xl:text-md">
                  {x.interviewer ? x.interviewer.candidate.first_name : "-"}
                </td> */}
                <td className="p-2 text-xs 2xl:text-md">
                  {format(x.utc_starttime, "yyyy-MMM-dd")}
                </td>
                <td className="p-2 text-xs 2xl:text-md">
                  {timeConvert(x.utc_starttime, x.utc_endtime)}
                </td>
                <td className="p-2 text-xs 2xl:text-md">
                  {x.meeting_id ? x.meeting_id : "-"}
                </td>
                <td className="p-2 text-xs 2xl:text-md">
                  {x.candidate.first_name}
                </td>
                {/* <td
                  className="p-2 text-xs 2xl:text-md truncate"
                  title={x.candidate.email}
                >
                  {x.candidate.email}
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>}
      </div>
    </>
  );
};
export default ScheduleHistory;
