import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SVG } from "../../assets/SVG/SVG";
import { useAuth } from "../../utils/UseContext";
const dash = require("../../assets/images/dash.png");

const OnboardingSuccess: React.FC = () => {
  const [username, setUsername] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    var un = localStorage.getItem("user_name")?.toString();
    setUsername(un!);
  }, []);
  const gotoDashboard = async () => {
    login();
    navigate("/dashboard", { replace: true });
  };

  return (
    <div>
      <div className=" flex justify-center items-center max-h-screen">
        <div className="lg:p-24 md:p-52 sm:20 p-8 w-full lg:w-1/2">
          <div className="">
            <SVG.LogoDark />
          </div>
          <div className="">
            <SVG.OnboardSuccess />
          </div>
          <div className="text-md font-semibold text-gray-800 mb-4 mt-4 text-center">
            {"Congratulations, " + username + "!"}
          </div>
          <h6 className="text-xs text-gray-400 mb-4 mt-2 text-center">
            You have completed onboarding, you can start using Screenit
          </h6>
          <button
            onClick={() => gotoDashboard()}
            className="button-primary text-sm text-white font-semibold rounded-md py-2 px-4 w-full"
          >
            Get Started
          </button>
        </div>
        <div className="w-1/2 h-screen hidden lg:block button-primary ">
          <div className="dashimg h-4/5 my-14">
            <img className="" src={dash} alt="dashboard"></img>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OnboardingSuccess;
