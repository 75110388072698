import React from "react";
import { useParams } from "react-router-dom";
import orgApi from "../../api/org_api";
import { BsEnvelope, BsFillTelephoneFill } from "react-icons/bs";
import { format } from "date-fns";
import S from "../../Services/Services";
type InterviewResultProps = { setLoading: Function };
const InterviewResult: React.FC<InterviewResultProps> = ({
  setLoading,
}: InterviewResultProps) => {
  const [candidateList, setCandidateList] = React.useState([] as any);
  const [search, setSearch] = React.useState("");

  const { type } = useParams();
  React.useEffect(() => {
    fetchSchedules();
  }, [search.length > 3, search.length === 0]);

  const fetchSchedules = async () => {
    setLoading(true);
    var userInfo = JSON.parse(
      localStorage.getItem("user__details")?.toString()!
    );
    var orgId = userInfo.id;
    var reqData = {
      type: type,
      search: search,
    };
    const res = await orgApi.fetchInterviewSchedule(orgId, reqData);

    if (res.data.success) {
      setCandidateList(res.data.schedule);
    } else {
    }
    setLoading(false);
  };

  const timezoneList = [
    { id: 0, name: "IST", fullName: "Asia/Kolkata" },
    { id: 1, name: "IST", fullName: "Asia/Calcutta" },
    { id: 2, name: "EST", fullName: "America/New_York" },
    { id: 3, name: "GMT", fullName: "Europe/London" },
    { id: 4, name: "CST", fullName: "America/Chicago" },
    { id: 5, name: "PST", fullName: "America/Los_Angeles" },
    { id: 6, name: "HST", fullName: "Pacific/Honolulu" },
    { id: 7, name: "AHST", fullName: "America/Anchorage" },
  ];

  const getTz = (tz: any) => {
    var timezonetype = "";
    for (let i = 0; i < timezoneList.length; i++) {
      timezoneList[i].fullName === tz && (timezonetype = timezoneList[i].name);
    }
    return timezonetype;
  };

  return (
    <>
      <div className="flex flex-col" style={{ height: "86vh" }}>
        <div className="flex flex-row justify-end">
          <div className="w-1/5">
            <form className="searchForm">
              <input
                placeholder="Search.."
                type="search"
                id="search"
                name="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className={"w-full border py-2 px-3 outline-none  rounded"}
              ></input>
              <button className="searchButton" type="button">
                Search
              </button>
            </form>
          </div>
        </div>
        <div className="m-2 overflow-y-auto">
          <table className="table-fixed text-xs w-full">
            <thead>
              <tr>
                <th align="left" className="w-1/5">
                  <p className="p-2">Candidate Details</p>
                </th>
                <th align="left">
                  <p className="p-2">Post Title</p>
                </th>
                <th align="left">
                  <p className="p-2">Interview Date</p>
                </th>
                <th align="left">
                  <p className="p-2">Interview Time</p>
                </th>
                <th align="left">
                  <p className="p-2">Status</p>
                </th>
                {S.getOrgDomain() !== "nam-it.com" && (
                  <th align="left">
                    <p className="p-2">SIT Score</p>
                  </th>
                )}
                <th align="left">
                  <p className="p-2">Action</p>
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-500">
              {candidateList.map((value: any) => (
                <tr className="bg-gray-50 border-b-2 border-white ">
                  <td>
                    <div className="p-2 flex flex-col items-start gap-1 truncate">
                      <p className="font-semibold truncate">
                        {value.candidate.first_name} {value.candidate.last_name}
                      </p>
                      <p className="flex flex-row items-center gap-1 truncate">
                        <BsEnvelope /> &nbsp;
                        <p>{value.candidate.email}</p>
                      </p>
                      <p className="flex flex-row items-center gap-1">
                        <BsFillTelephoneFill /> &nbsp;
                        {value.candidate.mobile}
                      </p>
                    </div>
                  </td>

                  <td>
                    <p className="p-2 truncate">{value.post.job_title}</p>
                  </td>
                  <td>
                    <p className="p-2">
                      {format(value.utc_starttime, "dd MMM yyyy")}
                    </p>
                  </td>
                  <td>
                    <p className="p-2">
                      {format(value.utc_starttime, "hh:mm a")} to{" "}
                      {format(value.utc_endtime, "hh:mm a")}{" "}
                      {getTz(value.candidate_tz)}
                    </p>
                  </td>
                  <td>
                    <p className="p-2">
                      {value.feedback[0].interview_status === "Completed" ? (
                        <p className="text-green-500">
                          {value.feedback[0].interview_status}
                        </p>
                      ) : (
                        <p className="text-red-500">
                          {value.feedback[0].interview_status}
                        </p>
                      )}
                    </p>
                  </td>
                  {S.getOrgDomain() !== "nam-it.com" && (
                    <td>
                      <p className="text-green-500 p-2">
                        {value.feedback[0].sit_score}
                      </p>
                    </td>
                  )}
                  <td>
                    <p
                      onClick={() => S.viewFeedback(value.id)}
                      className="cursor-pointer text-blue-500 underline"
                    >
                      View Details
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default InterviewResult;
