import React from "react";
import { BsPersonFill } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import orgApi from "../../../api/org_api";
import { Style } from "../../../utils/Style";
import StarRatings from "react-star-ratings";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useNavigate } from "react-router-dom";

type InterviewerProps = { setLoading: Function };

const InterviewerManagement: React.FC<InterviewerProps> = ({
  setLoading,
}: InterviewerProps): JSX.Element => {
  const [search, setSearch] = React.useState("");
  const [role, setRole] = React.useState("");
  const [intType, setIntType] = React.useState("in-house");
  const [showAddInterviewersModal, setShowAddInterviewersModal] =
    React.useState(false);

  const [interviewers, setInterviewers] = React.useState([]);

  const [interviewer, setInterviewer] = React.useState({} as any);
  const [openInsDetailModal, setOpenInsDetailModal] = React.useState(false);
  const [defaultPage, setDefaultPage] = React.useState("profile");
  const [phErrorMsg, setPhErrorMessage] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email Id is required")
        .email("Invalid Email"),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      var ph;
      if (
        phone !== undefined &&
        countryCode !== "" &&
        countryCode !== undefined
      ) {
        ph = phone.replace("+" + countryCode, "");
        if (ph !== "") {
        } else {
          setPhErrorMessage("Please enter the valid Phone number");
          return;
        }
      } else {
        setPhErrorMessage("Please enter the valid Phone number");
        return;
      }
      setLoading(true);
      var reqData = {
        email: values.email.toLowerCase(),
        mobile: ph!,
        role: intType,
        mobile_country_code: countryCode,
      };
      var userInfo = JSON.parse(
        localStorage.getItem("user__details")?.toString()!
      );
      var orgId =
        userInfo.master_id !== null ? userInfo.master_id : userInfo.id;
      const res = await orgApi.inviteInterviewers(orgId, reqData);
      if (res.data.success) {
        setShowAddInterviewersModal(false);
        toast(res.data.msg);
        fetchOrgInterviewers();
      } else {
        toast(res.data.msg);
      }
      setLoading(false);
    },
  });
  const addInterviewer = () => {
    formik.resetForm();
    setIntType("in-house");
    setShowAddInterviewersModal(true);
  };
  React.useEffect(() => {
    fetchOrgInterviewers();
  }, [role]);
  const fetchOrgInterviewers = async () => {
    setLoading(true);
    var userInfo = JSON.parse(
      localStorage.getItem("user__details")?.toString()!
    );
    var orgId =
        userInfo.master_id !== null ? userInfo.master_id : userInfo.id;
    var reqData = {
      type: role,
    };
    const res = await orgApi.fetchOrgInterviewers(orgId, reqData);

    if (res.data.success) {
      setInterviewers(res.data.interviewer);
    } else {
    }
    setLoading(false);
  };
  const intDetail = async (data: any) => {
    setInterviewer(data);
    setOpenInsDetailModal(true);
  };
  function handlechange(e: any) {
    setPhErrorMessage("");
    setPhone(e);
    if (e !== undefined && countryCode !== "") {
      const ph = e.replace("+" + countryCode, "");
      !ph && setPhErrorMessage("Please enter the valid Phone number");
    } else {
      setPhErrorMessage("Please enter the valid Phone number");
    }
  }
  function handleBlur(e: any) {
    setPhErrorMessage("");
    const len = e.target.value.split(" ").length;
    const c = e.target.value.split(" ");
    const cc = c[0].split("+");
    setCountryCode(cc[1]);
    const ph = e.target.value.substring(e.target.value.indexOf(" ") + 1);
    if (len > 1 && ph !== "") {
    } else {
      setPhErrorMessage("Please enter the valid Phone number");
    }
  }

  const gotoIntGrp = () => {
    navigate("/interviewer_group"); 
  }

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      {showAddInterviewersModal && (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setShowAddInterviewersModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white shadow-lg">
                <div className="sm:flex">
                  <div className="mt-1 text-center sm:text-left w-full">
                    <div className="flex items-center justify-between border-b">
                      <h6 className="text-sm font-bold text-gray-800 mb-2">
                        Add Interviewers
                      </h6>
                      <button
                        onClick={() => setShowAddInterviewersModal(false)}
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        <svg
                          className="w-2 h-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span className="sr-only">Close modal</span>
                      </button>
                    </div>
                    <div className="mt-1">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="grid md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 ">
                          <div className="my-2 lg:mx-5 md:mx-5 xl:mx-5">
                            <div className="flex flex-row item-center justify-center mb-3 gap-5">
                              <div className="flex items-center gap-2">
                                <input
                                  checked={intType === "in-house"}
                                  id="default-radio-1"
                                  type="radio"
                                  // value="Manual"
                                  name="default-radio"
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                  onChange={() => {
                                    setIntType("in-house");
                                  }}
                                ></input>
                                <label className={Style.label}>In-House</label>
                              </div>
                              <div className="flex items-center gap-2">
                                <input
                                  checked={intType === "external"}
                                  id="default-radio-2"
                                  type="radio"
                                  // value="AI"
                                  name="default-radio"
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                                  onChange={() => {
                                    setIntType("external");
                                  }}
                                ></input>
                                <label className={Style.label}>External</label>
                              </div>
                            </div>
                            <label className={Style.label}>Email<span title="required">*</span></label>
                            <input
                              placeholder="Email"
                              type="text"
                              id="email"
                              name="email"
                              value={formik.values.email}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              className={
                                formik.touched.email && formik.errors.email
                                  ? Style.iError
                                  : Style.iInput
                              }
                            ></input>
                            {formik.touched.email && formik.errors.email ? (
                              <div className="error">{formik.errors.email}</div>
                            ) : null}
                          </div>
                          <div className="my-2 lg:mx-5 md:mx-5 xl:mx-5">
                            <label className={Style.label}>Phone<span title="required">*</span></label>
                            <PhoneInput
                              defaultCountry="IN"
                              international
                              countryCallingCodeEditable={false}
                              initialValueFormat="national"
                              name="phone"
                              value={phone}
                              onBlur={handleBlur}
                              onChange={handlechange}
                              className={
                                phErrorMsg !== "" ? Style.iError : Style.iInput
                              }
                              autoComplete="off"
                            />
                            {phErrorMsg && (
                              <div className="error">{phErrorMsg}</div>
                            )}
                          </div>
                        </div>
                        <div className=" gap-2 mt-3 sm:flex md:flex lg:flex justify-center">
                          <button
                            className=" mt-2 py-1.5 px-2.5 text-gray-800 outline-none border bg-gray-100 text-xs"
                            onClick={() => setShowAddInterviewersModal(false)}
                            type="button"
                          >
                            Cancel
                          </button>
                          <button
                            className="mt-2 py-1.5 px-2.5 text-white button-primary outline-none text-xs"
                            type="submit"
                          >
                            Invite
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {openInsDetailModal && (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="fixed inset-0 w-full h-full bg-black opacity-40"
              onClick={() => setOpenInsDetailModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-3xl p-4 mx-auto bg-white shadow-lg">
                <div className="sm:flex">
                  <div className="mt-1 text-center sm:text-left w-full">
                    <div className="flex items-center justify-between border-b">
                      <h6 className="text-sm font-bold text-gray-800 mb-2">
                        Profile
                      </h6>
                      <button
                        onClick={() => setOpenInsDetailModal(false)}
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      >
                        <svg
                          className="w-2 h-2"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 14 14"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                          />
                        </svg>
                        <span className="sr-only">Close modal</span>
                      </button>
                    </div>
                    <div className=" w-full mt-4 max-w-3xl">
                      <div className=" grid grid-cols-4 gap-1 px-5 py-4 items-center mb-4">
                        <div className="col-span-1 flex justify-center">
                          <div
                            onClick={() => setOpenInsDetailModal(true)}
                            className="h-20 w-20 ml-4 sm:ml-3 mr-1 bg-gray-100 rounded-full overflow-hidden"
                          >
                            <img
                              src="https://randomuser.me/api/portraits/women/68.jpg"
                              alt="user_profile_photo"
                              className="h-full w-full object-cover"
                            ></img>
                          </div>
                        </div>
                        <div className="col-span-1 ">
                          <div className="flex flex-col justify-between gap-3 h-24">
                            <div>
                              <p className="text-xs text-gray-400 font-thin">
                                Name
                              </p>
                              <p className="text-xs text-gray-900 font-semibold truncate">
                                {interviewer.first_name} {interviewer.last_name}
                              </p>
                            </div>
                            <div>
                              <p className="text-xs text-gray-400 font-thin">
                                Designation
                              </p>
                              <p className="text-xs text-gray-900 font-semibold truncate">
                                {interviewer.designation}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-1">
                          <div className="flex flex-col justify-between gap-3 h-24">
                            <div>
                              <p className="text-xs text-gray-400 font-thin">
                                Rating
                              </p>
                              <p className="text-xs text-gray-900 font-semibold truncate">
                                <StarRatings
                                  rating={interviewer.interviewer.avg_rating}
                                  changeRating={(rate: number) =>
                                    console.log(rate)
                                  }
                                  numberOfStars={5}
                                  name="rating"
                                  starRatedColor="orange"
                                  starHoverColor="orange"
                                  starDimension="14px"
                                  starSpacing="2px"
                                />
                              </p>
                            </div>
                            <div>
                              <p className="text-xs text-gray-400 font-thin">
                                Experience
                              </p>
                              <p className="text-xs text-gray-900 font-semibold truncate">
                                {interviewer.year_of_experience} Years{" "}
                                {interviewer.month_of_experience} Months
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-span-1">
                          <div className="flex flex-col justify-between gap-3 h-24">
                            <div>
                              <p className="text-xs text-gray-400 font-thin">
                                Conducted Interviews
                              </p>
                              <p className="text-xs text-gray-900 font-semibold truncate">
                                {interviewer.interviewer.no_of_rating}
                              </p>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                        <ul
                          className="flex justify-start -mb-px"
                          id="default-tab"
                          data-tabs-toggle="#default-tab-content"
                          role="tablist"
                        >
                          <li className="me-2">
                            <a
                              onClick={() => setDefaultPage("profile")}
                              className={
                                defaultPage === "profile"
                                  ? "inline-flex items-center justify-center  p-2 text-blue-600 border-b-4 border-blue-600 rounded-t-lg active font-semibold text-xs text-gray-900 dark:border-blue-500"
                                  : "inline-flex items-center justify-center  p-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 text-xs"
                              }
                            >
                              Profile Details
                            </a>
                          </li>
                          <li className="me-2">
                            <a
                              onClick={() => setDefaultPage("skill")}
                              className={
                                defaultPage === "skill"
                                  ? "inline-flex items-center justify-center  p-2 text-blue-600 border-b-4 border-blue-600 rounded-t-lg active font-semibold text-xs text-gray-900 dark:border-blue-500"
                                  : "inline-flex items-center justify-center  p-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 text-xs"
                              }
                            >
                              Skill Set
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div id="default-tab-content">
                        {defaultPage === "profile" ? (
                          <div className="flex justify-start mt-2">
                            <div className="my-2 flex flex-col gap-2">
                              <label className={Style.label}>
                                Short Introduction
                              </label>
                              {interviewer.profile_video != null ? (
                                <video
                                  width="300"
                                  height="200"
                                  controls
                                  autoPlay={false}
                                >
                                  <source
                                    src={interviewer.profile_video}
                                    type="video/mp4"
                                  />
                                </video>
                              ) : (
                                <p className="font-normal text-xs text-gray-500">
                                  Vidio not uploaded
                                </p>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="">
                            {interviewer.u_p_skill.map((x: any) => {
                              return (
                                <div className="grid grid-cols-2 border-b py-2 px-2 gap-3">
                                  <p className="text-sm text-gray-500 ">
                                    {x.skill.name}
                                  </p>
                                  <div className="">
                                    <StarRatings
                                      rating={x.self_rating}
                                      changeRating={(rate: number) =>
                                        console.log(rate)
                                      }
                                      numberOfStars={5}
                                      name="rating"
                                      starRatedColor="blue"
                                      starHoverColor="blue"
                                      starDimension="18px"
                                      starSpacing="5px"
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className=" gap-2 mt-3 sm:flex md:flex lg:flex flex-row-reverse">
                      <button
                        className=" mt-2 py-1 px-2.5 text-gray-800 outline-none border text-sm"
                        onClick={() => setOpenInsDetailModal(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="flex flex-col" style={{ height: "86vh" }}>
        <div className="flex flex-row justify-between grid grid-cols-5">
          <div className="w-full mr-5 col-span-1">
            <form className="searchForm">
              <input
                placeholder="Search.."
                type="search"
                id="search"
                name="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className={"w-full border py-2 px-3 outline-none  rounded"}
              ></input>
              <button className="searchButton" type="button">
                Search
              </button>
            </form>
          </div>
          <div className="col-span-2"></div>
          <div className="w-full col-span-2">
            <div className="gap-2 flex flex-row justify-end">
              <select
                id="role"
                name="role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                className="w-2/4 border border-gray-200 py-2 px-3 focus:outline-none focus:border-gray-300 rounded"
              >
                <option value="">Select Role</option>
                <option value="in-house">In-House</option>
                <option value="external">External</option>
              </select>
              <button
                type="button"
                onClick={() => addInterviewer()}
                className="py-2 px-3.5 text-white outline-none bg-primary border-primary text-xs rounded-md flex gap-2 items-center"
              >
                + Add
              </button>
              <button
                type="button"
                onClick={() => gotoIntGrp()}
                className="py-2 px-3.5 text-white outline-none bg-primary border-primary text-xs rounded-md flex gap-2 items-center"
              >
                Group
              </button>
            </div>
          </div>
        </div>
        <div className="m-2 overflow-y-auto">
          <table className="table-fixed text-xs w-full">
            <thead>
              <tr>
                <th align="left" className="w-1/2">
                  <p className="p-2">Profile Pic</p>
                </th>
                <th align="left" className="w-1/2">
                  <p className="p-2">Name</p>
                </th>
                <th align="left" className="w-1/2">
                  <p className="p-2">Email</p>
                </th>
                <th align="left" className="w-1/2">
                  <p className="p-2">Contact Number</p>
                </th>
                <th align="left" className="w-1/2">
                  <p className="p-2">Role</p>
                </th>
                <th align="left" className="w-1/2">
                  <p className="p-2">Type</p>
                </th>
                <th align="left" className="w-1/2">
                  <p className="p-2">Designation</p>
                </th>
                <th align="left" className="w-1/2">
                  <p className="p-2"></p>
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-600">
              {interviewers.map((value: any) => (
                <tr className="bg-gray-50 border-b-2 border-white ">
                  <td>
                    <p className="p-2">
                      {value.candidate !== null && (
                        <>
                          {value.candidate.profile_pic === null && (
                            <div className="h-16 w-16 bg-gray-100 rounded-full overflow-hidden">
                              <div className="flex justify-center items-center h-16">
                                <BsPersonFill color="grey" size={28} />
                              </div>
                            </div>
                          )}
                          {value.candidate.profile_pic !== null && (
                            <div className="h-16 w-16 bg-gray-100 rounded-full overflow-hidden">
                              <div className="flex justify-center items-center h-16">
                                <img
                                  src={value.candidate.profile_pic}
                                  alt="user_profile_photo"
                                  className="h-full w-full object-cover"
                                ></img>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </p>
                  </td>
                  <td>
                    <p className="p-2">
                      {value.candidate !== null
                        ? value.candidate.first_name +
                          " " +
                          value.candidate.last_name
                        : "-"}
                    </p>
                  </td>
                  <td>
                    <p className="p-2 truncate" title={value.email}>{value.email}</p>
                  </td>
                  <td>
                    <p className="p-2">+{value.mobile_country_code} {value.mobile}</p>
                  </td>
                  <td>
                    <p className="p-2">{value.role.charAt(0).toUpperCase() + value.role.slice(1)}</p>
                  </td>
                  <td>
                    <p className="p-2">
                      {value.candidate !== null && value.candidate.interviewer !== null
                        ? value.candidate.interviewer.type
                        : "-"}
                    </p>
                  </td>
                  <td>
                    <p className="p-2">
                      {value.candidate !== null
                        ? value.candidate.designation
                        : "-"}
                    </p>
                  </td>
                  <td>
                    {value.candidate !== null && <p
                      className="p-2 cursor-pointer underline text-primary"
                      onClick={() => intDetail(value.candidate)}
                    >
                      View Detail
                    </p>}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default InterviewerManagement;
