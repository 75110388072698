import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

interface AuthContextType {
  authenticated: boolean;
  login: () => void;
  logout: () => void;
  int_authenticated: boolean;
  intlogin: () => void;
  intlogout: () => void;
}

// Create a context for the Auth
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Create a provider component
export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [int_authenticated, setIntAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, [authenticated]);

  useEffect(() => {
    const token = localStorage.getItem("int_token");
    if (token) {
      setIntAuthenticated(true);
    } else {
      setIntAuthenticated(false);
    }
  }, [int_authenticated]);

  const login = () => setAuthenticated(true);
  const logout = () => {
    localStorage.clear();
    setAuthenticated(false);
  };

  const intlogin = () => setIntAuthenticated(true);
  const intlogout = () => {
    localStorage.clear();
    setIntAuthenticated(false);
  };

  return (
    // <AuthContext.Provider value={{ authenticated, login, logout }}>
    <AuthContext.Provider value={{ authenticated, login, logout, int_authenticated, intlogin, intlogout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Create a custom hook to use the auth context
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
