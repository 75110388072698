import React from "react";
import { SVG } from "../../../assets/SVG/SVG";
import Carousel from "../../../component/Carousel/Carousel";
import * as slides from "../../../component/Carousel/CarousalSlides";
import { ToastContainer } from "react-toastify";
import orgApi from "../../../api/org_api";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";

const ScheduleConfirmation: React.FC = () => {
  const navigate = useNavigate();
  const { schedule_id, interviewer_id } = useParams();
  const [schedule_details, setScheduleDetails] = React.useState(null);
  const [post_details, setPostDetails] = React.useState(null);
  const [int_data, setIntDetails] = React.useState(null);
  const [timezone, setTimeZone] = React.useState("");
  const [isDisabled, setisDisabled] = React.useState(false);

  const timezoneList = [
    { id: 1, name: "IST", fullName: "Asia/Calcutta" },
    { id: 2, name: "EST", fullName: "America/New_York" },
    { id: 3, name: "GMT", fullName: "Europe/London" },
    { id: 4, name: "CST", fullName: "America/Chicago" },
    { id: 5, name: "PST", fullName: "America/Los_Angeles" },
    { id: 6, name: "HST", fullName: "Pacific/Honolulu" },
    { id: 7, name: "AHST", fullName: "America/Anchorage" },
  ];

  React.useEffect(() => {
    getScheduleDetails();
  }, []);

  const getScheduleDetails = async () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var timezonetype = "IST";
    for (let i = 0; i < timezoneList.length; i++) {
      if (timezoneList[i].fullName === timezone!) {
        timezonetype = timezoneList[i].name;
        setTimeZone(timezonetype);
      }
    }
    const res = await orgApi.getScheduleDetail(schedule_id, interviewer_id);
    if (res.data.success) {
      setScheduleDetails(res.data.interviewer.s_interviewer[0].schedule);
      setPostDetails(res.data.interviewer.s_interviewer[0].schedule.post);
      setIntDetails(res.data.interviewer);
      if (res.data.interviewer.s_interviewer[0].select_tag === "false") {
        navigate("/success/sch_dec/null", { replace: true });
      }
      if (res.data.interviewer.s_interviewer[0].select_tag === "true") {
        navigate("/success/sch_acc/null", { replace: true });
      }
      if (
        res.data.interviewer.s_interviewer[0].select_tag === "pending" &&
        res.data.interviewer.s_interviewer[0].schedule.interviewer_id !== null
      ) {
        navigate("/success/ass_panel/null", { replace: true });
      }
    } else {
      setScheduleDetails(null);
      setPostDetails(null);
      navigate("/success/sch_del/null", { replace: true });
    }
  };

  const RespondRequest = async (action: any) => {
    const timezone1 = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setisDisabled(true);
    const data = { schedule_id: schedule_id, action: action, tz: timezone1 };
    const res = await orgApi.scheduleResponse(interviewer_id, data);
    navigate("/success/other/" + res.data.msg, { replace: true });
    setisDisabled(false);
  };
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      <div>
        <div className=" flex justify-center items-center max-h-screen">
          <div className="lg:p-24 md:p-52 sm:20 p-8 w-full lg:w-1/2">
            <div className="">
              <SVG.LogoDark />
            </div>
            <div className="flex flex-row justify-between items-center mb-3 mt-3 grid grid-cols-5 gap-2">
              <h1 className="text-md font-semibold xs:col-span-5 sm:col-span-5 md:col-span-3 lg:col-span-3 xl:col-span-3">
                Interview Schedule Request!
              </h1>
              <div className="w-full flex flex-row justify-end xs:col-span-5 sm:col-span-5  md:col-span-2 lg:col-span-2 xl:col-span-2 gap-1">
                <button
                  className={!isDisabled ? "bg-red-500 text-sm text-white font-semibold rounded-md py-2 px-4" : "bg-red-300 text-sm text-white font-semibold rounded-md py-2 px-4"}
                  type="button"
                  onClick={() => RespondRequest("reject")}
                  disabled={isDisabled}
                >
                  Decline
                </button>
                <button
                  className={!isDisabled ? "button-primary text-sm text-white font-semibold rounded-md py-2 px-4" : "button-primary-tint text-sm text-white font-semibold rounded-md py-2 px-4"}
                  type="button"
                  onClick={() => RespondRequest("accept")}
                  disabled={isDisabled}
                >
                  Accept
                </button>
              </div>
            </div>

            <form>
              {post_details && schedule_details && int_data && (
                <div className="border rounded-lg p-4">
                  <div className="text-xs flex items-center justify-between mb-3 grid grid-cols-2">
                    <span className="text-gray-800 font-semibold">
                      Job Title
                    </span>
                    <span className="text-gray-600 font-normal">
                      {post_details["job_title"]}
                    </span>
                  </div>
                  <div className="text-xs flex items-center justify-between mb-3 grid grid-cols-2">
                    <span className="text-gray-800 font-semibold">
                      Candidate Name
                    </span>
                    <span className="text-gray-600 font-normal">
                      {schedule_details["candidate"]["first_name"]}{" "}
                      {schedule_details["candidate"]["last_name"]}
                    </span>
                  </div>
                  <div className="text-xs flex items-center justify-between mb-3  grid grid-cols-2">
                    <span className="text-gray-800 font-semibold">
                      Interview Time ({timezone})
                    </span>
                    <span className="text-gray-600 font-normal">
                      {format(schedule_details["utc_starttime"], "dd MMM yyyy")}{" "}
                      {format(schedule_details["utc_starttime"], "hh:mm a")} -{" "}
                      {format(schedule_details["utc_endtime"], "hh:mm a")}
                    </span>
                  </div>
                  {/* <div className="text-xs flex items-center justify-between mb-3  grid grid-cols-2">
                    <span className="text-gray-800 font-semibold">
                      Interview cost
                    </span>
                    <span className="text-gray-600 font-normal">
                      {int_data["currency_symbol"] +
                        " " +
                        int_data["s_interviewer"][0]["schedule"]["post"]["org"][
                          "shown"
                        ]}
                    </span>
                  </div> */}
                  <div className="text-xs flex items-center justify-start  grid grid-cols-1 gap-1">
                    <span className="text-gray-800 font-semibold">
                      Job Description:
                    </span>
                    <textarea
                      disabled
                      rows={7}
                      className="text-xs text-gray-600  w-full border border-gray-300  py-2 px-3 focus:outline-none focus:border-blue-500 rounded"
                    >
                      {post_details["job_comments"]}
                    </textarea>
                  </div>
                  {post_details["remarks"] !== null && post_details["remarks"] !== '' && <div className="text-xs flex items-center justify-start  grid grid-cols-1 gap-1 mt-2">
                    <span className="text-gray-800 font-semibold">
                      Remarks:
                    </span>
                    <textarea
                      disabled
                      rows={3}
                      className="text-xs text-gray-600  w-full border border-gray-300  py-2 px-3 focus:outline-none focus:border-blue-500 rounded"
                    >
                      {post_details["remarks"]}
                    </textarea>
                  </div>}
                </div>
              )}
            </form>
          </div>
          <div className="w-1/2 h-screen hidden lg:block">
            <div className="onboard-box">
              <Carousel slides={slides.slides} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleConfirmation;
