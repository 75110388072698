import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import api from "../../api/auth_api";
import { useNavigate } from "react-router-dom";
import { SVG } from "../../assets/SVG/SVG";
import { Style } from "../../utils/Style";
const dash = require("../../assets/images/dash.png");

const ProfileNameUpdate: React.FC = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      emailId: localStorage.getItem("emailId")?.toString(),
      phoneNumber: localStorage.getItem("phoneNumber")?.toString(),
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      emailId: Yup.string(),
      phoneNumber: Yup.string(),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      var userInfo = JSON.parse(
        localStorage.getItem("user__details")?.toString()!
      );
      var orgId = userInfo.id;
      const data = { first_name: values.firstName, last_name: values.lastName };
      const res = await api.updateName(data, orgId);
      if (res.data.success === true) {
        let tok = localStorage.getItem("token_dum")?.toString();
        localStorage.setItem("token", tok!);
        localStorage.setItem("user__details", JSON.stringify(res.data.org));
        localStorage.setItem("user_name", res.data.org.first_name);
        navigate("/onboarding_success", { replace: true });
      }
    },
  });

  return (
    <div>
      <div className="flex justify-center items-center h-screen">
        <div className="lg:p-24 md:p-52 sm:20 p-8 w-full lg:w-1/2">
          <div className="mb-4">
            <SVG.LogoDark />
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div>
              <div className="mb-3 space-y-2 w-full ">
                <label className={Style.label}>
                  First name <span title="required">*</span>
                </label>
                <input
                  placeholder="First name"
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  className={
                    formik.touched.firstName && formik.errors.firstName
                      ? Style.iError
                      : Style.iInput
                  }
                ></input>
                {formik.touched.firstName && formik.errors.firstName && (
                  <div className="error">{formik.errors.firstName}</div>
                )}
              </div>
              <div className="mb-3 space-y-2 w-full">
                <label className={Style.label}>
                  Last name <span title="required">*</span>
                </label>
                <input
                  placeholder="Last name"
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formik.values.lastName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  className={
                    formik.touched.lastName && formik.errors.lastName
                      ? Style.iError
                      : Style.iInput
                  }
                ></input>
                {formik.touched.lastName && formik.errors.lastName && (
                  <div className="error">{formik.errors.lastName}</div>
                )}
              </div>
              <div className="mb-3 space-y-2 w-full">
                <label className={Style.label}>
                  Business mail
                </label>
                <input
                  disabled
                  placeholder="Business mail"
                  type="text"
                  id="emailId"
                  name="emailId"
                  value={formik.values.emailId}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  className={
                    formik.touched.emailId && formik.errors.emailId
                      ? Style.iError
                      : Style.iInput
                  }
                ></input>
                {formik.touched.emailId && formik.errors.emailId && (
                  <div className="error">{formik.errors.emailId}</div>
                )}
              </div>
              <div className="mb-3 space-y-2 w-full">
                <label className={Style.label}>
                  Phone
                </label>
                <input
                  disabled
                  placeholder="Phone Number"
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  className={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                      ? Style.iError
                      : Style.iInput
                  }
                ></input>
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <div className="error">{formik.errors.phoneNumber}</div>
                )}
              </div>

              <div className="mt-5 text-right md:space-x-3 md:block flex flex-col-reverse">
                <button type="submit" className="button-primary text-sm hover:button-primary text-white font-bold py-2 px-4 rounded">
                  Submit
                </button>
              </div>
            </div>
          </form>
          
        </div>
        <div className="w-1/2 h-screen hidden lg:block button-primary ">
          <div className="dashimg h-4/5 my-14">
            <img className="" src={dash} alt="dashboard"></img>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProfileNameUpdate;
