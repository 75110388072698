import * as Yup from "yup";

export const Valid = {
  password: Yup.object({
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .max(22, "Password must be maximum 22 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!*]).*$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      )
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), ""], "Passwords must match")
      .required("Confirm Password is required"),
  }),
  email: Yup.object({
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email")
      .test("valid-domain", "Enter your organization email", function (value) {
        const domain = value.split("@")[1];
        if (
          domain === "gmail.com" ||
          domain === "Gmail.com" ||
          domain === "yahoo.com" ||
          domain === "Yahoo.com"
        ) {
          return false;
        } else {
          return true;
        }
      }),
  }),
};
