import React from "react";
import Bar from "./Bar";

type BarChartProps = { chartData: object[]; schedulePage: Function };

const BarChart: React.FC<BarChartProps> = ({
  chartData, schedulePage,
}: BarChartProps): JSX.Element => {
  return (
    <div className="flex justify-between items-end"  style={{height:'35vh'}}>
      {chartData.map((data: any) => (
        <div
          key={data.day}
          className="flex flex-col items-center gap-1 sm:gap-2 group cursor-pointer relative"
        >
          <div className="bg-dark-brown text-card-white p-2 rounded-[5px] text-sm font-bold group-hover:opacity-100 opacity-0 absolute -top-10 transition-opacity">
            {data.schedule}
          </div>
          <Bar height={data.schedule * 15} length={chartData.length} date={data.date} schedulePage={schedulePage}/>
          <span className="text-xs font-normal">{data.day}</span>
        </div>
      ))}
    </div>
  );
};

export default BarChart;
