import React from "react";
import { SVG } from "../../../assets/SVG/SVG";
import Carousel from "../../../component/Carousel/Carousel";
import * as slides from "../../../component/Carousel/CarousalSlides";
import { useParams } from "react-router-dom";

const AvailabilitySuccess: React.FC = () => {
  const { type, msg } = useParams();
  
  return (
    <div>
      <div className=" flex justify-center items-center max-h-screen">
        <div className="lg:p-24 md:p-52 sm:20 p-8 w-full lg:w-1/2">
          <SVG.LogoDark />
          <div className="my-8">
            <SVG.Success />
          </div>
          <h6 className="text-md font-semibold text-gray-800 mb-4 mt-4 text-center">
            {type === 'availability_success' && "Availability set successfully"}
            {type === 'snft' && "Apologies ! It seems the requested link is no longer active. Please contact the recruiter for further assistance or inquiries"}
            {type === 'availability_set' && "Availability has been set already"}
            {type === 'sch_dec' && "Schedule Request was declined"}
            {type === 'sch_acc' && "Schedule Request already accepted"}
            {type === 'ass_panel' && "The current Schedule is assigned to another panel ! We appreciate your interest ! Watch out for further schedules"}
            {type === 'other' && msg}
            {type === 'sch_del' && "Oops! This schedule has been cancelled due to some unforseen situation by the recruiter, will keep you poster on the update"}
          </h6>
        </div>
        <div className="w-1/2 h-screen hidden lg:block">
          <div className="onboard-box">
            <Carousel slides={slides.slides} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AvailabilitySuccess;
