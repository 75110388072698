import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { SVG } from "../../assets/SVG/SVG";
import Carousel from "../../component/Carousel/Carousel";
import * as slides from "../../component/Carousel/CarousalSlides";
import intApi from "../../api/int_api";
import { Style } from "../../utils/Style";
import Multiselect from "multiselect-react-dropdown";
import StarRatings from "react-star-ratings";
import { BsUpload } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../utils/UseContext";

const InterviewerOnboarding: React.FC = () => {
  const [errorMsg, setErrorMessage] = React.useState("");
  const [skillList, setSkillList] = React.useState([]);
  const year_options = Array.from({ length: 39 }, (_, index) => index + 2);
  const month_options = Array.from({ length: 10 }, (_, index) => index + 2);
  const [primary_error, setPrimaryError] = React.useState("");
  const [primary, setPrimary] = React.useState([] as any);
  const [selectedFile, setSelectedFile] = React.useState<File>();
  const [fileError, setfileError] = React.useState("");
  const [selectedVideoFile, setSelectedVideoFile] = React.useState<File>();
  const [videoFileError, setVideofileError] = React.useState("");
  const navigate = useNavigate();
  const { intlogin } = useAuth();

  React.useEffect(() => {
    getSkills();
  }, []);

  const getSkills = async () => {
    const data = { search: "" };
    const res = await intApi.allSkills(data);
    setSkillList(res.data.skills);
  };

  const formik = useFormik({
    initialValues: {
      type: "",
      designation: "",
      experience: "",
      exp_month: "",
      skill: [] as any,
    },
    validationSchema: Yup.object({
      designation: Yup.string().required("Designation is required"),
      experience: Yup.string().required("Experience is required"),
      exp_month: Yup.string().required("Experience is required"),
      skill: Yup.array().required("Select Skills"),
      type: Yup.string().required("Type is required"),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      var cand = JSON.parse(localStorage.getItem("candidate")!);
      var canId = cand["id"];
      setPrimaryError("");
      if (primary.length === 0) {
        setPrimaryError("Select Skills");
        return;
      }
      setErrorMessage("");
      if (localStorage.getItem("interviewer_type") !== "in-house") {
        if (selectedFile === undefined) {
          setfileError("Please upload resume");
          return;
        }
      }
      // if (selectedVideoFile === undefined) {
      //   setVideofileError("Please upload intro video");
      //   return;
      // }

      var skillSet: any = [];
      primary.forEach((element: any) => {
        skillSet.push({
          skill_id: element["id"],
          year: 0,
          month: 0,
          rating: element["rating"],
        });
      });
      var reqData = {
        type: values.type,
        designation: values.designation,
        // role: "interviewer",
        year_of_experience: values.experience,
        month_of_experience: values.exp_month,
        primary: skillSet,
      };
      const res = await intApi.onboard(
        canId,
        reqData,
        localStorage.getItem("int_token1")!
      );
      if (res.data.success === true) {
        localStorage.setItem(
          "candidate",
          JSON.stringify(res.data.candidate[0])
        );

        if (localStorage.getItem("interviewer_type") !== "in-house") {
          const formData1 = new FormData();
          formData1.append("resume", selectedFile!);

          const res1 = await intApi.uploadResume(canId, formData1, localStorage.getItem("int_token1")!);
          if (res1.data.success) {
            if (selectedVideoFile !== null && selectedVideoFile !== undefined) {
              const formData2 = new FormData();
              formData2.append("video", selectedVideoFile!);
              const res2 = await intApi.uploadProfileVideo(canId, formData2);
              if (res2.data.success) {
                // navigate("/intervierer_register", { replace: true });
                localStorage.setItem(
                  "int_token",
                  localStorage.getItem("int_token1")!
                );
                intlogin();
                navigate("/schedule_history", { replace: true });
                toast(res.data.msg);
              }
            } else {
              localStorage.setItem(
                "int_token",
                localStorage.getItem("int_token1")!
              );
              intlogin();
              navigate("/schedule_history", { replace: true });
              toast(res.data.msg);
            }
          }
        } else {
          localStorage.setItem(
            "int_token",
            localStorage.getItem("int_token1")!
          );
          intlogin();
          navigate("/schedule_history", { replace: true });
          toast(res.data.msg);
        }
      } else {
        setErrorMessage(res.data.msg);
      }
    },
  });

  const handleImageChange = async (event: any) => {
    setfileError("");
    const file = event.target.files[0];
    if (file) {
      if (
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/pdf"
      ) {
        if (file.size <= 3 * 1024 * 1024) {
          setSelectedFile(file);
          setfileError("");
        } else {
          setfileError("Please upload the file with size <3 MB");
          setSelectedFile(undefined);
        }
      } else {
        setfileError("Please upload only pdf or docx format");
        setSelectedFile(undefined);
      }
    }
  };

  const handleVideoChange = async (event: any) => {
    setfileError("");
    const file = event.target.files[0];
    if (file) {
      if (file.type === "video/mp4") {
        if (file.size <= 3 * 1024 * 1024) {
          setSelectedVideoFile(file);
          setVideofileError("");
        } else {
          setVideofileError("Please upload the video with size <3 MB");
          setSelectedVideoFile(undefined);
        }
      } else {
        setVideofileError("Please upload only mp4 format");
        setSelectedVideoFile(undefined);
      }
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      <div className=" flex justify-center items-center max-h-screen overflow-hidden">
        <div className="lg:p-24 md:p-52 sm:20 p-8 w-full lg:w-1/2 overflow-y-auto h-screen">
          <div className="">
            <SVG.LogoDark />
          </div>
          <h1 className="text-xl mt-4 mb-4 font-semibold">Profile Details</h1>

          {errorMsg !== "" ? (
            <div className="error text-center ">{errorMsg}</div>
          ) : null}
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="grid grid-cols-1">
              <div className="my-2 ">
                <label className={Style.label}>
                  Type<span title="required">*</span>
                </label>
                <select
                  id="type"
                  name="type"
                  value={formik.values.type}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  className={
                    formik.touched.type && formik.errors.type
                      ? Style.iError
                      : Style.iInput
                  }
                >
                  <option value="" disabled>
                    Select Type *
                  </option>
                  <option value="HR">HR</option>
                  <option value="Technical">Tech</option>
                </select>

                {formik.touched.type && formik.errors.type ? (
                  <div className="error">{formik.errors.type}</div>
                ) : null}
              </div>
              <div className="my-2 ">
                <label className={Style.label}>
                  Designation<span title="required">*</span>
                </label>
                <input
                  placeholder="Designation"
                  type="text"
                  id="designation"
                  name="designation"
                  value={formik.values.designation}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  className={
                    formik.touched.designation && formik.errors.designation
                      ? Style.iError
                      : Style.iInput
                  }
                ></input>
                {formik.touched.designation && formik.errors.designation ? (
                  <div className="error">{formik.errors.designation}</div>
                ) : null}
              </div>
              <div className="my-2 ">
                <label className={Style.label}>
                  Select Experience<span title="required">*</span>
                </label>
                <div className="flex flex-row grid grid-cols-2 gap-2">
                  <div>
                    <select
                      id="experience"
                      name="experience"
                      value={formik.values.experience}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      className={
                        formik.touched.experience && formik.errors.experience
                          ? Style.iError
                          : Style.iInput
                      }
                    >
                      <option value="" disabled>
                        Select Year *
                      </option>
                      <option value="1">1 year</option>
                      {year_options.map((number) => (
                        <option key={number} value={number}>
                          {number} years
                        </option>
                      ))}
                    </select>

                    {formik.touched.experience && formik.errors.experience ? (
                      <div className="error">{formik.errors.experience}</div>
                    ) : null}
                  </div>
                  <div>
                    <select
                      id="exp_month"
                      name="exp_month"
                      value={formik.values.exp_month}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      className={
                        formik.touched.exp_month && formik.errors.exp_month
                          ? Style.iError
                          : Style.iInput
                      }
                    >
                      <option value="" disabled>
                        Select Month *
                      </option>
                      <option value="0">0 month</option>
                      <option value="1">1 month</option>
                      {month_options.map((number) => (
                        <option key={number} value={number}>
                          {number} months
                        </option>
                      ))}
                    </select>

                    {formik.touched.exp_month && formik.errors.exp_month ? (
                      <div className="error">{formik.errors.exp_month}</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="my-2">
                <label className={Style.label}>
                  Skill Sets<span title="required">*</span>
                </label>
                <Multiselect
                  options={skillList}
                  selectedValues={primary}
                  onSelect={(selectedList, _) => {
                    var data: any = [];
                    selectedList.map((e: any) => {
                      return data.push({
                        id: e["id"],
                        name: e["name"],
                        rating: 0,
                      });
                    });
                    setPrimary(data);
                    formik.values.skill = data;
                    setPrimaryError("");
                  }}
                  onRemove={(selectedList, _) => {
                    var data: any = [];
                    selectedList.map((e: any) => {
                      return data.push({
                        id: e["id"],
                        name: e["name"],
                        rating: 0,
                      });
                    });
                    setPrimaryError("");
                    setPrimary(data);
                    formik.values.skill = data;
                  }}
                  displayValue="name"
                ></Multiselect>
                {primary_error !== "" ? (
                  <div className="error">Please select the skills</div>
                ) : null}
                <div>
                  {primary.map((val: any) => (
                    <div className="gap-2 grid grid-cols-2 my-2 ">
                      <label className="text-xs font-normal text-gray-600 py-2">
                        {val["name"]}
                      </label>
                      <div>
                        <StarRatings
                          rating={val["rating"]}
                          changeRating={(rate: number) => {
                            val["rating"] = rate;
                            setPrimary((prevItems: any) =>
                              prevItems.map((item: any) =>
                                item.id === val["id"]
                                  ? { ...item, rating: rate }
                                  : item
                              )
                            );
                          }}
                          numberOfStars={5}
                          name="rating"
                          starRatedColor="blue"
                          starHoverColor="blue"
                          starDimension="18px"
                          starSpacing="5px"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="my-2">
                <label className={Style.label}>
                  Upload Resume
                  {localStorage.getItem("interviewer_type") !== "in-house" && (
                    <span title="required">*</span>
                  )}
                </label>
                <div className="flex items-center w-full border-primary rounded ">
                  <div className="cursor-pointer w-full">
                    <label className="cursor-pointer w-full flex justify-start">
                      {selectedFile && (
                        <div className="flex justify-center items-center border-r overflow-hidden font-semibold text-xs p-2 bg-primary text-white w-2/4">
                          <BsUpload color="white" size={10} /> &nbsp; Change
                        </div>
                      )}
                      {!selectedFile && (
                        <div className="flex justify-center items-center border-r  overflow-hidden font-semibold text-xs p-2 bg-primary text-white w-2/4">
                          <BsUpload color="white" size={10} /> &nbsp; Upload
                        </div>
                      )}
                      <div className="flex justify-start items-center w-2/4">
                        &nbsp;
                        {selectedFile && (
                          <div className="text-sm px-3 text-gray-400 truncate">
                            {selectedFile?.name}
                          </div>
                        )}
                        <input
                          type="file"
                          className="hidden"
                          onChange={handleImageChange}
                          accept=".pdf, .docx"
                        ></input>
                      </div>
                    </label>
                  </div>
                </div>
                {fileError !== "" ? (
                  <div className="error mb-2">{fileError}</div>
                ) : null}
                <div
                  className="mt-1 text-xs text-gray-500"
                  id="user_avatar_help"
                >
                  Supported format (.pdf, .docx), Size (Max 3MB)
                </div>
              </div>
              <div className="my-2">
                <label className={Style.label}>Upload Intro Video</label>
                <div className="flex items-center w-full border-primary rounded ">
                  <div className="cursor-pointer w-full">
                    <label className="cursor-pointer w-full flex justify-start">
                      {selectedVideoFile && (
                        <div className="flex justify-center items-center border-r overflow-hidden font-semibold text-xs p-2 bg-primary text-white w-2/4">
                          <BsUpload color="white" size={10} /> &nbsp; Change
                        </div>
                      )}
                      {!selectedVideoFile && (
                        <div className="flex justify-center items-center border-r  overflow-hidden font-semibold text-xs p-2 bg-primary text-white w-2/4">
                          <BsUpload color="white" size={10} /> &nbsp; Upload
                        </div>
                      )}
                      <div className="flex justify-start items-center w-2/4">
                        &nbsp;
                        {selectedVideoFile && (
                          <div className="text-sm px-3 text-gray-400 truncate">
                            {selectedVideoFile?.name}
                          </div>
                        )}
                        <input
                          type="file"
                          className="hidden"
                          onChange={handleVideoChange}
                          accept=".mp4"
                        ></input>
                      </div>
                    </label>
                  </div>
                </div>
                {videoFileError !== "" ? (
                  <div className="error mb-2">{videoFileError}</div>
                ) : null}
                <div
                  className="mt-1 text-xs text-gray-500"
                  id="user_avatar_help"
                >
                  Supported format (.mp4), Size (Max 2MB)
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="button-primary text-sm text-white font-semibold rounded-md py-2 px-4 w-full mt-4"
            >
              Submit
            </button>
          </form>
        </div>
        <div className="w-1/2 h-screen hidden lg:block">
          <div className="onboard-box">
            <Carousel slides={slides.slides} />
          </div>
        </div>
      </div>
    </>
  );
};

export default InterviewerOnboarding;
