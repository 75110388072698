import React from "react";
import { useParams } from "react-router-dom";
import intApi from "../../../api/int_api";
import MeetingChat from "./MeetingChat";

const ViewChat: React.FC = () => {
  const { schedule_id } = useParams();
  const [chat, setChat] = React.useState([] as any);

  React.useEffect(() => {
    getChat();
  }, []);

  const getChat = async () => {
    if (chat.length === 0) {
      const res = await intApi.getChat(schedule_id);
      setChat(res.data.message);
    }
  };

  return (
    <div className="flex flex-row justify-center w-full">
      <div className="flex flex-col gap-2 my-5 w-2/4">
        <p className="text-lg text-gray-900 font-semibold">Chat</p>
        <div
          className=" bg-gray-50 p-5  overflow-y-auto"
          style={{ height: "86vh" }}
        >
          <MeetingChat chat={chat} />
        </div>
      </div>
    </div>
  );
};
export default ViewChat;
