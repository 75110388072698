import React from "react";
import { format } from "date-fns";
import orgApi from "../../../api/org_api";
import PaginationButtons from "../../../component/PaginationButtons";
import { useNavigate } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";

const Report: React.FC = () => {
  const [schedule, setSchedule] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalPost, setTotal] = React.useState(0);
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const navigate = useNavigate();
  const [csvData, setCsvData] = React.useState([] as any);

  React.useEffect(() => {
    const fetchData = async () => {
      const page = totalPages === 0 ? 1 : Math.min(currentPage + 1, totalPages);
      const org = JSON.parse(
        localStorage.getItem("user__details")?.toString()!
      );
      const reqData = {
        page: page,
        from: from
          ? format(from, "yyyy-MM-dd 00:00:00")
          : !from && to
          ? format(to, "yyyy-MM-dd 00:00:00")
          : "",
        to: to
          ? format(to, "yyyy-MM-dd 23:59:59")
          : !to && from
          ? format(from, "yyyy-MM-dd 23:59:59")
          : "",
      };
      const res = await orgApi.scheduleReport(org.id, reqData);

      if (res.data.success) {
        setTotal(res.data.total);
        var t = Math.ceil(res.data.total / 10);
        setTotalPages(t);
        setSchedule(res.data.schedule);
      } else {
        setSchedule([]);
      }
    };
    fetchData();
  }, [currentPage, from, to]);

  const timeConvert = (t1: any, t2: any) => {
    return format(t1, "hh:mm a") + " - " + format(t2, "hh:mm a");
  };
  const handleFromDateChange = (date: any) => setFrom(date);
  const handleToDateChange = (date: any) => setTo(date);
  const clearFilter = () => {
    setFrom(null);
    setTo(null);
  };

  const exportData = async () => {
    const org = JSON.parse(localStorage.getItem("user__details")?.toString()!);
    const reqData = {
      from: from
        ? format(from, "yyyy-MM-dd 00:00:00")
        : !from && to
        ? format(to, "yyyy-MM-dd 00:00:00")
        : "",
      to: to
        ? format(to, "yyyy-MM-dd 23:59:59")
        : !to && from
        ? format(from, "yyyy-MM-dd 23:59:59")
        : "",
    };
    const res = await orgApi.exportScheduleReport(org.id, reqData);

    if (res.data.success) {
      const datas = res.data.schedule;
      const data = [];
      for (let index = 0; index < datas.length; index++) {
        const element = datas[index];
        const val = {
          JobTittle: element.post.job_title,
          OrgName: element.post.org.org_name,
          RecruiterName:
            element.post.org.first_name + "" + element.post.org.last_name,
          ClientName: element.post.client_name,
          InterviewerName:
            element.interviewer !== null
              ? element.interviewer.candidate.first_name +
                " " +
                element.interviewer.candidate.last_name
              : "",
          ScheduleStartTime: format(
            element.utc_starttime,
            "dd MMM yyyy hh:mm a"
          ),
          MeetingId: element.meeting_id,
          CandidateName:
            element.candidate.first_name + " " + element.candidate.last_name,
          CandidateEmail: element.candidate.email,
          Status: element.status,
        };
        data.push(val);
      }
      setCsvData(data);
      setTimeout(() => {
        document.getElementById("expbtn")?.click();
      }, 1000);
    }
  };

  return (
    <>
      <div className="overflow-y-auto" style={{ height: "80vh" }}>
        <h1 className="text-center font-bold">Schedule Report</h1>
        <div className="flex justify-between items-center my-2">
          <div className="flex flex-row items-center gap-2 mb-2">
            <div className="text-xs font-bold text-gray-800">Select Date</div>
            <div className="flex flex-row gap-2">
              <DatePicker
                selected={from}
                onChange={handleFromDateChange}
                dateFormat="dd MMM, yyyy"
                isClearable
                placeholderText="Select From Date"
                className="w-full border border-gray-200 py-2 px-3 focus:outline-none focus:border-gray-300 rounded"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
              <DatePicker
                selected={to}
                onChange={handleToDateChange}
                dateFormat="dd MMM, yyyy"
                isClearable
                placeholderText="Select To Date"
                className="w-full border border-gray-200 py-2 px-3 focus:outline-none focus:border-gray-300 rounded"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
              <button
                type="button"
                onClick={() => clearFilter()}
                className="text-primary text-xs font-thin bg-transparent rounded-lg text-sm w-8 h-8 ms-auto underline"
              >
                Clear
              </button>
            </div>
          </div>
          <div className="flex flex-row items-center gap-2 justify-end">
            <CSVLink
              className="bg-primary rounded px-2.5 py-1.5 text-xs text-white hidden"
              type="button"
              filename="ScheduleReport.csv"
              data={csvData}
              id="expbtn"
            >
              Export to CSV
            </CSVLink>
            <button
              className="bg-primary rounded px-2.5 py-1.5 text-xs text-white"
              type="button"
              onClick={() => exportData()}
            >
              Export to CSV
            </button>
            <button
              type="button"
              className="text-primary text-sm font-semibold py-1 px-2 flex items-center"
              onClick={() => navigate(-1)}
            >
              <BsArrowLeftShort /> Back
            </button>
          </div>
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 overflow-y-auto table-fixed">
          <thead className="text-xs text-white bg-primary">
            <tr>
              <th className="p-2">S.No</th>
              <th className="p-2">Post Title</th>
              <th className="p-2">Recruiter Name</th>
              <th className="p-2">Client Name</th>
              <th className="p-2">Status</th>
              <th className="p-2">Interviewer Name</th>
              <th className="p-2">Interview Date</th>
              <th className="p-2">Interview Time</th>
              <th className="p-2">Meeting ID</th>
              <th className="p-2">Candidate Name</th>
              <th className="p-2">Candidate Email</th>
              <th className="p-2">Resume</th>
            </tr>
          </thead>
          <tbody>
            {schedule.map((x: any, index: any) => (
              <tr className="odd:bg-white even:bg-gray-100 border-b text-gray-700">
                <th className="p-2 text-xs 2xl:text-md font-medium text-gray-700">
                  {currentPage * 10 + index + 1}
                </th>
                <td
                  className="p-2 text-xs 2xl:text-md truncate"
                  title={x.post.job_title}
                >
                  {x.post.job_title}
                </td>
                <td className="p-2 text-xs 2xl:text-md">
                  {x.org.first_name + " " + x.org.last_name}
                </td>
                <td className="p-2 text-xs 2xl:text-md">
                  {x.post.client_name ? x.post.client_name : "-"}
                </td>
                <td className="p-2 text-xs 2xl:text-md">{x.status}</td>
                <td className="p-2 text-xs 2xl:text-md">
                  {x.interviewer ? x.interviewer.candidate.first_name : "-"}
                </td>
                <td className="p-2 text-xs 2xl:text-md">
                  {format(x.utc_starttime, "yyyy-MMM-dd")}
                </td>
                <td className="p-2 text-xs 2xl:text-md">
                  {timeConvert(x.utc_starttime, x.utc_endtime)}
                </td>
                <td className="p-2 text-xs 2xl:text-md">
                  {x.meeting_id ? x.meeting_id : "-"}
                </td>
                <td className="p-2 text-xs 2xl:text-md">
                  {x.candidate.first_name}
                </td>
                <td
                  className="p-2 text-xs 2xl:text-md truncate"
                  title={x.candidate.email}
                >
                  {x.candidate.email}
                </td>
                <td className="p-2 text-xs 2xl:text-md">
                  {x.candidate.resume && x.candidate.resume.length !== 0 && (
                    <a
                      href={x.candidate.resume[0].url}
                      target="_blank"
                      className="text-xs font-normal text-blue-500"
                    >
                      Download
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {totalPost > 10 && (
          <PaginationButtons
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </>
  );
};
export default Report;
