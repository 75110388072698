import { format } from "date-fns";
import React from "react";

type ChatProps = { chat: [] };

const MeetingChat: React.FC<ChatProps> = ({ chat }: ChatProps) => {
  return (
    <>
      <div className="flex flex-col gap-2 w-full">
        {chat.map((v: any) => (
          <>
            {v.role === "2" && (
              <div className="flex flex-row justify-start">
                <p className="text-xs flex flex-col gap-1 border bg-red-50  rounded-lg mr-20">
                  <p
                    className="bg-red-500 px-1 py-0.5 rounded-br-lg rounded-tl-lg w-max text-white text-semibold"
                    style={{ fontSize: "10px" }}
                  >
                    Interviewer
                  </p>
                  <span className="text-gray-700 px-2">{v.msg}</span>
                  <span
                    style={{ fontSize: "10px" }}
                    className="text-gray-400 flex flex-row justify-end  px-2"
                  >
                    {format(v.time, "dd MMM yyyy hh:mm a")}
                  </span>
                </p>
              </div>
            )}
            {v.role === "3" && (
              <div className="flex flex-row justify-end ">
                <p className="text-xs flex flex-col gap-1 border bg-blue-50  rounded-lg ml-20">
                  <p
                    className="bg-primary px-1 py-0.5 rounded-br-lg rounded-tl-lg w-max text-white text-semibold"
                    style={{ fontSize: "10px" }}
                  >
                    Candidate
                  </p>
                  <span className="text-gray-700 px-2">{v.msg}</span>
                  <span
                    style={{ fontSize: "10px" }}
                    className="text-gray-400 flex flex-row justify-end  px-2"
                  >
                    {format(v.time, "dd MMM yyyy hh:mm a")}
                  </span>
                </p>
              </div>
            )}
          </>
        ))}
      </div>
    </>
  );
};
export default MeetingChat;
